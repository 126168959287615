@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

body {
  background: #e64040;
}

.login {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
  font-family: 'Comfortaa', cursive;
}

.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  border-radius: 10px;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
}

.form input {
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  border-radius: 5px;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: 'Comfortaa', cursive;
}

.form input:focus {
  background: #dbdbdb;
}

.form button {
  font-family: 'Comfortaa', cursive;
  text-transform: uppercase;
  outline: 0;
  background: #03C165;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}

.form button:active {
  background: #03C165;
}


.form p.error {
  margin: 0 0 10px 10px;
  text-align: left;
  font-size: 10px;
  color: red;
}

[design-system="air-3-0"],
[theme="air-2-76"],
[theme="air-3-0"],
html[theme="dark"],
html[theme="high-contrast-light"] {
  --ws-mobile-x: var(--ws-4x);
  --ws-heading: 0.5em;
  --height-base: 40px;
  --height-sm: 32px;
  --width-sm: 218px;
  --max-width-global-container: 1600px;
  --font-family-base: "Neue Montreal";
  --font-family-rebrand: Ubuntu, Ubuntu;
  --font-size-base: 16px;
  --line-height-base: 22px;
  --font-size-base-sm: 14px;
  --line-height-base-sm: 20px;
  --font-size-base-xs: 11px;
  --line-height-base-xs: 16px;
  --font-size-h1: 48px;
  --line-height-h1: 48px;
  --font-size-h2: 36px;
  --line-height-h2: 40px;
  --font-size-h3: 28px;
  --line-height-h3: 32px;
  --font-size-h4: 24px;
  --line-height-h4: 28px;
  --font-size-h5: 20px;
  --line-height-h5: 24px;
}
@media (max-width: 699px) {
  [design-system="air-3-0"],
  [theme="air-2-76"],
  [theme="air-3-0"],
  html[theme="dark"],
  html[theme="high-contrast-light"] {
      --font-size-h1: 36px;
      --line-height-h1: 40px;
      --font-size-h2: 32px;
      --line-height-h2: 36px;
      --font-size-h3: 28px;
      --line-height-h3: 32px;
  }
}
[design-system="air-3-0"],
[theme="air-2-76"],
[theme="air-3-0"],
html[theme="dark"],
html[theme="high-contrast-light"] {
  --font-weight-base: var(--font-weight-500);
  --font-weight-body: var(--font-weight-400);
  --font-weight-strong: var(--font-weight-500);
  --font-weight-heading: var(--font-weight-500);
  --font-weight-label: var(--font-weight-500);
  --font-weight-sub-label: var(--font-weight-400);
  --font-weight-rebrand: var(--font-weight-400);
  --letter-spacing-base: 0.02em;
  --letter-spacing-h1: 0;
  --letter-spacing-h2: 0.1px;
  --letter-spacing-h3: 0.2px;
  --letter-spacing-h4: 0.3px;
  --letter-spacing-h5: 0.4px;
  --letter-spacing-h1-rebrand: -2px;
  --letter-spacing-h2-rebrand: -1px;
  --letter-spacing-h3-rebrand: -0.5px;
  --letter-spacing-h4-rebrand: -0.25px;
  --letter-spacing-h5-rebrand: 0;
  --text-heading: inherit;
  --text-label: inherit;
  --text-link: var(--brand-green);
  --text-link-hover: var(--up-green);
  --text-link-navigation: var(--up-black);
  --text-link-navigation-hover: var(--up-green);
  --text-link-navigation-dark: var(--up-black);
  --text-link-navigation-dark-hover: var(--up-black);
  --text-link-navigation-light: var(--gray-45);
  --text-link-navigation-light-hover: var(--up-green);
  --text-link-navigation-selected: var(--brand-green);
  --text-link-inverse: var(--white);
  --text-link-inverse-hover: var(--opacity-white-80);
  --underline-link: underline;
  --underline-link-hover: underline;
  --border-color: var(--gray-70);
  --border: 1px solid var(--border-color);
  --border-color-inverse: var(--white);
  --border-inverse: 1px solid var(--border-color-inverse);
  --bg-info: var(--up-black);
  --text-on-info: var(--white);
  --bg-success: var(--up-green);
  --text-on-success: var(--white);
  --bg-warning: var(--yellow);
  --text-on-warning: var(--up-black);
  --bg-danger: var(--brick);
  --text-on-danger: var(--white);
  --bg-notification: var(--orange-red);
  --text-on-notification: var(--white);
  --bg-offline: var(--gray-60);
  --bg-info-light: var(--gray-95);
  --text-on-info-light: var(--up-black);
  --bg-success-light: var(--opacity-up-green-15);
  --text-on-success-light: var(--up-black);
  --bg-warning-light: var(--opacity-yellow-25);
  --text-on-warning-light: var(--up-black);
  --bg-danger-light: var(--opacity-brick-15);
  --text-on-danger-light: var(--up-black);
  --color-info: var(--up-black);
  --color-success: var(--up-green);
  --color-warning: var(--yellow);
  --color-danger: var(--brick);
  --color-notification: var(--orange-red);
  --border-width-btn: 2px;
  --bg-btn-dark: var(--brand-green);
  --bg-btn-dark-hover: var(--up-green);
  --text-on-btn-dark: var(--white);
  --text-on-btn-dark-hover: var(--white);
  --border-color-btn-dark: transparent;
  --shadow-on-btn-dark: none;
  --text-icon-on-btn-dark: inherit;
  --bg-btn-light: var(--white);
  --bg-btn-light-hover: var(--gray-95);
  --text-on-btn-light: var(--brand-green);
  --text-on-btn-light-hover: var(--brand-green);
  --border-color-btn-light: currentColor;
  --shadow-on-btn-light: none;
  --text-icon-on-btn-light: inherit;
  --bg-btn-muted: var(--white);
  --bg-btn-muted-hover: var(--gray-95);
  --text-on-btn-muted: var(--brand-green);
  --text-on-btn-muted-hover: var(--brand-green);
  --border-color-btn-muted: var(--gray-70);
  --shadow-on-btn-muted: none;
  --text-icon-on-btn-muted: inherit;
  --bg-btn-dark-inverted: var(--white);
  --bg-btn-dark-inverted-hover: var(--opacity-white-80);
  --text-on-btn-dark-inverted: var(--up-black);
  --text-on-btn-dark-inverted-hover: var(--up-black);
  --border-color-btn-dark-inverted: transparent;
  --shadow-on-btn-dark-inverted: none;
  --text-icon-on-btn-dark-inverted: inherit;
  --bg-btn-light-inverted: transparent;
  --bg-btn-light-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-light-inverted: var(--white);
  --text-on-btn-light-inverted-hover: var(--white);
  --border-color-btn-light-inverted: currentColor;
  --shadow-on-btn-light-inverted: none;
  --text-icon-on-btn-light-inverted: inherit;
  --bg-btn-muted-inverted: transparent;
  --bg-btn-muted-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-muted-inverted: var(--white);
  --text-on-btn-muted-inverted-hover: var(--white);
  --border-color-btn-muted-inverted: var(--opacity-up-black-20);
  --shadow-on-btn-muted-inverted: none;
  --text-icon-on-btn-muted-inverted: inherit;
  --bg-btn-link: transparent;
  --bg-btn-link-hover: transparent;
  --text-on-btn-link: var(--brand-green);
  --text-on-btn-link-hover: var(--up-green);
  --underline-btn-link: none;
  --underline-btn-link-hover: none;
  --bg-btn-circle: transparent;
  --bg-btn-circle-hover: var(--gray-90);
  --text-on-btn-circle: var(--brand-green);
  --text-on-btn-circle-hover: var(--brand-green);
  --bg-btn-circle-inverted: transparent;
  --bg-btn-circle-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-circle-inverted: var(--white);
  --text-on-btn-circle-inverted-hover: var(--white);
  --text-on-btn-close: var(--up-black);
  --text-on-btn-close-hover: var(--up-black);
  --text-inverse-on-btn-close: var(--white);
  --bg-btn-group: var(--white);
  --bg-btn-group-hover: var(--gray-95);
  --border-color-btn-group: var(--gray-70);
  --border-color-btn-group-selected: var(--gray-70);
  --border-width-btn-group: 2px;
  --shadow-on-btn-group: none;
  --bg-btn-group-btn: var(--white);
  --bg-btn-group-btn-hover: var(--gray-95);
  --bg-btn-group-btn-selected: var(--brand-green);
  --bg-btn-group-btn-selected-hover: var(--up-green);
  --text-on-btn-group-btn: var(--brand-green);
  --text-on-btn-group-btn-hover: var(--brand-green);
  --text-on-btn-group-btn-selected: var(--white);
  --bg-btn-check-light: var(--white);
  --bg-btn-check-light-hover: var(--gray-95);
  --bg-btn-check-light-selected: var(--brand-green);
  --bg-btn-check-light-selected-hover: var(--up-green);
  --text-on-btn-check-light: var(--brand-green);
  --text-on-btn-check-light-selected: var(--white);
  --border-color-btn-check-light: var(--gray-70);
  --border-color-btn-check-light-selected: transparent;
  --border-width-btn-check-light: 2px;
  --text-icon-on-btn-check-light: inherit;
  --bg-btn-check-dark: var(--white);
  --bg-btn-check-dark-hover: var(--white);
  --bg-btn-check-dark-selected: var(--brand-green);
  --bg-btn-check-dark-selected-hover: var(--up-green);
  --text-on-btn-check-dark: var(--up-black);
  --text-on-btn-check-dark-hover: var(--up-green);
  --text-on-btn-check-dark-selected: var(--white);
  --text-on-btn-check-dark-selected-hover: var(--white);
  --border-color-btn-check-dark: var(--up-black);
  --border-color-btn-check-dark-hover: var(--up-green);
  --border-color-btn-check-dark-selected: var(--brand-green);
  --border-color-btn-check-dark-selected-hover: var(--up-green);
  --border-width-btn-check-dark: 2px;
  --bg-btn-check-group: var(--white);
  --bg-btn-check-group-hover: var(--gray-95);
  --bg-btn-check-group-selected: var(--gray-95);
  --text-dark-on-btn-check-group: var(--up-black);
  --text-light-on-btn-check-group: var(--gray-45);
  --text-dark-on-btn-check-group-selected: var(--up-black);
  --text-light-on-btn-check-group-selected: var(--gray-45);
  --border-color-btn-check-group: var(--gray-70);
  --border-color-btn-check-group-hover: var(--brand-green);
  --border-color-btn-check-group-selected: var(--brand-green);
  --border-width-btn-check-group: 2px;
  --bg-control: var(--white);
  --text-dark-on-control: var(--up-black);
  --text-light-on-control: var(--gray-45);
  --text-muted-on-control: var(--gray-60);
  --text-icon-on-control: var(--up-black);
  --text-icon-on-control-hover: var(--up-black);
  --border-color-control: var(--gray-80);
  --border-color-control-hover: var(--opacity-gray-80);
  --border-color-control-selected: var(--gray-55);
  --border-width-control: 2px;
  --bg-control-muted: var(--white);
  --bg-control-muted-hover-dark: var(--gray-80);
  --bg-control-muted-hover-light: var(--gray-95);
  --text-dark-on-control-muted: var(--up-black);
  --text-light-on-control-muted: var(--gray-45);
  --border-color-control-muted: var(--gray-80);
  --border-color-control-muted-selected: var(--gray-55);
  --border-width-control-muted: 2px;
  --bg-control-check: var(--white);
  --bg-control-check-hover: var(--white);
  --bg-control-check-selected: var(--brand-green);
  --bg-control-check-selected-hover: var(--up-green);
  --border-color-control-check: var(--gray-60);
  --border-color-control-check-hover: var(--gray-55);
  --border-color-control-check-selected: var(--brand-green);
  --border-color-control-check-selected-hover: var(--up-green);
  --border-width-control-check: 2px;
  --text-icon-on-control-check: var(--white);
  --bg-hoverable-light: var(--white);
  --bg-hoverable-light-hover: var(--gray-90);
  --bg-on-hoverable-light: var(--gray-80);
  --text-dark-on-hoverable-light: var(--up-black);
  --text-light-on-hoverable-light: var(--gray-45);
  --text-accent-on-hoverable-light: var(--brand-green);
  --bg-hoverable-dark: var(--white);
  --bg-hoverable-dark-hover: var(--gray-95);
  --text-dark-on-hoverable-dark: var(--up-black);
  --text-light-on-hoverable-dark: var(--gray-45);
  --text-accent-on-hoverable-dark: var(--brand-green);
  --bg-interactive-muted-light: var(--gray-95);
  --bg-interactive-muted-light-hover: var(--gray-90);
  --text-dark-on-interactive-muted-light: var(--up-black);
  --text-light-on-interactive-muted-light: var(--gray-45);
  --bg-accent-interactive: var(--brand-green);
  --text-on-accent-interactive: var(--white);
  --bg-selected: var(--brand-green);
  --bg-selected-hover: var(--up-green);
  --text-on-selected: var(--white);
  --text-selected: var(--brand-green);
  --bg-body: var(--white);
  --text-dark-on-body: var(--up-black);
  --text-light-on-body: var(--gray-45);
  --text-accent-on-body: var(--up-green);
  --text-muted-on-body: var(--gray-60);
  --bg-inverse: var(--white);
  --text-dark-on-inverse: var(--up-black);
  --text-light-on-inverse: var(--gray-45);
  --text-accent-on-inverse: var(--up-green);
  --text-muted-on-inverse: var(--gray-60);
  --bg-accent: var(--up-green);
  --text-on-accent: var(--white);
  --bg-dark: var(--up-black);
  --text-on-dark: var(--white);
  --bg-light: var(--gray-45);
  --text-on-light: var(--white);
  --bg-placeholder: var(--gray-55);
  --text-on-placeholder: var(--white);
  --bg-placeholder-muted-dark: var(--gray-80);
  --bg-placeholder-muted-light: var(--gray-95);
  --bg-muted-dark: var(--gray-90);
  --bg-muted-light: var(--gray-95);
  --text-dark-on-muted: var(--up-black);
  --text-light-on-muted: var(--gray-45);
  --bg-mark: var(--yellow);
  --text-on-mark: var(--up-black);
  --bg-disabled: var(--gray-80);
  --text-on-disabled: var(--gray-55);
  --bg-disabled-inverse: var(--white);
  --text-on-disabled-inverse: var(--gray-45);
  --bg-disabled-inner: var(--gray-90);
  --text-on-disabled-inner: var(--gray-45);
  --color-highlight-1: var(--blue);
  --color-highlight-2: var(--forest);
  --color-highlight-3: var(--clay);
  --color-highlight-4: var(--lilac);
  --color-highlight-5: var(--sky);
  --color-highlight-6: var(--pink);
  --color-highlight-7: var(--brown);
  --color-highlight-8: var(--yellow);
  --color-highlight-9: var(--brick);
  --color-highlight-10: var(--up-lime);
  --color-highlight-11: var(--mint);
  --color-highlight-12: var(--heather);
  --text-on-highlight: var(--white);
  --text-icon: var(--up-black);
  --text-icon-inverse: var(--white);
  --text-icon-accent: var(--up-green);
  --text-outline: var(--up-black);
  --text-outline-accent: var(--brand-green);
  --bg-backdrop: var(--up-black);
  --opacity-backdrop: 0.6;
  --list-margin: 0;
  --list-padding: 0;
  --list-style-ul: none;
  --list-style-ol: none;
  --shadow-color-popup: rgba(0, 30, 0, 0.15);
  --shadow-popup: 0 0 10px 2px rgba(0, 30, 0, 0.15);
  --shadow-interactive: 0 0 10px rgba(0, 0, 0, 0.15);
  --shadow-interactive-hover: 0 0 20px rgba(0, 0, 0, 0.2);
  --shadow-scroll: var(--transparent-white);
  --shadow-opacity-scroll: var(--transparent-white-85);
  --max-width-footer-container: var(--max-width-global-container);
  --bg-footer-icon: transparent;
  --bg-footer-icon-hover: var(--gray-55);
  --text-on-footer-icon: var(--white);
  --border-footer-icon: 1px solid var(--gray-60);
  --bg-footer: var(--up-black);
  --text-on-footer: var(--white);
  --text-footer-link: var(--white);
  --text-footer-link-hover: var(--white);
  --border-color-footer: var(--gray-55);
  --border-top-footer: 0;
  --radius-footer: var(--radius-4x);
  --bg-footer-visitor: var(--up-black);
  --text-on-footer-visitor: var(--white);
  --text-footer-visitor-link: var(--white);
  --text-footer-visitor-link-hover: var(--white);
  --border-color-footer-visitor: var(--gray-55);
  --border-top-footer-visitor: 0;
  --radius-footer-visitor: var(--radius-4x);
}
[theme="air-2-76"] {
  --font-weight-base: 400;
  --ws-heading: var(--ws-lg);
  --letter-spacing-h1: unset;
  --letter-spacing-h2: unset;
  --letter-spacing-h3: unset;
  --letter-spacing-h4: unset;
  --letter-spacing-h5: unset;
  --letter-spacing-h1-rebrand: unset;
  --letter-spacing-h2-rebrand: unset;
  --letter-spacing-h3-rebrand: unset;
  --letter-spacing-h4-rebrand: unset;
  --letter-spacing-h5-rebrand: unset;
}
html[theme="dark"],
html[theme="dark"] [design-system="air-3-0"] {
  --text-heading: inherit;
  --text-label: inherit;
  --text-link: var(--brand-green);
  --text-link-hover: var(--up-green);
  --text-link-navigation: var(--white);
  --text-link-navigation-hover: var(--up-green);
  --text-link-navigation-dark: var(--white);
  --text-link-navigation-dark-hover: var(--white);
  --text-link-navigation-light: var(--gray-50);
  --text-link-navigation-light-hover: var(--up-green);
  --text-link-navigation-selected: var(--brand-green);
  --text-link-inverse: var(--up-black-dark);
  --text-link-inverse-hover: var(--opacity-up-black-80);
  --underline-link: underline;
  --underline-link-hover: underline;
  --border-color: var(--gray-25);
  --border: 1px solid var(--border-color);
  --border-color-inverse: var(--up-black-dark);
  --border-inverse: 1px solid var(--border-color-inverse);
  --bg-info: var(--white);
  --text-on-info: var(--up-black-dark);
  --bg-sucess: var(--brand-green);
  --text-on-success: var(--white);
  --bg-warning: var(--yellow);
  --text-on-warning: var(--up-black-dark);
  --bg-danger: var(--carmine-red);
  --text-on-danger: var(--white);
  --bg-notification: var(--orange-red);
  --text-on-notification: var(--white);
  --bg-offline: var(--gray-30);
  --bg-info-light: var(--gray-10);
  --text-on-info-light: var(--white);
  --bg-success-light: var(--opacity-up-green-15);
  --text-on-success-light: var(--white);
  --bg-warning-light: var(--opacity-yellow-25);
  --text-on-warning-light: var(--white);
  --bg-danger-light: var(--opacity-carmine-red-15);
  --text-on-danger-light: var(--white);
  --color-info: var(--white);
  --color-success: var(--up-green);
  --color-warning: var(--yellow);
  --color-danger: var(--carmine-red);
  --color-notification: var(--orange-red);
  --bg-btn-dark: var(--brand-green);
  --bg-btn-dark-hover: var(--up-green);
  --text-on-btn-dark: var(--white);
  --text-on-btn-dark-hover: var(--white);
  --border-color-btn-dark: transparent;
  --shadow-on-btn-dark: none;
  --text-icon-on-btn-dark: inherit;
  --bg-btn-light: var(--up-black-dark);
  --bg-btn-light-hover: var(--gray-10);
  --text-on-btn-light: var(--brand-green);
  --text-on-btn-light-hover: var(--brand-green);
  --border-color-btn-light: currentColor;
  --shadow-on-btn-light: none;
  --text-icon-on-btn-light: inherit;
  --bg-btn-muted: var(--up-black-dark);
  --bg-btn-muted-hover: var(--gray-10);
  --text-on-btn-muted: var(--brand-green);
  --text-on-btn-muted-hover: var(--brand-green);
  --border-color-btn-muted: var(--gray-25);
  --shadow-on-btn-muted: none;
  --text-icon-on-btn-muted: inherit;
  --bg-btn-dark-inverted: var(--up-black-dark);
  --bg-btn-dark-inverted-hover: var(--opacity-up-black-80);
  --text-on-btn-dark-inverted: var(--white);
  --text-on-btn-dark-inverted-hover: var(--white);
  --border-color-btn-dark-inverted: transparent;
  --shadow-on-btn-dark-inverted: none;
  --text-icon-on-btn-dark-inverted: inherit;
  --bg-btn-light-inverted: transparent;
  --bg-btn-light-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-light-inverted: var(--up-black-dark);
  --text-on-btn-light-inverted-hover: var(--up-black-dark);
  --border-color-btn-light-inverted: currentColor;
  --shadow-on-btn-light-inverted: none;
  --text-icon-on-btn-light-inverted: inherit;
  --bg-btn-muted-inverted: transparent;
  --bg-btn-muted-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-muted-inverted: var(--up-black-dark);
  --text-on-btn-muted-inverted-hover: var(--up-black-dark);
  --border-color-btn-muted-inverted: currentColor;
  --shadow-on-btn-muted-inverted: none;
  --text-icon-on-btn-muted-inverted: inherit;
  --bg-btn-link: transparent;
  --bg-btn-link-hover: transparent;
  --text-on-btn-link: var(--brand-green);
  --text-on-btn-link-hover: var(--up-green);
  --underline-btn-link: none;
  --underline-btn-link-hover: none;
  --bg-btn-circle: transparent;
  --bg-btn-circle-hover: var(--gray-15);
  --text-on-btn-circle: var(--brand-green);
  --text-on-btn-circle-hover: var(--brand-green);
  --bg-btn-circle-inverted: transparent;
  --bg-btn-circle-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-circle-inverted: var(--up-black);
  --text-on-btn-circle-inverted-hover: var(--up-black);
  --text-on-btn-close: var(--white);
  --text-on-btn-close-hover: var(--white);
  --text-inverse-on-btn-close: var(--up-black);
  --bg-btn-group: var(--up-black-dark);
  --bg-btn-group-hover: var(--gray-10);
  --border-color-btn-group: var(--gray-25);
  --border-color-btn-group-selected: var(--gray-25);
  --border-width-btn-group: 2px;
  --shadow-on-btn-group: none;
  --bg-btn-group-btn: var(--up-black-dark);
  --bg-btn-group-btn-hover: var(--gray-10);
  --bg-btn-group-btn-selected: var(--brand-green);
  --bg-btn-group-btn-selected-hover: var(--up-green);
  --text-on-btn-group-btn: var(--brand-green);
  --text-on-btn-group-btn-hover: var(--brand-green);
  --text-on-btn-group-btn-selected: var(--white);
  --bg-btn-check-light: var(--up-black-dark);
  --bg-btn-check-light-hover: var(--gray-10);
  --bg-btn-check-light-selected: var(--brand-green);
  --bg-btn-check-light-selected-hover: var(--up-green);
  --text-on-btn-check-light: var(--brand-green);
  --text-on-btn-check-light-selected: var(--white);
  --border-color-btn-check-light: var(--gray-25);
  --border-color-btn-check-light-selected: transparent;
  --border-width-btn-check-light: 2px;
  --shadow-on-btn-check-light: none;
  --text-icon-on-btn-check-light: inherit;
  --bg-btn-check-dark: var(--up-black-dark);
  --bg-btn-check-dark-hover: var(--up-black-dark);
  --bg-btn-check-dark-selected: var(--brand-green);
  --bg-btn-check-dark-selected-hover: var(--up-green);
  --text-on-btn-check-dark: var(--white);
  --text-on-btn-check-dark-hover: var(--up-green);
  --text-on-btn-check-dark-selected: var(--up-black-dark);
  --text-on-btn-check-dark-selected-hover: var(--up-black-dark);
  --border-color-btn-check-dark: var(--white);
  --border-color-btn-check-dark-hover: var(--up-green);
  --border-color-btn-check-dark-selected: var(--brand-green);
  --border-color-btn-check-dark-selected-hover: var(--up-green);
  --border-width-btn-check-dark: 2px;
  --bg-btn-check-group: var(--up-black-dark);
  --bg-btn-check-group-hover: var(--gray-10);
  --bg-btn-check-group-selected: var(--gray-10);
  --text-dark-on-btn-check-group: var(--white);
  --text-light-on-btn-check-group: var(--gray-50);
  --text-dark-on-btn-check-group-selected: var(--white);
  --text-light-on-btn-check-group-selected: var(--gray-50);
  --border-color-btn-check-group: var(--gray-25);
  --border-color-btn-check-group-hover: var(--brand-green);
  --border-color-btn-check-group-selected: var(--brand-green);
  --border-width-btn-check-group: 2px;
  --bg-control: var(--up-black-dark);
  --text-dark-on-control: var(--white);
  --text-light-on-control: var(--gray-50);
  --text-muted-on-control: var(--gray-30);
  --text-icon-on-control: var(--white);
  --text-icon-on-control-hover: var(--white);
  --border-color-control: var(--gray-20);
  --border-color-control-hover: var(--gray-40);
  --border-color-control-selected: var(--opacity-gray-80);
  --border-width-control: 2px;
  --bg-control-muted: var(--up-black-dark);
  --bg-control-muted-hover-dark: var(--gray-20);
  --bg-control-muted-hover-light: var(--gray-10);
  --text-dark-on-control-muted: var(--white);
  --text-light-on-control-muted: var(--gray-50);
  --border-color-control-muted: var(--gray-20);
  --border-color-control-muted-selected: var(--gray-40);
  --border-width-control-muted: 2px;
  --bg-control-check: var(--up-black-dark);
  --bg-control-check-hover: var(--up-black-dark);
  --bg-control-check-selected: var(--brand-green);
  --bg-control-check-selected-hover: var(--up-green);
  --border-color-control-check: var(--gray-30);
  --border-color-control-check-hover: var(--gray-40);
  --border-color-control-check-selected: var(--brand-green);
  --border-color-control-check-selected-hover: var(--up-green);
  --border-width-control-check: 2px;
  --text-icon-on-control-check: var(--up-black-dark);
  --bg-hoverable-light: var(--up-black-dark);
  --bg-hoverable-light-hover: var(--gray-15);
  --bg-on-hoverable-light: var(--gray-80);
  --text-dark-on-hoverable-light: var(--white);
  --text-light-on-hoverable-light: var(--gray-50);
  --text-accent-on-hoverable-light: var(--brand-green);
  --bg-hoverable-dark: var(--up-black-dark);
  --bg-hoverable-dark-hover: var(--gray-15);
  --text-dark-on-hoverable-dark: var(--white);
  --text-light-on-hoverable-dark: var(--gray-50);
  --text-accent-on-hoverable-dark: var(--brand-green);
  --bg-interactive-muted-light: var(--gray-10);
  --bg-interactive-muted-light-hover: var(--gray-15);
  --text-dark-on-interactive-muted-light: var(--white);
  --text-light-on-interactive-muted-light: var(--gray-50);
  --bg-accent-interactive: var(--brand-green);
  --text-on-accent-interactive: var(--white);
  --bg-selected: var(--brand-green);
  --bg-selected-hover: var(--up-green);
  --text-on-selected: var(--white);
  --text-selected: var(--brand-green);
  --bg-body: var(--up-black-dark);
  --text-dark-on-body: var(--white);
  --text-light-on-body: var(--gray-50);
  --text-accent-on-body: var(--up-green);
  --text-muted-on-body: var(--gray-30);
  --bg-inverse: var(--up-black-dark);
  --bg-inverse-hover: var(--opacity-up-black-80);
  --text-dark-on-inverse: var(--white);
  --text-light-on-inverse: var(--gray-50);
  --text-accent-on-inverse: var(--up-green);
  --text-muted-on-inverse: var(--gray-30);
  --bg-accent: var(--up-green);
  --text-on-accent: var(--white);
  --bg-dark: var(--white);
  --text-on-dark: var(--up-black-dark);
  --bg-light: var(--gray-50);
  --text-on-light: var(--up-black-dark);
  --bg-placeholder: var(--gray-40);
  --text-on-placeholder: var(--up-black-dark);
  --bg-placeholder-muted-dark: var(--gray-20);
  --bg-placeholder-muted-light: var(--gray-10);
  --bg-muted-dark: var(--gray-20);
  --bg-muted-light: var(--gray-10);
  --text-dark-on-muted: var(--white);
  --text-light-on-muted: var(--gray-50);
  --bg-mark: var(--yellow);
  --text-on-mark: var(--up-black);
  --bg-disabled: var(--gray-20);
  --text-on-disabled: var(--gray-30);
  --bg-disabled-inverted: var(--white);
  --text-on-disabled-inverse: var(--gray-50);
  --bg-disabled-inner: var(--gray-15);
  --text-on-disabled-inner: var(--gray-50);
  --color-highlight-1: var(--cyan-blue);
  --color-highlight-2: var(--forest);
  --color-highlight-3: var(--clay);
  --color-highlight-4: var(--lilac);
  --color-highlight-5: var(--sky);
  --color-highlight-6: var(--pink);
  --color-highlight-7: var(--brown);
  --color-highlight-8: var(--yellow);
  --color-highlight-9: var(--carmine-red);
  --color-highlight-10: var(--up-lime);
  --color-highlight-11: var(--mint);
  --color-highlight-12: var(--heather);
  --text-on-highlight: var(--white);
  --text-icon: var(--white);
  --text-icon-inverse: var(--up-black-dark);
  --text-icon-accent: var(--up-green);
  --text-outline: var(--up-black-dark);
  --text-outline-accent: var(--brand-green);
  --bg-backdrop: var(--white);
  --opacity-backdrop: 0.6;
  --shadow-color-popup: rgba(0, 30, 0, 0.15);
  --shadow-popup: 0 0 10px 2px hsla(0, 0%, 100%, 0.15);
  --shadow-interactive: 0 0 10px hsla(0, 0%, 100%, 0.15);
  --shadow-interactive-hover: 0 0 20px hsla(0, 0%, 100%, 0.2);
  --shadow-scroll: var(--transparent-black);
  --shadow-opacity-scroll: var(--transparent-black-85);
  --max-width-footer-container: none;
  --bg-footer-icon: transparent;
  --bg-footer-icon-hover: var(--gray-55);
  --text-on-footer-icon: var(--white);
  --border-footer-icon: 1px solid var(--gray-60);
  --bg-footer: var(--up-black-dark);
  --text-on-footer: var(--white);
  --text-on-link-footer: var(--white);
  --text-on-link-footer-hover: var(--white);
  --border-color-footer: var(--gray-20);
  --border-top-footer: 1px solid var(--border-color-footer);
  --radius-footer: 0;
  --bg-footer-visitor: var(--up-black-dark);
  --text-on-footer-visitor: var(--white);
  --text-link-footer-visitor: var(--white);
  --text-link-footer-visitor-hover: var(--white);
  --border-color-footer-visitor: var(--gray-20);
  --border-top-footer-visitor: 1px solid var(--border-color-footer-visitor);
  --radius-footer-visitor: 0;
}
html[theme="high-contrast-light"],
html[theme="high-contrast-light"] [design-system="air-3-0"] {
  --text-heading: inherit;
  --text-label: inherit;
  --text-link: var(--brand-green-hc);
  --text-link-hover: var(--up-black);
  --text-link-navigation: var(--up-black);
  --text-link-navigation-hover: var(--up-green-hc);
  --text-link-navigation-dark: var(--up-black);
  --text-link-navigation-dark-hover: var(--up-black);
  --text-link-navigation-light: var(--up-black);
  --text-link-navigation-light-hover: var(--up-green-hc);
  --text-link-navigation-selected: var(--brand-green-hc);
  --text-link-inverse: var(--white);
  --text-link-inverse-hover: var(--opacity-white-80);
  --underline-link: underline;
  --underline-link-hover: underline;
  --border-color: var(--gray-70);
  --border: 1px solid var(--border-color);
  --border-color-inverse: var(--white);
  --border-inverse: 1px solid var(--border-color-inverse);
  --bg-info: var(--up-black);
  --text-on-info: var(--white);
  --bg-success: var(--up-green-hc);
  --text-on-success: var(--white);
  --bg-warning: var(--yellow);
  --text-on-warning: var(--up-black);
  --bg-danger: var(--brick);
  --text-on-danger: var(--white);
  --bg-notification: var(--orange-red-hc);
  --text-on-notification: var(--white);
  --bg-offline: var(--gray-60);
  --bg-info-light: var(--gray-95);
  --text-on-info-light: var(--up-black);
  --bg-success-light: var(--opacity-up-green-15);
  --text-on-success-light: var(--up-black);
  --bg-warning-light: var(--opacity-yellow-25);
  --text-on-warning-light: var(--up-black);
  --bg-danger-light: var(--opacity-brick-15);
  --text-on-danger-light: var(--up-black);
  --color-info: var(--up-black);
  --color-success: var(--up-green-hc);
  --color-warning: var(--yellow-hc);
  --color-danger: var(--brick);
  --color-notification: var(--orange-red-hc);
  --bg-btn-dark: var(--brand-green-hc);
  --bg-btn-dark-hover: var(--up-black);
  --text-on-btn-dark: var(--white);
  --text-on-btn-dark-hover: var(--white);
  --border-color-btn-dark: transparent;
  --shadow-on-btn-dark: none;
  --text-icon-on-btn-dark: inherit;
  --bg-btn-light: var(--white);
  --bg-btn-light-hover: var(--gray-95);
  --text-on-btn-light: var(--brand-green-hc);
  --text-on-btn-light-hover: var(--brand-green-hc);
  --border-color-btn-light: currentColor;
  --shadow-on-btn-light: none;
  --text-icon-on-btn-light: inherit;
  --bg-btn-muted: var(--white);
  --bg-btn-muted-hover: var(--gray-95);
  --text-on-btn-muted: var(--brand-green-hc);
  --text-on-btn-muted-hover: var(--brand-green-hc);
  --border-color-btn-muted: var(--brand-green-hc);
  --shadow-on-btn-muted: none;
  --text-icon-on-btn-muted: inherit;
  --bg-btn-dark-inverted: var(--white);
  --bg-btn-dark-inverted-hover: var(--opacity-white-80);
  --text-on-btn-dark-inverted: var(--up-black);
  --text-on-btn-dark-inverted-hover: var(--up-black);
  --border-color-btn-dark-inverted: transparent;
  --shadow-on-btn-dark-inverted: none;
  --text-icon-on-btn-dark-inverted: inherit;
  --bg-btn-light-inverted: transparent;
  --bg-btn-light-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-light-inverted: var(--white);
  --text-on-btn-light-inverted-hover: var(--white);
  --border-color-btn-light-inverted: currentColor;
  --shadow-on-btn-light-inverted: none;
  --text-icon-on-btn-light-inverted: inherit;
  --bg-btn-muted-inverted: transparent;
  --bg-btn-muted-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-muted-inverted: var(--white);
  --text-on-btn-muted-inverted-hover: var(--white);
  --border-color-btn-muted-inverted: currentColor;
  --shadow-on-btn-muted-inverted: none;
  --text-icon-on-btn-muted-inverted: inherit;
  --bg-btn-link: transparent;
  --bg-btn-link-hover: transparent;
  --text-on-btn-link: var(--brand-green-hc);
  --text-on-btn-link-hover: var(--up-green-hc);
  --underline-btn-link: none;
  --underline-btn-link-hover: none;
  --bg-btn-circle: transparent;
  --bg-btn-circle-hover: var(--gray-90);
  --text-on-btn-circle: var(--brand-green-hc);
  --text-on-btn-circle-hover: var(--brand-green-hc);
  --bg-btn-circle-inverted: transparent;
  --bg-btn-circle-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-circle-inverted: var(--white);
  --text-on-btn-circle-inverted-hover: var(--white);
  --text-on-btn-close: var(--up-black);
  --text-on-btn-close-hover: var(--up-black);
  --text-inverse-on-btn-close: var(--white);
  --bg-btn-group: var(--white);
  --bg-btn-group-hover: var(--gray-95);
  --border-color-btn-group: var(--gray-70);
  --border-color-btn-group-selected: var(--gray-70);
  --border-width-btn-group: 2px;
  --shadow-on-btn-group: none;
  --bg-btn-group-btn: var(--white);
  --bg-btn-group-btn-hover: var(--gray-95);
  --bg-btn-group-btn-selected: var(--brand-green-hc);
  --bg-btn-group-btn-selected-hover: var(--up-black);
  --text-on-btn-group-btn: var(--brand-green-hc);
  --text-on-btn-group-btn-hover: var(--brand-green-hc);
  --text-on-btn-group-btn-selected: var(--white);
  --bg-btn-check-light: var(--white);
  --bg-btn-check-light-hover: var(--gray-95);
  --bg-btn-check-light-selected: var(--brand-green-hc);
  --bg-btn-check-light-selected-hover: var(--up-black);
  --text-on-btn-check-light: var(--brand-green-hc);
  --text-on-btn-check-light-selected: var(--white);
  --border-color-btn-check-light: var(--gray-70);
  --border-color-btn-check-light-selected: transparent;
  --border-width-btn-check-light: 2px;
  --text-icon-on-btn-check-light: inherit;
  --bg-btn-check-dark: var(--white);
  --bg-btn-check-dark-hover: var(--white);
  --bg-btn-check-dark-selected: var(--brand-green-hc);
  --bg-btn-check-dark-selected-hover: var(--up-green-hc);
  --text-on-btn-check-dark: var(--up-black);
  --text-on-btn-check-dark-hover: var(--up-black);
  --text-on-btn-check-dark-selected: var(--white);
  --text-on-btn-check-dark-selected-hover: var(--white);
  --border-color-btn-check-dark: var(--up-black);
  --border-color-btn-check-dark-hover: var(--up-black);
  --border-color-btn-check-dark-selected: var(--brand-green-hc);
  --border-color-btn-check-dark-selected-hover: var(--up-black);
  --border-width-btn-check-dark: 2px;
  --bg-btn-check-group: var(--white);
  --bg-btn-check-group-hover: var(--gray-95);
  --bg-btn-check-group-selected: var(--gray-95);
  --text-dark-on-btn-check-group: var(--up-black);
  --text-light-on-btn-check-group: var(--up-black);
  --text-dark-on-btn-check-group-selected: var(--up-black);
  --text-light-on-btn-check-group-selected: var(--up-black);
  --border-color-btn-check-group: var(--up-black);
  --border-color-btn-check-group-hover: var(--brand-green-hc);
  --border-color-btn-check-group-selected: var(--brand-green-hc);
  --border-width-btn-check-group: 2px;
  --bg-control: var(--white);
  --text-dark-on-control: var(--up-black);
  --text-light-on-control: var(--up-black);
  --text-muted-on-control: var(--gray-45);
  --text-icon-on-control: var(--up-black);
  --text-icon-on-control-hover: var(--up-black);
  --border-color-control: var(--up-black);
  --border-color-control-hover: var(--brand-green-hc);
  --border-color-control-selected: var(--brand-green-hc);
  --border-width-control: 2px;
  --bg-control-muted: var(--white);
  --bg-control-muted-hover-dark: var(--gray-80);
  --bg-control-muted-hover-light: var(--gray-95);
  --text-dark-on-control-muted: var(--up-black);
  --text-light-on-control-muted: var(--up-black);
  --border-color-control-muted: var(--up-black);
  --border-color-control-muted-selected: var(--brand-green-hc);
  --border-width-control-muted: 2px;
  --bg-control-check: var(--white);
  --bg-control-check-hover: var(--white);
  --bg-control-check-selected: var(--brand-green-hc);
  --bg-control-check-selected-hover: var(--up-black);
  --border-color-control-check: var(--up-black);
  --border-color-control-check-hover: var(--up-black);
  --border-color-control-check-selected: var(--brand-green-hc);
  --border-color-control-check-selected-hover: var(--up-black);
  --border-width-control-check: 2px;
  --text-icon-on-control-check: var(--white);
  --bg-hoverable-light: var(--white);
  --bg-hoverable-light-hover: var(--gray-90);
  --bg-on-hoverable-light: var(--gray-80);
  --text-dark-on-hoverable-light: var(--up-black);
  --text-light-on-hoverable-light: var(--gray-45);
  --text-accent-on-hoverable-light: var(--brand-green-hc);
  --bg-hoverable-dark: var(--white);
  --bg-hoverable-dark-hover: var(--gray-95);
  --text-dark-on-hoverable-dark: var(--up-black);
  --text-light-on-hoverable-dark: var(--up-black);
  --text-accent-on-hoverable-dark: var(--brand-green-hc);
  --bg-interactive-muted-light: var(--gray-95);
  --bg-interactive-muted-light-hover: var(--gray-90);
  --text-dark-on-interactive-muted-light: var(--up-black);
  --text-light-on-interactive-muted-light: var(--up-black);
  --bg-accent-interactive: var(--brand-green-hc);
  --text-on-accent-interactive: var(--white);
  --bg-selected: var(--brand-green-hc);
  --bg-selected-hover: var(--up-black);
  --text-on-selected: var(--white);
  --text-selected: var(--brand-green-hc);
  --bg-body: var(--white);
  --text-dark-on-body: var(--up-black);
  --text-light-on-body: var(--up-black);
  --text-accent-on-body: var(--up-green-hc);
  --text-muted-on-body: var(--gray-45);
  --bg-inverse: var(--white);
  --text-dark-on-inverse: var(--up-black);
  --text-light-on-inverse: var(--up-black);
  --text-accent-on-inverse: var(--up-green-hc);
  --text-muted-on-inverse: var(--gray-45);
  --bg-accent: var(--up-green-hc);
  --text-on-accent: var(--white);
  --bg-dark: var(--up-black);
  --text-on-dark: var(--white);
  --bg-light: var(--up-black);
  --text-on-light: var(--white);
  --bg-placeholder: var(--gray-55);
  --text-on-placeholder: var(--white);
  --bg-placeholder-muted-dark: var(--gray-80);
  --bg-placeholder-muted-light: var(--gray-95);
  --bg-muted-dark: var(--gray-50);
  --bg-muted-light: var(--gray-95);
  --text-dark-on-muted: var(--up-black);
  --text-light-on-muted: var(--up-black);
  --bg-mark: var(--yellow);
  --text-on-mark: var(--up-black);
  --bg-disabled: var(--gray-80);
  --text-on-disabled: var(--up-black);
  --bg-disabled-inverse: var(--white);
  --text-on-disabled-inverse: var(--up-black);
  --bg-disabled-inner: var(--gray-90);
  --text-on-disabled-inner: var(--up-black);
  --color-highlight-1: var(--blue-hc);
  --color-highlight-2: var(--forest);
  --color-highlight-3: var(--clay-hc);
  --color-highlight-4: var(--lilac-hc);
  --color-highlight-5: var(--sky-hc);
  --color-highlight-6: var(--pink-hc);
  --color-highlight-7: var(--brown-hc);
  --color-highlight-8: var(--yellow-hc);
  --color-highlight-9: var(--brick);
  --color-highlight-10: var(--up-lime-hc);
  --color-highlight-11: var(--mint-hc);
  --color-highlight-12: var(--heather-hc);
  --text-on-highlight: var(--white);
  --text-icon: var(--up-black);
  --text-icon-inverse: var(--white);
  --text-icon-accent: var(--up-green-hc);
  --text-outline: var(--up-black);
  --text-outline-accent: var(--brand-green-hc);
  --bg-backdrop: var(--up-black);
  --opacity-backdrop: 0.6;
  --shadow-color-popup: rgba(0, 30, 0, 0.15);
  --shadow-popup: 0 0 10px 2px rgba(0, 30, 0, 0.15);
  --shadow-interactive: 0 0 10px rgba(0, 0, 0, 0.15);
  --shadow-interactive-hover: 0 0 20px rgba(0, 0, 0, 0.2);
  --shadow-scroll: var(--transparent-white);
  --shadow-opacity-scroll: var(--transparent-white-85);
  --bg-footer-icon: transparent;
  --bg-footer-icon-hover: var(--gray-45);
  --text-on-footer-icon: var(--white);
  --border-footer-icon: 1px solid var(--gray-60);
  --bg-footer: var(--up-black);
  --text-on-footer: var(--white);
  --text-footer-link: var(--white);
  --text-footer-link-hover: var(--white);
  --border-color-footer: var(--gray-55);
  --bg-footer-visitor: var(--up-black);
  --text-on-footer-visitor: var(--white);
  --text-footer-visitor-link: var(--white);
  --text-footer-visitor-link-hover: var(--white);
  --border-color-footer-visitor: var(--gray-55);
}
:root {
  --ws-xs: 4px;
  --ws-sm: 8px;
  --ws-smd: 12px;
  --ws-md: 16px;
  --ws-lg: 24px;
  --ws-xl: 32px;
  --ws-2xl: 48px;
  --ws-3xl: 56px;
  --ws-4xl: 64px;
  --ws-5xl: 80px;
  --ws-6xl: 96px;
  --radius: 2px;
  --radius-sm: 4px;
  --radius-md: 6px;
  --radius-lg: 8px;
  --radius-xl: 16px;
  --radius-2xl: 24px;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-black: 900;
  --card-section-padding: 25px;
  --checkbox-color-active: var(--text-disabled);
  --checkbox-helper-size: 16px;
  --strong-weight: var(--font-weight-medium);
  --heading-weight: var(--font-weight-medium);
  --heading-spacing: 0.5em;
  --success-color: var(--up-green);
  --warning-color: var(--yellow);
  --danger-color: var(--brick);
  --gray-01: #5e6d55;
  --gray-02: #9aaa97;
  --gray-03: #beccbe;
  --gray-04: #d5e0d5;
  --gray-05: #e4ebe4;
  --gray-06: #f2f7f2;
  --gray-07: #f7faf7;
  --gray-1: var(--gray-01);
  --gray-2: var(--gray-02);
  --gray-3: var(--gray-03);
  --gray-4: var(--gray-04);
  --gray-5: var(--gray-05);
  --gray-6: var(--gray-06);
  --gray-7: var(--gray-07);
  --text-dark: var(--up-black);
  --text-light: var(--gray-1);
  --text-disabled: var(--gray-3);
  --text-muted: var(--gray-3);
  --text-inverse: var(--white);
  --bg-1: var(--gray-1);
  --bg-2: var(--gray-2);
  --bg-3: var(--gray-3);
  --bg-4: var(--gray-4);
  --bg-5: var(--gray-5);
  --bg-6: var(--gray-6);
  --bg-7: var(--gray-7);
  --body-bg: var(--white);
  --shadow-color: 57, 73, 76;
  --shadow-color-active: rgba(var(--shadow-color), 0.25);
  --drop-shadow: none;
  --drop-shadow-active: none;
  --pop-shadow: 0 0 10px 2px rgba(0, 30, 0, 0.15);
  --dialog-shadow: 0 2px 7px rgba(var(--shadow-color), 0.45);
  --backdrop-opacity: 0.3;
  --backdrop: var(--up-black);
  --border-base-color: var(--gray-4);
  --border-base-alt-color: var(--gray-5);
  --border-base: 1px solid var(--border-base-color);
  --border-base-alt: 1px solid var(--border-base-alt-color);
  --primary-color-icon: var(--text-dark);
  --primary-bg-hover: var(--bg-5);
  --primary-color-hover: var(--text-dark);
  --primary-color-active: var(--text-dark);
  --primary-weight-active: var(--font-weight-medium);
  --primary-bg-highlight: var(--bg-inverse);
  --gray-05-h: 120;
  --gray-05-s: 15%;
  --gray-05-l: 91%;
  --brand-green-hsl: 113, 100%, 27%;
  --primary-dark-hover: hsla(var(--up-black-hsl), 80%);
  --primary-light-hover: hsla(var(--white-hsl), 80%);
  --secondary-dark-hover: hsla(var(--up-black-hsl), 20%);
  --secondary-light-hover: hsla(var(--up-black-hsl), 10%);
  --tertiary: var(--gray-5);
  --tertiary-hover: hsl(var(--gray-05-h), var(--gray-05-s), calc(var(--gray-05-l) - 10%));
  --link-color: var(--blue);
  --link-color-hover: hsl(var(--blue-h), var(--blue-s), calc(var(--blue-l) - 10%));
  --link-underline: underline;
  --illustration-color-1: #d5e0d5;
  --illustration-color-2: #000435;
  --illustration-color-3: #9aaa97;
  --illustration-color-4: #beccbe;
  --illustration-color-5: #00c217;
  --illustration-color-6: #95df00;
  --illustration-color-7: #000435;
  --illustration-color-8: #000435;
  --illustration-color-9: #ff4b25;
  --illustration-color-10: #debe1a;
  --illustration-color-11: #f7faf7;
  --illustration-color-12: #f2f7f2;
  --illustration-color-13: #e8f1e8;
  --illustration-color-14: #e4ebe4;
  --illustration-color-15: #9aaa97;
  --heading-letter-spacing-h1: 0;
  --heading-letter-spacing-h2: 0.003em;
  --heading-letter-spacing-h3: 0.005em;
  --heading-letter-spacing-h4: 0.01em;
  --heading-letter-spacing-h5: 0.015em;
  --heading-letter-spacing-h1-rebrand: -0.035em;
  --heading-letter-spacing-h2-rebrand: -0.03em;
  --heading-letter-spacing-h3-rebrand: -0.025em;
  --heading-letter-spacing-h4-rebrand: -0.015em;
  --heading-letter-spacing-h5-rebrand: -0.01em;
  --transparent: transparent;
  --transparent-inverse: hsla(0, 0%, 100%, 0);
  --semi-transparent-inverse: hsla(0, 0%, 100%, 0.85);
  --border-hc: 1px solid;
  --border-hc-disabled: 1px solid GrayText;
  --bg-hc-selected: Highlight;
  --bg-hc-button: ButtonFace;
  --bg-hc-base: canvas;
  --text-hc-selected: HighlightText;
  --text-hc-disabled: GrayText;
  --text-hc-link: linktext;
  --text-hc-base: canvastext;
  --text-hc-button: ButtonText;
  --ws-1x: 4px;
  --ws-2x: 8px;
  --ws-3x: 12px;
  --ws-4x: 16px;
  --ws-6x: 24px;
  --ws-8x: 32px;
  --ws-10x: 40px;
  --ws-12x: 48px;
  --ws-14x: 56px;
  --ws-16x: 64px;
  --ws-20x: 80px;
  --ws-24x: 96px;
  --radius-1x: 2px;
  --radius-2x: 4px;
  --radius-3x: 6px;
  --radius-4x: 8px;
  --radius-8x: 16px;
  --radius-12x: 24px;
  --radius-14x: 28px;
  --radius-pill: 10rem;
  --font-family-monospace: menlo, monaco, consolas, "Courier New", monospace;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-700: 700;
  --font-weight-900: 900;
  --black: #000;
  --gray-10: #1c2d1c;
  --gray-15: #1f321f;
  --gray-20: #2a3b2c;
  --gray-25: #2f3f2f;
  --gray-30: #3a4b3a;
  --gray-40: #52614f;
  --gray-45: #5e6d55;
  --gray-50: #87997d;
  --gray-55: #9aaa97;
  --gray-60: #beccbe;
  --gray-70: #d5e0d5;
  --gray-80: #e4ebe4;
  --gray-90: #f2f7f2;
  --gray-95: #f7faf7;
  --white: #fff;
  --up-green: #000435;
  --up-green-dark: #3c8224;
  --brand-green: #108a00;
  --brand-green-hc: #0b6100;
  --up-green-hc: #0c5c00;
  --up-lime: #95df00;
  --up-lime-hc: #749b2d;
  --up-black: #000435;
  --up-black-dark: #021202;
  --blue: #1f57c3;
  --blue-hc: #1c4faf;
  --cyan-blue: #557fd1;
  --mint: #000435;
  --mint-hc: #3a5648;
  --forest: #000435;
  --sky: #01cdbe;
  --sky-hc: #015c56;
  --brown: #67541f;
  --brown-hc: #5d4c1c;
  --kiwi: #97922d;
  --kiwi-hc: #535019;
  --yellow: #debe1a;
  --yellow-hc: #63560c;
  --brick: #9b211b;
  --carmine-red: #e44d45;
  --clay: #bc5118;
  --clay-hc: #8d3c13;
  --orange-red: #ff4b25;
  --orange-red-hc: #992d16;
  --pink: #f66dbc;
  --pink-hc: #853b67;
  --lilac: #bda1e7;
  --lilac-hc: #5b4d6f;
  --heather: #a18085;
  --heather-hc: #644f52;
  --opacity-up-black-80: hsla(var(--up-black-hsl), 80%);
  --opacity-up-black-20: hsla(var(--up-black-hsl), 20%);
  --opacity-up-black-10: hsla(var(--up-black-hsl), 10%);
  --opacity-white-80: hsla(var(--white-hsl), 80%);
  --opacity-gray-80: hsl(var(--gray-80-h), var(--gray-80-s), calc(var(--gray-80-l) - 10%));
  --opacity-up-green-15: hsla(var(--up-green-hsl), 15%);
  --opacity-blue: hsl(var(--blue-h), var(--blue-s), calc(var(--blue-l) - 10%));
  --opacity-cyan-blue: hsl(var(--cyan-blue-h), var(--cyan-blue-s), calc(var(--cyan-blue-l) - 10%));
  --opacity-yellow-25: hsla(var(--yellow-hsl), 25%);
  --opacity-brick-15: hsla(var(--brick-hsl), 15%);
  --opacity-carmine-red-15: hsla(var(--carmine-red-hsl), 15%);
  --blue-h: 220;
  --blue-s: 73%;
  --blue-l: 44%;
  --cyan-blue-h: 220;
  --cyan-blue-s: 57%;
  --cyan-blue-l: 58%;
  --gray-80-h: 120;
  --gray-80-s: 15%;
  --gray-80-l: 91%;
  --up-black-hsl: 120, 100%, 6%;
  --up-green-hsl: 113, 100%, 33%;
  --yellow-hsl: 50, 79%, 49%;
  --brick-hsl: 3, 70%, 36%;
  --white-hsl: 0, 0%, 100%;
  --carmine-red-hsl: 3, 75%, 58%;
  --transparent-black: transparent;
  --transparent-white: hsla(0, 0%, 100%, 0);
  --transparent-white-85: hsla(0, 0%, 100%, 0.85);
  --transparent-black-85: rgba(0, 0, 0, 0.85);
  --zindex-navbar: 1000;
  --zindex-dropdown: 1000;
  --zindex-popover: 1010;
  --zindex-tooltip: 1030;
  --zindex-navbar-fixed: 1030;
  --zindex-section-loading: 1035;
  --zindex-modal-background: 1040;
  --zindex-modal: 1050;
  --zindex-fullscreen: 1050;
  --zindex-modal-popover: 1060;
  --zindex-toaster: 1060;
}
@media (forced-colors: active) {
  :root {
      --hc-border: 1px solid;
      --hc-border-input: 1px solid ButtonText;
      --hc-border-disabled: 1px solid GrayText;
      --hc-bg-selected: Highlight;
      --hc-text-on-selected: HighlightText;
      --hc-bg-btn: ButtonFace;
      --hc-text-on-btn: ButtonText;
      --hc-bg-base: canvas;
      --hc-text-on-base: canvastext;
      --hc-text-link: linktext;
      --hc-disabled: GrayText;
      --bg-btn-dark: var(--hc-bg-selected);
      --text-on-btn-dark: var(--hc-text-on-selected);
      --border-color-btn-dark: var(--hc-bg-selected);
      --bg-btn-dark-hover: var(--bg-hc-base);
      --text-on-btn-dark-hover: var(--bg-hc-selected);
      --text-on-btn-dark-inverted-hover: var(--hc-bg-selected);
      --text-on-btn-light-hover: var(--bg-hc-selected);
      --text-on-btn-light-inverted-hover: var(--hc-bg-selected);
      --text-on-btn-muted-hover: var(--bg-hc-selected);
      --text-on-btn-link: var(--hc-text-on-btn);
      --text-on-btn-link-hover: var(--bg-hc-selected);
      --text-on-btn-circle-hover: var(--hc-bg-selected);
      --text-on-btn-circle-inverted-hover: var(--hc-bg-selected);
      --bg-btn-check-group-selected: var(--hc-bg-selected);
      --text-dark-on-btn-check-group: var(--hc-text-on-btn);
      --text-light-on-btn-check-group: var(--hc-text-on-btn);
      --text-dark-on-btn-check-group-selected: var(--hc-text-on-selected);
      --text-light-on-btn-check-group-selected: var(--hc-text-on-selected);
      --border-color-btn-check-group: var(--hc-text-on-btn);
      --border-color-btn-check-group-hover: var(--hc-bg-selected);
      --border-color-btn-check-group-selected: var(--hc-bg-selected);
      --bg-btn-check-light-hover: var(--hc-bg-selected);
      --bg-btn-check-light-selected: var(--hc-bg-selected);
      --bg-btn-check-light-selected-hover: var(--hc-bg-selected);
      --text-on-btn-check-light-selected: var(--hc-text-on-selected);
      --border-color-btn-check-dark: var(--hc-text-on-btn);
      --border-color-btn-check-dark-hover: var(--hc-bg-selected);
      --border-color-btn-check-dark-selected: var(--hc-bg-selected);
      --text-on-btn-check-dark: var(--hc-text-on-btn);
      --text-on-btn-check-dark-hover: var(--hc-bg-selected);
      --text-on-btn-check-dark-selected: var(--hc-bg-selected);
      --text-on-btn-check-dark-selected-hover: var(--hc-bg-selected);
      --border-color-btn-group: var(--hc-text-on-btn);
      --border-color-btn-group-selected: var(--hc-text-on-btn);
      --bg-btn-group-btn-hover: var(--hc-bg-selected);
      --bg-btn-group-btn-selected: var(--hc-bg-selected);
      --bg-btn-group-btn-selected-hover: var(--hc-bg-selected);
      --text-on-btn-group-btn-selected: var(--hc-text-on-selected);
      --text-link: var(--hc-text-link);
      --text-link-hover: var(--hc-text-link);
      --underline-link-hover: none;
      --border-color-inverse: var(--hc-bg-base);
      --border-color-control-check: var(--hc-text-on-base);
      --border-color-control-check-hover: var(--hc-text-on-base);
      --border-color-control: var(--hc-text-on-btn);
      --border-color-control-hover: var(--hc-text-on-btn);
      --border-color-control-selected: var(--hc-bg-selected);
      --bg-selected: var(--hc-bg-selected);
      --text-on-selected: var(--hc-text-on-selected);
      --bg-control-check-selected: var(--hc-bg-selected);
      --bg-control-check-selected-hover: var(--hc-bg-selected);
      --border-color-control-check-selected: var(--hc-bg-selected);
      --border-color-control-check-selected-hover: var(--hc-bg-selected);
      --text-icon-on-control-check: var(--hc-text-on-selected);
      --bg-hoverable-light-hover: var(--hc-bg-selected);
      --bg-hoverable-dark-hover: var(--hc-bg-selected);
      --text-link-navigation-selected: var(--hc-bg-selected);
      --bg-backdrop: var(--hc-bg-selected);
      --text-on-disabled: var(--hc-disabled);
      --text-on-disabled-inner: var(--hc-disabled);
      --text-outline: var(--hc-bg-selected);
      --text-outline-accent: var(--hc-bg-selected);
  }
}
@media (prefers-reduced-motion: reduce) {
  :root {
      --reduced-motion: none;
  }
}
*,
:after,
:before {
  box-sizing: border-box;
}
body,
html {
  height: 100%;
}
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: var(--transparent);
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  font-family: Ubuntu, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-base);
  line-height: 1.428571429;
  letter-spacing: var(--letter-spacing-base);
  background-color: #fff;
  background-color: var(--body-bg);
  color: var(--text-dark-on-body);
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}
button {
  color: inherit;
  cursor: pointer;
}
label {
  display: inline-block;
}
a {
  color: var(--text-link);
  font-weight: var(--font-weight-strong);
  -webkit-text-decoration: var(--underline-link);
  text-decoration: var(--underline-link);
  transition: color 0.1s ease-in-out;
}
@media (hover: hover) {
  a:active,
  a:focus,
  a:hover {
      -webkit-text-decoration: var(--underline-link-hover);
      text-decoration: var(--underline-link-hover);
      color: var(--text-link-hover);
  }
}
a.inverted {
  color: var(--text-link-inverse);
}
@media (hover: hover) {
  a.inverted:active,
  a.inverted:focus,
  a.inverted:hover {
      color: var(--text-link-inverse-hover);
  }
}
figure {
  margin: 0;
}
img {
  vertical-align: middle;
}
hr {
  margin-top: 18px;
  margin-bottom: 18px;
  border: 0;
  border-top: var(--border);
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
fieldset {
  border: 0;
  margin: 0;
}
fieldset,
legend {
  padding: 0;
}
mark {
  background: var(--bg-mark);
  color: var(--text-on-mark);
}
.label,
.up-label {
  display: inline-block;
  width: 100%;
  margin-bottom: 8px;
  margin-bottom: var(--ws-2x);
  font-weight: var(--font-weight-label);
  line-height: var(--line-height-base);
  font-size: var(--font-size-base);
  text-align: left;
  color: var(--text-label);
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
small {
  font-size: var(--font-size-base-xs);
  line-height: 1.454;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  word-break: break-word;
  margin: 0 0 var(--ws-heading);
  font-family: inherit;
  font-weight: var(--font-weight-heading);
  color: var(--text-heading);
}
.h1.display-rebrand,
.h2.display-rebrand,
.h3.display-rebrand,
.h4.display-rebrand,
.h5.display-rebrand,
.h6.display-rebrand,
h1.display-rebrand,
h2.display-rebrand,
h3.display-rebrand,
h4.display-rebrand,
h5.display-rebrand,
h6.display-rebrand {
  font-family: Ubuntu;
  font-weight: var(--font-weight-heading);
  font-weight: var(--font-weight-rebrand, var(--font-weight-heading));
}
.h1,
h1 {
  font-size: var(--font-size-h1);
  letter-spacing: var(--letter-spacing-h1);
  line-height: var(--line-height-h1);
}
.h1.display-rebrand,
h1.display-rebrand {
  font-size: var(--font-size-h1);
  font-size: var(--font-size-h1-rebrand, var(--font-size-h1));
  letter-spacing: var(--letter-spacing-h1-rebrand);
}
.h2,
h2 {
  font-size: var(--font-size-h2);
  letter-spacing: var(--letter-spacing-h2);
  line-height: var(--line-height-h2);
}
.h2.display-rebrand,
h2.display-rebrand {
  font-size: var(--font-size-h2);
  font-size: var(--font-size-h2-rebrand, var(--font-size-h2));
  letter-spacing: var(--letter-spacing-h2-rebrand);
}
.h3,
h3 {
  font-size: var(--font-size-h3);
  letter-spacing: var(--letter-spacing-h3);
  line-height: var(--line-height-h3);
}
.h3.display-rebrand,
h3.display-rebrand {
  letter-spacing: var(--letter-spacing-h3-rebrand);
}
.h4,
h4 {
  font-size: var(--font-size-h4);
  letter-spacing: var(--letter-spacing-h4);
  line-height: var(--line-height-h4);
}
.h4.display-rebrand,
h4.display-rebrand {
  letter-spacing: var(--letter-spacing-h4-rebrand);
}
.h5,
.h6,
h5,
h6 {
  font-size: var(--font-size-h5);
  letter-spacing: var(--letter-spacing-h5);
  line-height: var(--line-height-h5);
}
.h5.display-rebrand,
.h6.display-rebrand,
h5.display-rebrand,
h6.display-rebrand {
  letter-spacing: var(--letter-spacing-h5-rebrand);
}
cite {
  font-style: italic;
  font-size: calc(var(--font-size-base) + 5px);
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}
strong {
  font-weight: var(--font-weight-strong);
}
p {
  margin: 0 0 8px;
  margin: 0 0 var(--ws-2x);
  line-height: var(--line-height-base);
}
.break {
  word-wrap: break-word;
  word-break: break-word;
}
.nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-pre {
  white-space: pre !important;
}
.text-pre-line {
  white-space: pre-line !important;
}
code,
kbd,
pre,
samp {
  font-family: menlo, monaco, consolas, "Courier New", monospace;
  font-family: var(--font-family-monospace);
}
.text-vertical-separator {
  display: inline-block;
  position: relative;
  padding: 8px;
  padding: var(--ws-2x);
  font-size: var(--font-size-base-xs);
  color: currentColor;
}
.text-vertical-separator:after,
.text-vertical-separator:before {
  content: "";
  position: absolute;
  height: 8px;
  height: var(--ws-2x);
  border-right: 1px solid;
  left: 50%;
}
.text-vertical-separator:before {
  top: 0;
}
.text-vertical-separator:after {
  bottom: 0;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-justify {
  text-align: justify !important;
}
ul {
  list-style: var(--list-style-ul);
}
ol {
  list-style: var(--list-style-ol);
}
ol,
ul {
  margin-top: 0;
  margin-bottom: var(--list-margin);
  padding-left: var(--list-padding);
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
ol li,
ul li {
  margin-bottom: var(--list-margin);
}
.list-styled {
  padding-left: 16px;
  padding-left: var(--ws-4x);
}
.list-styled,
.list-styled li {
  margin-bottom: 8px;
  margin-bottom: var(--ws-2x);
}
ul.list-styled {
  list-style: disc;
}
ol.list-styled {
  list-style: decimal;
}
.list-inline,
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  margin-left: -4px;
  margin-left: calc(var(--ws-1x) * -1);
}
.list-inline > li {
  display: inline-block;
  padding-left: 4px;
  padding-left: var(--ws-1x);
  padding-right: 4px;
  padding-right: var(--ws-1x);
}
dl {
  margin-top: 0;
  margin-bottom: 18px;
}
dt {
  font-weight: var(--font-weight-strong);
}
dd {
  margin-left: 0;
}
abbr[title] {
  cursor: help;
  border-bottom: 1px dotted var(--border-color);
  text-decoration: none;
}
sub,
sup {
  font-size: var(--font-size-base-xs);
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.layout {
  flex: 1 0 auto;
}
.layout > .container,
.layout > .container-visitor,
.layout > main > .container,
.layout > main > .container-visitor {
  padding-top: var(--container-ws-y);
  padding-bottom: var(--container-ws-y);
}
@media (min-width: 700px) {
  .layout > .container,
  .layout > .container-visitor,
  .layout > main > .container,
  .layout > main > .container-visitor {
      --container-ws-y: var(--ws-8x);
  }
}
.container,
.container-visitor {
  --container-width: 1280px;
  --container-ws-x: var(--ws-mobile-x);
  --container-ws-y: var(--ws-mobile-x);
  position: relative;
  width: min(100%, calc(var(--container-width) + var(--container-ws-x) * 2));
  margin-left: auto;
  margin-right: auto;
  padding-right: var(--container-ws-x) !important;
  padding-left: var(--container-ws-x) !important;
}
@media (min-width: 700px) {
  .container,
  .container-visitor {
      --container-ws-x: var(--ws-8x);
  }
}
@media (min-width: 1200px) {
  .container,
  .container-visitor {
      --container-ws-x: var(--ws-20x);
  }
}
@media (max-width: 699.98px) {
  .container,
  .container-visitor {
      --card-radius-custom: 0;
  }
}
@media (min-width: 700px) {
  .container .up-card {
      margin-left: 0;
      margin-right: 0;
  }
}
@media (max-width: 699.98px) and (orientation: landscape) {
  body:not(.visitor-nav-is-open) {
      .nav-collapse {
          height: 0 !important;
          padding: 0 !important;
      }
      .navbar-cta {
          display: none !important;
      }
  }
}
#__layout,
#__nuxt {
  height: 100%;
}
[design-system="air-3-0"],
[theme="air-2-76"],
[theme="air-3-0"],
html[theme="dark"],
html[theme="high-contrast-light"] {
  --ws-mobile-x: var(--ws-4x);
  --ws-heading: 0.5em;
  --height-base: 40px;
  --height-sm: 32px;
  --width-sm: 218px;
  --max-width-global-container: 1600px;
  --font-family-base: "Neue Montreal";
  --font-family-rebrand: Ubuntu, Ubuntu;
  --font-size-base: 16px;
  --line-height-base: 22px;
  --font-size-base-sm: 14px;
  --line-height-base-sm: 20px;
  --font-size-base-xs: 11px;
  --line-height-base-xs: 16px;
  --font-size-h1: 48px;
  --line-height-h1: 48px;
  --font-size-h2: 36px;
  --line-height-h2: 40px;
  --font-size-h3: 28px;
  --line-height-h3: 32px;
  --font-size-h4: 24px;
  --line-height-h4: 28px;
  --font-size-h5: 20px;
  --line-height-h5: 24px;
}
@media (max-width: 699px) {
  [design-system="air-3-0"],
  [theme="air-2-76"],
  [theme="air-3-0"],
  html[theme="dark"],
  html[theme="high-contrast-light"] {
      --font-size-h1: 36px;
      --line-height-h1: 40px;
      --font-size-h2: 32px;
      --line-height-h2: 36px;
      --font-size-h3: 28px;
      --line-height-h3: 32px;
  }
}
[design-system="air-3-0"],
[theme="air-2-76"],
[theme="air-3-0"],
html[theme="dark"],
html[theme="high-contrast-light"] {
  --font-weight-base: var(--font-weight-500);
  --font-weight-body: var(--font-weight-400);
  --font-weight-strong: var(--font-weight-500);
  --font-weight-heading: var(--font-weight-500);
  --font-weight-label: var(--font-weight-500);
  --font-weight-sub-label: var(--font-weight-400);
  --font-weight-rebrand: var(--font-weight-400);
  --letter-spacing-base: 0.02em;
  --letter-spacing-h1: 0;
  --letter-spacing-h2: 0.1px;
  --letter-spacing-h3: 0.2px;
  --letter-spacing-h4: 0.3px;
  --letter-spacing-h5: 0.4px;
  --letter-spacing-h1-rebrand: -2px;
  --letter-spacing-h2-rebrand: -1px;
  --letter-spacing-h3-rebrand: -0.5px;
  --letter-spacing-h4-rebrand: -0.25px;
  --letter-spacing-h5-rebrand: 0;
  --text-heading: inherit;
  --text-label: inherit;
  --text-link: var(--brand-green);
  --text-link-hover: var(--up-green);
  --text-link-navigation: var(--up-black);
  --text-link-navigation-hover: var(--up-green);
  --text-link-navigation-dark: var(--up-black);
  --text-link-navigation-dark-hover: var(--up-black);
  --text-link-navigation-light: var(--gray-45);
  --text-link-navigation-light-hover: var(--up-green);
  --text-link-navigation-selected: var(--brand-green);
  --text-link-inverse: var(--white);
  --text-link-inverse-hover: var(--opacity-white-80);
  --underline-link: underline;
  --underline-link-hover: underline;
  --border-color: var(--gray-70);
  --border: 1px solid var(--border-color);
  --border-color-inverse: var(--white);
  --border-inverse: 1px solid var(--border-color-inverse);
  --bg-info: var(--up-black);
  --text-on-info: var(--white);
  --bg-success: var(--up-green);
  --text-on-success: var(--white);
  --bg-warning: var(--yellow);
  --text-on-warning: var(--up-black);
  --bg-danger: var(--brick);
  --text-on-danger: var(--white);
  --bg-notification: var(--orange-red);
  --text-on-notification: var(--white);
  --bg-offline: var(--gray-60);
  --bg-info-light: var(--gray-95);
  --text-on-info-light: var(--up-black);
  --bg-success-light: var(--opacity-up-green-15);
  --text-on-success-light: var(--up-black);
  --bg-warning-light: var(--opacity-yellow-25);
  --text-on-warning-light: var(--up-black);
  --bg-danger-light: var(--opacity-brick-15);
  --text-on-danger-light: var(--up-black);
  --color-info: var(--up-black);
  --color-success: var(--up-green);
  --color-warning: var(--yellow);
  --color-danger: var(--brick);
  --color-notification: var(--orange-red);
  --border-width-btn: 2px;
  --bg-btn-dark: var(--brand-green);
  --bg-btn-dark-hover: var(--up-green);
  --text-on-btn-dark: var(--white);
  --text-on-btn-dark-hover: var(--white);
  --border-color-btn-dark: transparent;
  --shadow-on-btn-dark: none;
  --text-icon-on-btn-dark: inherit;
  --bg-btn-light: var(--white);
  --bg-btn-light-hover: var(--gray-95);
  --text-on-btn-light: var(--brand-green);
  --text-on-btn-light-hover: var(--brand-green);
  --border-color-btn-light: currentColor;
  --shadow-on-btn-light: none;
  --text-icon-on-btn-light: inherit;
  --bg-btn-muted: var(--white);
  --bg-btn-muted-hover: var(--gray-95);
  --text-on-btn-muted: var(--brand-green);
  --text-on-btn-muted-hover: var(--brand-green);
  --border-color-btn-muted: var(--gray-70);
  --shadow-on-btn-muted: none;
  --text-icon-on-btn-muted: inherit;
  --bg-btn-dark-inverted: var(--white);
  --bg-btn-dark-inverted-hover: var(--opacity-white-80);
  --text-on-btn-dark-inverted: var(--up-black);
  --text-on-btn-dark-inverted-hover: var(--up-black);
  --border-color-btn-dark-inverted: transparent;
  --shadow-on-btn-dark-inverted: none;
  --text-icon-on-btn-dark-inverted: inherit;
  --bg-btn-light-inverted: transparent;
  --bg-btn-light-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-light-inverted: var(--white);
  --text-on-btn-light-inverted-hover: var(--white);
  --border-color-btn-light-inverted: currentColor;
  --shadow-on-btn-light-inverted: none;
  --text-icon-on-btn-light-inverted: inherit;
  --bg-btn-muted-inverted: transparent;
  --bg-btn-muted-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-muted-inverted: var(--white);
  --text-on-btn-muted-inverted-hover: var(--white);
  --border-color-btn-muted-inverted: var(--opacity-up-black-20);
  --shadow-on-btn-muted-inverted: none;
  --text-icon-on-btn-muted-inverted: inherit;
  --bg-btn-link: transparent;
  --bg-btn-link-hover: transparent;
  --text-on-btn-link: var(--brand-green);
  --text-on-btn-link-hover: var(--up-green);
  --underline-btn-link: none;
  --underline-btn-link-hover: none;
  --bg-btn-circle: transparent;
  --bg-btn-circle-hover: var(--gray-90);
  --text-on-btn-circle: var(--brand-green);
  --text-on-btn-circle-hover: var(--brand-green);
  --bg-btn-circle-inverted: transparent;
  --bg-btn-circle-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-circle-inverted: var(--white);
  --text-on-btn-circle-inverted-hover: var(--white);
  --text-on-btn-close: var(--up-black);
  --text-on-btn-close-hover: var(--up-black);
  --text-inverse-on-btn-close: var(--white);
  --bg-btn-group: var(--white);
  --bg-btn-group-hover: var(--gray-95);
  --border-color-btn-group: var(--gray-70);
  --border-color-btn-group-selected: var(--gray-70);
  --border-width-btn-group: 2px;
  --shadow-on-btn-group: none;
  --bg-btn-group-btn: var(--white);
  --bg-btn-group-btn-hover: var(--gray-95);
  --bg-btn-group-btn-selected: var(--brand-green);
  --bg-btn-group-btn-selected-hover: var(--up-green);
  --text-on-btn-group-btn: var(--brand-green);
  --text-on-btn-group-btn-hover: var(--brand-green);
  --text-on-btn-group-btn-selected: var(--white);
  --bg-btn-check-light: var(--white);
  --bg-btn-check-light-hover: var(--gray-95);
  --bg-btn-check-light-selected: var(--brand-green);
  --bg-btn-check-light-selected-hover: var(--up-green);
  --text-on-btn-check-light: var(--brand-green);
  --text-on-btn-check-light-selected: var(--white);
  --border-color-btn-check-light: var(--gray-70);
  --border-color-btn-check-light-selected: transparent;
  --border-width-btn-check-light: 2px;
  --text-icon-on-btn-check-light: inherit;
  --bg-btn-check-dark: var(--white);
  --bg-btn-check-dark-hover: var(--white);
  --bg-btn-check-dark-selected: var(--brand-green);
  --bg-btn-check-dark-selected-hover: var(--up-green);
  --text-on-btn-check-dark: var(--up-black);
  --text-on-btn-check-dark-hover: var(--up-green);
  --text-on-btn-check-dark-selected: var(--white);
  --text-on-btn-check-dark-selected-hover: var(--white);
  --border-color-btn-check-dark: var(--up-black);
  --border-color-btn-check-dark-hover: var(--up-green);
  --border-color-btn-check-dark-selected: var(--brand-green);
  --border-color-btn-check-dark-selected-hover: var(--up-green);
  --border-width-btn-check-dark: 2px;
  --bg-btn-check-group: var(--white);
  --bg-btn-check-group-hover: var(--gray-95);
  --bg-btn-check-group-selected: var(--gray-95);
  --text-dark-on-btn-check-group: var(--up-black);
  --text-light-on-btn-check-group: var(--gray-45);
  --text-dark-on-btn-check-group-selected: var(--up-black);
  --text-light-on-btn-check-group-selected: var(--gray-45);
  --border-color-btn-check-group: var(--gray-70);
  --border-color-btn-check-group-hover: var(--brand-green);
  --border-color-btn-check-group-selected: var(--brand-green);
  --border-width-btn-check-group: 2px;
  --bg-control: var(--white);
  --text-dark-on-control: var(--up-black);
  --text-light-on-control: var(--gray-45);
  --text-muted-on-control: var(--gray-60);
  --text-icon-on-control: var(--up-black);
  --text-icon-on-control-hover: var(--up-black);
  --border-color-control: var(--gray-80);
  --border-color-control-hover: var(--opacity-gray-80);
  --border-color-control-selected: var(--gray-55);
  --border-width-control: 2px;
  --bg-control-muted: var(--white);
  --bg-control-muted-hover-dark: var(--gray-80);
  --bg-control-muted-hover-light: var(--gray-95);
  --text-dark-on-control-muted: var(--up-black);
  --text-light-on-control-muted: var(--gray-45);
  --border-color-control-muted: var(--gray-80);
  --border-color-control-muted-selected: var(--gray-55);
  --border-width-control-muted: 2px;
  --bg-control-check: var(--white);
  --bg-control-check-hover: var(--white);
  --bg-control-check-selected: var(--brand-green);
  --bg-control-check-selected-hover: var(--up-green);
  --border-color-control-check: var(--gray-60);
  --border-color-control-check-hover: var(--gray-55);
  --border-color-control-check-selected: var(--brand-green);
  --border-color-control-check-selected-hover: var(--up-green);
  --border-width-control-check: 2px;
  --text-icon-on-control-check: var(--white);
  --bg-hoverable-light: var(--white);
  --bg-hoverable-light-hover: var(--gray-90);
  --bg-on-hoverable-light: var(--gray-80);
  --text-dark-on-hoverable-light: var(--up-black);
  --text-light-on-hoverable-light: var(--gray-45);
  --text-accent-on-hoverable-light: var(--brand-green);
  --bg-hoverable-dark: var(--white);
  --bg-hoverable-dark-hover: var(--gray-95);
  --text-dark-on-hoverable-dark: var(--up-black);
  --text-light-on-hoverable-dark: var(--gray-45);
  --text-accent-on-hoverable-dark: var(--brand-green);
  --bg-interactive-muted-light: var(--gray-95);
  --bg-interactive-muted-light-hover: var(--gray-90);
  --text-dark-on-interactive-muted-light: var(--up-black);
  --text-light-on-interactive-muted-light: var(--gray-45);
  --bg-accent-interactive: var(--brand-green);
  --text-on-accent-interactive: var(--white);
  --bg-selected: var(--brand-green);
  --bg-selected-hover: var(--up-green);
  --text-on-selected: var(--white);
  --text-selected: var(--brand-green);
  --bg-body: var(--white);
  --text-dark-on-body: var(--up-black);
  --text-light-on-body: var(--gray-45);
  --text-accent-on-body: var(--up-green);
  --text-muted-on-body: var(--gray-60);
  --bg-inverse: var(--white);
  --text-dark-on-inverse: var(--up-black);
  --text-light-on-inverse: var(--gray-45);
  --text-accent-on-inverse: var(--up-green);
  --text-muted-on-inverse: var(--gray-60);
  --bg-accent: var(--up-green);
  --text-on-accent: var(--white);
  --bg-dark: var(--up-black);
  --text-on-dark: var(--white);
  --bg-light: var(--gray-45);
  --text-on-light: var(--white);
  --bg-placeholder: var(--gray-55);
  --text-on-placeholder: var(--white);
  --bg-placeholder-muted-dark: var(--gray-80);
  --bg-placeholder-muted-light: var(--gray-95);
  --bg-muted-dark: var(--gray-90);
  --bg-muted-light: var(--gray-95);
  --text-dark-on-muted: var(--up-black);
  --text-light-on-muted: var(--gray-45);
  --bg-mark: var(--yellow);
  --text-on-mark: var(--up-black);
  --bg-disabled: var(--gray-80);
  --text-on-disabled: var(--gray-55);
  --bg-disabled-inverse: var(--white);
  --text-on-disabled-inverse: var(--gray-45);
  --bg-disabled-inner: var(--gray-90);
  --text-on-disabled-inner: var(--gray-45);
  --color-highlight-1: var(--blue);
  --color-highlight-2: var(--forest);
  --color-highlight-3: var(--clay);
  --color-highlight-4: var(--lilac);
  --color-highlight-5: var(--sky);
  --color-highlight-6: var(--pink);
  --color-highlight-7: var(--brown);
  --color-highlight-8: var(--yellow);
  --color-highlight-9: var(--brick);
  --color-highlight-10: var(--up-lime);
  --color-highlight-11: var(--mint);
  --color-highlight-12: var(--heather);
  --text-on-highlight: var(--white);
  --text-icon: var(--up-black);
  --text-icon-inverse: var(--white);
  --text-icon-accent: var(--up-green);
  --text-outline: var(--up-black);
  --text-outline-accent: var(--brand-green);
  --bg-backdrop: var(--up-black);
  --opacity-backdrop: 0.6;
  --list-margin: 0;
  --list-padding: 0;
  --list-style-ul: none;
  --list-style-ol: none;
  --shadow-color-popup: rgba(0, 30, 0, 0.15);
  --shadow-popup: 0 0 10px 2px rgba(0, 30, 0, 0.15);
  --shadow-interactive: 0 0 10px rgba(0, 0, 0, 0.15);
  --shadow-interactive-hover: 0 0 20px rgba(0, 0, 0, 0.2);
  --shadow-scroll: var(--transparent-white);
  --shadow-opacity-scroll: var(--transparent-white-85);
  --max-width-footer-container: var(--max-width-global-container);
  --bg-footer-icon: transparent;
  --bg-footer-icon-hover: var(--gray-55);
  --text-on-footer-icon: var(--white);
  --border-footer-icon: 1px solid var(--gray-60);
  --bg-footer: var(--up-black);
  --text-on-footer: var(--white);
  --text-footer-link: var(--white);
  --text-footer-link-hover: var(--white);
  --border-color-footer: var(--gray-55);
  --border-top-footer: 0;
  --radius-footer: var(--radius-4x);
  --bg-footer-visitor: var(--up-black);
  --text-on-footer-visitor: var(--white);
  --text-footer-visitor-link: var(--white);
  --text-footer-visitor-link-hover: var(--white);
  --border-color-footer-visitor: var(--gray-55);
  --border-top-footer-visitor: 0;
  --radius-footer-visitor: var(--radius-4x);
}
[theme="air-2-76"] {
  --font-weight-base: 400;
  --ws-heading: var(--ws-lg);
  --letter-spacing-h1: unset;
  --letter-spacing-h2: unset;
  --letter-spacing-h3: unset;
  --letter-spacing-h4: unset;
  --letter-spacing-h5: unset;
  --letter-spacing-h1-rebrand: unset;
  --letter-spacing-h2-rebrand: unset;
  --letter-spacing-h3-rebrand: unset;
  --letter-spacing-h4-rebrand: unset;
  --letter-spacing-h5-rebrand: unset;
}
html[theme="dark"],
html[theme="dark"] [design-system="air-3-0"] {
  --text-heading: inherit;
  --text-label: inherit;
  --text-link: var(--brand-green);
  --text-link-hover: var(--up-green);
  --text-link-navigation: var(--white);
  --text-link-navigation-hover: var(--up-green);
  --text-link-navigation-dark: var(--white);
  --text-link-navigation-dark-hover: var(--white);
  --text-link-navigation-light: var(--gray-50);
  --text-link-navigation-light-hover: var(--up-green);
  --text-link-navigation-selected: var(--brand-green);
  --text-link-inverse: var(--up-black-dark);
  --text-link-inverse-hover: var(--opacity-up-black-80);
  --underline-link: underline;
  --underline-link-hover: underline;
  --border-color: var(--gray-25);
  --border: 1px solid var(--border-color);
  --border-color-inverse: var(--up-black-dark);
  --border-inverse: 1px solid var(--border-color-inverse);
  --bg-info: var(--white);
  --text-on-info: var(--up-black-dark);
  --bg-sucess: var(--brand-green);
  --text-on-success: var(--white);
  --bg-warning: var(--yellow);
  --text-on-warning: var(--up-black-dark);
  --bg-danger: var(--carmine-red);
  --text-on-danger: var(--white);
  --bg-notification: var(--orange-red);
  --text-on-notification: var(--white);
  --bg-offline: var(--gray-30);
  --bg-info-light: var(--gray-10);
  --text-on-info-light: var(--white);
  --bg-success-light: var(--opacity-up-green-15);
  --text-on-success-light: var(--white);
  --bg-warning-light: var(--opacity-yellow-25);
  --text-on-warning-light: var(--white);
  --bg-danger-light: var(--opacity-carmine-red-15);
  --text-on-danger-light: var(--white);
  --color-info: var(--white);
  --color-success: var(--up-green);
  --color-warning: var(--yellow);
  --color-danger: var(--carmine-red);
  --color-notification: var(--orange-red);
  --bg-btn-dark: var(--brand-green);
  --bg-btn-dark-hover: var(--up-green);
  --text-on-btn-dark: var(--white);
  --text-on-btn-dark-hover: var(--white);
  --border-color-btn-dark: transparent;
  --shadow-on-btn-dark: none;
  --text-icon-on-btn-dark: inherit;
  --bg-btn-light: var(--up-black-dark);
  --bg-btn-light-hover: var(--gray-10);
  --text-on-btn-light: var(--brand-green);
  --text-on-btn-light-hover: var(--brand-green);
  --border-color-btn-light: currentColor;
  --shadow-on-btn-light: none;
  --text-icon-on-btn-light: inherit;
  --bg-btn-muted: var(--up-black-dark);
  --bg-btn-muted-hover: var(--gray-10);
  --text-on-btn-muted: var(--brand-green);
  --text-on-btn-muted-hover: var(--brand-green);
  --border-color-btn-muted: var(--gray-25);
  --shadow-on-btn-muted: none;
  --text-icon-on-btn-muted: inherit;
  --bg-btn-dark-inverted: var(--up-black-dark);
  --bg-btn-dark-inverted-hover: var(--opacity-up-black-80);
  --text-on-btn-dark-inverted: var(--white);
  --text-on-btn-dark-inverted-hover: var(--white);
  --border-color-btn-dark-inverted: transparent;
  --shadow-on-btn-dark-inverted: none;
  --text-icon-on-btn-dark-inverted: inherit;
  --bg-btn-light-inverted: transparent;
  --bg-btn-light-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-light-inverted: var(--up-black-dark);
  --text-on-btn-light-inverted-hover: var(--up-black-dark);
  --border-color-btn-light-inverted: currentColor;
  --shadow-on-btn-light-inverted: none;
  --text-icon-on-btn-light-inverted: inherit;
  --bg-btn-muted-inverted: transparent;
  --bg-btn-muted-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-muted-inverted: var(--up-black-dark);
  --text-on-btn-muted-inverted-hover: var(--up-black-dark);
  --border-color-btn-muted-inverted: currentColor;
  --shadow-on-btn-muted-inverted: none;
  --text-icon-on-btn-muted-inverted: inherit;
  --bg-btn-link: transparent;
  --bg-btn-link-hover: transparent;
  --text-on-btn-link: var(--brand-green);
  --text-on-btn-link-hover: var(--up-green);
  --underline-btn-link: none;
  --underline-btn-link-hover: none;
  --bg-btn-circle: transparent;
  --bg-btn-circle-hover: var(--gray-15);
  --text-on-btn-circle: var(--brand-green);
  --text-on-btn-circle-hover: var(--brand-green);
  --bg-btn-circle-inverted: transparent;
  --bg-btn-circle-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-circle-inverted: var(--up-black);
  --text-on-btn-circle-inverted-hover: var(--up-black);
  --text-on-btn-close: var(--white);
  --text-on-btn-close-hover: var(--white);
  --text-inverse-on-btn-close: var(--up-black);
  --bg-btn-group: var(--up-black-dark);
  --bg-btn-group-hover: var(--gray-10);
  --border-color-btn-group: var(--gray-25);
  --border-color-btn-group-selected: var(--gray-25);
  --border-width-btn-group: 2px;
  --shadow-on-btn-group: none;
  --bg-btn-group-btn: var(--up-black-dark);
  --bg-btn-group-btn-hover: var(--gray-10);
  --bg-btn-group-btn-selected: var(--brand-green);
  --bg-btn-group-btn-selected-hover: var(--up-green);
  --text-on-btn-group-btn: var(--brand-green);
  --text-on-btn-group-btn-hover: var(--brand-green);
  --text-on-btn-group-btn-selected: var(--white);
  --bg-btn-check-light: var(--up-black-dark);
  --bg-btn-check-light-hover: var(--gray-10);
  --bg-btn-check-light-selected: var(--brand-green);
  --bg-btn-check-light-selected-hover: var(--up-green);
  --text-on-btn-check-light: var(--brand-green);
  --text-on-btn-check-light-selected: var(--white);
  --border-color-btn-check-light: var(--gray-25);
  --border-color-btn-check-light-selected: transparent;
  --border-width-btn-check-light: 2px;
  --shadow-on-btn-check-light: none;
  --text-icon-on-btn-check-light: inherit;
  --bg-btn-check-dark: var(--up-black-dark);
  --bg-btn-check-dark-hover: var(--up-black-dark);
  --bg-btn-check-dark-selected: var(--brand-green);
  --bg-btn-check-dark-selected-hover: var(--up-green);
  --text-on-btn-check-dark: var(--white);
  --text-on-btn-check-dark-hover: var(--up-green);
  --text-on-btn-check-dark-selected: var(--up-black-dark);
  --text-on-btn-check-dark-selected-hover: var(--up-black-dark);
  --border-color-btn-check-dark: var(--white);
  --border-color-btn-check-dark-hover: var(--up-green);
  --border-color-btn-check-dark-selected: var(--brand-green);
  --border-color-btn-check-dark-selected-hover: var(--up-green);
  --border-width-btn-check-dark: 2px;
  --bg-btn-check-group: var(--up-black-dark);
  --bg-btn-check-group-hover: var(--gray-10);
  --bg-btn-check-group-selected: var(--gray-10);
  --text-dark-on-btn-check-group: var(--white);
  --text-light-on-btn-check-group: var(--gray-50);
  --text-dark-on-btn-check-group-selected: var(--white);
  --text-light-on-btn-check-group-selected: var(--gray-50);
  --border-color-btn-check-group: var(--gray-25);
  --border-color-btn-check-group-hover: var(--brand-green);
  --border-color-btn-check-group-selected: var(--brand-green);
  --border-width-btn-check-group: 2px;
  --bg-control: var(--up-black-dark);
  --text-dark-on-control: var(--white);
  --text-light-on-control: var(--gray-50);
  --text-muted-on-control: var(--gray-30);
  --text-icon-on-control: var(--white);
  --text-icon-on-control-hover: var(--white);
  --border-color-control: var(--gray-20);
  --border-color-control-hover: var(--gray-40);
  --border-color-control-selected: var(--opacity-gray-80);
  --border-width-control: 2px;
  --bg-control-muted: var(--up-black-dark);
  --bg-control-muted-hover-dark: var(--gray-20);
  --bg-control-muted-hover-light: var(--gray-10);
  --text-dark-on-control-muted: var(--white);
  --text-light-on-control-muted: var(--gray-50);
  --border-color-control-muted: var(--gray-20);
  --border-color-control-muted-selected: var(--gray-40);
  --border-width-control-muted: 2px;
  --bg-control-check: var(--up-black-dark);
  --bg-control-check-hover: var(--up-black-dark);
  --bg-control-check-selected: var(--brand-green);
  --bg-control-check-selected-hover: var(--up-green);
  --border-color-control-check: var(--gray-30);
  --border-color-control-check-hover: var(--gray-40);
  --border-color-control-check-selected: var(--brand-green);
  --border-color-control-check-selected-hover: var(--up-green);
  --border-width-control-check: 2px;
  --text-icon-on-control-check: var(--up-black-dark);
  --bg-hoverable-light: var(--up-black-dark);
  --bg-hoverable-light-hover: var(--gray-15);
  --bg-on-hoverable-light: var(--gray-80);
  --text-dark-on-hoverable-light: var(--white);
  --text-light-on-hoverable-light: var(--gray-50);
  --text-accent-on-hoverable-light: var(--brand-green);
  --bg-hoverable-dark: var(--up-black-dark);
  --bg-hoverable-dark-hover: var(--gray-15);
  --text-dark-on-hoverable-dark: var(--white);
  --text-light-on-hoverable-dark: var(--gray-50);
  --text-accent-on-hoverable-dark: var(--brand-green);
  --bg-interactive-muted-light: var(--gray-10);
  --bg-interactive-muted-light-hover: var(--gray-15);
  --text-dark-on-interactive-muted-light: var(--white);
  --text-light-on-interactive-muted-light: var(--gray-50);
  --bg-accent-interactive: var(--brand-green);
  --text-on-accent-interactive: var(--white);
  --bg-selected: var(--brand-green);
  --bg-selected-hover: var(--up-green);
  --text-on-selected: var(--white);
  --text-selected: var(--brand-green);
  --bg-body: var(--up-black-dark);
  --text-dark-on-body: var(--white);
  --text-light-on-body: var(--gray-50);
  --text-accent-on-body: var(--up-green);
  --text-muted-on-body: var(--gray-30);
  --bg-inverse: var(--up-black-dark);
  --bg-inverse-hover: var(--opacity-up-black-80);
  --text-dark-on-inverse: var(--white);
  --text-light-on-inverse: var(--gray-50);
  --text-accent-on-inverse: var(--up-green);
  --text-muted-on-inverse: var(--gray-30);
  --bg-accent: var(--up-green);
  --text-on-accent: var(--white);
  --bg-dark: var(--white);
  --text-on-dark: var(--up-black-dark);
  --bg-light: var(--gray-50);
  --text-on-light: var(--up-black-dark);
  --bg-placeholder: var(--gray-40);
  --text-on-placeholder: var(--up-black-dark);
  --bg-placeholder-muted-dark: var(--gray-20);
  --bg-placeholder-muted-light: var(--gray-10);
  --bg-muted-dark: var(--gray-20);
  --bg-muted-light: var(--gray-10);
  --text-dark-on-muted: var(--white);
  --text-light-on-muted: var(--gray-50);
  --bg-mark: var(--yellow);
  --text-on-mark: var(--up-black);
  --bg-disabled: var(--gray-20);
  --text-on-disabled: var(--gray-30);
  --bg-disabled-inverted: var(--white);
  --text-on-disabled-inverse: var(--gray-50);
  --bg-disabled-inner: var(--gray-15);
  --text-on-disabled-inner: var(--gray-50);
  --color-highlight-1: var(--cyan-blue);
  --color-highlight-2: var(--forest);
  --color-highlight-3: var(--clay);
  --color-highlight-4: var(--lilac);
  --color-highlight-5: var(--sky);
  --color-highlight-6: var(--pink);
  --color-highlight-7: var(--brown);
  --color-highlight-8: var(--yellow);
  --color-highlight-9: var(--carmine-red);
  --color-highlight-10: var(--up-lime);
  --color-highlight-11: var(--mint);
  --color-highlight-12: var(--heather);
  --text-on-highlight: var(--white);
  --text-icon: var(--white);
  --text-icon-inverse: var(--up-black-dark);
  --text-icon-accent: var(--up-green);
  --text-outline: var(--up-black-dark);
  --text-outline-accent: var(--brand-green);
  --bg-backdrop: var(--white);
  --opacity-backdrop: 0.6;
  --shadow-color-popup: rgba(0, 30, 0, 0.15);
  --shadow-popup: 0 0 10px 2px hsla(0, 0%, 100%, 0.15);
  --shadow-interactive: 0 0 10px hsla(0, 0%, 100%, 0.15);
  --shadow-interactive-hover: 0 0 20px hsla(0, 0%, 100%, 0.2);
  --shadow-scroll: var(--transparent-black);
  --shadow-opacity-scroll: var(--transparent-black-85);
  --max-width-footer-container: none;
  --bg-footer-icon: transparent;
  --bg-footer-icon-hover: var(--gray-55);
  --text-on-footer-icon: var(--white);
  --border-footer-icon: 1px solid var(--gray-60);
  --bg-footer: var(--up-black-dark);
  --text-on-footer: var(--white);
  --text-on-link-footer: var(--white);
  --text-on-link-footer-hover: var(--white);
  --border-color-footer: var(--gray-20);
  --border-top-footer: 1px solid var(--border-color-footer);
  --radius-footer: 0;
  --bg-footer-visitor: var(--up-black-dark);
  --text-on-footer-visitor: var(--white);
  --text-link-footer-visitor: var(--white);
  --text-link-footer-visitor-hover: var(--white);
  --border-color-footer-visitor: var(--gray-20);
  --border-top-footer-visitor: 1px solid var(--border-color-footer-visitor);
  --radius-footer-visitor: 0;
}
html[theme="high-contrast-light"],
html[theme="high-contrast-light"] [design-system="air-3-0"] {
  --text-heading: inherit;
  --text-label: inherit;
  --text-link: var(--brand-green-hc);
  --text-link-hover: var(--up-black);
  --text-link-navigation: var(--up-black);
  --text-link-navigation-hover: var(--up-green-hc);
  --text-link-navigation-dark: var(--up-black);
  --text-link-navigation-dark-hover: var(--up-black);
  --text-link-navigation-light: var(--up-black);
  --text-link-navigation-light-hover: var(--up-green-hc);
  --text-link-navigation-selected: var(--brand-green-hc);
  --text-link-inverse: var(--white);
  --text-link-inverse-hover: var(--opacity-white-80);
  --underline-link: underline;
  --underline-link-hover: underline;
  --border-color: var(--gray-70);
  --border: 1px solid var(--border-color);
  --border-color-inverse: var(--white);
  --border-inverse: 1px solid var(--border-color-inverse);
  --bg-info: var(--up-black);
  --text-on-info: var(--white);
  --bg-success: var(--up-green-hc);
  --text-on-success: var(--white);
  --bg-warning: var(--yellow);
  --text-on-warning: var(--up-black);
  --bg-danger: var(--brick);
  --text-on-danger: var(--white);
  --bg-notification: var(--orange-red-hc);
  --text-on-notification: var(--white);
  --bg-offline: var(--gray-60);
  --bg-info-light: var(--gray-95);
  --text-on-info-light: var(--up-black);
  --bg-success-light: var(--opacity-up-green-15);
  --text-on-success-light: var(--up-black);
  --bg-warning-light: var(--opacity-yellow-25);
  --text-on-warning-light: var(--up-black);
  --bg-danger-light: var(--opacity-brick-15);
  --text-on-danger-light: var(--up-black);
  --color-info: var(--up-black);
  --color-success: var(--up-green-hc);
  --color-warning: var(--yellow-hc);
  --color-danger: var(--brick);
  --color-notification: var(--orange-red-hc);
  --bg-btn-dark: var(--brand-green-hc);
  --bg-btn-dark-hover: var(--up-black);
  --text-on-btn-dark: var(--white);
  --text-on-btn-dark-hover: var(--white);
  --border-color-btn-dark: transparent;
  --shadow-on-btn-dark: none;
  --text-icon-on-btn-dark: inherit;
  --bg-btn-light: var(--white);
  --bg-btn-light-hover: var(--gray-95);
  --text-on-btn-light: var(--brand-green-hc);
  --text-on-btn-light-hover: var(--brand-green-hc);
  --border-color-btn-light: currentColor;
  --shadow-on-btn-light: none;
  --text-icon-on-btn-light: inherit;
  --bg-btn-muted: var(--white);
  --bg-btn-muted-hover: var(--gray-95);
  --text-on-btn-muted: var(--brand-green-hc);
  --text-on-btn-muted-hover: var(--brand-green-hc);
  --border-color-btn-muted: var(--brand-green-hc);
  --shadow-on-btn-muted: none;
  --text-icon-on-btn-muted: inherit;
  --bg-btn-dark-inverted: var(--white);
  --bg-btn-dark-inverted-hover: var(--opacity-white-80);
  --text-on-btn-dark-inverted: var(--up-black);
  --text-on-btn-dark-inverted-hover: var(--up-black);
  --border-color-btn-dark-inverted: transparent;
  --shadow-on-btn-dark-inverted: none;
  --text-icon-on-btn-dark-inverted: inherit;
  --bg-btn-light-inverted: transparent;
  --bg-btn-light-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-light-inverted: var(--white);
  --text-on-btn-light-inverted-hover: var(--white);
  --border-color-btn-light-inverted: currentColor;
  --shadow-on-btn-light-inverted: none;
  --text-icon-on-btn-light-inverted: inherit;
  --bg-btn-muted-inverted: transparent;
  --bg-btn-muted-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-muted-inverted: var(--white);
  --text-on-btn-muted-inverted-hover: var(--white);
  --border-color-btn-muted-inverted: currentColor;
  --shadow-on-btn-muted-inverted: none;
  --text-icon-on-btn-muted-inverted: inherit;
  --bg-btn-link: transparent;
  --bg-btn-link-hover: transparent;
  --text-on-btn-link: var(--brand-green-hc);
  --text-on-btn-link-hover: var(--up-green-hc);
  --underline-btn-link: none;
  --underline-btn-link-hover: none;
  --bg-btn-circle: transparent;
  --bg-btn-circle-hover: var(--gray-90);
  --text-on-btn-circle: var(--brand-green-hc);
  --text-on-btn-circle-hover: var(--brand-green-hc);
  --bg-btn-circle-inverted: transparent;
  --bg-btn-circle-inverted-hover: var(--opacity-up-black-20);
  --text-on-btn-circle-inverted: var(--white);
  --text-on-btn-circle-inverted-hover: var(--white);
  --text-on-btn-close: var(--up-black);
  --text-on-btn-close-hover: var(--up-black);
  --text-inverse-on-btn-close: var(--white);
  --bg-btn-group: var(--white);
  --bg-btn-group-hover: var(--gray-95);
  --border-color-btn-group: var(--gray-70);
  --border-color-btn-group-selected: var(--gray-70);
  --border-width-btn-group: 2px;
  --shadow-on-btn-group: none;
  --bg-btn-group-btn: var(--white);
  --bg-btn-group-btn-hover: var(--gray-95);
  --bg-btn-group-btn-selected: var(--brand-green-hc);
  --bg-btn-group-btn-selected-hover: var(--up-black);
  --text-on-btn-group-btn: var(--brand-green-hc);
  --text-on-btn-group-btn-hover: var(--brand-green-hc);
  --text-on-btn-group-btn-selected: var(--white);
  --bg-btn-check-light: var(--white);
  --bg-btn-check-light-hover: var(--gray-95);
  --bg-btn-check-light-selected: var(--brand-green-hc);
  --bg-btn-check-light-selected-hover: var(--up-black);
  --text-on-btn-check-light: var(--brand-green-hc);
  --text-on-btn-check-light-selected: var(--white);
  --border-color-btn-check-light: var(--gray-70);
  --border-color-btn-check-light-selected: transparent;
  --border-width-btn-check-light: 2px;
  --text-icon-on-btn-check-light: inherit;
  --bg-btn-check-dark: var(--white);
  --bg-btn-check-dark-hover: var(--white);
  --bg-btn-check-dark-selected: var(--brand-green-hc);
  --bg-btn-check-dark-selected-hover: var(--up-green-hc);
  --text-on-btn-check-dark: var(--up-black);
  --text-on-btn-check-dark-hover: var(--up-black);
  --text-on-btn-check-dark-selected: var(--white);
  --text-on-btn-check-dark-selected-hover: var(--white);
  --border-color-btn-check-dark: var(--up-black);
  --border-color-btn-check-dark-hover: var(--up-black);
  --border-color-btn-check-dark-selected: var(--brand-green-hc);
  --border-color-btn-check-dark-selected-hover: var(--up-black);
  --border-width-btn-check-dark: 2px;
  --bg-btn-check-group: var(--white);
  --bg-btn-check-group-hover: var(--gray-95);
  --bg-btn-check-group-selected: var(--gray-95);
  --text-dark-on-btn-check-group: var(--up-black);
  --text-light-on-btn-check-group: var(--up-black);
  --text-dark-on-btn-check-group-selected: var(--up-black);
  --text-light-on-btn-check-group-selected: var(--up-black);
  --border-color-btn-check-group: var(--up-black);
  --border-color-btn-check-group-hover: var(--brand-green-hc);
  --border-color-btn-check-group-selected: var(--brand-green-hc);
  --border-width-btn-check-group: 2px;
  --bg-control: var(--white);
  --text-dark-on-control: var(--up-black);
  --text-light-on-control: var(--up-black);
  --text-muted-on-control: var(--gray-45);
  --text-icon-on-control: var(--up-black);
  --text-icon-on-control-hover: var(--up-black);
  --border-color-control: var(--up-black);
  --border-color-control-hover: var(--brand-green-hc);
  --border-color-control-selected: var(--brand-green-hc);
  --border-width-control: 2px;
  --bg-control-muted: var(--white);
  --bg-control-muted-hover-dark: var(--gray-80);
  --bg-control-muted-hover-light: var(--gray-95);
  --text-dark-on-control-muted: var(--up-black);
  --text-light-on-control-muted: var(--up-black);
  --border-color-control-muted: var(--up-black);
  --border-color-control-muted-selected: var(--brand-green-hc);
  --border-width-control-muted: 2px;
  --bg-control-check: var(--white);
  --bg-control-check-hover: var(--white);
  --bg-control-check-selected: var(--brand-green-hc);
  --bg-control-check-selected-hover: var(--up-black);
  --border-color-control-check: var(--up-black);
  --border-color-control-check-hover: var(--up-black);
  --border-color-control-check-selected: var(--brand-green-hc);
  --border-color-control-check-selected-hover: var(--up-black);
  --border-width-control-check: 2px;
  --text-icon-on-control-check: var(--white);
  --bg-hoverable-light: var(--white);
  --bg-hoverable-light-hover: var(--gray-90);
  --bg-on-hoverable-light: var(--gray-80);
  --text-dark-on-hoverable-light: var(--up-black);
  --text-light-on-hoverable-light: var(--gray-45);
  --text-accent-on-hoverable-light: var(--brand-green-hc);
  --bg-hoverable-dark: var(--white);
  --bg-hoverable-dark-hover: var(--gray-95);
  --text-dark-on-hoverable-dark: var(--up-black);
  --text-light-on-hoverable-dark: var(--up-black);
  --text-accent-on-hoverable-dark: var(--brand-green-hc);
  --bg-interactive-muted-light: var(--gray-95);
  --bg-interactive-muted-light-hover: var(--gray-90);
  --text-dark-on-interactive-muted-light: var(--up-black);
  --text-light-on-interactive-muted-light: var(--up-black);
  --bg-accent-interactive: var(--brand-green-hc);
  --text-on-accent-interactive: var(--white);
  --bg-selected: var(--brand-green-hc);
  --bg-selected-hover: var(--up-black);
  --text-on-selected: var(--white);
  --text-selected: var(--brand-green-hc);
  --bg-body: var(--white);
  --text-dark-on-body: var(--up-black);
  --text-light-on-body: var(--up-black);
  --text-accent-on-body: var(--up-green-hc);
  --text-muted-on-body: var(--gray-45);
  --bg-inverse: var(--white);
  --text-dark-on-inverse: var(--up-black);
  --text-light-on-inverse: var(--up-black);
  --text-accent-on-inverse: var(--up-green-hc);
  --text-muted-on-inverse: var(--gray-45);
  --bg-accent: var(--up-green-hc);
  --text-on-accent: var(--white);
  --bg-dark: var(--up-black);
  --text-on-dark: var(--white);
  --bg-light: var(--up-black);
  --text-on-light: var(--white);
  --bg-placeholder: var(--gray-55);
  --text-on-placeholder: var(--white);
  --bg-placeholder-muted-dark: var(--gray-80);
  --bg-placeholder-muted-light: var(--gray-95);
  --bg-muted-dark: var(--gray-50);
  --bg-muted-light: var(--gray-95);
  --text-dark-on-muted: var(--up-black);
  --text-light-on-muted: var(--up-black);
  --bg-mark: var(--yellow);
  --text-on-mark: var(--up-black);
  --bg-disabled: var(--gray-80);
  --text-on-disabled: var(--up-black);
  --bg-disabled-inverse: var(--white);
  --text-on-disabled-inverse: var(--up-black);
  --bg-disabled-inner: var(--gray-90);
  --text-on-disabled-inner: var(--up-black);
  --color-highlight-1: var(--blue-hc);
  --color-highlight-2: var(--forest);
  --color-highlight-3: var(--clay-hc);
  --color-highlight-4: var(--lilac-hc);
  --color-highlight-5: var(--sky-hc);
  --color-highlight-6: var(--pink-hc);
  --color-highlight-7: var(--brown-hc);
  --color-highlight-8: var(--yellow-hc);
  --color-highlight-9: var(--brick);
  --color-highlight-10: var(--up-lime-hc);
  --color-highlight-11: var(--mint-hc);
  --color-highlight-12: var(--heather-hc);
  --text-on-highlight: var(--white);
  --text-icon: var(--up-black);
  --text-icon-inverse: var(--white);
  --text-icon-accent: var(--up-green-hc);
  --text-outline: var(--up-black);
  --text-outline-accent: var(--brand-green-hc);
  --bg-backdrop: var(--up-black);
  --opacity-backdrop: 0.6;
  --shadow-color-popup: rgba(0, 30, 0, 0.15);
  --shadow-popup: 0 0 10px 2px rgba(0, 30, 0, 0.15);
  --shadow-interactive: 0 0 10px rgba(0, 0, 0, 0.15);
  --shadow-interactive-hover: 0 0 20px rgba(0, 0, 0, 0.2);
  --shadow-scroll: var(--transparent-white);
  --shadow-opacity-scroll: var(--transparent-white-85);
  --bg-footer-icon: transparent;
  --bg-footer-icon-hover: var(--gray-45);
  --text-on-footer-icon: var(--white);
  --border-footer-icon: 1px solid var(--gray-60);
  --bg-footer: var(--up-black);
  --text-on-footer: var(--white);
  --text-footer-link: var(--white);
  --text-footer-link-hover: var(--white);
  --border-color-footer: var(--gray-55);
  --bg-footer-visitor: var(--up-black);
  --text-on-footer-visitor: var(--white);
  --text-footer-visitor-link: var(--white);
  --text-footer-visitor-link-hover: var(--white);
  --border-color-footer-visitor: var(--gray-55);
}
:root {
  --ws-xs: 4px;
  --ws-sm: 8px;
  --ws-smd: 12px;
  --ws-md: 16px;
  --ws-lg: 24px;
  --ws-xl: 32px;
  --ws-2xl: 48px;
  --ws-3xl: 56px;
  --ws-4xl: 64px;
  --ws-5xl: 80px;
  --ws-6xl: 96px;
  --radius: 2px;
  --radius-sm: 4px;
  --radius-md: 6px;
  --radius-lg: 8px;
  --radius-xl: 16px;
  --radius-2xl: 24px;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-weight-black: 900;
  --card-section-padding: 25px;
  --checkbox-color-active: var(--text-disabled);
  --checkbox-helper-size: 16px;
  --strong-weight: var(--font-weight-medium);
  --heading-weight: var(--font-weight-medium);
  --heading-spacing: 0.5em;
  --success-color: var(--up-green);
  --warning-color: var(--yellow);
  --danger-color: var(--brick);
  --gray-01: #5e6d55;
  --gray-02: #9aaa97;
  --gray-03: #beccbe;
  --gray-04: #d5e0d5;
  --gray-05: #e4ebe4;
  --gray-06: #f2f7f2;
  --gray-07: #f7faf7;
  --gray-1: var(--gray-01);
  --gray-2: var(--gray-02);
  --gray-3: var(--gray-03);
  --gray-4: var(--gray-04);
  --gray-5: var(--gray-05);
  --gray-6: var(--gray-06);
  --gray-7: var(--gray-07);
  --text-dark: var(--up-black);
  --text-light: var(--gray-1);
  --text-disabled: var(--gray-3);
  --text-muted: var(--gray-3);
  --text-inverse: var(--white);
  --bg-1: var(--gray-1);
  --bg-2: var(--gray-2);
  --bg-3: var(--gray-3);
  --bg-4: var(--gray-4);
  --bg-5: var(--gray-5);
  --bg-6: var(--gray-6);
  --bg-7: var(--gray-7);
  --body-bg: var(--white);
  --shadow-color: 57, 73, 76;
  --shadow-color-active: rgba(var(--shadow-color), 0.25);
  --drop-shadow: none;
  --drop-shadow-active: none;
  --pop-shadow: 0 0 10px 2px rgba(0, 30, 0, 0.15);
  --dialog-shadow: 0 2px 7px rgba(var(--shadow-color), 0.45);
  --backdrop-opacity: 0.3;
  --backdrop: var(--up-black);
  --border-base-color: var(--gray-4);
  --border-base-alt-color: var(--gray-5);
  --border-base: 1px solid var(--border-base-color);
  --border-base-alt: 1px solid var(--border-base-alt-color);
  --primary-color-icon: var(--text-dark);
  --primary-bg-hover: var(--bg-5);
  --primary-color-hover: var(--text-dark);
  --primary-color-active: var(--text-dark);
  --primary-weight-active: var(--font-weight-medium);
  --primary-bg-highlight: var(--bg-inverse);
  --gray-05-h: 120;
  --gray-05-s: 15%;
  --gray-05-l: 91%;
  --brand-green-hsl: 113, 100%, 27%;
  --primary-dark-hover: hsla(var(--up-black-hsl), 80%);
  --primary-light-hover: hsla(var(--white-hsl), 80%);
  --secondary-dark-hover: hsla(var(--up-black-hsl), 20%);
  --secondary-light-hover: hsla(var(--up-black-hsl), 10%);
  --tertiary: var(--gray-5);
  --tertiary-hover: hsl(var(--gray-05-h), var(--gray-05-s), calc(var(--gray-05-l) - 10%));
  --link-color: var(--blue);
  --link-color-hover: hsl(var(--blue-h), var(--blue-s), calc(var(--blue-l) - 10%));
  --link-underline: underline;
  --illustration-color-1: #d5e0d5;
  --illustration-color-2: #000435;
  --illustration-color-3: #9aaa97;
  --illustration-color-4: #beccbe;
  --illustration-color-5: #00c217;
  --illustration-color-6: #95df00;
  --illustration-color-7: #000435;
  --illustration-color-8: #000435;
  --illustration-color-9: #ff4b25;
  --illustration-color-10: #debe1a;
  --illustration-color-11: #f7faf7;
  --illustration-color-12: #f2f7f2;
  --illustration-color-13: #e8f1e8;
  --illustration-color-14: #e4ebe4;
  --illustration-color-15: #9aaa97;
  --heading-letter-spacing-h1: 0;
  --heading-letter-spacing-h2: 0.003em;
  --heading-letter-spacing-h3: 0.005em;
  --heading-letter-spacing-h4: 0.01em;
  --heading-letter-spacing-h5: 0.015em;
  --heading-letter-spacing-h1-rebrand: -0.035em;
  --heading-letter-spacing-h2-rebrand: -0.03em;
  --heading-letter-spacing-h3-rebrand: -0.025em;
  --heading-letter-spacing-h4-rebrand: -0.015em;
  --heading-letter-spacing-h5-rebrand: -0.01em;
  --transparent: transparent;
  --transparent-inverse: hsla(0, 0%, 100%, 0);
  --semi-transparent-inverse: hsla(0, 0%, 100%, 0.85);
  --border-hc: 1px solid;
  --border-hc-disabled: 1px solid GrayText;
  --bg-hc-selected: Highlight;
  --bg-hc-button: ButtonFace;
  --bg-hc-base: canvas;
  --text-hc-selected: HighlightText;
  --text-hc-disabled: GrayText;
  --text-hc-link: linktext;
  --text-hc-base: canvastext;
  --text-hc-button: ButtonText;
  --ws-1x: 4px;
  --ws-2x: 8px;
  --ws-3x: 12px;
  --ws-4x: 16px;
  --ws-6x: 24px;
  --ws-8x: 32px;
  --ws-10x: 40px;
  --ws-12x: 48px;
  --ws-14x: 56px;
  --ws-16x: 64px;
  --ws-20x: 80px;
  --ws-24x: 96px;
  --radius-1x: 2px;
  --radius-2x: 4px;
  --radius-3x: 6px;
  --radius-4x: 8px;
  --radius-8x: 16px;
  --radius-12x: 24px;
  --radius-14x: 28px;
  --radius-pill: 10rem;
  --font-family-monospace: menlo, monaco, consolas, "Courier New", monospace;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-700: 700;
  --font-weight-900: 900;
  --black: #000;
  --gray-10: #1c2d1c;
  --gray-15: #1f321f;
  --gray-20: #2a3b2c;
  --gray-25: #2f3f2f;
  --gray-30: #3a4b3a;
  --gray-40: #52614f;
  --gray-45: #5e6d55;
  --gray-50: #87997d;
  --gray-55: #9aaa97;
  --gray-60: #beccbe;
  --gray-70: #d5e0d5;
  --gray-80: #e4ebe4;
  --gray-90: #f2f7f2;
  --gray-95: #f7faf7;
  --white: #fff;
  --up-green: #000435;
  --up-green-dark: #3c8224;
  --brand-green: #108a00;
  --brand-green-hc: #0b6100;
  --up-green-hc: #0c5c00;
  --up-lime: #95df00;
  --up-lime-hc: #749b2d;
  --up-black: #000435;
  --up-black-dark: #021202;
  --blue: #1f57c3;
  --blue-hc: #1c4faf;
  --cyan-blue: #557fd1;
  --mint: #000435;
  --mint-hc: #3a5648;
  --forest: #000435;
  --sky: #01cdbe;
  --sky-hc: #015c56;
  --brown: #67541f;
  --brown-hc: #5d4c1c;
  --kiwi: #97922d;
  --kiwi-hc: #535019;
  --yellow: #debe1a;
  --yellow-hc: #63560c;
  --brick: #9b211b;
  --carmine-red: #e44d45;
  --clay: #bc5118;
  --clay-hc: #8d3c13;
  --orange-red: #ff4b25;
  --orange-red-hc: #992d16;
  --pink: #f66dbc;
  --pink-hc: #853b67;
  --lilac: #bda1e7;
  --lilac-hc: #5b4d6f;
  --heather: #a18085;
  --heather-hc: #644f52;
  --opacity-up-black-80: hsla(var(--up-black-hsl), 80%);
  --opacity-up-black-20: hsla(var(--up-black-hsl), 20%);
  --opacity-up-black-10: hsla(var(--up-black-hsl), 10%);
  --opacity-white-80: hsla(var(--white-hsl), 80%);
  --opacity-gray-80: hsl(var(--gray-80-h), var(--gray-80-s), calc(var(--gray-80-l) - 10%));
  --opacity-up-green-15: hsla(var(--up-green-hsl), 15%);
  --opacity-blue: hsl(var(--blue-h), var(--blue-s), calc(var(--blue-l) - 10%));
  --opacity-cyan-blue: hsl(var(--cyan-blue-h), var(--cyan-blue-s), calc(var(--cyan-blue-l) - 10%));
  --opacity-yellow-25: hsla(var(--yellow-hsl), 25%);
  --opacity-brick-15: hsla(var(--brick-hsl), 15%);
  --opacity-carmine-red-15: hsla(var(--carmine-red-hsl), 15%);
  --blue-h: 220;
  --blue-s: 73%;
  --blue-l: 44%;
  --cyan-blue-h: 220;
  --cyan-blue-s: 57%;
  --cyan-blue-l: 58%;
  --gray-80-h: 120;
  --gray-80-s: 15%;
  --gray-80-l: 91%;
  --up-black-hsl: 120, 100%, 6%;
  --up-green-hsl: 113, 100%, 33%;
  --yellow-hsl: 50, 79%, 49%;
  --brick-hsl: 3, 70%, 36%;
  --white-hsl: 0, 0%, 100%;
  --carmine-red-hsl: 3, 75%, 58%;
  --transparent-black: transparent;
  --transparent-white: hsla(0, 0%, 100%, 0);
  --transparent-white-85: hsla(0, 0%, 100%, 0.85);
  --transparent-black-85: rgba(0, 0, 0, 0.85);
  --zindex-navbar: 1000;
  --zindex-dropdown: 1000;
  --zindex-popover: 1010;
  --zindex-tooltip: 1030;
  --zindex-navbar-fixed: 1030;
  --zindex-section-loading: 1035;
  --zindex-modal-background: 1040;
  --zindex-modal: 1050;
  --zindex-fullscreen: 1050;
  --zindex-modal-popover: 1060;
  --zindex-toaster: 1060;
}
@media (forced-colors: active) {
  :root {
      --hc-border: 1px solid;
      --hc-border-input: 1px solid ButtonText;
      --hc-border-disabled: 1px solid GrayText;
      --hc-bg-selected: Highlight;
      --hc-text-on-selected: HighlightText;
      --hc-bg-btn: ButtonFace;
      --hc-text-on-btn: ButtonText;
      --hc-bg-base: canvas;
      --hc-text-on-base: canvastext;
      --hc-text-link: linktext;
      --hc-disabled: GrayText;
      --bg-btn-dark: var(--hc-bg-selected);
      --text-on-btn-dark: var(--hc-text-on-selected);
      --border-color-btn-dark: var(--hc-bg-selected);
      --bg-btn-dark-hover: var(--bg-hc-base);
      --text-on-btn-dark-hover: var(--bg-hc-selected);
      --text-on-btn-dark-inverted-hover: var(--hc-bg-selected);
      --text-on-btn-light-hover: var(--bg-hc-selected);
      --text-on-btn-light-inverted-hover: var(--hc-bg-selected);
      --text-on-btn-muted-hover: var(--bg-hc-selected);
      --text-on-btn-link: var(--hc-text-on-btn);
      --text-on-btn-link-hover: var(--bg-hc-selected);
      --text-on-btn-circle-hover: var(--hc-bg-selected);
      --text-on-btn-circle-inverted-hover: var(--hc-bg-selected);
      --bg-btn-check-group-selected: var(--hc-bg-selected);
      --text-dark-on-btn-check-group: var(--hc-text-on-btn);
      --text-light-on-btn-check-group: var(--hc-text-on-btn);
      --text-dark-on-btn-check-group-selected: var(--hc-text-on-selected);
      --text-light-on-btn-check-group-selected: var(--hc-text-on-selected);
      --border-color-btn-check-group: var(--hc-text-on-btn);
      --border-color-btn-check-group-hover: var(--hc-bg-selected);
      --border-color-btn-check-group-selected: var(--hc-bg-selected);
      --bg-btn-check-light-hover: var(--hc-bg-selected);
      --bg-btn-check-light-selected: var(--hc-bg-selected);
      --bg-btn-check-light-selected-hover: var(--hc-bg-selected);
      --text-on-btn-check-light-selected: var(--hc-text-on-selected);
      --border-color-btn-check-dark: var(--hc-text-on-btn);
      --border-color-btn-check-dark-hover: var(--hc-bg-selected);
      --border-color-btn-check-dark-selected: var(--hc-bg-selected);
      --text-on-btn-check-dark: var(--hc-text-on-btn);
      --text-on-btn-check-dark-hover: var(--hc-bg-selected);
      --text-on-btn-check-dark-selected: var(--hc-bg-selected);
      --text-on-btn-check-dark-selected-hover: var(--hc-bg-selected);
      --border-color-btn-group: var(--hc-text-on-btn);
      --border-color-btn-group-selected: var(--hc-text-on-btn);
      --bg-btn-group-btn-hover: var(--hc-bg-selected);
      --bg-btn-group-btn-selected: var(--hc-bg-selected);
      --bg-btn-group-btn-selected-hover: var(--hc-bg-selected);
      --text-on-btn-group-btn-selected: var(--hc-text-on-selected);
      --text-link: var(--hc-text-link);
      --text-link-hover: var(--hc-text-link);
      --underline-link-hover: none;
      --border-color-inverse: var(--hc-bg-base);
      --border-color-control-check: var(--hc-text-on-base);
      --border-color-control-check-hover: var(--hc-text-on-base);
      --border-color-control: var(--hc-text-on-btn);
      --border-color-control-hover: var(--hc-text-on-btn);
      --border-color-control-selected: var(--hc-bg-selected);
      --bg-selected: var(--hc-bg-selected);
      --text-on-selected: var(--hc-text-on-selected);
      --bg-control-check-selected: var(--hc-bg-selected);
      --bg-control-check-selected-hover: var(--hc-bg-selected);
      --border-color-control-check-selected: var(--hc-bg-selected);
      --border-color-control-check-selected-hover: var(--hc-bg-selected);
      --text-icon-on-control-check: var(--hc-text-on-selected);
      --bg-hoverable-light-hover: var(--hc-bg-selected);
      --bg-hoverable-dark-hover: var(--hc-bg-selected);
      --text-link-navigation-selected: var(--hc-bg-selected);
      --bg-backdrop: var(--hc-bg-selected);
      --text-on-disabled: var(--hc-disabled);
      --text-on-disabled-inner: var(--hc-disabled);
      --text-outline: var(--hc-bg-selected);
      --text-outline-accent: var(--hc-bg-selected);
  }
}
@media (prefers-reduced-motion: reduce) {
  :root {
      --reduced-motion: none;
  }
}
*,
:after,
:before {
  box-sizing: border-box;
}
body,
html {
  height: 100%;
}
html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: var(--transparent);
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  font-family: Ubuntu, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-base);
  line-height: 1.428571429;
  letter-spacing: var(--letter-spacing-base);
  background-color: #fff;
  background-color: var(--body-bg);
  color: var(--text-dark-on-body);
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}
button {
  color: inherit;
  cursor: pointer;
}
label {
  display: inline-block;
}
a {
  color: var(--text-link);
  font-weight: var(--font-weight-strong);
  -webkit-text-decoration: var(--underline-link);
  text-decoration: var(--underline-link);
  transition: color 0.1s ease-in-out;
}
@media (hover: hover) {
  a:active,
  a:focus,
  a:hover {
      -webkit-text-decoration: var(--underline-link-hover);
      text-decoration: var(--underline-link-hover);
      color: var(--text-link-hover);
  }
}
a.inverted {
  color: var(--text-link-inverse);
}
@media (hover: hover) {
  a.inverted:active,
  a.inverted:focus,
  a.inverted:hover {
      color: var(--text-link-inverse-hover);
  }
}
figure {
  margin: 0;
}
img {
  vertical-align: middle;
}
hr {
  margin-top: 18px;
  margin-bottom: 18px;
  border: 0;
  border-top: var(--border);
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
fieldset {
  border: 0;
  margin: 0;
}
fieldset,
legend {
  padding: 0;
}
mark {
  background: var(--bg-mark);
  color: var(--text-on-mark);
}
.label,
.up-label {
  display: inline-block;
  width: 100%;
  margin-bottom: 8px;
  margin-bottom: var(--ws-2x);
  font-weight: var(--font-weight-label);
  line-height: var(--line-height-base);
  font-size: var(--font-size-base);
  text-align: left;
  color: var(--text-label);
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
small {
  font-size: var(--font-size-base-xs);
  line-height: 1.454;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  word-break: break-word;
  margin: 0 0 var(--ws-heading);
  font-family: inherit;
  font-weight: var(--font-weight-heading);
  color: var(--text-heading);
}
.h1.display-rebrand,
.h2.display-rebrand,
.h3.display-rebrand,
.h4.display-rebrand,
.h5.display-rebrand,
.h6.display-rebrand,
h1.display-rebrand,
h2.display-rebrand,
h3.display-rebrand,
h4.display-rebrand,
h5.display-rebrand,
h6.display-rebrand {
  font-family: Ubuntu;
  font-weight: var(--font-weight-heading);
  font-weight: var(--font-weight-rebrand, var(--font-weight-heading));
}
.h1,
h1 {
  font-size: var(--font-size-h1);
  letter-spacing: var(--letter-spacing-h1);
  line-height: var(--line-height-h1);
}
.h1.display-rebrand,
h1.display-rebrand {
  font-size: var(--font-size-h1);
  font-size: var(--font-size-h1-rebrand, var(--font-size-h1));
  letter-spacing: var(--letter-spacing-h1-rebrand);
}
.h2,
h2 {
  font-size: var(--font-size-h2);
  letter-spacing: var(--letter-spacing-h2);
  line-height: var(--line-height-h2);
}
.h2.display-rebrand,
h2.display-rebrand {
  font-size: var(--font-size-h2);
  font-size: var(--font-size-h2-rebrand, var(--font-size-h2));
  letter-spacing: var(--letter-spacing-h2-rebrand);
}
.h3,
h3 {
  font-size: var(--font-size-h3);
  letter-spacing: var(--letter-spacing-h3);
  line-height: var(--line-height-h3);
}
.h3.display-rebrand,
h3.display-rebrand {
  letter-spacing: var(--letter-spacing-h3-rebrand);
}
.h4,
h4 {
  font-size: var(--font-size-h4);
  letter-spacing: var(--letter-spacing-h4);
  line-height: var(--line-height-h4);
}
.h4.display-rebrand,
h4.display-rebrand {
  letter-spacing: var(--letter-spacing-h4-rebrand);
}
.h5,
.h6,
h5,
h6 {
  font-size: var(--font-size-h5);
  letter-spacing: var(--letter-spacing-h5);
  line-height: var(--line-height-h5);
}
.h5.display-rebrand,
.h6.display-rebrand,
h5.display-rebrand,
h6.display-rebrand {
  letter-spacing: var(--letter-spacing-h5-rebrand);
}
cite {
  font-style: italic;
  font-size: calc(var(--font-size-base) + 5px);
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}
strong {
  font-weight: var(--font-weight-strong);
}
p {
  margin: 0 0 8px;
  margin: 0 0 var(--ws-2x);
  line-height: var(--line-height-base);
}
.break {
  word-wrap: break-word;
  word-break: break-word;
}
.nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-pre {
  white-space: pre !important;
}
.text-pre-line {
  white-space: pre-line !important;
}
code,
kbd,
pre,
samp {
  font-family: menlo, monaco, consolas, "Courier New", monospace;
  font-family: var(--font-family-monospace);
}
.text-vertical-separator {
  display: inline-block;
  position: relative;
  padding: 8px;
  padding: var(--ws-2x);
  font-size: var(--font-size-base-xs);
  color: currentColor;
}
.text-vertical-separator:after,
.text-vertical-separator:before {
  content: "";
  position: absolute;
  height: 8px;
  height: var(--ws-2x);
  border-right: 1px solid;
  left: 50%;
}
.text-vertical-separator:before {
  top: 0;
}
.text-vertical-separator:after {
  bottom: 0;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-justify {
  text-align: justify !important;
}
ul {
  list-style: var(--list-style-ul);
}
ol {
  list-style: var(--list-style-ol);
}
ol,
ul {
  margin-top: 0;
  margin-bottom: var(--list-margin);
  padding-left: var(--list-padding);
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
ol li,
ul li {
  margin-bottom: var(--list-margin);
}
.list-styled {
  padding-left: 16px;
  padding-left: var(--ws-4x);
}
.list-styled,
.list-styled li {
  margin-bottom: 8px;
  margin-bottom: var(--ws-2x);
}
ul.list-styled {
  list-style: disc;
}
ol.list-styled {
  list-style: decimal;
}
.list-inline,
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  margin-left: -4px;
  margin-left: calc(var(--ws-1x) * -1);
}
.list-inline > li {
  display: inline-block;
  padding-left: 4px;
  padding-left: var(--ws-1x);
  padding-right: 4px;
  padding-right: var(--ws-1x);
}
dl {
  margin-top: 0;
  margin-bottom: 18px;
}
dt {
  font-weight: var(--font-weight-strong);
}
dd {
  margin-left: 0;
}
abbr[title] {
  cursor: help;
  border-bottom: 1px dotted var(--border-color);
  text-decoration: none;
}
sub,
sup {
  font-size: var(--font-size-base-xs);
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.layout {
  flex: 1 0 auto;
}
.layout > .container,
.layout > .container-visitor,
.layout > main > .container,
.layout > main > .container-visitor {
  padding-top: var(--container-ws-y);
  padding-bottom: var(--container-ws-y);
}
@media (min-width: 700px) {
  .layout > .container,
  .layout > .container-visitor,
  .layout > main > .container,
  .layout > main > .container-visitor {
      --container-ws-y: var(--ws-8x);
  }
}
.container,
.container-visitor {
  --container-width: 1280px;
  --container-ws-x: var(--ws-mobile-x);
  --container-ws-y: var(--ws-mobile-x);
  position: relative;
  width: min(100%, calc(var(--container-width) + var(--container-ws-x) * 2));
  margin-left: auto;
  margin-right: auto;
  padding-right: var(--container-ws-x) !important;
  padding-left: var(--container-ws-x) !important;
}
@media (min-width: 700px) {
  .container,
  .container-visitor {
      --container-ws-x: var(--ws-8x);
  }
}
@media (min-width: 1200px) {
  .container,
  .container-visitor {
      --container-ws-x: var(--ws-20x);
  }
}
@media (max-width: 699.98px) {
  .container,
  .container-visitor {
      --card-radius-custom: 0;
  }
}
@media (min-width: 700px) {
  .container .up-card {
      margin-left: 0;
      margin-right: 0;
  }
}
.m-0 {
  margin: 0 !important;
}
.m-1,
.m-1x {
  margin: var(--ws-1x) !important;
}
.m-2,
.m-2x {
  margin: var(--ws-2x) !important;
}
.m-3,
.m-3x {
  margin: var(--ws-3x) !important;
}
.m-4,
.m-4x {
  margin: var(--ws-4x) !important;
}
.m-5,
.m-6x {
  margin: var(--ws-6x) !important;
}
.m-6,
.m-8x {
  margin: var(--ws-8x) !important;
}
.m-10x {
  margin: var(--ws-10x) !important;
}
.m-7,
.m-12x {
  margin: var(--ws-12x) !important;
}
.m-auto {
  margin: auto !important;
}
@media (min-width: 700px) {
  .m-md-0 {
      margin: 0 !important;
  }
  .m-md-1,
  .m-md-1x {
      margin: var(--ws-1x) !important;
  }
  .m-md-2,
  .m-md-2x {
      margin: var(--ws-2x) !important;
  }
  .m-md-3,
  .m-md-3x {
      margin: var(--ws-3x) !important;
  }
  .m-md-4,
  .m-md-4x {
      margin: var(--ws-4x) !important;
  }
  .m-md-5,
  .m-md-6x {
      margin: var(--ws-6x) !important;
  }
  .m-md-6,
  .m-md-8x {
      margin: var(--ws-8x) !important;
  }
  .m-md-10x {
      margin: var(--ws-10x) !important;
  }
  .m-md-7,
  .m-md-12x {
      margin: var(--ws-12x) !important;
  }
  .m-md-auto {
      margin: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-lg-0 {
      margin: 0 !important;
  }
  .m-lg-1,
  .m-lg-1x {
      margin: var(--ws-1x) !important;
  }
  .m-lg-2,
  .m-lg-2x {
      margin: var(--ws-2x) !important;
  }
  .m-lg-3,
  .m-lg-3x {
      margin: var(--ws-3x) !important;
  }
  .m-lg-4,
  .m-lg-4x {
      margin: var(--ws-4x) !important;
  }
  .m-lg-5,
  .m-lg-6x {
      margin: var(--ws-6x) !important;
  }
  .m-lg-6,
  .m-lg-8x {
      margin: var(--ws-8x) !important;
  }
  .m-lg-10x {
      margin: var(--ws-10x) !important;
  }
  .m-lg-7,
  .m-lg-12x {
      margin: var(--ws-12x) !important;
  }
  .m-lg-auto {
      margin: auto !important;
  }
}
.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.mx-1,
.mx-1x {
  margin-right: var(--ws-1x) !important;
  margin-left: var(--ws-1x) !important;
}
.mx-2,
.mx-2x {
  margin-right: var(--ws-2x) !important;
  margin-left: var(--ws-2x) !important;
}
.mx-3,
.mx-3x {
  margin-right: var(--ws-3x) !important;
  margin-left: var(--ws-3x) !important;
}
.mx-4,
.mx-4x {
  margin-right: var(--ws-4x) !important;
  margin-left: var(--ws-4x) !important;
}
.mx-5,
.mx-6x {
  margin-right: var(--ws-6x) !important;
  margin-left: var(--ws-6x) !important;
}
.mx-6,
.mx-8x {
  margin-right: var(--ws-8x) !important;
  margin-left: var(--ws-8x) !important;
}
.mx-10x {
  margin-right: var(--ws-10x) !important;
  margin-left: var(--ws-10x) !important;
}
.mx-7,
.mx-12x {
  margin-right: var(--ws-12x) !important;
  margin-left: var(--ws-12x) !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
@media (min-width: 700px) {
  .mx-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
  }
  .mx-md-1,
  .mx-md-1x {
      margin-right: var(--ws-1x) !important;
      margin-left: var(--ws-1x) !important;
  }
  .mx-md-2,
  .mx-md-2x {
      margin-right: var(--ws-2x) !important;
      margin-left: var(--ws-2x) !important;
  }
  .mx-md-3,
  .mx-md-3x {
      margin-right: var(--ws-3x) !important;
      margin-left: var(--ws-3x) !important;
  }
  .mx-md-4,
  .mx-md-4x {
      margin-right: var(--ws-4x) !important;
      margin-left: var(--ws-4x) !important;
  }
  .mx-md-5,
  .mx-md-6x {
      margin-right: var(--ws-6x) !important;
      margin-left: var(--ws-6x) !important;
  }
  .mx-md-6,
  .mx-md-8x {
      margin-right: var(--ws-8x) !important;
      margin-left: var(--ws-8x) !important;
  }
  .mx-md-10x {
      margin-right: var(--ws-10x) !important;
      margin-left: var(--ws-10x) !important;
  }
  .mx-md-7,
  .mx-md-12x {
      margin-right: var(--ws-12x) !important;
      margin-left: var(--ws-12x) !important;
  }
  .mx-md-auto {
      margin-right: auto !important;
      margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .mx-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
  }
  .mx-lg-1,
  .mx-lg-1x {
      margin-right: var(--ws-1x) !important;
      margin-left: var(--ws-1x) !important;
  }
  .mx-lg-2,
  .mx-lg-2x {
      margin-right: var(--ws-2x) !important;
      margin-left: var(--ws-2x) !important;
  }
  .mx-lg-3,
  .mx-lg-3x {
      margin-right: var(--ws-3x) !important;
      margin-left: var(--ws-3x) !important;
  }
  .mx-lg-4,
  .mx-lg-4x {
      margin-right: var(--ws-4x) !important;
      margin-left: var(--ws-4x) !important;
  }
  .mx-lg-5,
  .mx-lg-6x {
      margin-right: var(--ws-6x) !important;
      margin-left: var(--ws-6x) !important;
  }
  .mx-lg-6,
  .mx-lg-8x {
      margin-right: var(--ws-8x) !important;
      margin-left: var(--ws-8x) !important;
  }
  .mx-lg-10x {
      margin-right: var(--ws-10x) !important;
      margin-left: var(--ws-10x) !important;
  }
  .mx-lg-7,
  .mx-lg-12x {
      margin-right: var(--ws-12x) !important;
      margin-left: var(--ws-12x) !important;
  }
  .mx-lg-auto {
      margin-right: auto !important;
      margin-left: auto !important;
  }
}
.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.my-1,
.my-1x {
  margin-top: var(--ws-1x) !important;
  margin-bottom: var(--ws-1x) !important;
}
.my-2,
.my-2x {
  margin-top: var(--ws-2x) !important;
  margin-bottom: var(--ws-2x) !important;
}
.my-3,
.my-3x {
  margin-top: var(--ws-3x) !important;
  margin-bottom: var(--ws-3x) !important;
}
.my-4,
.my-4x {
  margin-top: var(--ws-4x) !important;
  margin-bottom: var(--ws-4x) !important;
}
.my-5,
.my-6x {
  margin-top: var(--ws-6x) !important;
  margin-bottom: var(--ws-6x) !important;
}
.my-6,
.my-8x {
  margin-top: var(--ws-8x) !important;
  margin-bottom: var(--ws-8x) !important;
}
.my-10x {
  margin-top: var(--ws-10x) !important;
  margin-bottom: var(--ws-10x) !important;
}
.my-7,
.my-12x {
  margin-top: var(--ws-12x) !important;
  margin-bottom: var(--ws-12x) !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
@media (min-width: 700px) {
  .my-md-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
  }
  .my-md-1,
  .my-md-1x {
      margin-top: var(--ws-1x) !important;
      margin-bottom: var(--ws-1x) !important;
  }
  .my-md-2,
  .my-md-2x {
      margin-top: var(--ws-2x) !important;
      margin-bottom: var(--ws-2x) !important;
  }
  .my-md-3,
  .my-md-3x {
      margin-top: var(--ws-3x) !important;
      margin-bottom: var(--ws-3x) !important;
  }
  .my-md-4,
  .my-md-4x {
      margin-top: var(--ws-4x) !important;
      margin-bottom: var(--ws-4x) !important;
  }
  .my-md-5,
  .my-md-6x {
      margin-top: var(--ws-6x) !important;
      margin-bottom: var(--ws-6x) !important;
  }
  .my-md-6,
  .my-md-8x {
      margin-top: var(--ws-8x) !important;
      margin-bottom: var(--ws-8x) !important;
  }
  .my-md-10x {
      margin-top: var(--ws-10x) !important;
      margin-bottom: var(--ws-10x) !important;
  }
  .my-md-7,
  .my-md-12x {
      margin-top: var(--ws-12x) !important;
      margin-bottom: var(--ws-12x) !important;
  }
  .my-md-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
  }
}
@media (min-width: 1200px) {
  .my-lg-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
  }
  .my-lg-1,
  .my-lg-1x {
      margin-top: var(--ws-1x) !important;
      margin-bottom: var(--ws-1x) !important;
  }
  .my-lg-2,
  .my-lg-2x {
      margin-top: var(--ws-2x) !important;
      margin-bottom: var(--ws-2x) !important;
  }
  .my-lg-3,
  .my-lg-3x {
      margin-top: var(--ws-3x) !important;
      margin-bottom: var(--ws-3x) !important;
  }
  .my-lg-4,
  .my-lg-4x {
      margin-top: var(--ws-4x) !important;
      margin-bottom: var(--ws-4x) !important;
  }
  .my-lg-5,
  .my-lg-6x {
      margin-top: var(--ws-6x) !important;
      margin-bottom: var(--ws-6x) !important;
  }
  .my-lg-6,
  .my-lg-8x {
      margin-top: var(--ws-8x) !important;
      margin-bottom: var(--ws-8x) !important;
  }
  .my-lg-10x {
      margin-top: var(--ws-10x) !important;
      margin-bottom: var(--ws-10x) !important;
  }
  .my-lg-7,
  .my-lg-12x {
      margin-top: var(--ws-12x) !important;
      margin-bottom: var(--ws-12x) !important;
  }
  .my-lg-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
  }
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1,
.mt-1x {
  margin-top: var(--ws-1x) !important;
}
.mt-2,
.mt-2x {
  margin-top: var(--ws-2x) !important;
}
.mt-3,
.mt-3x {
  margin-top: var(--ws-3x) !important;
}
.mt-4,
.mt-4x {
  margin-top: var(--ws-4x) !important;
}
.mt-5,
.mt-6x {
  margin-top: var(--ws-6x) !important;
}
.mt-6,
.mt-8x {
  margin-top: var(--ws-8x) !important;
}
.mt-10x {
  margin-top: var(--ws-10x) !important;
}
.mt-7,
.mt-12x {
  margin-top: var(--ws-12x) !important;
}
.mt-auto {
  margin-top: auto !important;
}
@media (min-width: 700px) {
  .mt-md-0 {
      margin-top: 0 !important;
  }
  .mt-md-1,
  .mt-md-1x {
      margin-top: var(--ws-1x) !important;
  }
  .mt-md-2,
  .mt-md-2x {
      margin-top: var(--ws-2x) !important;
  }
  .mt-md-3,
  .mt-md-3x {
      margin-top: var(--ws-3x) !important;
  }
  .mt-md-4,
  .mt-md-4x {
      margin-top: var(--ws-4x) !important;
  }
  .mt-md-5,
  .mt-md-6x {
      margin-top: var(--ws-6x) !important;
  }
  .mt-md-6,
  .mt-md-8x {
      margin-top: var(--ws-8x) !important;
  }
  .mt-md-10x {
      margin-top: var(--ws-10x) !important;
  }
  .mt-md-7,
  .mt-md-12x {
      margin-top: var(--ws-12x) !important;
  }
  .mt-md-auto {
      margin-top: auto !important;
  }
}
@media (min-width: 1200px) {
  .mt-lg-0 {
      margin-top: 0 !important;
  }
  .mt-lg-1,
  .mt-lg-1x {
      margin-top: var(--ws-1x) !important;
  }
  .mt-lg-2,
  .mt-lg-2x {
      margin-top: var(--ws-2x) !important;
  }
  .mt-lg-3,
  .mt-lg-3x {
      margin-top: var(--ws-3x) !important;
  }
  .mt-lg-4,
  .mt-lg-4x {
      margin-top: var(--ws-4x) !important;
  }
  .mt-lg-5,
  .mt-lg-6x {
      margin-top: var(--ws-6x) !important;
  }
  .mt-lg-6,
  .mt-lg-8x {
      margin-top: var(--ws-8x) !important;
  }
  .mt-lg-10x {
      margin-top: var(--ws-10x) !important;
  }
  .mt-lg-7,
  .mt-lg-12x {
      margin-top: var(--ws-12x) !important;
  }
  .mt-lg-auto {
      margin-top: auto !important;
  }
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-1,
.mr-1x {
  margin-right: var(--ws-1x) !important;
}
.mr-2,
.mr-2x {
  margin-right: var(--ws-2x) !important;
}
.mr-3,
.mr-3x {
  margin-right: var(--ws-3x) !important;
}
.mr-4,
.mr-4x {
  margin-right: var(--ws-4x) !important;
}
.mr-5,
.mr-6x {
  margin-right: var(--ws-6x) !important;
}
.mr-6,
.mr-8x {
  margin-right: var(--ws-8x) !important;
}
.mr-10x {
  margin-right: var(--ws-10x) !important;
}
.mr-7,
.mr-12x {
  margin-right: var(--ws-12x) !important;
}
.mr-auto {
  margin-right: auto !important;
}
@media (min-width: 700px) {
  .mr-md-0 {
      margin-right: 0 !important;
  }
  .mr-md-1,
  .mr-md-1x {
      margin-right: var(--ws-1x) !important;
  }
  .mr-md-2,
  .mr-md-2x {
      margin-right: var(--ws-2x) !important;
  }
  .mr-md-3,
  .mr-md-3x {
      margin-right: var(--ws-3x) !important;
  }
  .mr-md-4,
  .mr-md-4x {
      margin-right: var(--ws-4x) !important;
  }
  .mr-md-5,
  .mr-md-6x {
      margin-right: var(--ws-6x) !important;
  }
  .mr-md-6,
  .mr-md-8x {
      margin-right: var(--ws-8x) !important;
  }
  .mr-md-10x {
      margin-right: var(--ws-10x) !important;
  }
  .mr-md-7,
  .mr-md-12x {
      margin-right: var(--ws-12x) !important;
  }
  .mr-md-auto {
      margin-right: auto !important;
  }
}
@media (min-width: 1200px) {
  .mr-lg-0 {
      margin-right: 0 !important;
  }
  .mr-lg-1,
  .mr-lg-1x {
      margin-right: var(--ws-1x) !important;
  }
  .mr-lg-2,
  .mr-lg-2x {
      margin-right: var(--ws-2x) !important;
  }
  .mr-lg-3,
  .mr-lg-3x {
      margin-right: var(--ws-3x) !important;
  }
  .mr-lg-4,
  .mr-lg-4x {
      margin-right: var(--ws-4x) !important;
  }
  .mr-lg-5,
  .mr-lg-6x {
      margin-right: var(--ws-6x) !important;
  }
  .mr-lg-6,
  .mr-lg-8x {
      margin-right: var(--ws-8x) !important;
  }
  .mr-lg-10x {
      margin-right: var(--ws-10x) !important;
  }
  .mr-lg-7,
  .mr-lg-12 {
      margin-right: var(--ws-12x) !important;
  }
  .mr-lg-auto {
      margin-right: auto !important;
  }
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1,
.mb-1x {
  margin-bottom: var(--ws-1x) !important;
}
.mb-2,
.mb-2x {
  margin-bottom: var(--ws-2x) !important;
}
.mb-3,
.mb-3x {
  margin-bottom: var(--ws-3x) !important;
}
.mb-4,
.mb-4x {
  margin-bottom: var(--ws-4x) !important;
}
.mb-5,
.mb-6x {
  margin-bottom: var(--ws-6x) !important;
}
.mb-6,
.mb-8x {
  margin-bottom: var(--ws-8x) !important;
}
.mb-10x {
  margin-bottom: var(--ws-10x) !important;
}
.mb-7,
.mb-12x {
  margin-bottom: var(--ws-12x) !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
@media (min-width: 700px) {
  .mb-md-0 {
      margin-bottom: 0 !important;
  }
  .mb-md-1,
  .mb-md-1x {
      margin-bottom: var(--ws-1x) !important;
  }
  .mb-md-2,
  .mb-md-2x {
      margin-bottom: var(--ws-2x) !important;
  }
  .mb-md-3,
  .mb-md-3x {
      margin-bottom: var(--ws-3x) !important;
  }
  .mb-md-4,
  .mb-md-4x {
      margin-bottom: var(--ws-4x) !important;
  }
  .mb-md-5,
  .mb-md-6x {
      margin-bottom: var(--ws-6x) !important;
  }
  .mb-md-6,
  .mb-md-8x {
      margin-bottom: var(--ws-8x) !important;
  }
  .mb-md-10x {
      margin-bottom: var(--ws-10x) !important;
  }
  .mb-md-7,
  .mb-md-12x {
      margin-bottom: var(--ws-12x) !important;
  }
  .mb-md-auto {
      margin-bottom: auto !important;
  }
}
@media (min-width: 1200px) {
  .mb-lg-0 {
      margin-bottom: 0 !important;
  }
  .mb-lg-1,
  .mb-lg-1x {
      margin-bottom: var(--ws-1x) !important;
  }
  .mb-lg-2,
  .mb-lg-2x {
      margin-bottom: var(--ws-2x) !important;
  }
  .mb-lg-3,
  .mb-lg-3x {
      margin-bottom: var(--ws-3x) !important;
  }
  .mb-lg-4,
  .mb-lg-4x {
      margin-bottom: var(--ws-4x) !important;
  }
  .mb-lg-5,
  .mb-lg-6x {
      margin-bottom: var(--ws-6x) !important;
  }
  .mb-lg-6,
  .mb-lg-8x {
      margin-bottom: var(--ws-8x) !important;
  }
  .mb-lg-10x {
      margin-bottom: var(--ws-10x) !important;
  }
  .mb-lg-7,
  .mb-lg-12x {
      margin-bottom: var(--ws-12x) !important;
  }
  .mb-lg-auto {
      margin-bottom: auto !important;
  }
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-1,
.ml-1x {
  margin-left: var(--ws-1x) !important;
}
.ml-2,
.ml-2x {
  margin-left: var(--ws-2x) !important;
}
.ml-3,
.ml-3x {
  margin-left: var(--ws-3x) !important;
}
.ml-4,
.ml-4x {
  margin-left: var(--ws-4x) !important;
}
.ml-5,
.ml-6x {
  margin-left: var(--ws-6x) !important;
}
.ml-6,
.ml-8x {
  margin-left: var(--ws-8x) !important;
}
.ml-10x {
  margin-left: var(--ws-10x) !important;
}
.ml-7,
.ml-12x {
  margin-left: var(--ws-12x) !important;
}
.ml-auto {
  margin-left: auto !important;
}
@media (min-width: 700px) {
  .ml-md-0 {
      margin-left: 0 !important;
  }
  .ml-md-1,
  .ml-md-1x {
      margin-left: var(--ws-1x) !important;
  }
  .ml-md-2,
  .ml-md-2x {
      margin-left: var(--ws-2x) !important;
  }
  .ml-md-3,
  .ml-md-3x {
      margin-left: var(--ws-3x) !important;
  }
  .ml-md-4,
  .ml-md-4x {
      margin-left: var(--ws-4x) !important;
  }
  .ml-md-5,
  .ml-md-6x {
      margin-left: var(--ws-6x) !important;
  }
  .ml-md-6,
  .ml-md-8x {
      margin-left: var(--ws-8x) !important;
  }
  .ml-md-10x {
      margin-left: var(--ws-10x) !important;
  }
  .ml-md-7,
  .ml-md-12x {
      margin-left: var(--ws-12x) !important;
  }
  .ml-md-auto {
      margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .ml-lg-0 {
      margin-left: 0 !important;
  }
  .ml-lg-1,
  .ml-lg-1x {
      margin-left: var(--ws-1x) !important;
  }
  .ml-lg-2,
  .ml-lg-2x {
      margin-left: var(--ws-2x) !important;
  }
  .ml-lg-3,
  .ml-lg-3x {
      margin-left: var(--ws-3x) !important;
  }
  .ml-lg-4,
  .ml-lg-4x {
      margin-left: var(--ws-4x) !important;
  }
  .ml-lg-5,
  .ml-lg-6x {
      margin-left: var(--ws-6x) !important;
  }
  .ml-lg-6,
  .ml-lg-8x {
      margin-left: var(--ws-8x) !important;
  }
  .ml-lg-10x {
      margin-left: var(--ws-10x) !important;
  }
  .ml-lg-7,
  .ml-lg-12x {
      margin-left: var(--ws-12x) !important;
  }
  .ml-lg-auto {
      margin-left: auto !important;
  }
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-grid {
  display: grid !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 700px) {
  .d-md-none {
      display: none !important;
  }
  .d-md-inline {
      display: inline !important;
  }
  .d-md-inline-block {
      display: inline-block !important;
  }
  .d-md-block {
      display: block !important;
  }
  .d-md-grid {
      display: grid !important;
  }
  .d-md-flex {
      display: flex !important;
  }
  .d-md-inline-flex {
      display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-lg-none {
      display: none !important;
  }
  .d-lg-inline {
      display: inline !important;
  }
  .d-lg-inline-block {
      display: inline-block !important;
  }
  .d-lg-block {
      display: block !important;
  }
  .d-lg-grid {
      display: grid !important;
  }
  .d-lg-flex {
      display: flex !important;
  }
  .d-lg-inline-flex {
      display: inline-flex !important;
  }
}
.flex-1 {
  flex: 1 !important;
}
.flex-none {
  flex: none !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row {
  flex-direction: row !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.justify-space-between {
  justify-content: space-between !important;
}
.justify-content-center {
  justify-content: center !important;
}
.align-items-center {
  align-items: center !important;
}
.air3-grid-container {
  --template-col: repeat(12, 1fr);
  --gap: var(--row-gap) var(--col-gap);
  display: grid;
  grid-template-columns: var(--template-col);
  grid-gap: var(--gap);
  gap: var(--gap);
}
@media (min-width: 700px) {
  .air3-grid-container {
      --row-gap: var(--ws-6x);
      --col-gap: var(--ws-6x);
  }
}
@media (min-width: 1200px) {
  .air3-grid-container {
      --row-gap: var(--ws-8x);
      --col-gap: var(--ws-8x);
  }
}
.air3-grid-container.gap-0 {
  --gap: 0;
}
.air3-grid-container.col-gap-0 {
  --col-gap: 0;
}
.air3-grid-container.row-gap-0 {
  --row-gap: 0;
}
[class*="span-"] {
  grid-column: 1/-1;
  min-width: 0;
}
.span-1 {
  grid-column: span 1;
}
.span-2 {
  grid-column: span 2;
}
.span-3 {
  grid-column: span 3;
}
.span-4 {
  grid-column: span 4;
}
.span-5 {
  grid-column: span 5;
}
.span-6 {
  grid-column: span 6;
}
.span-7 {
  grid-column: span 7;
}
.span-8 {
  grid-column: span 8;
}
.span-9 {
  grid-column: span 9;
}
.span-10 {
  grid-column: span 10;
}
.span-11 {
  grid-column: span 11;
}
.span-12 {
  grid-column: span 12;
}
@media (min-width: 700px) {
  .span-md-1 {
      grid-column: span 1;
  }
  .span-md-2 {
      grid-column: span 2;
  }
  .span-md-3 {
      grid-column: span 3;
  }
  .span-md-4 {
      grid-column: span 4;
  }
  .span-md-5 {
      grid-column: span 5;
  }
  .span-md-6 {
      grid-column: span 6;
  }
  .span-md-7 {
      grid-column: span 7;
  }
  .span-md-8 {
      grid-column: span 8;
  }
  .span-md-9 {
      grid-column: span 9;
  }
  .span-md-10 {
      grid-column: span 10;
  }
  .span-md-11 {
      grid-column: span 11;
  }
  .span-md-12 {
      grid-column: span 12;
  }
}
@media (min-width: 1200px) {
  .span-lg-1 {
      grid-column: span 1;
  }
  .span-lg-2 {
      grid-column: span 2;
  }
  .span-lg-3 {
      grid-column: span 3;
  }
  .span-lg-4 {
      grid-column: span 4;
  }
  .span-lg-5 {
      grid-column: span 5;
  }
  .span-lg-6 {
      grid-column: span 6;
  }
  .span-lg-7 {
      grid-column: span 7;
  }
  .span-lg-8 {
      grid-column: span 8;
  }
  .span-lg-9 {
      grid-column: span 9;
  }
  .span-lg-10 {
      grid-column: span 10;
  }
  .span-lg-11 {
      grid-column: span 11;
  }
  .span-lg-12 {
      grid-column: span 12;
  }
}

.p-0 {
  padding: 0 !important;
}
.p-1,
.p-1x {
  padding: var(--ws-1x) !important;
}
.p-2,
.p-2x {
  padding: var(--ws-2x) !important;
}
.p-3,
.p-3x {
  padding: var(--ws-3x) !important;
}
.p-4,
.p-4x {
  padding: var(--ws-4x) !important;
}
.p-5,
.p-6x {
  padding: var(--ws-6x) !important;
}
.p-6,
.p-8x {
  padding: var(--ws-8x) !important;
}
.p-10x {
  padding: var(--ws-10x) !important;
}
.p-7,
.p-12x {
  padding: var(--ws-12x) !important;
}
.p-auto {
  padding: auto !important;
}
@media (min-width: 700px) {
  .p-md-0 {
      padding: 0 !important;
  }
  .p-md-1,
  .p-md-1x {
      padding: var(--ws-1x) !important;
  }
  .p-md-2,
  .p-md-2x {
      padding: var(--ws-2x) !important;
  }
  .p-md-3,
  .p-md-3x {
      padding: var(--ws-3x) !important;
  }
  .p-md-4,
  .p-md-4x {
      padding: var(--ws-4x) !important;
  }
  .p-md-5,
  .p-md-6x {
      padding: var(--ws-6x) !important;
  }
  .p-md-6,
  .p-md-8x {
      padding: var(--ws-8x) !important;
  }
  .p-md-10x {
      padding: var(--ws-10x) !important;
  }
  .p-md-7,
  .p-md-12x {
      padding: var(--ws-12x) !important;
  }
  .p-md-auto {
      padding: auto !important;
  }
}
@media (min-width: 1200px) {
  .p-lg-0 {
      padding: 0 !important;
  }
  .p-lg-1,
  .p-lg-1x {
      padding: var(--ws-1x) !important;
  }
  .p-lg-2,
  .p-lg-2x {
      padding: var(--ws-2x) !important;
  }
  .p-lg-3,
  .p-lg-3x {
      padding: var(--ws-3x) !important;
  }
  .p-lg-4,
  .p-lg-4x {
      padding: var(--ws-4x) !important;
  }
  .p-lg-5,
  .p-lg-6x {
      padding: var(--ws-6x) !important;
  }
  .p-lg-6,
  .p-lg-8x {
      padding: var(--ws-8x) !important;
  }
  .p-lg-10x {
      padding: var(--ws-10x) !important;
  }
  .p-lg-7,
  .p-lg-12x {
      padding: var(--ws-12x) !important;
  }
  .p-lg-auto {
      padding: auto !important;
  }
}
.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.px-1,
.px-1x {
  padding-right: var(--ws-1x) !important;
  padding-left: var(--ws-1x) !important;
}
.px-2,
.px-2x {
  padding-right: var(--ws-2x) !important;
  padding-left: var(--ws-2x) !important;
}
.px-3,
.px-3x {
  padding-right: var(--ws-3x) !important;
  padding-left: var(--ws-3x) !important;
}
.px-4,
.px-4x {
  padding-right: var(--ws-4x) !important;
  padding-left: var(--ws-4x) !important;
}
.px-5,
.px-6x {
  padding-right: var(--ws-6x) !important;
  padding-left: var(--ws-6x) !important;
}
.px-6,
.px-8x {
  padding-right: var(--ws-8x) !important;
  padding-left: var(--ws-8x) !important;
}
.px-10x {
  padding-right: var(--ws-10x) !important;
  padding-left: var(--ws-10x) !important;
}
.px-7,
.px-12x {
  padding-right: var(--ws-12x) !important;
  padding-left: var(--ws-12x) !important;
}
.px-auto {
  padding-right: auto !important;
  padding-left: auto !important;
}
@media (min-width: 700px) {
  .px-md-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
  }
  .px-md-1,
  .px-md-1x {
      padding-right: var(--ws-1x) !important;
      padding-left: var(--ws-1x) !important;
  }
  .px-md-2,
  .px-md-2x {
      padding-right: var(--ws-2x) !important;
      padding-left: var(--ws-2x) !important;
  }
  .px-md-3,
  .px-md-3x {
      padding-right: var(--ws-3x) !important;
      padding-left: var(--ws-3x) !important;
  }
  .px-md-4,
  .px-md-4x {
      padding-right: var(--ws-4x) !important;
      padding-left: var(--ws-4x) !important;
  }
  .px-md-5,
  .px-md-6x {
      padding-right: var(--ws-6x) !important;
      padding-left: var(--ws-6x) !important;
  }
  .px-md-6,
  .px-md-8x {
      padding-right: var(--ws-8x) !important;
      padding-left: var(--ws-8x) !important;
  }
  .px-md-10x {
      padding-right: var(--ws-10x) !important;
      padding-left: var(--ws-10x) !important;
  }
  .px-md-7,
  .px-md-12x {
      padding-right: var(--ws-12x) !important;
      padding-left: var(--ws-12x) !important;
  }
  .px-md-auto {
      padding-right: auto !important;
      padding-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .px-lg-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
  }
  .px-lg-1,
  .px-lg-1x {
      padding-right: var(--ws-1x) !important;
      padding-left: var(--ws-1x) !important;
  }
  .px-lg-2,
  .px-lg-2x {
      padding-right: var(--ws-2x) !important;
      padding-left: var(--ws-2x) !important;
  }
  .px-lg-3,
  .px-lg-3x {
      padding-right: var(--ws-3x) !important;
      padding-left: var(--ws-3x) !important;
  }
  .px-lg-4,
  .px-lg-4x {
      padding-right: var(--ws-4x) !important;
      padding-left: var(--ws-4x) !important;
  }
  .px-lg-5,
  .px-lg-6x {
      padding-right: var(--ws-6x) !important;
      padding-left: var(--ws-6x) !important;
  }
  .px-lg-6,
  .px-lg-8x {
      padding-right: var(--ws-8x) !important;
      padding-left: var(--ws-8x) !important;
  }
  .px-lg-10x {
      padding-right: var(--ws-10x) !important;
      padding-left: var(--ws-10x) !important;
  }
  .px-lg-7,
  .px-lg-12x {
      padding-right: var(--ws-12x) !important;
      padding-left: var(--ws-12x) !important;
  }
  .px-lg-auto {
      padding-right: auto !important;
      padding-left: auto !important;
  }
}
.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.py-1,
.py-1x {
  padding-top: var(--ws-1x) !important;
  padding-bottom: var(--ws-1x) !important;
}
.py-2,
.py-2x {
  padding-top: var(--ws-2x) !important;
  padding-bottom: var(--ws-2x) !important;
}
.py-3,
.py-3x {
  padding-top: var(--ws-3x) !important;
  padding-bottom: var(--ws-3x) !important;
}
.py-4,
.py-4x {
  padding-top: var(--ws-4x) !important;
  padding-bottom: var(--ws-4x) !important;
}
.py-5,
.py-6x {
  padding-top: var(--ws-6x) !important;
  padding-bottom: var(--ws-6x) !important;
}
.py-6,
.py-8x {
  padding-top: var(--ws-8x) !important;
  padding-bottom: var(--ws-8x) !important;
}
.py-10x {
  padding-top: var(--ws-10x) !important;
  padding-bottom: var(--ws-10x) !important;
}
.py-7,
.py-12x {
  padding-top: var(--ws-12x) !important;
  padding-bottom: var(--ws-12x) !important;
}
.py-auto {
  padding-top: auto !important;
  padding-bottom: auto !important;
}
@media (min-width: 700px) {
  .py-md-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
  }
  .py-md-1,
  .py-md-1x {
      padding-top: var(--ws-1x) !important;
      padding-bottom: var(--ws-1x) !important;
  }
  .py-md-2,
  .py-md-2x {
      padding-top: var(--ws-2x) !important;
      padding-bottom: var(--ws-2x) !important;
  }
  .py-md-3,
  .py-md-3x {
      padding-top: var(--ws-3x) !important;
      padding-bottom: var(--ws-3x) !important;
  }
  .py-md-4,
  .py-md-4x {
      padding-top: var(--ws-4x) !important;
      padding-bottom: var(--ws-4x) !important;
  }
  .py-md-5,
  .py-md-6x {
      padding-top: var(--ws-6x) !important;
      padding-bottom: var(--ws-6x) !important;
  }
  .py-md-6,
  .py-md-8x {
      padding-top: var(--ws-8x) !important;
      padding-bottom: var(--ws-8x) !important;
  }
  .py-md-10x {
      padding-top: var(--ws-10x) !important;
      padding-bottom: var(--ws-10x) !important;
  }
  .py-md-7,
  .py-md-12x {
      padding-top: var(--ws-12x) !important;
      padding-bottom: var(--ws-12x) !important;
  }
  .py-md-auto {
      padding-top: auto !important;
      padding-bottom: auto !important;
  }
}
@media (min-width: 1200px) {
  .py-lg-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
  }
  .py-lg-1,
  .py-lg-1x {
      padding-top: var(--ws-1x) !important;
      padding-bottom: var(--ws-1x) !important;
  }
  .py-lg-2,
  .py-lg-2x {
      padding-top: var(--ws-2x) !important;
      padding-bottom: var(--ws-2x) !important;
  }
  .py-lg-3,
  .py-lg-3x {
      padding-top: var(--ws-3x) !important;
      padding-bottom: var(--ws-3x) !important;
  }
  .py-lg-4,
  .py-lg-4x {
      padding-top: var(--ws-4x) !important;
      padding-bottom: var(--ws-4x) !important;
  }
  .py-lg-5,
  .py-lg-6x {
      padding-top: var(--ws-6x) !important;
      padding-bottom: var(--ws-6x) !important;
  }
  .py-lg-6,
  .py-lg-8x {
      padding-top: var(--ws-8x) !important;
      padding-bottom: var(--ws-8x) !important;
  }
  .py-lg-10x {
      padding-top: var(--ws-10x) !important;
      padding-bottom: var(--ws-10x) !important;
  }
  .py-lg-7,
  .py-lg-12x {
      padding-top: var(--ws-12x) !important;
      padding-bottom: var(--ws-12x) !important;
  }
  .py-lg-auto {
      padding-top: auto !important;
      padding-bottom: auto !important;
  }
}
.pt-0 {
  padding-top: 0 !important;
}
.pt-1,
.pt-1x {
  padding-top: var(--ws-1x) !important;
}
.pt-2,
.pt-2x {
  padding-top: var(--ws-2x) !important;
}
.pt-3,
.pt-3x {
  padding-top: var(--ws-3x) !important;
}
.pt-4,
.pt-4x {
  padding-top: var(--ws-4x) !important;
}
.pt-5,
.pt-6x {
  padding-top: var(--ws-6x) !important;
}
.pt-6,
.pt-8x {
  padding-top: var(--ws-8x) !important;
}
.pt-10x {
  padding-top: var(--ws-10x) !important;
}
.pt-7,
.pt-12x {
  padding-top: var(--ws-12x) !important;
}
.pt-auto {
  padding-top: auto !important;
}
@media (min-width: 700px) {
  .pt-md-0 {
      padding-top: 0 !important;
  }
  .pt-md-1,
  .pt-md-1x {
      padding-top: var(--ws-1x) !important;
  }
  .pt-md-2,
  .pt-md-2x {
      padding-top: var(--ws-2x) !important;
  }
  .pt-md-3,
  .pt-md-3x {
      padding-top: var(--ws-3x) !important;
  }
  .pt-md-4,
  .pt-md-4x {
      padding-top: var(--ws-4x) !important;
  }
  .pt-md-5,
  .pt-md-6x {
      padding-top: var(--ws-6x) !important;
  }
  .pt-md-6,
  .pt-md-8x {
      padding-top: var(--ws-8x) !important;
  }
  .pt-md-10x {
      padding-top: var(--ws-10x) !important;
  }
  .pt-md-7,
  .pt-md-12x {
      padding-top: var(--ws-12x) !important;
  }
  .pt-md-auto {
      padding-top: auto !important;
  }
}
@media (min-width: 1200px) {
  .pt-lg-0 {
      padding-top: 0 !important;
  }
  .pt-lg-1,
  .pt-lg-1x {
      padding-top: var(--ws-1x) !important;
  }
  .pt-lg-2,
  .pt-lg-2x {
      padding-top: var(--ws-2x) !important;
  }
  .pt-lg-3,
  .pt-lg-3x {
      padding-top: var(--ws-3x) !important;
  }
  .pt-lg-4,
  .pt-lg-4x {
      padding-top: var(--ws-4x) !important;
  }
  .pt-lg-5,
  .pt-lg-6x {
      padding-top: var(--ws-6x) !important;
  }
  .pt-lg-6,
  .pt-lg-8x {
      padding-top: var(--ws-8x) !important;
  }
  .pt-lg-10x {
      padding-top: var(--ws-10x) !important;
  }
  .pt-lg-7,
  .pt-lg-12x {
      padding-top: var(--ws-12x) !important;
  }
  .pt-lg-auto {
      padding-top: auto !important;
  }
}
.pr-0 {
  padding-right: 0 !important;
}
.pr-1,
.pr-1x {
  padding-right: var(--ws-1x) !important;
}
.pr-2,
.pr-2x {
  padding-right: var(--ws-2x) !important;
}
.pr-3,
.pr-3x {
  padding-right: var(--ws-3x) !important;
}
.pr-4,
.pr-4x {
  padding-right: var(--ws-4x) !important;
}
.pr-5,
.pr-6x {
  padding-right: var(--ws-6x) !important;
}
.pr-6,
.pr-8x {
  padding-right: var(--ws-8x) !important;
}
.pr-10x {
  padding-right: var(--ws-10x) !important;
}
.pr-7,
.pr-12x {
  padding-right: var(--ws-12x) !important;
}
.pr-auto {
  padding-right: auto !important;
}
@media (min-width: 700px) {
  .pr-md-0 {
      padding-right: 0 !important;
  }
  .pr-md-1,
  .pr-md-1x {
      padding-right: var(--ws-1x) !important;
  }
  .pr-md-2,
  .pr-md-2x {
      padding-right: var(--ws-2x) !important;
  }
  .pr-md-3,
  .pr-md-3x {
      padding-right: var(--ws-3x) !important;
  }
  .pr-md-4,
  .pr-md-4x {
      padding-right: var(--ws-4x) !important;
  }
  .pr-md-5,
  .pr-md-6x {
      padding-right: var(--ws-6x) !important;
  }
  .pr-md-6,
  .pr-md-8x {
      padding-right: var(--ws-8x) !important;
  }
  .pr-md-10x {
      padding-right: var(--ws-10x) !important;
  }
  .pr-md-7,
  .pr-md-12x {
      padding-right: var(--ws-12x) !important;
  }
  .pr-md-auto {
      padding-right: auto !important;
  }
}
@media (min-width: 1200px) {
  .pr-lg-0 {
      padding-right: 0 !important;
  }
  .pr-lg-1,
  .pr-lg-1x {
      padding-right: var(--ws-1x) !important;
  }
  .pr-lg-2,
  .pr-lg-2x {
      padding-right: var(--ws-2x) !important;
  }
  .pr-lg-3,
  .pr-lg-3x {
      padding-right: var(--ws-3x) !important;
  }
  .pr-lg-4,
  .pr-lg-4x {
      padding-right: var(--ws-4x) !important;
  }
  .pr-lg-5,
  .pr-lg-6x {
      padding-right: var(--ws-6x) !important;
  }
  .pr-lg-6,
  .pr-lg-8x {
      padding-right: var(--ws-8x) !important;
  }
  .pr-lg-10x {
      padding-right: var(--ws-10x) !important;
  }
  .pr-lg-7,
  .pr-lg-12x {
      padding-right: var(--ws-12x) !important;
  }
  .pr-lg-auto {
      padding-right: auto !important;
  }
}
.pb-0 {
  padding-bottom: 0 !important;
}
.pb-1,
.pb-1x {
  padding-bottom: var(--ws-1x) !important;
}
.pb-2,
.pb-2x {
  padding-bottom: var(--ws-2x) !important;
}
.pb-3,
.pb-3x {
  padding-bottom: var(--ws-3x) !important;
}
.pb-4,
.pb-4x {
  padding-bottom: var(--ws-4x) !important;
}
.pb-5,
.pb-6x {
  padding-bottom: var(--ws-6x) !important;
}
.pb-6,
.pb-8x {
  padding-bottom: var(--ws-8x) !important;
}
.pb-10x {
  padding-bottom: var(--ws-10x) !important;
}
.pb-7,
.pb-12x {
  padding-bottom: var(--ws-12x) !important;
}
.pb-auto {
  padding-bottom: auto !important;
}
@media (min-width: 700px) {
  .pb-md-0 {
      padding-bottom: 0 !important;
  }
  .pb-md-1,
  .pb-md-1x {
      padding-bottom: var(--ws-1x) !important;
  }
  .pb-md-2,
  .pb-md-2x {
      padding-bottom: var(--ws-2x) !important;
  }
  .pb-md-3,
  .pb-md-3x {
      padding-bottom: var(--ws-3x) !important;
  }
  .pb-md-4,
  .pb-md-4x {
      padding-bottom: var(--ws-4x) !important;
  }
  .pb-md-5,
  .pb-md-6x {
      padding-bottom: var(--ws-6x) !important;
  }
  .pb-md-6,
  .pb-md-8x {
      padding-bottom: var(--ws-8x) !important;
  }
  .pb-md-10x {
      padding-bottom: var(--ws-10x) !important;
  }
  .pb-md-7,
  .pb-md-12x {
      padding-bottom: var(--ws-12x) !important;
  }
  .pb-md-auto {
      padding-bottom: auto !important;
  }
}
@media (min-width: 1200px) {
  .pb-lg-0 {
      padding-bottom: 0 !important;
  }
  .pb-lg-1,
  .pb-lg-1x {
      padding-bottom: var(--ws-1x) !important;
  }
  .pb-lg-2,
  .pb-lg-2x {
      padding-bottom: var(--ws-2x) !important;
  }
  .pb-lg-3,
  .pb-lg-3x {
      padding-bottom: var(--ws-3x) !important;
  }
  .pb-lg-4,
  .pb-lg-4x {
      padding-bottom: var(--ws-4x) !important;
  }
  .pb-lg-5,
  .pb-lg-6x {
      padding-bottom: var(--ws-6x) !important;
  }
  .pb-lg-6,
  .pb-lg-8x {
      padding-bottom: var(--ws-8x) !important;
  }
  .pb-lg-10x {
      padding-bottom: var(--ws-10x) !important;
  }
  .pb-lg-7,
  .pb-lg-12x {
      padding-bottom: var(--ws-12x) !important;
  }
  .pb-lg-auto {
      padding-bottom: auto !important;
  }
}
.pl-0 {
  padding-left: 0 !important;
}
.pl-1,
.pl-1x {
  padding-left: var(--ws-1x) !important;
}
.pl-2,
.pl-2x {
  padding-left: var(--ws-2x) !important;
}
.pl-3,
.pl-3x {
  padding-left: var(--ws-3x) !important;
}
.pl-4,
.pl-4x {
  padding-left: var(--ws-4x) !important;
}
.pl-5,
.pl-6x {
  padding-left: var(--ws-6x) !important;
}
.pl-6,
.pl-8x {
  padding-left: var(--ws-8x) !important;
}
.pl-10x {
  padding-left: var(--ws-10x) !important;
}
.pl-7,
.pl-12x {
  padding-left: var(--ws-12x) !important;
}
.pl-auto {
  padding-left: auto !important;
}
@media (min-width: 700px) {
  .pl-md-0 {
      padding-left: 0 !important;
  }
  .pl-md-1,
  .pl-md-1x {
      padding-left: var(--ws-1x) !important;
  }
  .pl-md-2,
  .pl-md-2x {
      padding-left: var(--ws-2x) !important;
  }
  .pl-md-3,
  .pl-md-3x {
      padding-left: var(--ws-3x) !important;
  }
  .pl-md-4,
  .pl-md-4x {
      padding-left: var(--ws-4x) !important;
  }
  .pl-md-5,
  .pl-md-6x {
      padding-left: var(--ws-6x) !important;
  }
  .pl-md-6,
  .pl-md-8x {
      padding-left: var(--ws-8x) !important;
  }
  .pl-md-10x {
      padding-left: var(--ws-10x) !important;
  }
  .pl-md-7,
  .pl-md-12x {
      padding-left: var(--ws-12x) !important;
  }
  .pl-md-auto {
      padding-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .pl-lg-0 {
      padding-left: 0 !important;
  }
  .pl-lg-1,
  .pl-lg-1x {
      padding-left: var(--ws-1x) !important;
  }
  .pl-lg-2,
  .pl-lg-2x {
      padding-left: var(--ws-2x) !important;
  }
  .pl-lg-3,
  .pl-lg-3x {
      padding-left: var(--ws-3x) !important;
  }
  .pl-lg-4,
  .pl-lg-4x {
      padding-left: var(--ws-4x) !important;
  }
  .pl-lg-5,
  .pl-lg-6x {
      padding-left: var(--ws-6x) !important;
  }
  .pl-lg-6,
  .pl-lg-8x {
      padding-left: var(--ws-8x) !important;
  }
  .pl-lg-10x {
      padding-left: var(--ws-10x) !important;
  }
  .pl-lg-7,
  .pl-lg-12x {
      padding-left: var(--ws-12x) !important;
  }
  .pl-lg-auto {
      padding-left: auto !important;
  }
}
.air3-btn {
  --btn-height: var(--height-base);
  --btn-gap: var(--ws-4x);
  --btn-padding-x: var(--ws-6x);
  --btn-border: var(--border-width-btn) solid var(--btn-border-color, transparent);
  --btn-font-size: var(--font-size-base);
  --btn-font-weight: var(--font-weight-strong);
  --btn-radius: var(--radius-pill);
  --btn-bg-primary: #000435;
  --btn-bg-primary-hover: #000435;
  --btn-text-primary: var(--text-on-btn-dark);
  --btn-text-primary-hover: var(--text-on-btn-dark-hover);
  --btn-text-icon-primary: var(--text-icon-on-btn-dark);
  --btn-border-color-primary: var(--border-color-btn-dark);
  --btn-shadow-primary: var(--shadow-on-btn-dark);
  --btn-bg-primary-inverted: var(--bg-btn-dark-inverted);
  --btn-bg-primary-inverted-hover: var(--bg-btn-dark-inverted-hover);
  --btn-text-primary-inverted: var(--text-on-btn-dark-inverted);
  --btn-text-primary-inverted-hover: var(--text-on-btn-dark-inverted-hover);
  --btn-text-icon-primary-inverted: var(--text-icon-on-btn-dark-inverted);
  --btn-border-color-primary-inverted: var(--border-color-btn-dark-inverted);
  --btn-shadow-primary-inverted: var(--shadow-on-btn-dark-inverted);
  --btn-bg-secondary: var(--bg-btn-light);
  --btn-bg-secondary-hover: var(--bg-btn-light-hover);
  --btn-text-secondary: var(--text-on-btn-light);
  --btn-text-secondary-hover: var(--text-on-btn-light-hover);
  --btn-text-icon-secondary: var(--text-icon-on-btn-light);
  --btn-border-color-secondary: var(--border-color-btn-light);
  --btn-shadow-secondary: var(--shadow-on-btn-light);
  --btn-bg-secondary-inverted: var(--bg-btn-light-inverted);
  --btn-bg-secondary-inverted-hover: var(--bg-btn-light-inverted-hover);
  --btn-text-secondary-inverted: var(--text-on-btn-light-inverted);
  --btn-text-secondary-inverted-hover: var(--text-on-btn-light-inverted-hover);
  --btn-text-icon-secondary-inverted: var(--text-icon-on-btn-light-inverted);
  --btn-border-color-secondary-inverted: var(--border-color-btn-light-inverted);
  --btn-shadow-secondary-inverted: var(--shadow-on-btn-light-inverted);
  --btn-bg-tertiary: var(--bg-btn-muted);
  --btn-bg-tertiary-hover: var(--bg-btn-muted-hover);
  --btn-text-tertiary: var(--text-on-btn-muted);
  --btn-text-tertiary-hover: var(--text-on-btn-muted-hover);
  --btn-text-icon-tertiary: var(--text-icon-on-btn-muted);
  --btn-border-color-tertiary: var(--border-color-btn-muted);
  --btn-shadow-tertiary: var(--shadow-on-btn-muted);
  --btn-bg-tertiary-inverted: var(--bg-btn-muted-inverted);
  --btn-bg-tertiary-inverted-hover: var(--bg-btn-muted-inverted-hover);
  --btn-text-tertiary-inverted: var(--text-on-btn-muted-inverted);
  --btn-text-tertiary-inverted-hover: var(--text-on-btn-muted-inverted-hover);
  --btn-text-icon-tertiary-inverted: var(--text-icon-on-btn-muted-inverted);
  --btn-border-color-tertiary-inverted: var(--border-color-btn-muted-inverted);
  --btn-shadow-tertiary-inverted: var(--shadow-on-btn-muted-inverted);
  --btn-font-weight-link: var(--font-weight-strong);
  --btn-text-link-inverted: var(--text-link-inverse);
  --btn-text-link-inverted-hover: var(--text-link-inverse-hover);
  --btn-height-sm: var(--height-sm);
  --btn-gap-sm: var(--ws-2x);
  --btn-padding-x-sm: var(--ws-4x);
  --btn-font-size-sm: var(--font-size-base-sm);
  --btn-bg-circle: var(--bg-btn-circle);
  --btn-bg-circle-hover: var(--bg-btn-circle-hover);
  --btn-text-circle: var(--text-on-btn-circle);
  --btn-text-circle-hover: var(--text-on-btn-circle-hover);
  --btn-bg-circle-inverted: var(--bg-btn-circle-inverted);
  --btn-bg-circle-inverted-hover: var(--bg-btn-circle-inverted-hover);
  --btn-text-circle-inverted: var(--text-on-btn-circle-inverted);
  --btn-text-circle-inverted-hover: var(--text-on-btn-circle-inverted-hover);
  --btn-size-counter: var(--height-sm);
  --btn-bg-counter: var(--hc-bg-btn, #000435);
  --btn-bg-counter-primary: #000435;
  --btn-bg-counter-primary-hover: var(--hc-bg-btn, #000435);
  --btn-text-counter: var(--hc-text-on-btn, #fff);
  --btn-text-counter-inline: var(--hc-text-on-selected, var(--up-lime));
  --btn-text-counter-inline-hover: var(--hc-bg-selected, var(--up-lime));
  display: inline-flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: var(--btn-gap);
  -moz-column-gap: var(--btn-gap);
  column-gap: var(--btn-gap);
  max-height: var(--btn-height);
  line-height: calc(var(--btn-height) - 1px);
  padding: 0 var(--btn-padding-x);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  background-color: transparent;
  background-color: var(--btn-bg, transparent);
  color: inherit;
  color: var(--btn-text, inherit);
  border: var(--btn-border);
  border-radius: var(--btn-radius);
  box-shadow: none;
  box-shadow: var(--btn-shadow, none);
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  text-decoration: none;
}
@media (hover: hover) {
  .air3-btn:hover {
      --btn-bg: var(--btn-bg-hover, inherit);
      --btn-text: var(--btn-text-hover, inherit);
  }
}
.air3-btn .air3-icon {
  flex: none;
  color: inherit;
  color: var(--btn-text-icon, inherit);
}
.air3-btn:is(a) {
  --underline-link: none;
  --underline-link-hover: none;
  --text-link: var(--btn-text);
  --text-link-hover: var(--btn-text);
  --link-color-hover: var(--btn-text);
}
@media (forced-colors: active) {
  .air3-btn:is(a):hover {
      border-color: var(--hc-bg-selected);
  }
}
.air3-btn:active:not([disabled]):not(.is-disabled) {
  transform: scale(0.96);
  transform: scale(var(--reduced-motion, 0.96));
}
.air3-btn.is-disabled,
.air3-btn[disabled] {
  --btn-text: var(--text-on-disabled);
  cursor: not-allowed;
}
.air3-btn-primary {
  --btn-bg: #000435;
  --btn-bg-hover: #000435;
  --btn-shadow: var(--btn-shadow-primary);
  --btn-text-icon: var(--btn-text-icon-primary);
}
.air3-btn-primary:not([disabled]):not(.is-disabled) {
  --btn-border-color: var(--btn-border-color-primary);
}
@media (forced-colors: active) {
  .air3-btn-primary:not([disabled]):not(.is-disabled) {
      forced-color-adjust: none;
  }
  .air3-btn-primary:not([disabled]):not(.is-disabled):focus {
      outline-color: var(--hc-text-on-selected);
  }
}
.air3-btn-primary,
.air3-btn-primary:is(a) {
  --btn-text: #fff;
  --btn-text-hover: var(--btn-text-primary-hover);
}
@media (forced-colors: active) {
  .air3-btn-primary:is(a) {
      --btn-bg: var(--hc-text-link);
      --btn-text: var(--hc-bg-base);
      --btn-border-color-primary: var(--hc-text-link);
  }
}
.air3-btn-primary-inverted {
  --btn-bg: var(--btn-bg-primary-inverted);
  --btn-bg-hover: var(--btn-bg-primary-inverted-hover);
  --btn-text: var(--btn-text-primary-inverted);
  --btn-text-hover: var(--btn-text-primary-inverted-hover);
  --btn-text-icon: var(--btn-text-icon-primary-inverted);
  --btn-border-color: var(--btn-border-color-primary-inverted);
  --btn-shadow: var(--btn-shadow-primary-inverted);
}
.air3-btn-secondary {
  --btn-bg: var(--btn-bg-secondary);
  --btn-bg-hover: var(--btn-bg-secondary-hover);
  --btn-text: var(--btn-text-secondary);
  --btn-text-hover: var(--btn-text-secondary-hover);
  --btn-text-icon: var(--btn-text-icon-secondary);
  --btn-shadow: var(--btn-shadow-secondary);
}
.air3-btn-secondary:not([disabled]):not(.is-disabled) {
  --btn-border-color: var(--btn-border-color-secondary);
}
.air3-btn-secondary-inverted {
  --btn-bg: var(--btn-bg-secondary-inverted);
  --btn-bg-hover: var(--btn-bg-secondary-inverted-hover);
  --btn-text-icon: var(--btn-text-icon-secondary-inverted);
  --btn-shadow: var(--btn-shadow-secondary-inverted);
}
.air3-btn-secondary-inverted:not([disabled]):not(.is-disabled) {
  --btn-border-color: var(--btn-border-color-secondary-inverted);
}
.air3-btn-secondary-inverted,
.air3-btn-secondary-inverted:is(a) {
  --btn-text: var(--btn-text-secondary-inverted);
  --btn-text-hover: var(--btn-text-secondary-inverted-hover);
}
.air3-btn-tertiary {
  --btn-bg: var(--btn-bg-tertiary);
  --btn-bg-hover: var(--btn-bg-tertiary-hover);
  --btn-text: var(--btn-text-tertiary);
  --btn-text-hover: var(--btn-text-tertiary-hover);
  --btn-text-icon: var(--btn-text-icon-tertiary);
  --btn-shadow: var(--btn-shadow-tertiary);
}
.air3-btn-tertiary:not([disabled]):not(.is-disabled) {
  --btn-border-color: var(--btn-border-color-tertiary);
}
.air3-btn-tertiary-inverted {
  --btn-bg: var(--btn-bg-tertiary-inverted);
  --btn-bg-hover: var(--btn-bg-tertiary-inverted-hover);
  --btn-text: var(--btn-text-tertiary-inverted);
  --btn-text-hover: var(--btn-text-tertiary-inverted-hover);
  --btn-text-icon: var(--btn-text-icon-tertiary-inverted);
  --btn-border-color: var(--btn-border-color-tertiary-inverted);
  --btn-shadow: var(--btn-shadow-tertiary-inverted);
}
.air3-btn-link {
  background: var(--bg-btn-link);
  color: var(--text-on-btn-link);
  -webkit-text-decoration: var(--underline-btn-link);
  text-decoration: var(--underline-btn-link);
}
.air3-btn-link:hover:where(:not([disabled]):not(.is-disabled)) {
  background: var(--bg-btn-link-hover);
  color: var(--text-on-btn-link-hover);
  -webkit-text-decoration: var(--underline-btn-link-hover);
  text-decoration: var(--underline-btn-link-hover);
}
.air3-btn-link:not(.air3-btn):hover {
  --underline-btn-link-hover: underline;
  color: var(--text-link-hover);
}
.air3-btn-link-secondary {
  color: var(--text-link);
  -webkit-text-decoration: var(--underline-link);
  text-decoration: var(--underline-link);
}
.air3-btn-link-secondary:hover:where(:not([disabled]):not(.is-disabled)) {
  color: var(--text-link-hover);
  -webkit-text-decoration: var(--underline-link-hover);
  text-decoration: var(--underline-link-hover);
}
.air3-btn-link-inverted,
.air3-btn-link-inverted.is-disabled,
.air3-btn-link-inverted[disabled] {
  --btn-bg: transparent;
  --btn-text: var(--btn-text-link-inverted);
}
@media (hover: hover) {
  .air3-btn-link-inverted:hover:not([disabled]):not(.is-disabled) {
      --btn-bg: transparent;
      --btn-text: var(--btn-text-link-inverted-hover);
  }
}
.air3-btn-link,
.air3-btn-link-inverted,
.air3-btn-link-secondary {
  border: 0;
  font-weight: var(--font-weight-base);
}
.air3-btn-link-inverted:not(.air3-btn),
.air3-btn-link-secondary:not(.air3-btn),
.air3-btn-link:not(.air3-btn) {
  padding: 0;
  background: transparent;
}
.air3-btn-link-inverted.is-disabled,
.air3-btn-link-inverted[disabled],
.air3-btn-link-secondary.is-disabled,
.air3-btn-link-secondary[disabled],
.air3-btn-link.is-disabled,
.air3-btn-link[disabled] {
  color: var(--text-on-disabled);
  cursor: not-allowed;
}
.air3-btn-circle-inverted.is-disabled,
.air3-btn-circle-inverted[disabled],
.air3-btn-link-inverted.is-disabled,
.air3-btn-link-inverted[disabled],
.air3-btn-primary-inverted.is-disabled,
.air3-btn-primary-inverted[disabled],
.air3-btn-secondary-inverted.is-disabled,
.air3-btn-secondary-inverted[disabled],
.air3-btn-tertiary-inverted.is-disabled,
.air3-btn-tertiary-inverted[disabled] {
  --btn-bg-hover: transparent;
  --btn-border-color: transparent;
  opacity: 0.4;
}
@media (forced-colors: active) {
  .air3-btn-circle-inverted.is-disabled,
  .air3-btn-circle-inverted[disabled],
  .air3-btn-link-inverted.is-disabled,
  .air3-btn-link-inverted[disabled],
  .air3-btn-primary-inverted.is-disabled,
  .air3-btn-primary-inverted[disabled],
  .air3-btn-secondary-inverted.is-disabled,
  .air3-btn-secondary-inverted[disabled],
  .air3-btn-tertiary-inverted.is-disabled,
  .air3-btn-tertiary-inverted[disabled] {
      opacity: 1;
  }
}
.air3-btn-primary.is-disabled,
.air3-btn-primary[disabled],
.air3-btn-secondary.is-disabled,
.air3-btn-secondary[disabled],
.air3-btn-tertiary.is-disabled,
.air3-btn-tertiary[disabled] {
  --btn-bg: var(--bg-disabled);
}
.air3-btn-primary-inverted.is-disabled,
.air3-btn-primary-inverted[disabled],
.air3-btn-secondary-inverted.is-disabled,
.air3-btn-secondary-inverted[disabled],
.air3-btn-tertiary-inverted.is-disabled,
.air3-btn-tertiary-inverted[disabled] {
  --btn-bg: var(--bg-disabled-inverse);
  --btn-text: var(--text-on-disabled-inverse);
}
.air3-btn-sm {
  --btn-padding-x: var(--btn-padding-x-sm);
  --btn-font-size: var(--btn-font-size-sm);
  --btn-height: var(--btn-height-sm);
  --btn-gap: var(--btn-gap-sm);
}
.air3-btn-circle,
.air3-btn-circle-inverted {
  width: var(--btn-height);
  height: var(--btn-height);
  padding: 0;
  border-radius: 50%;
  max-height: unset;
}
.air3-btn-circle:not([disabled]):not(.is-disabled):not(.air3-btn-primary):not(.air3-btn-secondary):not(.air3-btn-tertiary):not(.air3-btn-primary-inverted):not(.air3-btn-secondary-inverted):not(.air3-btn-tertiary-inverted) {
  --btn-bg: var(--btn-bg-circle);
  --btn-text: var(--btn-text-circle);
}
.air3-btn-circle:not([disabled]):not(.is-disabled):not(.air3-btn-primary):not(.air3-btn-secondary):not(.air3-btn-tertiary):not(.air3-btn-primary-inverted):not(.air3-btn-secondary-inverted):not(.air3-btn-tertiary-inverted):hover {
  --btn-bg: var(--btn-bg-circle-hover);
  --btn-text: var(--btn-text-circle-hover);
}
.air3-btn-circle.is-disabled,
.air3-btn-circle[disabled] {
  --btn-bg-hover: transparent;
}
.air3-btn-circle-inverted,
.air3-btn-circle-inverted.is-disabled,
.air3-btn-circle-inverted[disabled] {
  --btn-text: var(--btn-text-circle-inverted);
}
.air3-btn-circle-inverted:not([disabled]):not(.is-disabled):not(.air3-btn-secondary):not(.air3-btn-tertiary) {
  --btn-bg: var(--btn-bg-circle-inverted);
  --btn-text: var(--btn-text-circle-inverted-hover);
}
.air3-btn-circle-inverted:not([disabled]):not(.is-disabled):not(.air3-btn-secondary):not(.air3-btn-tertiary):hover {
  --btn-bg: var(--btn-bg-circle-inverted-hover);
}
.air3-btn-block {
  display: flex;
  width: 100%;
}
@media (max-width: 699.98px) {
  .air3-btn-block-sm {
      width: 100%;
  }
}
.air3-btn-counter .air3-btn-counter-text {
  flex: 1;
}
.air3-btn-counter .air3-btn-counter-circle {
  background-color: var(--btn-bg-counter);
  color: var(--btn-text-counter);
  border-radius: 50%;
  width: var(--btn-size-counter);
  height: var(--btn-size-counter);
  min-width: var(--btn-size-counter);
  min-height: var(--btn-size-counter);
  line-height: 34px;
  margin-right: calc(var(--btn-padding-x) * -1 - var(--ws-1x) * -1);
}
@media (max-width: 699.98px) {
  .air3-btn-counter[class*="air3-btn-block"] .air3-btn-counter-circle {
      margin-left: calc(var(--btn-gap) * -2);
  }
}
.air3-btn-counter.air3-btn-primary {
  --btn-bg-counter: var(--btn-bg-counter-primary);
}
.air3-btn-counter.air3-btn-primary:hover {
  --btn-bg-counter: var(--btn-bg-counter-primary-hover);
}
.air3-btn-counter.is-disabled .air3-btn-counter-circle,
.air3-btn-counter[disabled] .air3-btn-counter-circle {
  --btn-bg-counter: var(--bg-disabled-inner);
  --btn-bg-counter-primary: var(--bg-disabled-inner);
  --btn-bg-counter-primary-hover: var(--bg-disabled-inner);
  --btn-text-counter: var(--text-on-disabled-inner);
}
.air3-btn-counter .air3-btn-counter-inline {
  display: contents;
}
.air3-btn-counter:not([disabled]):not(.is-disabled) .air3-btn-counter-inline {
  color: var(--btn-text-counter-inline);
}
.air3-btn-counter:not([disabled]):not(.is-disabled):hover .air3-btn-counter-inline {
  --btn-text-counter-inline: var(--btn-text-counter-inline-hover);
}
small {
  font-size: var(--font-size-base-xs);
  line-height: 1.454;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  word-break: break-word;
  margin: 0 0 var(--ws-heading);
  font-family: inherit;
  font-weight: var(--font-weight-heading);
  color: var(--text-heading);
}
.h1.display-rebrand,
.h2.display-rebrand,
.h3.display-rebrand,
.h4.display-rebrand,
.h5.display-rebrand,
.h6.display-rebrand,
h1.display-rebrand,
h2.display-rebrand,
h3.display-rebrand,
h4.display-rebrand,
h5.display-rebrand,
h6.display-rebrand {
  font-family: Ubuntu;
  font-weight: var(--font-weight-heading);
  font-weight: var(--font-weight-rebrand, var(--font-weight-heading));
}
.h1,
h1 {
  font-size: var(--font-size-h1);
  letter-spacing: var(--letter-spacing-h1);
  line-height: var(--line-height-h1);
}
.h1.display-rebrand,
h1.display-rebrand {
  font-size: var(--font-size-h1);
  font-size: var(--font-size-h1-rebrand, var(--font-size-h1));
  letter-spacing: var(--letter-spacing-h1-rebrand);
}
.h2,
h2 {
  font-size: var(--font-size-h2);
  letter-spacing: var(--letter-spacing-h2);
  line-height: var(--line-height-h2);
}
.h2.display-rebrand,
h2.display-rebrand {
  font-size: var(--font-size-h2);
  font-size: var(--font-size-h2-rebrand, var(--font-size-h2));
  letter-spacing: var(--letter-spacing-h2-rebrand);
}
.h3,
h3 {
  font-size: var(--font-size-h3);
  letter-spacing: var(--letter-spacing-h3);
  line-height: var(--line-height-h3);
}
.h3.display-rebrand,
h3.display-rebrand {
  letter-spacing: var(--letter-spacing-h3-rebrand);
}
.h4,
h4 {
  font-size: var(--font-size-h4);
  letter-spacing: var(--letter-spacing-h4);
  line-height: var(--line-height-h4);
}
.h4.display-rebrand,
h4.display-rebrand {
  letter-spacing: var(--letter-spacing-h4-rebrand);
}
.h5,
.h6,
h5,
h6 {
  font-size: var(--font-size-h5);
  letter-spacing: var(--letter-spacing-h5);
  line-height: var(--line-height-h5);
}
.h5.display-rebrand,
.h6.display-rebrand,
h5.display-rebrand,
h6.display-rebrand {
  letter-spacing: var(--letter-spacing-h5-rebrand);
}
cite {
  font-style: italic;
  font-size: calc(var(--font-size-base) + 5px);
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}
strong {
  font-weight: var(--font-weight-strong);
}
p {
  margin: 0 0 var(--ws-2x);
  line-height: var(--line-height-base);
}
.break {
  word-wrap: break-word;
  word-break: break-word;
}
.nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.text-pre {
  white-space: pre !important;
}
.text-pre-line {
  white-space: pre-line !important;
}
code,
kbd,
pre,
samp {
  font-family: var(--font-family-monospace);
}
.text-vertical-separator {
  display: inline-block;
  position: relative;
  padding: var(--ws-2x);
  font-size: var(--font-size-base-xs);
  color: currentColor;
}
.text-vertical-separator:after,
.text-vertical-separator:before {
  content: "";
  position: absolute;
  height: var(--ws-2x);
  border-right: 1px solid;
  left: 50%;
}
.text-vertical-separator:before {
  top: 0;
}
.text-vertical-separator:after {
  bottom: 0;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-justify {
  text-align: justify !important;
}
ul {
  list-style: var(--list-style-ul);
}
ol {
  list-style: var(--list-style-ol);
}
ol,
ul {
  margin-top: 0;
  margin-bottom: var(--list-margin);
  padding-left: var(--list-padding);
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
ol li,
ul li {
  margin-bottom: var(--list-margin);
}
.list-styled {
  padding-left: var(--ws-4x);
}
.list-styled,
.list-styled li {
  margin-bottom: var(--ws-2x);
}
ul.list-styled {
  list-style: disc;
}
ol.list-styled {
  list-style: decimal;
}
.list-inline,
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  margin-left: calc(var(--ws-1x) * -1);
}
.list-inline > li {
  display: inline-block;
  padding-left: var(--ws-1x);
  padding-right: var(--ws-1x);
}
dl {
  margin-top: 0;
  margin-bottom: 18px;
}
dt {
  font-weight: var(--font-weight-strong);
}
dd {
  margin-left: 0;
}
abbr[title] {
  cursor: help;
  border-bottom: 1px dotted var(--border-color);
  text-decoration: none;
}
sub,
sup {
  font-size: var(--font-size-base-xs);
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
.text-base,
.text-body {
  font-size: var(--font-size-base) !important;
  line-height: var(--line-height-base) !important;
}
.font-weight-body,
.text-body,
.text-body-sm {
  font-weight: var(--font-weight-body) !important;
}
.font-weight-base,
.text-base,
.text-base-sm {
  font-weight: var(--font-weight-base) !important;
}
.text-base-sm,
.text-body-sm {
  font-size: var(--font-size-base-sm) !important;
  line-height: var(--line-height-base-sm) !important;
}
.text-caption {
  font-size: var(--font-size-base-xs) !important;
  line-height: var(--line-height-base-xs) !important;
}
.heading-weight {
  font-weight: var(--font-weight-heading) !important;
}
.hidden,
.invisible {
  visibility: hidden !important;
}
.hidden {
  display: none !important;
}
.show {
  display: block !important;
}
.affix {
  position: fixed !important;
}
.resize-none {
  resize: none !important;
}
.overflow-y-auto {
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
}
.mh-300 {
  max-height: 300px !important;
}
.border-bottom-none {
  border-bottom: none !important;
}
.vertical-align-top {
  vertical-align: top !important;
}
.vertical-align-middle {
  vertical-align: middle !important;
}
.vertical-align-bottom {
  vertical-align: text-bottom !important;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.width-auto {
  width: 100% !important;
}
@media (min-width: 700px) {
  .width-auto {
      min-width: 0 !important;
      width: auto !important;
  }
}
:root {
  --nav-container-width: 1140px;
  @include media-breakpoint-down(lg) {
      --nav-container-width: 990px;
  }
}
@media (min-width: 1200px) {
  .container {
      --container-ws-x: var(--ws-16x);
  }
}
body.keyboard-user :focus {
  --outline-width: 0;
}
.nav-header-wrapper {
  margin-top: 0;
}
.hp-rebrand {
  padding-bottom: 60px;
}
@media (min-width: 1200px) {
  .hp-rebrand {
      padding-bottom: 100px;
  }
}
.hp-rebrand .air3-token {
  height: 30px;
  border-radius: 16px;
  padding: 2px 15px 0;
  color: var(--gray-01);
  border: 1px solid var(--gray-01);
  background: none;
  font-weight: 500;
}
.hp-rebrand .air3-token:hover {
  background-color: var(--gray-06);
}
@media (min-width: 1200px) {
  .hp-rebrand .air3-token {
      font-size: 14px;
      padding-top: 0;
  }
}
.hp-rebrand {
  --font-size-h2-rebrand: 42px;
  --line-height-h2-rebrand: 42px;
  --font-size-h1-rebrand: 52px;
  --line-height-h1-rebrand: 52px;
}
@media (min-width: 700px) {
  .hp-rebrand {
      --font-size-h2-rebrand: 62px;
      --line-height-h2-rebrand: 62px;
      --font-size-h1-rebrand: 70px;
      --line-height-h1-rebrand: 70px;
  }
}
@media (min-width: 1200px) {
  .hp-rebrand {
      --font-size-h2-rebrand: 72px;
      --line-height-h2-rebrand: 72px;
      --font-size-h1-rebrand: 80px;
      --line-height-h1-rebrand: 80px;
  }
}
.categories-first-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}
@media (min-width: 700px) {
  .categories-first-container {
      margin-top: 60px;
  }
}
@media (max-width: 30em) {
  .categories-first-container {
      margin-top: 50px;
  }
}
.up-s-nav-icon svg {
  fill: none;
}
.hero-search-typeahead .hero-search-cta,
.nav-visitor-mobile-right .air3-btn-primary,
.rebrand-hero-section .info-holder .air3-btn-primary,
.updated-why-upwork__card .air3-btn-primary,
header .nav-left a.air3-btn-primary,
header .navbar-cta a.air3-btn-primary {
  --btn-primary-bg: #000435;
  --btn-primary-bg-hover: var(--up-green-dark);
}
.apple-sso-button,
.instant-signup-google-sso-button {
  --btn-radius: var(--radius-pill);
}
.instant-signup-google-sso-button .google-sso-icon {
  top: 0 !important;
}
.enterprise-stay-ahead {
  margin-top: 120px;
}
@media (max-width: 699.98px) {
  .enterprise-stay-ahead {
      margin-top: 40px;
  }
}
.how-to-hire-hp-3way div.how-to-hire-details {
  order: 1;
}
@media (max-width: 699.98px) {
  .how-to-hire-hp-3way div.how-to-hire-details {
      order: 2;
  }
}
.how-to-hire-hp-3way .steps-container {
  order: 2;
}
@media (max-width: 699.98px) {
  .how-to-hire-hp-3way .steps-container {
      order: 1;
  }
}
@media (max-width: 699.98px) {
  .how-to-hire-hp-3way h2.display-rebrand {
      display: none;
  }
}
.how-to-hire-hp-3way h2.display-rebrand-top-mobile {
  font-family: Ubuntu, sans-serif;
  font-size: 28px !important;
  line-height: 32px;
  margin-bottom: 0 !important;
  letter-spacing: -0.7px;
}
.rebrand-for-talent .for-talent-content-first-section h2.display-rebrand {
  line-height: 42px;
}
@media (min-width: 700px) {
  .rebrand-for-talent .for-talent-content-first-section h2.display-rebrand {
      line-height: 62px;
  }
}
@media (min-width: 1200px) {
  .rebrand-for-talent .for-talent-content-first-section h2.display-rebrand {
      line-height: 72px;
  }
}
@media (min-width: 700px) {
  #credential_picker_container {
      top: 57px !important;
  }
}
@media (min-width: 1200px) {
  #credential_picker_container {
      top: 70px !important;
  }
}
@media (min-width: 1200px) {
  .ssi-layout .rebrand-hero-section-margin-fix[data-v-a32880c0] {
      margin-top: 41px;
  }
}
.rebrand-hero-section-margin-fix[data-v-a32880c0] {
  margin-top: 100px;
}
@media (max-width: 992px) {
  .rebrand-hero-section-margin-fix[data-v-a32880c0] {
      margin-top: 40px;
  }
}
@media (min-height: 550px), @media (--zoom-down) {
  .rebrand-hero-section-margin-fix[data-v-a32880c0] {
      margin-top: 40px;
  }
}
@media (max-width: 699.98px) {
  .rebrand-hero-section-margin-fix[data-v-a32880c0] {
      margin-top: 32px;
  }
}
.hero-title[data-v-a32880c0] {
  color: #000435;
  line-height: 52px;
}
@media (min-width: 700px) and (max-width: 1199.98px) {
  .hero-title[data-v-a32880c0] {
      line-height: 70px;
  }
}
@media (min-width: 1200px) {
  .hero-title[data-v-a32880c0] {
      --font-size-h1-rebrand: 80px;
      line-height: 80px;
  }
}
.rebrand-hero-section[data-v-a32880c0] {
  overflow: hidden;
  margin-bottom: 40px;
}
@media (min-width: 1200px) {
  .rebrand-hero-section[data-v-a32880c0] {
      margin-bottom: 80px;
      margin-top: 100px;
  }
}
@media (min-width: 700px) and (max-width: 1199.98px) {
  .rebrand-hero-section h1[data-v-a32880c0] {
      max-width: 550px;
  }
}
.rebrand-hero-section .hero-smaller-title[data-v-a32880c0] {
  font-size: 64px;
  letter-spacing: -0.035em;
}
@media (min-width: 700px) and (max-width: 1199.98px) {
  .rebrand-hero-section .hero-smaller-title[data-v-a32880c0] {
      font-size: 56px;
  }
}
@media (max-width: 699.98px) {
  .rebrand-hero-section .hero-smaller-title[data-v-a32880c0] {
      font-size: 46px;
  }
}
.rebrand-hero-section .globe[data-v-a32880c0]:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.rebrand-hero-section .globe[data-v-a32880c0] {
  width: 100%;
  margin-top: -55%;
  position: relative;
  z-index: 2;
}
.rebrand-hero-section .globe img[data-v-a32880c0] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}
@media (min-width: 700px) {
  .rebrand-hero-section .globe[data-v-a32880c0] {
      margin: 0;
      position: absolute;
      width: 370px;
      top: -20px;
      right: 20px;
  }
}
@media (min-width: 1200px) {
  .rebrand-hero-section .globe[data-v-a32880c0] {
      right: 80px;
      width: 440px;
  }
}
.rebrand-hero-section .globe-repeat-visitor[data-v-a32880c0],
.rebrand-hero-section .globe-repeat-visitor img[data-v-a32880c0] {
  width: 356px !important;
  height: 379px !important;
}
@media (min-width: 700px) and (max-width: 1199.98px) {
  .rebrand-hero-section .globe-repeat-visitor[data-v-a32880c0],
  .rebrand-hero-section .globe-repeat-visitor img[data-v-a32880c0] {
      width: 310px !important;
      height: 330px !important;
  }
}
@media (min-width: 700px) and (max-width: 1199.98px) {
  .rebrand-hero-section .globe-repeat-visitor[data-v-a32880c0],
  .rebrand-hero-section .globe-repeat-visitor img[data-v-a32880c0] {
      width: 229px !important;
      height: 244px !important;
  }
}
.rebrand-hero-section .subtitle[data-v-a32880c0] {
  color: var(--text-light-on-body);
  font-size: var(--font-size-h5);
  line-height: 26px;
  letter-spacing: 0.24px;
  font-style: normal;
}
@media (min-width: 1200px) {
  .rebrand-hero-section .subtitle[data-v-a32880c0] {
      font-size: 22px;
      line-height: 32px;
  }
}
.rebrand-hero-section .trusted-by-guided-flow-wrapper .title[data-v-a32880c0] {
  margin: 0;
  text-align: left;
}
.vs-position-relative {
  position: relative !important;
}
.vs-width-100 {
  width: 100% !important;
}
.logobar-skinny-section {
  margin-top: 40px;
}
@media (min-width: 700px) {
  .logobar-skinny-section {
      margin-top: 70px;
  }
}
.logo-holder.logobar-skinny-section .container-visitor {
  align-items: baseline !important;
  padding: 0 !important;
  margin-right: 0 !important;
}
.logo-holder.logobar-skinny-section .title p {
  color: var(--gray-02);
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 10px !important;
}
@media (min-width: 1200px) {
  .logo-holder.logobar-skinny-section .title p {
      font-size: 16px;
      line-height: 24px;
  }
}
@media (max-width: 699.98px) {
  .logo-holder.logobar-skinny-section .title {
      margin-bottom: 0;
  }
}
.logo-holder.logobar-skinny-section .title p.trusted-by-message-guided-flow {
  font-size: 20px;
  color: var(--text-dark-on-inverse);
  margin-bottom: 20px !important;
}
.logo-holder.logobar-skinny-section .logos {
  margin-left: 0 !important;
  padding-left: 0 !important;
}
.logo-holder.logobar-skinny-section .logo {
  height: 40px;
  width: auto;
  max-width: 110px;
}
@media (min-width: 700px) {
  .logo-holder.logobar-skinny-section .logo {
      margin-right: 30px;
  }
}
@media (max-width: 699.98px) {
  .logo-holder.logobar-skinny-section .logo {
      height: 30px;
  }
}
@media only screen and (max-width: 479px) {
  .logo-holder.logobar-skinny-section .hire-talent-categories {
      margin-bottom: 0;
  }
}
.logo-holder.logobar-skinny-section .logos.logos-mobile {
  align-items: baseline;
  justify-content: flex-start;
}
.logo-holder.logobar-skinny-section .logos.logos-mobile .logo {
  margin-right: 0.8rem !important;
}
.logobar-title {
  color: var(--bg-placeholder);
  font-size: var(--font-size-base);
  line-height: 24px;
  letter-spacing: 0.6px;
}
.hero-cta {
  grid-gap: 15px;
  gap: 15px;
}
@media (min-width: 700px) {
  .hero-cta {
      margin-bottom: 22px;
  }
}
:where(.up-n-link) > .air3-icon {
  vertical-align: middle;
  margin-top: -3px;
}
:where(.up-n-link)[aria-disabled] {
  color: var(--text-disabled);
  color: var(--text-on-disabled, var(--text-disabled));
  cursor: not-allowed;
  text-decoration: none;
}
:where(.up-n-link)[aria-disabled].inverted {
  opacity: 0.4;
}
@media (forced-colors: active) {
  :where(.up-n-link)[aria-disabled].inverted {
      opacity: 1;
  }
}
.vs-bg-white[data-v-54ec863e] {
  background: var(--bg-body);
}
.unilever-first[data-v-54ec863e] {
  --template-col: 50px repeat(12, 1fr);
}
.d-one-row[data-v-54ec863e] {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
@media (max-width: 1199.98px) {
  .d-one-row[data-v-54ec863e] {
      grid-template-columns: 1fr;
  }
}
.with-border[data-v-54ec863e] {
  border: 1px solid var(--border-color);
  border-radius: var(--radius-4x);
  padding: 15px;
  align-items: center;
}
@media (max-width: 1199.98px) {
  .with-border[data-v-54ec863e] {
      grid-gap: 20px;
      gap: 20px;
  }
}
@media (min-width: 700px) {
  .sm-screen-container[data-v-54ec863e] {
      display: none !important;
  }
}
.flex-full-width[data-v-54ec863e] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
@media (max-width: 699.98px) {
  .lg-screen-container[data-v-54ec863e],
  .md-screen-container[data-v-54ec863e],
  .xl-screen-container[data-v-54ec863e] {
      display: none !important;
  }
}
.logo {
  height: 40px;
  width: auto;
  max-width: 110px;
}
@media (max-width: 699.98px) {
  .logo {
      height: 35px;
  }
}
@media (max-width: 699.98px) {
  .logo.nasdaq {
      height: 28px;
  }
}
.logo.pladis {
  height: 36px;
}
.air3-grid-container[data-v-57dcc37e] {
  --template-col: repeat(12, 1fr);
  --row-gap: var(--ws-4x);
  --col-gap: var(--ws-4x);
  --gap: var(--row-gap) var(--col-gap);
  display: grid;
  grid-template-columns: var(--template-col);
  grid-gap: var(--gap);
  gap: var(--gap);
}
@media (min-width: 700px) {
  .air3-grid-container[data-v-57dcc37e] {
      --row-gap: var(--ws-6x);
      --col-gap: var(--ws-6x);
  }
}
@media (min-width: 1200px) {
  .air3-grid-container[data-v-57dcc37e] {
      --row-gap: var(--ws-8x);
      --col-gap: var(--ws-8x);
  }
}
.air3-grid-container.gap-0[data-v-57dcc37e] {
  --gap: 0;
}
.air3-grid-container.col-gap-0[data-v-57dcc37e] {
  --col-gap: 0;
}
.air3-grid-container.row-gap-0[data-v-57dcc37e] {
  --row-gap: 0;
}
[class*="span-"][data-v-57dcc37e] {
  grid-column: 1/-1;
  min-width: 0;
}
.span-1[data-v-57dcc37e] {
  grid-column: span 1;
}
.span-2[data-v-57dcc37e] {
  grid-column: span 2;
}
.span-3[data-v-57dcc37e] {
  grid-column: span 3;
}
.span-4[data-v-57dcc37e] {
  grid-column: span 4;
}
.span-5[data-v-57dcc37e] {
  grid-column: span 5;
}
.span-6[data-v-57dcc37e] {
  grid-column: span 6;
}
.span-7[data-v-57dcc37e] {
  grid-column: span 7;
}
.span-8[data-v-57dcc37e] {
  grid-column: span 8;
}
.span-9[data-v-57dcc37e] {
  grid-column: span 9;
}
.span-10[data-v-57dcc37e] {
  grid-column: span 10;
}
.span-11[data-v-57dcc37e] {
  grid-column: span 11;
}
.span-12[data-v-57dcc37e] {
  grid-column: span 12;
}
@media (min-width: 700px) {
  .span-md-1[data-v-57dcc37e] {
      grid-column: span 1;
  }
  .span-md-2[data-v-57dcc37e] {
      grid-column: span 2;
  }
  .span-md-3[data-v-57dcc37e] {
      grid-column: span 3;
  }
  .span-md-4[data-v-57dcc37e] {
      grid-column: span 4;
  }
  .span-md-5[data-v-57dcc37e] {
      grid-column: span 5;
  }
  .span-md-6[data-v-57dcc37e] {
      grid-column: span 6;
  }
  .span-md-7[data-v-57dcc37e] {
      grid-column: span 7;
  }
  .span-md-8[data-v-57dcc37e] {
      grid-column: span 8;
  }
  .span-md-9[data-v-57dcc37e] {
      grid-column: span 9;
  }
  .span-md-10[data-v-57dcc37e] {
      grid-column: span 10;
  }
  .span-md-11[data-v-57dcc37e] {
      grid-column: span 11;
  }
  .span-md-12[data-v-57dcc37e] {
      grid-column: span 12;
  }
}
@media (min-width: 1200px) {
  .span-lg-1[data-v-57dcc37e] {
      grid-column: span 1;
  }
  .span-lg-2[data-v-57dcc37e] {
      grid-column: span 2;
  }
  .span-lg-3[data-v-57dcc37e] {
      grid-column: span 3;
  }
  .span-lg-4[data-v-57dcc37e] {
      grid-column: span 4;
  }
  .span-lg-5[data-v-57dcc37e] {
      grid-column: span 5;
  }
  .span-lg-6[data-v-57dcc37e] {
      grid-column: span 6;
  }
  .span-lg-7[data-v-57dcc37e] {
      grid-column: span 7;
  }
  .span-lg-8[data-v-57dcc37e] {
      grid-column: span 8;
  }
  .span-lg-9[data-v-57dcc37e] {
      grid-column: span 9;
  }
  .span-lg-10[data-v-57dcc37e] {
      grid-column: span 10;
  }
  .span-lg-11[data-v-57dcc37e] {
      grid-column: span 11;
  }
  .span-lg-12[data-v-57dcc37e] {
      grid-column: span 12;
  }
}
.text-base[data-v-57dcc37e],
.text-body[data-v-57dcc37e] {
  font-size: var(--font-size-base) !important;
  line-height: var(--line-height-base) !important;
}
.font-weight-body[data-v-57dcc37e],
.text-body[data-v-57dcc37e],
.text-body-sm[data-v-57dcc37e] {
  font-weight: var(--font-weight-body) !important;
}
.font-weight-base[data-v-57dcc37e],
.text-base[data-v-57dcc37e],
.text-base-sm[data-v-57dcc37e] {
  font-weight: var(--font-weight-base) !important;
}
.text-base-sm[data-v-57dcc37e],
.text-body-sm[data-v-57dcc37e] {
  font-size: var(--font-size-base-sm) !important;
  line-height: var(--line-height-base-sm) !important;
}
.text-caption[data-v-57dcc37e] {
  font-size: var(--font-size-base-xs) !important;
  line-height: var(--line-height-base-xs) !important;
}
.heading-weight[data-v-57dcc37e] {
  font-weight: var(--font-weight-heading) !important;
}
.hidden[data-v-57dcc37e],
.invisible[data-v-57dcc37e] {
  visibility: hidden !important;
}
.hidden[data-v-57dcc37e] {
  display: none !important;
}
.show[data-v-57dcc37e] {
  display: block !important;
}
.affix[data-v-57dcc37e] {
  position: fixed !important;
}
.resize-none[data-v-57dcc37e] {
  resize: none !important;
}
.overflow-y-auto[data-v-57dcc37e] {
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
}
.mh-300[data-v-57dcc37e] {
  max-height: 300px !important;
}
.border-bottom-none[data-v-57dcc37e] {
  border-bottom: none !important;
}
.vertical-align-top[data-v-57dcc37e] {
  vertical-align: top !important;
}
.vertical-align-middle[data-v-57dcc37e] {
  vertical-align: middle !important;
}
.vertical-align-bottom[data-v-57dcc37e] {
  vertical-align: text-bottom !important;
}
.ellipsis[data-v-57dcc37e] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.width-auto[data-v-57dcc37e] {
  width: 100% !important;
}
@media (min-width: 700px) {
  .width-auto[data-v-57dcc37e] {
      min-width: 0 !important;
      width: auto !important;
  }
}
.air3-btn[data-v-57dcc37e] {
  --btn-height: var(--height-base);
  --btn-gap: var(--ws-4x);
  --btn-padding-x: var(--ws-6x);
  --btn-border: var(--border-width-btn) solid var(--btn-border-color, transparent);
  --btn-font-size: var(--font-size-base);
  --btn-font-weight: var(--font-weight-strong);
  --btn-radius: var(--radius-pill);
  --btn-bg-primary: #000435;
  --btn-bg-primary-hover: #000435;
  --btn-text-primary: var(--text-on-btn-dark);
  --btn-text-primary-hover: var(--text-on-btn-dark-hover);
  --btn-text-icon-primary: var(--text-icon-on-btn-dark);
  --btn-border-color-primary: var(--border-color-btn-dark);
  --btn-shadow-primary: var(--shadow-on-btn-dark);
  --btn-bg-primary-inverted: var(--bg-btn-dark-inverted);
  --btn-bg-primary-inverted-hover: var(--bg-btn-dark-inverted-hover);
  --btn-text-primary-inverted: var(--text-on-btn-dark-inverted);
  --btn-text-primary-inverted-hover: var(--text-on-btn-dark-inverted-hover);
  --btn-text-icon-primary-inverted: var(--text-icon-on-btn-dark-inverted);
  --btn-border-color-primary-inverted: var(--border-color-btn-dark-inverted);
  --btn-shadow-primary-inverted: var(--shadow-on-btn-dark-inverted);
  --btn-bg-secondary: var(--bg-btn-light);
  --btn-bg-secondary-hover: var(--bg-btn-light-hover);
  --btn-text-secondary: var(--text-on-btn-light);
  --btn-text-secondary-hover: var(--text-on-btn-light-hover);
  --btn-text-icon-secondary: var(--text-icon-on-btn-light);
  --btn-border-color-secondary: var(--border-color-btn-light);
  --btn-shadow-secondary: var(--shadow-on-btn-light);
  --btn-bg-secondary-inverted: var(--bg-btn-light-inverted);
  --btn-bg-secondary-inverted-hover: var(--bg-btn-light-inverted-hover);
  --btn-text-secondary-inverted: var(--text-on-btn-light-inverted);
  --btn-text-secondary-inverted-hover: var(--text-on-btn-light-inverted-hover);
  --btn-text-icon-secondary-inverted: var(--text-icon-on-btn-light-inverted);
  --btn-border-color-secondary-inverted: var(--border-color-btn-light-inverted);
  --btn-shadow-secondary-inverted: var(--shadow-on-btn-light-inverted);
  --btn-bg-tertiary: var(--bg-btn-muted);
  --btn-bg-tertiary-hover: var(--bg-btn-muted-hover);
  --btn-text-tertiary: var(--text-on-btn-muted);
  --btn-text-tertiary-hover: var(--text-on-btn-muted-hover);
  --btn-text-icon-tertiary: var(--text-icon-on-btn-muted);
  --btn-border-color-tertiary: var(--border-color-btn-muted);
  --btn-shadow-tertiary: var(--shadow-on-btn-muted);
  --btn-bg-tertiary-inverted: var(--bg-btn-muted-inverted);
  --btn-bg-tertiary-inverted-hover: var(--bg-btn-muted-inverted-hover);
  --btn-text-tertiary-inverted: var(--text-on-btn-muted-inverted);
  --btn-text-tertiary-inverted-hover: var(--text-on-btn-muted-inverted-hover);
  --btn-text-icon-tertiary-inverted: var(--text-icon-on-btn-muted-inverted);
  --btn-border-color-tertiary-inverted: var(--border-color-btn-muted-inverted);
  --btn-shadow-tertiary-inverted: var(--shadow-on-btn-muted-inverted);
  --btn-font-weight-link: var(--font-weight-strong);
  --btn-text-link-inverted: var(--text-link-inverse);
  --btn-text-link-inverted-hover: var(--text-link-inverse-hover);
  --btn-height-sm: var(--height-sm);
  --btn-gap-sm: var(--ws-2x);
  --btn-padding-x-sm: var(--ws-4x);
  --btn-font-size-sm: var(--font-size-base-sm);
  --btn-bg-circle: var(--bg-btn-circle);
  --btn-bg-circle-hover: var(--bg-btn-circle-hover);
  --btn-text-circle: var(--text-on-btn-circle);
  --btn-text-circle-hover: var(--text-on-btn-circle-hover);
  --btn-bg-circle-inverted: var(--bg-btn-circle-inverted);
  --btn-bg-circle-inverted-hover: var(--bg-btn-circle-inverted-hover);
  --btn-text-circle-inverted: var(--text-on-btn-circle-inverted);
  --btn-text-circle-inverted-hover: var(--text-on-btn-circle-inverted-hover);
  --btn-size-counter: var(--height-sm);
  --btn-bg-counter: var(--hc-bg-btn, #000435);
  --btn-bg-counter-primary: #000435;
  --btn-bg-counter-primary-hover: var(--hc-bg-btn, #000435);
  --btn-text-counter: var(--hc-text-on-btn, #fff);
  --btn-text-counter-inline: var(--hc-text-on-selected, var(--up-lime));
  --btn-text-counter-inline-hover: var(--hc-bg-selected, var(--up-lime));
  display: inline-flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: var(--btn-gap);
  -moz-column-gap: var(--btn-gap);
  column-gap: var(--btn-gap);
  max-height: var(--btn-height);
  line-height: calc(var(--btn-height) - 1px);
  padding: 0 var(--btn-padding-x);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  background-color: transparent;
  background-color: var(--btn-bg, transparent);
  color: inherit;
  color: var(--btn-text, inherit);
  border: var(--btn-border);
  border-radius: var(--btn-radius);
  box-shadow: none;
  box-shadow: var(--btn-shadow, none);
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  text-decoration: none;
}
@media (hover: hover) {
  .air3-btn[data-v-57dcc37e]:hover {
      --btn-bg: var(--btn-bg-hover, inherit);
      --btn-text: var(--btn-text-hover, inherit);
  }
}
.air3-btn .air3-icon[data-v-57dcc37e] {
  flex: none;
  color: inherit;
  color: var(--btn-text-icon, inherit);
}
.air3-btn[data-v-57dcc37e]:is(a) {
  --underline-link: none;
  --underline-link-hover: none;
  --text-link: var(--btn-text);
  --text-link-hover: var(--btn-text);
  --link-color-hover: var(--btn-text);
}
@media (forced-colors: active) {
  .air3-btn[data-v-57dcc37e]:is(a):hover {
      border-color: var(--hc-bg-selected);
  }
}
.air3-btn[data-v-57dcc37e]:active:not([disabled]):not(.is-disabled) {
  transform: scale(0.96);
  transform: scale(var(--reduced-motion, 0.96));
}
.air3-btn.is-disabled[data-v-57dcc37e],
.air3-btn[disabled][data-v-57dcc37e] {
  --btn-text: var(--text-on-disabled);
  cursor: not-allowed;
}
.air3-btn-primary[data-v-57dcc37e] {
  --btn-bg: #000435;
  --btn-bg-hover: #000435;
  --btn-shadow: var(--btn-shadow-primary);
  --btn-text-icon: var(--btn-text-icon-primary);
}
.air3-btn-primary[data-v-57dcc37e]:not([disabled]):not(.is-disabled) {
  --btn-border-color: var(--btn-border-color-primary);
}
@media (forced-colors: active) {
  .air3-btn-primary[data-v-57dcc37e]:not([disabled]):not(.is-disabled) {
      forced-color-adjust: none;
  }
  .air3-btn-primary[data-v-57dcc37e]:not([disabled]):not(.is-disabled):focus {
      outline-color: var(--hc-text-on-selected);
  }
}
.air3-btn-primary[data-v-57dcc37e],
.air3-btn-primary[data-v-57dcc37e]:is(a) {
  --btn-text: #fff;
  --btn-text-hover: var(--btn-text-primary-hover);
}
@media (forced-colors: active) {
  .air3-btn-primary[data-v-57dcc37e]:is(a) {
      --btn-bg: var(--hc-text-link);
      --btn-text: var(--hc-bg-base);
      --btn-border-color-primary: var(--hc-text-link);
  }
}
.air3-btn-primary-inverted[data-v-57dcc37e] {
  --btn-bg: var(--btn-bg-primary-inverted);
  --btn-bg-hover: var(--btn-bg-primary-inverted-hover);
  --btn-text: var(--btn-text-primary-inverted);
  --btn-text-hover: var(--btn-text-primary-inverted-hover);
  --btn-text-icon: var(--btn-text-icon-primary-inverted);
  --btn-border-color: var(--btn-border-color-primary-inverted);
  --btn-shadow: var(--btn-shadow-primary-inverted);
}
.air3-btn-secondary[data-v-57dcc37e] {
  --btn-bg: var(--btn-bg-secondary);
  --btn-bg-hover: var(--btn-bg-secondary-hover);
  --btn-text: var(--btn-text-secondary);
  --btn-text-hover: var(--btn-text-secondary-hover);
  --btn-text-icon: var(--btn-text-icon-secondary);
  --btn-shadow: var(--btn-shadow-secondary);
}
.air3-btn-secondary[data-v-57dcc37e]:not([disabled]):not(.is-disabled) {
  --btn-border-color: var(--btn-border-color-secondary);
}
.air3-btn-secondary-inverted[data-v-57dcc37e] {
  --btn-bg: var(--btn-bg-secondary-inverted);
  --btn-bg-hover: var(--btn-bg-secondary-inverted-hover);
  --btn-text-icon: var(--btn-text-icon-secondary-inverted);
  --btn-shadow: var(--btn-shadow-secondary-inverted);
}
.air3-btn-secondary-inverted[data-v-57dcc37e]:not([disabled]):not(.is-disabled) {
  --btn-border-color: var(--btn-border-color-secondary-inverted);
}
.air3-btn-secondary-inverted[data-v-57dcc37e],
.air3-btn-secondary-inverted[data-v-57dcc37e]:is(a) {
  --btn-text: var(--btn-text-secondary-inverted);
  --btn-text-hover: var(--btn-text-secondary-inverted-hover);
}
.air3-btn-tertiary[data-v-57dcc37e] {
  --btn-bg: var(--btn-bg-tertiary);
  --btn-bg-hover: var(--btn-bg-tertiary-hover);
  --btn-text: var(--btn-text-tertiary);
  --btn-text-hover: var(--btn-text-tertiary-hover);
  --btn-text-icon: var(--btn-text-icon-tertiary);
  --btn-shadow: var(--btn-shadow-tertiary);
}
.air3-btn-tertiary[data-v-57dcc37e]:not([disabled]):not(.is-disabled) {
  --btn-border-color: var(--btn-border-color-tertiary);
}
.air3-btn-tertiary-inverted[data-v-57dcc37e] {
  --btn-bg: var(--btn-bg-tertiary-inverted);
  --btn-bg-hover: var(--btn-bg-tertiary-inverted-hover);
  --btn-text: var(--btn-text-tertiary-inverted);
  --btn-text-hover: var(--btn-text-tertiary-inverted-hover);
  --btn-text-icon: var(--btn-text-icon-tertiary-inverted);
  --btn-border-color: var(--btn-border-color-tertiary-inverted);
  --btn-shadow: var(--btn-shadow-tertiary-inverted);
}
.air3-btn-link[data-v-57dcc37e] {
  background: var(--bg-btn-link);
  color: var(--text-on-btn-link);
  -webkit-text-decoration: var(--underline-btn-link);
  text-decoration: var(--underline-btn-link);
}
.air3-btn-link[data-v-57dcc37e]:hover:where(:not([disabled]):not(.is-disabled)) {
  background: var(--bg-btn-link-hover);
  color: var(--text-on-btn-link-hover);
  -webkit-text-decoration: var(--underline-btn-link-hover);
  text-decoration: var(--underline-btn-link-hover);
}
.air3-btn-link[data-v-57dcc37e]:not(.air3-btn):hover {
  --underline-btn-link-hover: underline;
  color: var(--text-link-hover);
}
.air3-btn-link-secondary[data-v-57dcc37e] {
  color: var(--text-link);
  -webkit-text-decoration: var(--underline-link);
  text-decoration: var(--underline-link);
}
.air3-btn-link-secondary[data-v-57dcc37e]:hover:where(:not([disabled]):not(.is-disabled)) {
  color: var(--text-link-hover);
  -webkit-text-decoration: var(--underline-link-hover);
  text-decoration: var(--underline-link-hover);
}
.air3-btn-link-inverted[data-v-57dcc37e],
.air3-btn-link-inverted.is-disabled[data-v-57dcc37e],
.air3-btn-link-inverted[disabled][data-v-57dcc37e] {
  --btn-bg: transparent;
  --btn-text: var(--btn-text-link-inverted);
}
@media (hover: hover) {
  .air3-btn-link-inverted[data-v-57dcc37e]:hover:not([disabled]):not(.is-disabled) {
      --btn-bg: transparent;
      --btn-text: var(--btn-text-link-inverted-hover);
  }
}
.air3-btn-link[data-v-57dcc37e],
.air3-btn-link-inverted[data-v-57dcc37e],
.air3-btn-link-secondary[data-v-57dcc37e] {
  border: 0;
  font-weight: var(--font-weight-base);
}
.air3-btn-link-inverted[data-v-57dcc37e]:not(.air3-btn),
.air3-btn-link-secondary[data-v-57dcc37e]:not(.air3-btn),
.air3-btn-link[data-v-57dcc37e]:not(.air3-btn) {
  padding: 0;
  background: transparent;
}
.air3-btn-link-inverted.is-disabled[data-v-57dcc37e],
.air3-btn-link-inverted[disabled][data-v-57dcc37e],
.air3-btn-link-secondary.is-disabled[data-v-57dcc37e],
.air3-btn-link-secondary[disabled][data-v-57dcc37e],
.air3-btn-link.is-disabled[data-v-57dcc37e],
.air3-btn-link[disabled][data-v-57dcc37e] {
  color: var(--text-on-disabled);
  cursor: not-allowed;
}
.air3-btn-circle-inverted.is-disabled[data-v-57dcc37e],
.air3-btn-circle-inverted[disabled][data-v-57dcc37e],
.air3-btn-link-inverted.is-disabled[data-v-57dcc37e],
.air3-btn-link-inverted[disabled][data-v-57dcc37e],
.air3-btn-primary-inverted.is-disabled[data-v-57dcc37e],
.air3-btn-primary-inverted[disabled][data-v-57dcc37e],
.air3-btn-secondary-inverted.is-disabled[data-v-57dcc37e],
.air3-btn-secondary-inverted[disabled][data-v-57dcc37e],
.air3-btn-tertiary-inverted.is-disabled[data-v-57dcc37e],
.air3-btn-tertiary-inverted[disabled][data-v-57dcc37e] {
  --btn-bg-hover: transparent;
  --btn-border-color: transparent;
  opacity: 0.4;
}
@media (forced-colors: active) {
  .air3-btn-circle-inverted.is-disabled[data-v-57dcc37e],
  .air3-btn-circle-inverted[disabled][data-v-57dcc37e],
  .air3-btn-link-inverted.is-disabled[data-v-57dcc37e],
  .air3-btn-link-inverted[disabled][data-v-57dcc37e],
  .air3-btn-primary-inverted.is-disabled[data-v-57dcc37e],
  .air3-btn-primary-inverted[disabled][data-v-57dcc37e],
  .air3-btn-secondary-inverted.is-disabled[data-v-57dcc37e],
  .air3-btn-secondary-inverted[disabled][data-v-57dcc37e],
  .air3-btn-tertiary-inverted.is-disabled[data-v-57dcc37e],
  .air3-btn-tertiary-inverted[disabled][data-v-57dcc37e] {
      opacity: 1;
  }
}
.air3-btn-primary.is-disabled[data-v-57dcc37e],
.air3-btn-primary[disabled][data-v-57dcc37e],
.air3-btn-secondary.is-disabled[data-v-57dcc37e],
.air3-btn-secondary[disabled][data-v-57dcc37e],
.air3-btn-tertiary.is-disabled[data-v-57dcc37e],
.air3-btn-tertiary[disabled][data-v-57dcc37e] {
  --btn-bg: var(--bg-disabled);
}
.air3-btn-primary-inverted.is-disabled[data-v-57dcc37e],
.air3-btn-primary-inverted[disabled][data-v-57dcc37e],
.air3-btn-secondary-inverted.is-disabled[data-v-57dcc37e],
.air3-btn-secondary-inverted[disabled][data-v-57dcc37e],
.air3-btn-tertiary-inverted.is-disabled[data-v-57dcc37e],
.air3-btn-tertiary-inverted[disabled][data-v-57dcc37e] {
  --btn-bg: var(--bg-disabled-inverse);
  --btn-text: var(--text-on-disabled-inverse);
}
.air3-btn-sm[data-v-57dcc37e] {
  --btn-padding-x: var(--btn-padding-x-sm);
  --btn-font-size: var(--btn-font-size-sm);
  --btn-height: var(--btn-height-sm);
  --btn-gap: var(--btn-gap-sm);
}
.air3-btn-circle[data-v-57dcc37e],
.air3-btn-circle-inverted[data-v-57dcc37e] {
  width: var(--btn-height);
  height: var(--btn-height);
  padding: 0;
  border-radius: 50%;
  max-height: unset;
}
.air3-btn-circle[data-v-57dcc37e]:not([disabled]):not(.is-disabled):not(.air3-btn-primary):not(.air3-btn-secondary):not(.air3-btn-tertiary):not(.air3-btn-primary-inverted):not(.air3-btn-secondary-inverted):not(.air3-btn-tertiary-inverted) {
  --btn-bg: var(--btn-bg-circle);
  --btn-text: var(--btn-text-circle);
}
.air3-btn-circle[data-v-57dcc37e]:not([disabled]):not(.is-disabled):not(.air3-btn-primary):not(.air3-btn-secondary):not(.air3-btn-tertiary):not(.air3-btn-primary-inverted):not(.air3-btn-secondary-inverted):not(.air3-btn-tertiary-inverted):hover {
  --btn-bg: var(--btn-bg-circle-hover);
  --btn-text: var(--btn-text-circle-hover);
}
.air3-btn-circle.is-disabled[data-v-57dcc37e],
.air3-btn-circle[disabled][data-v-57dcc37e] {
  --btn-bg-hover: transparent;
}
.air3-btn-circle-inverted[data-v-57dcc37e],
.air3-btn-circle-inverted.is-disabled[data-v-57dcc37e],
.air3-btn-circle-inverted[disabled][data-v-57dcc37e] {
  --btn-text: var(--btn-text-circle-inverted);
}
.air3-btn-circle-inverted[data-v-57dcc37e]:not([disabled]):not(.is-disabled):not(.air3-btn-secondary):not(.air3-btn-tertiary) {
  --btn-bg: var(--btn-bg-circle-inverted);
  --btn-text: var(--btn-text-circle-inverted-hover);
}
.air3-btn-circle-inverted[data-v-57dcc37e]:not([disabled]):not(.is-disabled):not(.air3-btn-secondary):not(.air3-btn-tertiary):hover {
  --btn-bg: var(--btn-bg-circle-inverted-hover);
}
.air3-btn-block[data-v-57dcc37e] {
  display: flex;
  width: 100%;
}
@media (max-width: 699.98px) {
  .air3-btn-block-sm[data-v-57dcc37e] {
      width: 100%;
  }
}
.air3-btn-counter .air3-btn-counter-text[data-v-57dcc37e] {
  flex: 1;
}
.air3-btn-counter .air3-btn-counter-circle[data-v-57dcc37e] {
  background-color: var(--btn-bg-counter);
  color: var(--btn-text-counter);
  border-radius: 50%;
  width: var(--btn-size-counter);
  height: var(--btn-size-counter);
  min-width: var(--btn-size-counter);
  min-height: var(--btn-size-counter);
  line-height: 34px;
  margin-right: calc(var(--btn-padding-x) * -1 - var(--ws-1x) * -1);
}
@media (max-width: 699.98px) {
  .air3-btn-counter[class*="air3-btn-block"] .air3-btn-counter-circle[data-v-57dcc37e] {
      margin-left: calc(var(--btn-gap) * -2);
  }
}
.air3-btn-counter.air3-btn-primary[data-v-57dcc37e] {
  --btn-bg-counter: var(--btn-bg-counter-primary);
}
.air3-btn-counter.air3-btn-primary[data-v-57dcc37e]:hover {
  --btn-bg-counter: var(--btn-bg-counter-primary-hover);
}
.air3-btn-counter.is-disabled .air3-btn-counter-circle[data-v-57dcc37e],
.air3-btn-counter[disabled] .air3-btn-counter-circle[data-v-57dcc37e] {
  --btn-bg-counter: var(--bg-disabled-inner);
  --btn-bg-counter-primary: var(--bg-disabled-inner);
  --btn-bg-counter-primary-hover: var(--bg-disabled-inner);
  --btn-text-counter: var(--text-on-disabled-inner);
}
.air3-btn-counter .air3-btn-counter-inline[data-v-57dcc37e] {
  display: contents;
}
.air3-btn-counter:not([disabled]):not(.is-disabled) .air3-btn-counter-inline[data-v-57dcc37e] {
  color: var(--btn-text-counter-inline);
}
.air3-btn-counter:not([disabled]):not(.is-disabled):hover .air3-btn-counter-inline[data-v-57dcc37e] {
  --btn-text-counter-inline: var(--btn-text-counter-inline-hover);
}
.flex-1[data-v-57dcc37e] {
  flex: 1 !important;
}
.flex-none[data-v-57dcc37e] {
  flex: none !important;
}
.flex-column[data-v-57dcc37e] {
  flex-direction: column !important;
}
.flex-row[data-v-57dcc37e] {
  flex-direction: row !important;
}
.flex-wrap[data-v-57dcc37e] {
  flex-wrap: wrap !important;
}
.justify-space-between[data-v-57dcc37e] {
  justify-content: space-between !important;
}
.justify-content-center[data-v-57dcc37e] {
  justify-content: center !important;
}
.align-items-center[data-v-57dcc37e] {
  align-items: center !important;
}
.air3-card[data-v-57dcc37e],
.air3-card-section[data-v-57dcc37e] {
  --card-padding-x: var(--ws-6x);
  --card-padding-y: var(--ws-6x);
  --card-padding: var(--card-padding-y) var(--card-padding-x);
  --card-section-padding: var(--card-padding);
  --card-section-border: var(--border);
}
@media (min-width: 700px) {
  .air3-card[data-v-57dcc37e],
  .air3-card-section[data-v-57dcc37e] {
      --card-padding-x: var(--ws-8x);
      --card-padding-y: var(--ws-8x);
  }
}
.air3-card[data-v-57dcc37e] {
  --card-bg: var(--bg-muted-light);
  --card-border: 1px solid transparent;
  --card-radius: var(--radius-8x);
  --card-bg-outline: var(--bg-inverse);
  --card-border-outline: var(--border);
  --card-radius-list: 0;
  --card-padding-x-list: 0;
  --card-bg-list: var(--bg-inverse);
  --card-border-list: var(--border);
  --card-padding-x-sm: var(--ws-6x);
  --card-padding-y-sm: var(--ws-6x);
  --card-link-bg: var(--bg-hoverable-light);
  --card-link-bg-hover: var(--bg-hoverable-light-hover);
  --card-link-text: var(--text-dark-on-hoverable-light);
  --card-link-text-hover: var(--hc-text-on-selected, var(--text-dark-on-hoverable-light));
  padding: var(--card-padding);
  background: var(--card-bg);
  border: var(--card-border);
  border-radius: var(--card-radius);
  width: 100%;
}
.air3-card.air3-card-link[data-v-57dcc37e] {
  --card-bg: var(--card-link-bg);
  --text-link: var(--card-link-text);
  --text-link-hover: var(--card-link-text-hover);
  --underline-link: none;
  --underline-link-hover: none;
  display: block;
}
.air3-card.air3-card-link[data-v-57dcc37e]:focus,
.air3-card.air3-card-link[data-v-57dcc37e]:hover {
  --card-bg: var(--card-link-bg-hover);
  --card-border: 1px solid var(--card-link-bg-hover);
}
@media (forced-colors: active) {
  .air3-card.air3-card-link[data-v-57dcc37e]:focus,
  .air3-card.air3-card-link[data-v-57dcc37e]:hover {
      forced-color-adjust: none;
  }
  .air3-card.air3-card-link[data-v-57dcc37e]:focus:focus,
  .air3-card.air3-card-link[data-v-57dcc37e]:hover:focus {
      outline-color: var(--hc-text-on-selected);
  }
}
.air3-card-sm[data-v-57dcc37e] {
  --card-padding-x: var(--card-padding-x-sm);
  --card-padding-y: var(--card-padding-y-sm);
}
.air3-card-outline[data-v-57dcc37e] {
  --card-bg: var(--card-bg-outline);
  --card-border: var(--card-border-outline);
}
.air3-card-interactive[data-v-57dcc37e] {
  box-shadow: var(--shadow-interactive);
  border: 0;
  border: var(--hc-border, 0);
  cursor: pointer;
  transition: box-shadow 0.5s ease;
  transition: var(--reduced-motion, box-shadow 0.5s ease);
}
.air3-card-interactive[data-v-57dcc37e]:hover {
  box-shadow: var(--shadow-interactive-hover);
}
.air3-card-list[data-v-57dcc37e] {
  --card-radius: var(--card-radius-list);
  --card-padding-x: var(--card-padding-x-list);
  --card-bg: var(--card-bg-list);
  --card-border: var(--card-border-list);
  border-left: 0;
  border-right: 0;
}
.air3-card-list + .air3-card-list[data-v-57dcc37e] {
  border-top: 0;
}
.air3-card-section[data-v-57dcc37e] {
  padding: var(--card-section-padding);
}
.air3-card-section[data-v-57dcc37e]:first-child {
  border-radius: var(--card-radius) var(--card-radius) 0 0;
}
.air3-card-section[data-v-57dcc37e]:last-child {
  border-radius: 0 0 var(--card-radius) var(--card-radius);
}
.air3-card-section[data-v-57dcc37e]:only-child {
  border-radius: var(--card-radius);
}
.air3-card-section[data-v-57dcc37e]:not(:last-child) {
  border-bottom: var(--card-section-border);
}
.air3-card-section[data-v-57dcc37e] {
  & + &[data-v-57dcc37e]:not(:last-child) {
      border-radius: 0;
  }
}
@media (hover: hover) {
  .air3-card-section.air3-card-hover[data-v-57dcc37e]:hover,
  .air3-card-section.air3-card-hover[focus-within][data-v-57dcc37e] {
      --hoverable-bg: var(--bg-on-hoverable-light);
      background-color: var(--bg-muted-light);
      cursor: pointer;
  }
  .air3-card-section.air3-card-hover[data-v-57dcc37e]:focus-within,
  .air3-card-section.air3-card-hover[data-v-57dcc37e]:hover {
      --hoverable-bg: var(--bg-on-hoverable-light);
      background-color: var(--bg-muted-light);
      cursor: pointer;
  }
}
.air3-card-sections[data-v-57dcc37e] {
  padding: 0;
}
.how-to-hire-home-page-container[data-v-57dcc37e] {
  letter-spacing: var(--letter-spacing-base);
  margin-bottom: 54px;
}
@media (min-width: 700px) {
  .how-to-hire-home-page-container[data-v-57dcc37e] {
      margin-bottom: 64px;
  }
}
@media (min-width: 700px) {
  .how-to-hire-home-page-container .air3-grid-container[data-v-57dcc37e] {
      grid-gap: var(--ws-8x);
      gap: var(--ws-8x);
  }
}
@media (max-width: 850px) {
  .how-to-hire-home-page-container .air3-grid-container[data-v-57dcc37e] {
      grid-template-columns: 1fr;
  }
}
.how-to-hire-home-page-container .how-to-hire-list[data-v-57dcc37e] {
  margin-bottom: 16px;
}
@media (min-width: 700px) {
  .how-to-hire-home-page-container .how-to-hire-list[data-v-57dcc37e] {
      margin-bottom: 24px;
  }
}
.how-to-hire-home-page-container .how-to-hire-list .air3-icon[data-v-57dcc37e] {
  margin-right: 16px;
}
@media (max-width: 1199.98px) {
  .how-to-hire-home-page-container .how-to-hire-list .air3-icon[data-v-57dcc37e] {
      width: 41px;
  }
}
.how-to-hire-home-page-container h2[data-v-57dcc37e] {
  margin-bottom: 24px;
  font-size: 34px;
}
@media (min-width: 700px) {
  .how-to-hire-home-page-container h2[data-v-57dcc37e] {
      font-size: 40px;
      margin-bottom: 16px;
  }
}
.how-to-hire-home-page-container p[data-v-57dcc37e] {
  font-weight: var(--font-weight-body);
  margin-bottom: 0;
  margin-top: 4px;
}
.how-to-hire-home-page-container .how-to-hire-cta[data-v-57dcc37e] {
  display: flex;
  margin-top: var(--ws-8x);
}
@media (min-width: 700px) {
  .how-to-hire-home-page-container .how-to-hire-cta a[data-v-57dcc37e]:first-of-type {
      margin-right: 16px;
  }
}
@media (max-width: 699.98px) {
  .how-to-hire-home-page-container .how-to-hire-cta .full-sm-width[data-v-57dcc37e] {
      width: 100%;
  }
}
.how-to-hire-home-page-container .how-to-hire-list-subtitle[data-v-57dcc37e] {
  font-size: var(--font-size-base-sm);
  line-height: var(--line-height-base-sm);
}
.how-to-hire-home-page-container .how-to-hire-details[data-v-57dcc37e] {
  position: relative;
  z-index: 2;
  background: #fff;
  padding-bottom: 10px;
}
.how-to-hire-home-page-container .air3-btn[data-v-57dcc37e] {
  font-size: var(--font-size-base);
}
.how-to-hire-home-page-container .cta-second[data-v-57dcc37e] {
  line-height: 40px;
}
.how-to-hire-home-page-container .steps-container[data-v-57dcc37e] {
  background: var(--gray-06);
  border-radius: var(--radius-4x);
  justify-content: center;
}
@media (min-width: 700px) {
  .how-to-hire-home-page-container .steps-container[data-v-57dcc37e] {
      min-width: 352px;
  }
}
.how-to-hire-home-page-container .steps-container[data-v-57dcc37e] {
  min-height: 264px;
}
.air3-lottie svg {
  border-radius: var(--radius-4x);
}
@media (min-width: 1200px) {
  .air3-lottie svg {
      height: 370px !important;
      display: flex;
  }
}
.air3-icon {
  --icon-color-bg: var(--bg-inverse);
  --icon-color: currentColor;
  --icon-size: 24px;
}
@media (forced-colors: active) {
  .air3-icon {
      --icon-color-bg: var(--hc-bg-base);
      fill: currentColor;
  }
  .air3-icon.is-disabled {
      --icon-color: var(--hc-disabled);
  }
}
.air3-icon {
  display: inline-flex;
  width: var(--icon-size);
  height: var(--icon-size);
  color: currentColor;
}
.air3-icon > svg {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100% !important;
  height: 100% !important;
  display: block;
}
.air3-icon.sm {
  --icon-size: 16px;
}
.air3-icon.lg {
  --icon-size: 32px;
}
.air3-icon.xl {
  --icon-size: 48px;
}
.air3-card,
.air3-card-section {
  --card-padding-x: var(--ws-6x);
  --card-padding-y: var(--ws-6x);
  --card-padding: var(--card-padding-y) var(--card-padding-x);
  --card-section-padding: var(--card-padding);
  --card-section-border: var(--border);
}
@media (min-width: 700px) {
  .air3-card,
  .air3-card-section {
      --card-padding-x: var(--ws-8x);
      --card-padding-y: var(--ws-8x);
  }
}
.air3-card {
  --card-bg: var(--bg-muted-light);
  --card-border: 1px solid transparent;
  --card-radius: var(--radius-8x);
  --card-bg-outline: var(--bg-inverse);
  --card-border-outline: var(--border);
  --card-radius-list: 0;
  --card-padding-x-list: 0;
  --card-bg-list: var(--bg-inverse);
  --card-border-list: var(--border);
  --card-padding-x-sm: var(--ws-6x);
  --card-padding-y-sm: var(--ws-6x);
  --card-link-bg: var(--bg-hoverable-light);
  --card-link-bg-hover: var(--bg-hoverable-light-hover);
  --card-link-text: var(--text-dark-on-hoverable-light);
  --card-link-text-hover: var(--hc-text-on-selected, var(--text-dark-on-hoverable-light));
  padding: var(--card-padding);
  background: var(--card-bg);
  border: var(--card-border);
  border-radius: var(--card-radius);
  width: 100%;
}
.air3-card.air3-card-link {
  --card-bg: var(--card-link-bg);
  --text-link: var(--card-link-text);
  --text-link-hover: var(--card-link-text-hover);
  --underline-link: none;
  --underline-link-hover: none;
  display: block;
}
.air3-card.air3-card-link:focus,
.air3-card.air3-card-link:hover {
  --card-bg: var(--card-link-bg-hover);
  --card-border: 1px solid var(--card-link-bg-hover);
}
@media (forced-colors: active) {
  .air3-card.air3-card-link:focus,
  .air3-card.air3-card-link:hover {
      forced-color-adjust: none;
  }
  .air3-card.air3-card-link:focus:focus,
  .air3-card.air3-card-link:hover:focus {
      outline-color: var(--hc-text-on-selected);
  }
}
.air3-card-sm {
  --card-padding-x: var(--card-padding-x-sm);
  --card-padding-y: var(--card-padding-y-sm);
}
.air3-card-outline {
  --card-bg: var(--card-bg-outline);
  --card-border: var(--card-border-outline);
}
.air3-card-interactive {
  box-shadow: var(--shadow-interactive);
  border: 0;
  border: var(--hc-border, 0);
  cursor: pointer;
  transition: box-shadow 0.5s ease;
  transition: var(--reduced-motion, box-shadow 0.5s ease);
}
.air3-card-interactive:hover {
  box-shadow: var(--shadow-interactive-hover);
}
.air3-card-list {
  --card-radius: var(--card-radius-list);
  --card-padding-x: var(--card-padding-x-list);
  --card-bg: var(--card-bg-list);
  --card-border: var(--card-border-list);
  border-left: 0;
  border-right: 0;
}
.air3-card-list + .air3-card-list {
  border-top: 0;
}
.air3-card-section {
  padding: var(--card-section-padding);
}
.air3-card-section:first-child {
  border-radius: var(--card-radius) var(--card-radius) 0 0;
}
.air3-card-section:last-child {
  border-radius: 0 0 var(--card-radius) var(--card-radius);
}
.air3-card-section:only-child {
  border-radius: var(--card-radius);
}
.air3-card-section:not(:last-child) {
  border-bottom: var(--card-section-border);
}
.air3-card-section {
  & + &:not(:last-child) {
      border-radius: 0;
  }
}
@media (hover: hover) {
  .air3-card-section.air3-card-hover:hover,
  .air3-card-section.air3-card-hover[focus-within] {
      --hoverable-bg: var(--bg-on-hoverable-light);
      background-color: var(--bg-muted-light);
      cursor: pointer;
  }
  .air3-card-section.air3-card-hover:focus-within,
  .air3-card-section.air3-card-hover:hover {
      --hoverable-bg: var(--bg-on-hoverable-light);
      background-color: var(--bg-muted-light);
      cursor: pointer;
  }
}
.air3-card-sections {
  padding: 0;
}
.category-tile[data-v-24d628fa] {
  color: var(--text);
  text-decoration: none;
}
.category-pills[data-v-24d628fa] {
  margin-bottom: 50px;
}
@media (min-width: 700px) {
  .category-pills[data-v-24d628fa] {
      margin-bottom: 60px;
  }
}
@media (min-width: 1200px) {
  .category-pills[data-v-24d628fa] {
      margin-bottom: 80px;
  }
}
.category-tile[data-v-24d628fa] {
  font-weight: 500;
  cursor: pointer;
  border-radius: var(--radius-4x);
  padding: 20px;
}
@media (min-width: 1200px) {
  .category-tile[data-v-24d628fa] {
      height: 134px;
      padding: 20px 20px 52px;
      font-size: 22px;
  }
}
@media (max-width: 699.98px) {
  .category-tile[data-v-24d628fa] {
      padding: 15px;
  }
}
.category-tile[data-v-24d628fa]:active,
.category-tile[data-v-24d628fa]:focus,
.category-tile[data-v-24d628fa]:hover {
  background: var(--bg-control-muted-hover-dark);
}
.browse-talent-categories[data-v-24d628fa] {
  margin-top: 1px;
  font-family: Ubuntu;
  font-size: 56px;
  letter-spacing: -0.035em;
}
@media (min-width: 700px) {
  .browse-talent-categories[data-v-24d628fa] {
      font-size: 42px;
  }
}
@media (max-width: 699.98px) {
  .browse-talent-categories[data-v-24d628fa] {
      font-size: 38px;
  }
}
.browse-jobs-label[data-v-24d628fa] {
  font-size: var(--font-size-base);
  color: var(--text-light-on-control);
  font-weight: 500;
  margin-bottom: 40px;
  letter-spacing: 0.02em;
}
@media (max-width: 699.98px) {
  .browse-jobs-label[data-v-24d628fa] {
      margin-bottom: 30px;
  }
}
.show-more-label[data-v-24d628fa] {
  font-size: 15px;
  text-decoration: none;
}
@media (max-width: 699.98px) {
  .show-more-label[data-v-24d628fa] {
      margin-top: 20px;
  }
}
.air3-grid-container[data-v-24d628fa] {
  --row-gap: 15px;
}
.category-pill-title[data-v-a6bcbe58] {
  letter-spacing: 0.6px;
  line-height: 29px;
  font-size: 23px;
}
@media (min-width: 700px) {
  .category-pill-title[data-v-a6bcbe58] {
      font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .category-pill-title[data-v-a6bcbe58] {
      font-size: 23px;
  }
}
.category-pill-details[data-v-a6bcbe58] {
  font-weight: var(--font-weight-body);
}
.category-pill-details span[data-v-a6bcbe58] {
  color: var(--text-light-on-hoverable-dark);
  font-size: var(--font-size-base);
  padding-top: 2px;
}
.category-pill-details span[data-v-a6bcbe58]:not(:last-child) {
  margin-right: 40px;
}
.category-pill-details .air3-icon[data-v-a6bcbe58] {
  margin-right: 4px;
  color: #000435 !important;
}
.bg-inverse {
  background-color: var(--bg-inverse) !important;
}
.bg-accent,
.bg-up-green {
  background-color: #000435 !important;
}
.bg-dark,
.bg-up-black {
  background-color: var(--bg-dark) !important;
}
.bg-gray-1,
.bg-light {
  background-color: var(--bg-light) !important;
}
.bg-danger {
  background-color: var(--color-danger) !important;
}
.bg-warning {
  background-color: var(--color-warning) !important;
}
.bg-success {
  background-color: var(--color-success) !important;
}
.bg-notification,
.bg-orange-red {
  background-color: var(--color-notification) !important;
}
.bg-gray-7,
.bg-muted-light {
  background-color: var(--bg-muted-light) !important;
}
.bg-blue,
.bg-highlight-1 {
  background-color: var(--color-highlight-1) !important;
}
.bg-forest,
.bg-highlight-2 {
  background-color: var(--color-highlight-2) !important;
}
.bg-clay,
.bg-highlight-3 {
  background-color: var(--color-highlight-3) !important;
}
.bg-highlight-4,
.bg-lilac {
  background-color: var(--color-highlight-4) !important;
}
.bg-highlight-5,
.bg-sky {
  background-color: var(--color-highlight-5) !important;
}
.bg-highlight-6,
.bg-pink {
  background-color: var(--color-highlight-6) !important;
}
.bg-brown,
.bg-highlight-7 {
  background-color: var(--color-highlight-7) !important;
}
.bg-highlight-8,
.bg-yellow {
  background-color: var(--color-highlight-8) !important;
}
.bg-brick,
.bg-highlight-9 {
  background-color: var(--color-highlight-9) !important;
}
.bg-highlight-10,
.bg-up-lime {
  background-color: var(--color-highlight-10) !important;
}
.bg-highlight-11,
.bg-mint {
  background-color: var(--color-highlight-11) !important;
}
.bg-heather,
.bg-highlight-12 {
  background-color: var(--color-highlight-12) !important;
}
.text-dark,
.text-dark-on-inverse {
  color: var(--text-dark-on-inverse) !important;
}
.text-light,
.text-light-on-inverse {
  color: var(--text-light-on-inverse) !important;
}
.text-muted,
.text-muted-on-inverse {
  color: var(--text-muted-on-inverse) !important;
}
.text-accent-on-inverse,
.text-up-green {
  color: var(--text-accent-on-inverse) !important;
}
.text-on-accent {
  color: #fff !important;
}
.text-on-dark {
  color: var(--text-on-dark) !important;
}
.text-on-light {
  color: var(--text-on-light) !important;
}
.text-dark-on-muted {
  color: var(--text-dark-on-muted) !important;
}
.text-light-on-muted {
  color: var(--text-light-on-muted) !important;
}
.text-disabled,
.text-on-disabled {
  color: var(--text-on-disabled) !important;
}
.text-danger {
  color: var(--color-danger) !important;
}
.text-warning {
  color: var(--color-warning) !important;
}
.text-success {
  color: var(--color-success) !important;
}
.text-blue,
.text-highlight-1 {
  color: var(--color-highlight-1) !important;
}
.text-forest,
.text-highlight-2 {
  color: var(--color-highlight-2) !important;
}
.text-clay,
.text-highlight-3 {
  color: var(--color-highlight-3) !important;
}
.text-highlight-4,
.text-lilac {
  color: var(--color-highlight-4) !important;
}
.text-highlight-5,
.text-sky {
  color: var(--color-highlight-5) !important;
}
.text-highlight-6,
.text-pink {
  color: var(--color-highlight-6) !important;
}
.text-brown,
.text-highlight-7 {
  color: var(--color-highlight-7) !important;
}
.text-highlight-8,
.text-yellow {
  color: var(--color-highlight-8) !important;
}
.text-brick,
.text-highlight-9 {
  color: var(--color-highlight-9) !important;
}
.text-highlight-10,
.text-lime {
  color: var(--color-highlight-10) !important;
}
.text-highlight-11,
.text-mint {
  color: var(--color-highlight-11) !important;
}
.text-heather,
.text-highlight-12 {
  color: var(--color-highlight-12) !important;
}
.text-on-highlight {
  color: var(--text-on-highlight) !important;
}
.text-inherit {
  color: inherit !important;
}
.container[data-v-69f77594] {
  --font-weight-body: 400;
  letter-spacing: 0.6px;
}
.for-enterprise-banner-container[data-v-69f77594] {
  display: flex;
  overflow: hidden;
}
@media (min-width: 700px) {
  .for-enterprise-banner-container[data-v-69f77594] {
      border-radius: var(--radius-4x);
      --col-gap: var(--ws-2x);
  }
}
@media (min-width: 1200px) {
  .for-enterprise-banner-container[data-v-69f77594] {
      --col-gap: var(--ws-24x);
  }
}
.enterprise-learn-more-btn[data-v-69f77594] {
  background: var(--bg-btn-light);
  border: transparent;
  color: var(--carbonite);
  margin: 25px 0 0;
}
.enterprise-container[data-v-69f77594] {
  padding: 35px 45px 35px 35px;
}
@media (max-width: 699.98px) {
  .enterprise-container[data-v-69f77594] {
      padding: 20px 15px;
  }
}
@media (min-width: 700px) and (max-width: 1199.98px) {
  .enterprise-container[data-v-69f77594] {
      padding: 40px 25px 29px 30px;
  }
}
.enterprise-title[data-v-69f77594] {
  margin-bottom: 10px;
  line-height: 100%;
  letter-spacing: -1.96px;
}
@media (min-width: 700px) {
  .enterprise-title[data-v-69f77594] {
      margin-bottom: 20px;
  }
}
@media (max-width: 1199.98px) {
  .enterprise-title[data-v-69f77594] {
      font-size: 38px;
      line-height: 100%;
  }
}
@media (min-width: 700px) and (max-width: 1199.98px) {
  .enterprise-title[data-v-69f77594] {
      font-size: 42px;
      margin-bottom: 25px;
  }
}
@media (min-width: 1200px) {
  .enterprise-title[data-v-69f77594] {
      font-size: 56px;
  }
}
.enterprise-image[data-v-69f77594] {
  z-index: 1;
  max-width: 100%;
  max-height: 100%;
}
.enterprise-divider[data-v-69f77594] {
  border-color: #fff;
  margin-bottom: 26px;
  margin-top: 50px;
}
@media (min-width: 700px) {
  .enterprise-divider[data-v-69f77594] {
      margin-top: 78px;
  }
}
@media (min-width: 1200px) {
  .enterprise-divider[data-v-69f77594] {
      margin-top: 104px;
  }
}
.enterprise-headline[data-v-69f77594] {
  margin-bottom: 16px;
  font-size: 22px;
  line-height: 130%;
}
@media (--down-sm) {
  .enterprise-headline[data-v-69f77594] {
      font-size: 18px;
  }
}
@media (min-width: 1200px) {
  .enterprise-headline[data-v-69f77594] {
      margin-bottom: 35px;
  }
}
.enterprise-subtitle[data-v-69f77594] {
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.6px;
  font-weight: var(--font-weight-body);
}
.bg-highlight-2[data-v-69f77594] {
  background-color: var(--color-highlight-2);
}
.c-mint[data-v-69f77594] {
  color: var(--color-highlight-11);
  stroke: var(--color-highlight-11) !important;
}
.c-forest[data-v-69f77594] {
  color: var(--color-highlight-2);
}
.text-inverse[data-v-69f77594] {
  color: #fff;
}
@media (max-width: 699.98px) {
  .for-enterprise[data-v-69f77594] {
      padding: 0 !important;
  }
}
.for-enterprise .enterprise-image-container picture[data-v-69f77594] {
  height: 535px;
  display: block;
}
.for-enterprise .enterprise-image-container img[data-v-69f77594] {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.ent-more-details[data-v-69f77594] {
  line-height: 110%;
  font-size: 18px;
}
.arrow[data-v-69f77594] {
  border: solid var(--border-color-inverse);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 8px;
}
.arrow .up[data-v-69f77594] {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}
.arrow .down[data-v-69f77594] {
  margin-bottom: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.enterprise-description-list[data-v-69f77594] {
  font-weight: 400;
}
img.more-details-item-icon[data-v-69f77594] {
  height: 20px;
}
.rebrand-delivery-models[data-v-881f17c8] {
  overflow: hidden;
  margin-top: 50px;
}
@media (max-width: 699.98px) {
  .rebrand-delivery-models[data-v-881f17c8] {
      padding: 0 !important;
  }
}
.vs-color-white[data-v-881f17c8] {
  color: #fff;
}
.delivery-model-card[data-v-881f17c8] {
  font-weight: 400;
}
.bg-delivery-model[data-v-881f17c8] {
  background-image: url(https://res.cloudinary.com/dr6nn6thq/image/upload/v1704712188/fntp-brand_qxwzhe.png);
  background-size: cover;
  background-position: top;
  padding: 15px;
  letter-spacing: 0.6px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 192dpi) {
  .bg-delivery-model[data-v-881f17c8] {
      background-image: url(https://res.cloudinary.com/dr6nn6thq/image/upload/v1704712188/fntp-brand_qxwzhe.png);
  }
}
@media (min-width: 700px) {
  .bg-delivery-model[data-v-881f17c8] {
      padding: 30px;
      border-radius: 8px;
      background-image: url(https://res.cloudinary.com/dr6nn6thq/image/upload/v1704712188/fntp-brand_qxwzhe.png);
  }
}
@media (min-width: 700px) and only screen and (-webkit-min-device-pixel-ratio: 2), (min-width: 700px) and only screen and (min-resolution: 2dppx), (min-width: 700px) and only screen and (min-resolution: 192dpi) {
  .bg-delivery-model[data-v-881f17c8] {
      background-image: url(https://res.cloudinary.com/dr6nn6thq/image/upload/v1704712188/fntp-brand_qxwzhe.png);
  }
}
.bg-delivery-model .deliver-models-card-link[data-v-881f17c8] {
  align-items: flex-start;
  max-height: unset;
  white-space: unset;
  text-align: left;
  border-radius: 8px;
  display: flex !important;
  margin: 0 !important;
  height: 100% !important;
  flex-direction: column;
  padding: 15px !important;
}
.bg-delivery-model .deliver-models-card-link[data-v-881f17c8]:hover {
  background-color: #fff;
}
.bg-delivery-model .deliver-models-card-link:hover span[data-v-881f17c8] {
  color: #000435;
}
.bg-delivery-model .deliver-models-card-link:hover svg[data-v-881f17c8] {
  fill: #000435;
}
.bg-delivery-model .model-title[data-v-881f17c8] {
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.002em;
  margin-top: 30px;
}
@media (min-width: 1200px) {
  .bg-delivery-model .model-title[data-v-881f17c8] {
      font-size: 36px;
      line-height: 36px;
  }
}
@media (min-width: 700px) {
  .bg-delivery-model .model-title[data-v-881f17c8] {
      margin-bottom: 20px;
      margin-top: 0;
  }
}
.bg-delivery-model .model-name[data-v-881f17c8] {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.002em;
  margin-top: -18px;
  text-align: right;
}
@media (max-width: 380px) {
  .bg-delivery-model .model-name[data-v-881f17c8] {
      margin-top: 10px;
  }
}
@media (min-width: 700px) {
  .bg-delivery-model .model-name[data-v-881f17c8] {
      margin-top: auto;
      text-align: left;
  }
}
@media (min-width: 1200px) {
  .bg-delivery-model .model-name[data-v-881f17c8] {
      font-size: 18px;
      line-height: 22px;
  }
}
.bg-delivery-model h2.display-rebrand[data-v-881f17c8] {
  font-size: 42px;
  line-height: 42px;
  margin-bottom: 20px;
}
@media (min-width: 700px) {
  .bg-delivery-model h2.display-rebrand[data-v-881f17c8] {
      margin-bottom: 30px;
  }
}
@media (min-width: 1200px) {
  .bg-delivery-model h2.display-rebrand[data-v-881f17c8] {
      font-size: 72px;
      line-height: 72px;
  }
}
.bg-delivery-model .headline[data-v-881f17c8] {
  font-size: var(--font-size-h5);
  line-height: var(--line-height-h5);
  letter-spacing: -0.002em;
  margin-bottom: 100px;
  font-weight: 400;
}
@media (min-width: 1200px) {
  .bg-delivery-model .headline[data-v-881f17c8] {
      font-size: var(--font-size-h4);
      line-height: 32px;
  }
}
.bg-delivery-model .subtitle[data-v-881f17c8] {
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  margin-bottom: 5px;
}
@media (min-width: 700px) {
  .bg-delivery-model .subtitle[data-v-881f17c8] {
      margin-bottom: 20px;
  }
}
@media (min-width: 1200px) {
  .bg-delivery-model .subtitle[data-v-881f17c8] {
      font-size: 18px;
      line-height: 24px;
  }
}
@media (max-width: 699.98px) {
  .bg-delivery-model .delivery-model-card-container[data-v-881f17c8] {
      grid-gap: 0;
      gap: 0;
  }
}
.bg-delivery-model .delivery-model-card[data-v-881f17c8] {
  margin-top: 10px;
}
a .model-name[data-v-881f17c8],
button .model-name[data-v-881f17c8] {
  display: block;
  width: 100%;
}
.rebrand-delivery-models {
  sup {
      font-size: 6px;
      vertical-align: top;
      line-height: 14px;
  }
  @media (min-width: 1200px) {
      sup {
          font-size: 8px;
          line-height: 18px;
      }
  }
}
.vs-height-100 {
  height: 100% !important;
}
.vs-color-white[data-v-0e092248] {
  color: #fff;
}
.updated-why-upwork.why-upwork-margins[data-v-0e092248] {
  margin-top: 80px;
}
@media (min-width: 1200px) {
  .updated-why-upwork.why-upwork-margins[data-v-0e092248] {
      margin-top: 100px;
  }
}
@media (max-width: 699.98px) {
  .updated-why-upwork.why-upwork-margins[data-v-0e092248] {
      margin-top: 30px;
  }
}
@media (max-width: 699.98px) {
  .updated-why-upwork[data-v-0e092248] {
      padding: 0 !important;
  }
}
.updated-why-upwork .air3-grid-container[data-v-0e092248] {
  z-index: 9;
  position: relative;
  --container-ws-x: var(--ws-8x);
}
@media (max-width: 30em) {
  .updated-why-upwork .air3-grid-container[data-v-0e092248] {
      padding: 0 !important;
  }
}
.updated-why-upwork .catalog-why-upwork[data-v-0e092248] {
  font-family: "Neue Montreal", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: -0.008em;
}
.updated-why-upwork .vs-rebrand-underlay[data-v-0e092248] {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}
@media (max-width: 699.98px) {
  .updated-why-upwork .vs-rebrand-underlay[data-v-0e092248] {
      display: none;
      border-radius: 8px;
  }
}
.updated-why-upwork .vs-bg-up-gray[data-v-0e092248] {
  border-radius: 8px;
}
@media (min-width: 700px) {
  .updated-why-upwork .vs-bg-up-gray[data-v-0e092248] {
      overflow: hidden;
      width: 65%;
      background-color: var(--bg-muted-dark);
  }
}
@media (min-width: 1200px) {
  .updated-why-upwork .vs-bg-up-gray[data-v-0e092248] {
      margin-right: 29px;
  }
}
.updated-why-upwork .vs-bg-up-green[data-v-0e092248] {
  overflow: hidden;
  flex: 1;
  background-color: #000435;
  border-radius: 8px;
}
.updated-why-upwork .updated-why-upwork__container[data-v-0e092248] {
  padding-top: 0;
  padding-bottom: 0;
}
@media (min-width: 700px) {
  .updated-why-upwork .updated-why-upwork__container[data-v-0e092248] {
      padding-top: 30px;
      padding-bottom: 30px;
  }
}
@media (min-width: 1200px) {
  .updated-why-upwork .updated-why-upwork__container[data-v-0e092248] {
      padding-top: 50px;
      padding-bottom: 50px;
  }
}
.updated-why-upwork .updated-why-upwork__main-heading[data-v-0e092248] {
  font-size: 72px;
  line-height: 72px;
  margin-top: 30px;
  margin-bottom: 30px;
}
@media (min-width: 700px) {
  .updated-why-upwork .updated-why-upwork__main-heading[data-v-0e092248] {
      padding-right: 100px;
  }
}
@media (min-width: 700px) and (max-width: 1199.98px) {
  .updated-why-upwork .updated-why-upwork__main-heading[data-v-0e092248] {
      font-size: 62px;
      line-height: 62px;
  }
}
@media screen and (max-width: 990px) {
  .updated-why-upwork .updated-why-upwork__main-heading[data-v-0e092248] {
      font-size: 42px;
      line-height: 42px;
  }
}
@media (min-width: 700px) and (max-width: 810px) {
  .updated-why-upwork .award-title[data-v-0e092248] {
      font-size: 26px;
  }
}
.updated-why-upwork .updated-why-upwork__awards-ratings[data-v-0e092248] {
  position: relative;
  align-items: flex-end;
}
@media (max-width: 699.98px) {
  .updated-why-upwork .updated-why-upwork__awards-ratings[data-v-0e092248] {
      background-color: #000435;
      padding-top: 31px;
      padding-bottom: 28px;
      margin-top: 50px;
      overflow: hidden;
  }
}
.updated-why-upwork .updated-why-upwork__awards-ratings .award-area[data-v-0e092248] {
  padding-left: 30px;
}
@media (max-width: 699.98px) {
  .updated-why-upwork .updated-why-upwork__awards-ratings .award-area[data-v-0e092248] {
      padding-left: 15px;
  }
}
@media (min-width: 700px) {
  .updated-why-upwork .updated-why-upwork__awards-ratings .award-area[data-v-0e092248] {
      margin-bottom: 40px;
  }
}
@media (min-width: 1200px) {
  .updated-why-upwork .updated-why-upwork__awards-ratings .award-area[data-v-0e092248] {
      margin-bottom: 30px;
  }
}
.updated-why-upwork .updated-why-upwork__awards-ratings .award-area h3[data-v-0e092248] {
  font-size: 24px;
  line-height: 26px;
}
@media (min-width: 700px) {
  .updated-why-upwork .updated-why-upwork__awards-ratings .award-area h3[data-v-0e092248] {
      margin-bottom: 30px;
      font-size: 33px;
      line-height: 100%;
  }
}
@media (max-width: 699.98px) {
  .updated-why-upwork .updated-why-upwork__awards-ratings .award-area .main-heading[data-v-0e092248] {
      margin-bottom: 31px;
  }
}
.updated-why-upwork .updated-why-upwork__awards-ratings .award-area .award-winner[data-v-0e092248] {
  font-size: 24px;
  line-height: 26px;
}
@media (min-width: 1200px) {
  .updated-why-upwork .updated-why-upwork__awards-ratings .award-area .award-winner[data-v-0e092248] {
      font-size: 38px;
      line-height: 39px;
  }
}
.updated-why-upwork .updated-why-upwork__awards-ratings .award-area .award-winner-content[data-v-0e092248] {
  margin-left: 15px;
}
.updated-why-upwork .updated-why-upwork__awards-ratings .award-area .award-winner-desc[data-v-0e092248] {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}
@media (min-width: 700px) {
  .updated-why-upwork .updated-why-upwork__awards-ratings .award-area .award-winner-desc[data-v-0e092248] {
      padding-right: 15px;
  }
}
@media (max-width: 699.98px) {
  .updated-why-upwork .updated-why-upwork__awards-ratings .award-area .award-winner-desc[data-v-0e092248] {
      margin-bottom: 30px;
  }
}
@media (min-width: 1200px) {
  .updated-why-upwork .updated-why-upwork__awards-ratings .award-area .award-winner-desc[data-v-0e092248] {
      padding-right: 0;
  }
}
.updated-why-upwork .updated-why-upwork__awards-ratings .award-area .air3-icon.lg[data-v-0e092248] {
  --size: 24px;
}
@media (min-width: 700px) {
  .updated-why-upwork .updated-why-upwork__awards-ratings .award-area .air3-icon.lg[data-v-0e092248] {
      --size: 39px;
  }
}
.updated-why-upwork .updated-why-upwork__awards-ratings .award-area .award-heading-item[data-v-0e092248] {
  margin-bottom: 5px;
}
@media (min-width: 1200px) {
  .updated-why-upwork .updated-why-upwork__awards-ratings .award-area .award-heading-item[data-v-0e092248] {
      margin-bottom: 10px;
  }
}
@media (max-width: 699.98px) {
  .updated-why-upwork .updated-why-upwork__awards-ratings .award-area .award-heading-item[data-v-0e092248] {
      margin-top: 1px;
  }
}
@media (max-width: 699.98px) {
  .updated-why-upwork .updated-why-upwork__awards-ratings .award-area .award-heading-item + p[data-v-0e092248] {
      max-width: 215px;
  }
}
@media (max-width: 30em) {
  .updated-why-upwork .updated-why-upwork__awards-ratings .award-area .award-heading-item + p[data-v-0e092248] {
      max-width: 165px;
  }
}
@media screen and (max-width: 350px) {
  .updated-why-upwork .updated-why-upwork__awards-ratings .award-area .award-heading-item + p[data-v-0e092248] {
      max-width: inherit;
  }
}
.updated-why-upwork .updated-why-upwork__awards-ratings .award-area .award-heading-item.award-winner[data-v-0e092248] {
  margin-top: 0;
}
.updated-why-upwork .updated-why-upwork__awards-ratings .award-area .g2-image[data-v-0e092248] {
  width: auto;
  height: 25px;
}
@media (min-width: 1200px) {
  .updated-why-upwork .updated-why-upwork__awards-ratings .award-area .g2-image[data-v-0e092248] {
      height: 31px;
  }
}
@media (max-width: 699.98px) {
  .updated-why-upwork .updated-why-upwork__awards-ratings .award-area .icon-side[data-v-0e092248] {
      flex-shrink: 0;
      width: 25px;
  }
}
.updated-why-upwork .updated-why-upwork__reaching-person[data-v-0e092248] {
  position: absolute;
  top: 0;
  left: 0;
}
@media (max-width: 699.98px) {
  .updated-why-upwork .updated-why-upwork__reaching-person[data-v-0e092248] {
      transform: translate(0) rotate(-8deg);
      left: auto;
      height: 332px;
      top: 20px;
      right: 13px;
      width: 172px;
      right: -3px;
      top: 29px;
      height: 335px;
  }
}
@media screen and (max-width: 350px) {
  .updated-why-upwork .updated-why-upwork__reaching-person[data-v-0e092248] {
      display: none;
  }
}
@media (min-width: 700px) {
  .updated-why-upwork .updated-why-upwork__reaching-person[data-v-0e092248] {
      transform: translate(-267px, -110px) rotate(-8deg) scale(0.93);
  }
}
@media (min-width: 1200px) {
  .updated-why-upwork .updated-why-upwork__reaching-person[data-v-0e092248] {
      transform: translate(-284px, -97px);
  }
}
.updated-why-upwork .updated-why-upwork__cards[data-v-0e092248] {
  overflow: auto;
  white-space: nowrap;
  display: flex;
}
.updated-why-upwork .updated-why-upwork__card[data-v-0e092248] {
  min-width: 312px;
  background-color: #f7faf7;
  padding: 28px 21px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-right: 15px;
}
@media (max-width: 699.98px) {
  .updated-why-upwork .updated-why-upwork__card[data-v-0e092248] {
      margin-left: auto;
      margin-right: auto;
      min-width: 100%;
      width: 100%;
  }
}
.updated-why-upwork .updated-why-upwork__card p[data-v-0e092248] {
  width: 273px;
  white-space: normal;
  margin-bottom: 0 !important;
}
.updated-why-upwork .updated-why-upwork__card .updated-why-upwork-heading[data-v-0e092248] {
  white-space: normal;
  font-size: 36px;
  letter-spacing: -0.008em;
  width: 92%;
  line-height: 100%;
  align-items: center;
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px;
}
.updated-why-upwork .updated-why-upwork__card .updated-why-upwork-cta[data-v-0e092248] {
  justify-content: center;
}
.updated-why-upwork .updated-why-upwork__card .updated-why-upwork-cta h3[data-v-0e092248] {
  font-family: "Ubuntu", serif;
  text-align: center;
  font-size: 30px;
  margin-bottom: 16px !important;
  margin-top: 0 !important;
}
.updated-why-upwork .updated-why-upwork__card .updated-why-upwork-cta .air3-btn[data-v-0e092248] {
  margin-bottom: 0 !important;
}
.updated-why-upwork .updated-why-upwork__sign-up-prompt[data-v-0e092248] {
  background-color: transparent;
  padding: 50px 15px 0;
  width: 100%;
}
.updated-why-upwork .updated-why-upwork__sign-up-prompt h3[data-v-0e092248] {
  font-size: 38px;
  letter-spacing: -0.032em;
  line-height: 39px;
}
.updated-why-upwork .updated-why-upwork__sign-up-prompt button[data-v-0e092248] {
  width: 137px;
  margin-right: 0;
}
@media (max-width: 699.98px) {
  .updated-why-upwork .updated-why-upwork__proof[data-v-0e092248] {
      padding-right: 15px !important;
      padding-left: 15px !important;
  }
}
.updated-why-upwork .font-weight-400[data-v-0e092248] {
  font-weight: 400;
}
@media (min-width: 700px) {
  .updated-why-upwork__proof-points[data-v-eb0f579c] {
      padding-right: 180px;
  }
}
@media (min-width: 1200px) {
  .updated-why-upwork__proof-points[data-v-eb0f579c] {
      padding-right: 310px;
  }
}
.updated-why-upwork__proof-points p[data-v-eb0f579c] {
  font-size: 16px;
  color: var(--gray-01);
  line-height: 22px;
  margin-bottom: 20px;
}
@media (min-width: 1200px) {
  .updated-why-upwork__proof-points p[data-v-eb0f579c] {
      margin-bottom: 30px;
  }
}
.updated-why-upwork__point-heading[data-v-eb0f579c] {
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 5px;
}
@media (min-width: 700px) {
  .updated-why-upwork__point-heading[data-v-eb0f579c] {
      margin-bottom: 10px;
      font-size: 36px;
      line-height: 100%;
  }
}
.icon-wrapper[data-v-eb0f579c] {
  margin-right: 15px;
}
@media (min-width: 1200px) {
  .icon-wrapper[data-v-eb0f579c] {
      margin-top: 5px;
  }
}
.text-inverse[data-v-243ecbb2] {
  color: #fff;
}
.rebrand-for-talent[data-v-243ecbb2] {
  padding-top: 50px;
}
@media (max-width: 699.98px) {
  .rebrand-for-talent[data-v-243ecbb2] {
      padding-right: 0 !important;
      padding-left: 0 !important;
  }
}
@media screen and (width: 1200px) {
  .rebrand-for-talent[data-v-243ecbb2] {
      padding-top: 85px;
  }
}
.rebrand-for-talent .air3-btn[data-v-243ecbb2]:hover {
  background-color: var(--text-muted-on-inverse);
}
.rebrand-for-talent .air3-btn[data-v-243ecbb2] {
  color: var(--color-highlight-1);
}
.rebrand-for-talent .bg-for-talent[data-v-243ecbb2] {
  min-height: 375px;
  background-size: cover;
  background-position: 100% 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  -o-object-fit: cover;
  object-fit: cover;
}
@media (max-width: 699.98px) {
  .rebrand-for-talent .bg-for-talent[data-v-243ecbb2] {
      z-index: 99;
      height: 375px;
      width: 100%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
  }
}
@media (min-width: 700px) {
  .rebrand-for-talent .bg-for-talent[data-v-243ecbb2] {
      border-radius: 0;
      -o-object-position: 100%;
      object-position: 100%;
  }
}
.rebrand-for-talent .headline[data-v-243ecbb2] {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.002em;
}
@media (min-width: 1200px) {
  .rebrand-for-talent .headline[data-v-243ecbb2] {
      font-size: 24px;
      line-height: 32px;
  }
}
.rebrand-for-talent span[data-v-243ecbb2]:not(.ignore-span-style) {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.005em;
}
@media (min-width: 1200px) {
  .rebrand-for-talent span[data-v-243ecbb2]:not(.ignore-span-style) {
      font-size: 18px;
      line-height: 21px;
  }
}
.rebrand-for-talent .air3-grid-container[data-v-243ecbb2] {
  border-radius: 8px;
  overflow: hidden;
}
@media (max-width: 699.98px) {
  .rebrand-for-talent .air3-grid-container[data-v-243ecbb2] {
      border-radius: 0;
  }
}
.rebrand-for-talent .find-talent-footer div[data-v-243ecbb2] {
  padding: 0 15px 20px;
}
@media (max-width: 699.98px) {
  .rebrand-for-talent .find-talent-footer div[data-v-243ecbb2] {
      padding-left: 0;
  }
}
.rebrand-for-talent .find-talent-footer span[data-v-243ecbb2] {
  font-weight: 400;
}
.for-talent-content[data-v-243ecbb2] {
  background: var(--color-highlight-1);
  padding: 15px;
}
@media (min-width: 700px) {
  .for-talent-content[data-v-243ecbb2] {
      padding: 20px;
  }
}
@media (min-width: 1200px) {
  .for-talent-content[data-v-243ecbb2] {
      padding: 30px;
  }
}
.for-talent-content-first-section[data-v-243ecbb2] {
  padding-bottom: 40px;
  font-weight: 400;
}
@media (min-width: 700px) {
  .for-talent-content-first-section[data-v-243ecbb2] {
      padding-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .for-talent-content-first-section[data-v-243ecbb2] {
      margin-bottom: 60px;
  }
}
@media only screen and (max-width: 1225px) {
  .for-talent-content-first-section[data-v-243ecbb2] {
      margin-bottom: 30px;
  }
}
@media only screen and (min-width: 700px) and (max-width: 767px) {
  .for-talent-content-first-section[data-v-243ecbb2] {
      margin-bottom: 0;
      padding-bottom: 40px;
  }
}
.bg-for-talent-img-container[data-v-243ecbb2] {
  width: 100%;
}
.hr-seperator[data-v-243ecbb2] {
  margin-bottom: 10px;
}
.signup-cta[data-v-243ecbb2] {
  margin-top: 5px;
  margin-bottom: 20px;
}
@media (min-width: 700px) {
  .signup-cta[data-v-243ecbb2] {
      margin-top: 20px;
      margin-bottom: 20px;
  }
}
@media (min-width: 1200px) {
  .signup-cta[data-v-243ecbb2] {
      margin-bottom: 10px;
  }
}
@media only screen and (min-width: 700px) and (max-width: 767px) {
  .signup-cta[data-v-243ecbb2] {
      padding-bottom: 0;
  }
}
.bg-for-talent-md[data-v-243ecbb2] {
  display: none;
}
@media (min-width: 700px) {
  .bg-for-talent-md[data-v-243ecbb2] {
      display: block;
  }
}
.bg-for-talent-sm[data-v-243ecbb2] {
  display: block;
}
@media (min-width: 700px) {
  .bg-for-talent-sm[data-v-243ecbb2] {
      display: none;
  }
}
.testimonials-carousel-section[data-v-689fb947] {
  --body-font-size: 16px;
  --body-line-height: 22px;
  --body-letter-spacing: 0.6px;
  --letter-spacing-h2: -0.035em;
  --line-height-h2: 100%;
  --font-size-h4: 26px;
  --line-height-h4: 23px;
  --letter-spacing-h4: -0.2%;
  --font-family-h6: Neue Montreal;
  --font-size-h6: 18px;
  --line-height-h6: 21px;
  --box-border-radius: 10px;
  --primary-with-up-black: #108c00;
  --transition-common: all 0.3s ease-in-out;
}
.testimonials-carousel-section .item-company-logos[data-v-689fb947] {
  width: 100%;
}
.testimonials-carousel-section .testimonials-vue-slick-carousel-padding[data-v-689fb947] {
  padding: 15px;
}
@media (min-width: 1200px) {
  .testimonials-carousel-section .testimonials-vue-slick-carousel-padding[data-v-689fb947] {
      padding: 25px;
  }
}
@media (max-width: 30em) {
  .testimonials-carousel-section[data-v-689fb947] {
      margin-right: 0;
  }
}
@media (max-width: 992px) {
  .testimonials-carousel-section[data-v-689fb947] {
      margin-top: 60px !important;
  }
}
@media (min-width: 700px) {
  .testimonials-carousel-section[data-v-689fb947] {
      margin-top: 85px !important;
      --font-size-h4: 26px;
      --line-height-h4: 25px;
      --font-size-h1: 64px;
      --font-size-h6: 18px;
      --line-height-h6: 21px;
  }
}
.testimonials-carousel-section .testimonial-carousel-title[data-v-689fb947] {
  --font-size-h2: 34px;
  --letter-spacing-h2: -0.035em;
  --line-height-h2: 100%;
}
@media (min-width: 1200px) {
  .testimonials-carousel-section .testimonial-carousel-title[data-v-689fb947] {
      --font-size-h2: 48px;
      margin-bottom: 0;
  }
}
.testimonials-carousel-section .testimonial-carousel-title[data-v-689fb947] {
  font-size: var(--font-size-h2);
  letter-spacing: var(--letter-spacing-h2);
}
.testimonials-carousel-section .fl-tile-carousel[data-v-689fb947] {
  --tile-padding: 8px;
}
@media (min-width: 1200px) {
  .testimonials-carousel-section .fl-tile-carousel[data-v-689fb947] {
      --tile-padding: 15px;
  }
}
.testimonials-carousel-section .fl-tile-carousel[data-v-689fb947] {
  padding-left: var(--tile-padding);
  padding-right: var(--tile-padding);
  flex-shrink: 0;
  box-sizing: content-box;
  flex-basis: 78%;
}
@media (min-width: 700px) {
  .testimonials-carousel-section .fl-tile-carousel[data-v-689fb947] {
      flex-basis: 46%;
  }
}
.testimonials-carousel-section .fl-tile-carousel[data-v-689fb947]:first-child {
  padding-left: 0 !important;
}
@media (min-width: 1200px) {
  .testimonials-carousel-section .tile-swiper-container[data-v-689fb947] {
      padding-bottom: 40px;
  }
}
.testimonials-carousel-section .testimonial-item-outer[data-v-689fb947] {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}
.testimonials-carousel-section .testimonial-item-outer .testimonial-item[data-v-689fb947] {
  flex: 1;
}
@media screen and((min-width:700px) and (max-width:800px)) {
  .testimonials-carousel-section .testimonial-item-outer[data-v-689fb947] {
      height: 500px;
  }
}
.testimonials-carousel-section .more-reviews-link[data-v-689fb947] {
  display: inline-block;
  border-bottom: 1px solid var(--gray-04);
  width: 125px;
  margin-top: 10px;
  text-decoration: none;
  transition: var(--transition-common);
}
@media (min-width: 1200px) {
  .testimonials-carousel-section .more-reviews-link[data-v-689fb947] {
      position: absolute;
      bottom: -40px;
  }
}
.testimonials-carousel-section .more-reviews-link[data-v-689fb947]:hover {
  border-bottom-color: var(--primary-with-up-black);
}
.testimonials-carousel-section .testimonial-item hr[data-v-689fb947] {
  margin-bottom: 10px;
  margin-top: 5px;
}
@media (min-width: 1200px) {
  .testimonials-carousel-section .testimonial-item hr[data-v-689fb947] {
      margin-bottom: 17px;
      margin-top: 12px;
  }
}
.testimonials-carousel-section .testimonial-item[data-v-689fb947] {
  color: var(--gray-04);
}
.testimonials-carousel-section .testimonial-item h2[data-v-689fb947],
.testimonials-carousel-section .testimonial-item h3[data-v-689fb947],
.testimonials-carousel-section .testimonial-item h4[data-v-689fb947] {
  color: #fff;
}
.testimonials-carousel-section .testimonial-item h4[data-v-689fb947] {
  font-family: Ubuntu;
  letter-spacing: var(--letter-spacing-h3-rebrand);
}
.testimonials-carousel-section .testimonial-item[data-v-689fb947] {
  height: 100%;
  border-radius: var(--box-border-radius);
}
.testimonials-carousel-section .testimonial-item .company-logos[data-v-689fb947] {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.testimonials-carousel-section .testimonial-item .company-logo[data-v-689fb947] {
  margin-bottom: 20px;
}
@media screen and ((max-width: 992px)) {
  .testimonials-carousel-section .testimonial-item .company-logo[data-v-689fb947] {
      margin-bottom: 15px;
  }
}
@media screen and ((max-width: 30em)) {
  .testimonials-carousel-section .testimonial-item .company-logo[data-v-689fb947] {
      margin-bottom: 15px;
  }
}
.testimonials-carousel-section .testimonial-item.nasdaq-testimonial[data-v-689fb947] {
  background-color: #000435;
}
.testimonials-carousel-section .testimonial-item.double-testimonial[data-v-689fb947] {
  background-color: #bc511b;
  @media (min-width: 1200px) {
      .company-logo[data-v-689fb947] {
          margin-bottom: 25px;
      }
  }
}
.testimonials-carousel-section .testimonial-item.double-testimonial[data-v-689fb947],
.testimonials-carousel-section .testimonial-item.item-single-testimonial[data-v-689fb947] {
  justify-content: space-between;
}
@media (min-width: 1200px) {
  .testimonials-carousel-section .equal-col[data-v-689fb947] {
      display: flex !important;
      flex-wrap: wrap;
  }
}
.testimonials-carousel-section .company-logos__cover-image[data-v-689fb947] {
  flex: 1;
  max-width: 290px;
  background-image: url(https://res.cloudinary.com/upwork-cloud-stage/image/upload/v1650909071/general/logobar/colored/company-logos-cover.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  border-top-left-radius: var(--box-border-radius);
  border-bottom-left-radius: var(--box-border-radius);
}
.testimonials-carousel-section .company-logos[data-v-689fb947] {
  flex: 1;
  background: var(--text-dark-on-inverse);
  border-top-right-radius: var(--box-border-radius);
  border-bottom-right-radius: var(--box-border-radius);
}
@media (max-width: 992px) {
  .testimonials-carousel-section .company-logos[data-v-689fb947] {
      border-top-left-radius: var(--box-border-radius);
      border-bottom-left-radius: var(--box-border-radius);
      padding-left: 30px !important;
  }
}
.testimonials-carousel-section-v2[data-v-689fb947] {
  --body-line-height: 22px;
  --letter-spacing-h2: -0.032em;
  --font-size-description: 20px;
  line-height: var(--body-line-height);
}
.testimonials-carousel-section-v2 h2[data-v-689fb947] {
  font-family: Ubuntu;
}
.testimonials-carousel-section-v2 .carousel-container[data-v-689fb947] {
  width: 100%;
}
.testimonials-carousel-section-v2 .title[data-v-689fb947] {
  letter-spacing: -0.032em;
  padding-right: 20px;
  margin-bottom: 20px;
}
@media (min-width: 700px) {
  .testimonials-carousel-section-v2 .title[data-v-689fb947] {
      margin-bottom: 30px;
  }
}
@media screen and (max-width: 30em) {
  .testimonials-carousel-section-v2 .testimonial-item[data-v-689fb947] {
      min-height: 485px;
  }
}
@media screen and ((min-width: 700px) and (max-width:800px)) {
  .testimonials-carousel-section-v2 .testimonial-item[data-v-689fb947] {
      height: 500px;
      max-height: 500px;
  }
}
@media (min-width: 1200px) {
  .testimonials-carousel-section-v2 .testimonial-item[data-v-689fb947] {
      min-height: 450px;
      flex: 1;
  }
}
.testimonials-carousel-section-v2 .testimonial-item .testimonial-many-more[data-v-689fb947] {
  height: 100%;
}
@media (min-width: 700px) {
  .testimonials-carousel-section-v2 .testimonial-item .testimonial-many-more[data-v-689fb947] {
      padding: 41px;
  }
}
@media (max-width: 992px) {
  .testimonials-carousel-section-v2 .testimonial-item .testimonial-many-more[data-v-689fb947] {
      padding-left: 15px !important;
  }
  .testimonials-carousel-section-v2 .testimonial-item .testimonial-many-more div[data-v-689fb947] {
      margin: 0 auto;
  }
}
.testimonials-carousel-section-v2 .testimonial-item .testimonial-many-more-title[data-v-689fb947] {
  font-size: var(--font-size-description);
  color: #fff;
  letter-spacing: -0.002em;
}
.testimonials-carousel-section-v2 .testimonial-bottom-half[data-v-689fb947] {
  min-height: 126px;
}
.testimonials-carousel-section-v2 .testimonial-bottom-half h4[data-v-689fb947] {
  font-family: Ubuntu;
  letter-spacing: -0.03em;
}
.testimonials-carousel-section-v2 .testimonial-bottom-half__column[data-v-689fb947] {
  padding-right: 40px;
}
.testimonials-carousel-section-v2 .company-logo[data-v-689fb947] {
  margin-bottom: 15px;
}
@media (max-width: 992px) {
  .testimonials-carousel-section-v2 .company-logo[data-v-689fb947] {
      height: 30px;
  }
}
.testimonials-carousel-section-v2 h4[data-v-689fb947] {
  font-size: var(--font-size-h4);
  line-height: 25px;
}
.testimonials-carousel-section-v2 .testimonials-desc[data-v-689fb947] {
  font-family: Ubuntu;
  margin-bottom: 10px;
  letter-spacing: -0.021em;
}
@media (max-width: 992px) {
  .testimonials-carousel-section-v2 .testimonials-desc[data-v-689fb947] {
      font-size: var(--font-size-description);
  }
}
.testimonials-carousel-section-v2 .testimonial-author[data-v-689fb947] {
  font-size: var(--body-font-size);
  letter-spacing: 0.6px;
}
.testimonials-carousel-section-v2 .testimonial-many-more-title[data-v-689fb947] {
  margin-bottom: 35px;
}
.testimonials-carousel-section .air3-carousel-list {
  grid-column-gap: var(--ws-8x);
  -moz-column-gap: var(--ws-8x);
  column-gap: var(--ws-8x);
  --carousel-list-gap: 0;
}
.air3-carousel-list-container {
  --carousel-list-control-width: var(--ws-14x);
  --carousel-list-container-padding: var(--ws-4x) 0;
  --carousel-list-gap: var(--ws-4x);
  position: relative;
}
.air3-carousel-list {
  display: flex;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: none;
  margin: 0 calc(var(--carousel-list-gap) / -2);
  padding: var(--carousel-list-container-padding);
}
@media (min-width: 700px) {
  .air3-carousel-list {
      --carousel-list-gap: var(--ws-6x);
  }
}
@media (min-width: 1200px) {
  .air3-carousel-list {
      --carousel-list-gap: var(--ws-8x);
  }
}
@media (prefers-reduced-motion: reduce) {
  .air3-carousel-list {
      scroll-behavior: auto;
  }
}
.air3-carousel-list::-webkit-scrollbar {
  display: none;
}
.air3-carousel-list > li {
  scroll-snap-align: start;
  display: flex;
  flex: 0 0 calc(100% - var(--carousel-list-gap) * 2);
  transition: opacity 0.2s ease-in;
  padding: 0 calc(var(--carousel-list-gap) / 2);
}
@media (min-width: 700px) {
  .air3-carousel-list > li {
      flex: 0 0 40%;
  }
}
@media (min-width: 1200px) {
  .air3-carousel-list > li {
      flex: 0 0 25%;
  }
  .air3-carousel-list > li.is-inactive {
      opacity: 0.4;
  }
}
.air3-carousel-list-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
}
@media (max-width: 699.98px) {
  .air3-carousel-list-arrow {
      display: none;
  }
}
.air3-carousel-list-arrow.air3-carousel-list-arrow-right {
  right: 0;
}
.air3-carousel-list-arrow.air3-carousel-list-arrow-left {
  left: 0;
}
.air3-banner {
  --banner-row: max-content auto;
  --banner-col: repeat(2, 1fr);
  --banner-img-width: var(--illustration-width);
  --banner-img-height: var(--illustration-height);
  --banner-min-height: 254px;
  --banner-row-gap: 0;
  --banner-title-font-family: 'Ubuntu, sans-serif';
  display: grid;
  grid-template: var(--banner-row) / var(--banner-col);
  position: relative;
  min-height: var(--banner-min-height);
  grid-row-gap: var(--banner-row-gap);
  row-gap: var(--banner-row-gap);
  border: none;
  border: var(--hc-border, none);
}
@media (min-width: 700px) {
  .air3-banner {
      --banner-col: 5fr 2fr 1fr;
      --banner-min-height: auto;
      --banner-row-gap: var(--ws-8x);
  }
}
.air3-banner .air3-illustration {
  width: var(--banner-img-width);
  height: var(--banner-img-height);
  min-height: auto;
  min-width: auto;
}
.air3-banner .air3-banner-icon {
  position: absolute;
  top: var(--card-padding-y);
  right: var(--card-padding-x);
}
.air3-banner .air3-popper-trigger {
  position: relative;
  z-index: 1;
}
.air3-banner .air3-banner-title {
  order: 2;
  margin-bottom: 0;
  font-family: var(--banner-title-font-family);
}
.air3-banner-img {
  position: relative;
  display: flex;
  justify-content: flex-end;
}
@media (min-width: 700px) {
  .air3-banner-img {
      grid-area: 1/2/-1/-1;
      justify-content: flex-start;
  }
}
.air3-banner-header {
  display: flex;
  flex-direction: column;
}
@media (max-width: 699.98px) {
  .air3-banner-header {
      grid-column: 1/-1;
  }
}
.air3-banner-cta {
  grid-column: 1/2;
  align-self: flex-end;
}
.air3-banner[data-v-567b12bc] {
  --banner-row-gap: 27px;
  --banner-col: 10fr 2fr;
  margin-top: 100px;
}
.air3-banner-img[data-v-567b12bc] {
  background-repeat: no-repeat;
}
@media (max-width: 699.98px) {
  .air3-banner-img[data-v-567b12bc] {
      background-clip: content-box;
      position: absolute;
      bottom: 0;
      right: 25px;
      width: 125px;
      height: 80px;
      background-position-y: 5px;
      background-size: 110px;
  }
}
.air3-banner-header[data-v-567b12bc] {
  flex-direction: column-reverse;
}
.air3-banner-title[data-v-567b12bc] {
  margin-bottom: 8px;
  font-size: 38px;
  line-height: 42px;
  letter-spacing: -1.2px;
}
@media (max-width: 699.98px) {
  .air3-banner-title[data-v-567b12bc] {
      font-size: 28px;
  }
}
h6[data-v-567b12bc] {
  color: var(--text-light-on-body);
  font-size: 18px;
  line-height: 21px;
}
@media (max-width: 1199.98px) {
  h6[data-v-567b12bc] {
      width: 70%;
  }
}
@media (max-width: 699.98px) {
  h6[data-v-567b12bc] {
      width: 100%;
  }
}
@media (min-width: 1200px) {
  .keyboard-user .skills-list-links[data-v-1dce2ec3],
  .keyboard-user .skills-list-titles[data-v-1dce2ec3] {
      overflow: visible;
  }
}
.rebrand-skills-list[data-v-1dce2ec3] {
  overflow: hidden;
  padding-top: 60px;
}
@media (min-width: 700px) {
  .rebrand-skills-list[data-v-1dce2ec3] {
      padding-top: 100px;
  }
}
.skills-list-titles[data-v-1dce2ec3] {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.skills-list-titles button[data-v-1dce2ec3] {
  font-size: 40px;
}
@media (max-width: 1199.98px) {
  .skills-list-titles button[data-v-1dce2ec3] {
      font-size: 32px;
  }
}
.skills-list-titles button[data-v-1dce2ec3]:hover {
  text-decoration: none;
  color: #000435;
}
@media (min-width: 1200px) {
  .skills-list-titles[data-v-1dce2ec3] {
      padding-left: 2px;
  }
}
.skills-list-titles-title-button[data-v-1dce2ec3] {
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-weight: var(--font-weight-heading);
  letter-spacing: var(--letter-spacing-h2);
  line-height: 40px;
  color: var(--border-color-btn-muted);
}
[dir="ltr"] ul[data-v-1dce2ec3] {
  padding-left: 0;
}
[dir="rtl"] ul[data-v-1dce2ec3] {
  padding-right: 0;
}
ul[data-v-1dce2ec3] {
  list-style-type: none;
  padding-left: 0;
}
sup[data-v-1dce2ec3] {
  vertical-align: top;
  line-height: 20px;
  font-size: 8px;
}
@media (min-width: 700px) {
  sup[data-v-1dce2ec3] {
      font-size: 10px;
  }
}
.active-skill-item[data-v-1dce2ec3] {
  color: #000435;
}
.skills-list-links[data-v-1dce2ec3] {
  margin-bottom: 15px;
}
@media (min-width: 700px) {
  .skills-list-links[data-v-1dce2ec3] {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
}
.skills-list-link[data-v-1dce2ec3] {
  font-size: 16px;
  line-height: 18px;
  color: var(--text-link-navigation-light);
  text-decoration: none;
  font-weight: 400;
}
@media (min-width: 700px) {
  .skills-list-link[data-v-1dce2ec3] {
      font-size: 22px;
      line-height: 24px;
  }
}
@media (min-width: 1200px) {
  .skills-list-link[data-v-1dce2ec3] {
      font-size: 24px;
      line-height: 28px;
  }
}
.skills-list-link[data-v-1dce2ec3]:focus {
  text-decoration: none;
}
.skills-list-link[data-v-1dce2ec3]:hover {
  color: #000435;
}
.skills-list-titles--mobile[data-v-1dce2ec3] {
  scroll-behavior: smooth;
  overflow-y: hidden;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-bottom: 40px;
}
.skills-list-titles--mobile button[data-v-1dce2ec3] {
  margin-right: 15px;
  font-size: 24px;
  line-height: 28px;
}
.skills-list-titles--mobile button[data-v-1dce2ec3]:first-child {
  margin-left: 0;
}
@media (max-width: 699.98px) {
  .skills-list-titles--mobile button[data-v-1dce2ec3]:first-child {
      margin-left: 15px;
  }
}
@media (max-width: 699.98px) {
  .skills-list-titles--mobile button[data-v-1dce2ec3]:last-child {
      margin-right: 15px;
  }
}
.skills-list-titles--mobile[data-v-1dce2ec3]::-webkit-scrollbar {
  display: none;
}
body,
html {
  height: 100%;
}
#layout {
  flex: 1 0 auto;
}
.footer-visitor {
  --footer-visitor-margin: auto var(--ws-mobile-x) var(--ws-mobile-x);
  --footer-visitor-padding: var(--ws-2x) 0 var(--ws-8x);
  --footer-visitor-bg: var(--bg-footer-visitor);
  --footer-visitor-text: var(--text-on-footer-visitor);
  --footer-visitor-border-top: var(--border-top-footer-visitor);
  --footer-visitor-radius: var(--radius-footer-visitor);
  --footer-visitor-header-margin: 0;
  --footer-visitor-header-padding: var(--ws-6x) 0;
  --footer-visitor-header-border: 1px solid var(--border-color-footer-visitor);
  --footer-visitor-header-case: unset;
  --footer-visitor-header-text: var(--text-4);
  --footer-visitor-header-font-size: var(--font-size-base-sm);
  --footer-visitor-header-font-weight: var(--font-weight-normal);
  --footer-visitor-header-align: space-between;
  --footer-visitor-header-icon-text: var(--white);
  --footer-visitor-columns-gap: var(--ws-4x);
  --footer-visitor-columns-template: repeat(4, 1fr);
  --footer-visitor-list-margin: 0 0 var(--ws-4x) var(--ws-4x);
  --footer-visitor-list-gap: var(--ws-2x);
  --footer-visitor-list-line-height: var(--line-height-base);
  --footer-visitor-list-item-margin: 0;
  --footer-visitor-links-text: var(--text-footer-visitor-link);
  --footer-visitor-links-text-hover: var(--text-footer-visitor-link);
  --footer-visitor-links-underline: none;
  --footer-visitor-links-underline-hover: underline;
  --footer-visitor-links-font-size: var(--font-size-base-sm);
  --footer-visitor-links-font-weight: var(--font-weight-normal);
  --footer-visitor-copy-margin: 0 0 var(--ws-6x);
  --footer-visitor-copy-padding: 0;
  --footer-visitor-copy-border: 0;
  --footer-visitor-copy-font-size: var(--font-size-base-sm);
  --footer-visitor-bottom-list-gap: var(--ws-6x);
  --footer-container-max-width: var(--max-width-footer-container);
}
@media (min-width: 700px) {
  .footer-visitor {
      --footer-visitor-margin: auto var(--ws-6x) var(--ws-6x);
      --footer-visitor-padding: var(--ws-14x) 0 var(--ws-6x);
      --footer-visitor-list-margin: var(--ws-4x) 0;
      --footer-visitor-copy-margin: 0 var(--ws-6x) 0 0;
      --footer-visitor-copy-padding: 0 var(--ws-6x) 0 0;
      --footer-visitor-bottom-list-gap: var(--ws-4x);
  }
}
@media (min-width: 1200px) {
  .footer-visitor {
      --footer-visitor-margin: auto auto var(--ws-6x);
      --footer-visitor-padding: var(--ws-24x) 0;
      width: min(calc(100% - var(--ws-6x) * 2), var(--footer-container-max-width));
      max-width: calc(var(--footer-container-max-width) - var(--ws-6x) * 2);
  }
}
.footer-visitor {
  flex: none;
  margin: var(--footer-visitor-margin);
  color: var(--footer-visitor-text);
  background-color: var(--footer-visitor-bg);
  overflow: hidden;
  padding: var(--footer-visitor-padding);
  border-top: var(--footer-visitor-border-top);
  border-radius: var(--footer-visitor-radius);
}
.footer-visitor .container {
  width: 100%;
  max-width: 1488px;
}
@media (min-width: 700px) {
  .footer-visitor .container {
      padding: 0 var(--ws-8x);
  }
}
@media (min-width: 1200px) {
  .footer-visitor .container {
      padding: 0 var(--ws-20x);
  }
}
.footer-visitor .up-footer-social {
  --footer-social-border-bottom: 1px solid var(--border-color-footer-visitor);
  --footer-social-margin: var(--ws-8x) 0 var(--ws-14x);
  --footer-social-padding: var(--ws-4x) 0;
  --footer-social-label-font-weight: var(--font-weight-700);
  --footer-social-list-margin: 0 0 20px;
  --footer-social-label-padding: 20px 0;
}
@media (min-width: 700px) {
  .footer-visitor .up-footer-social {
      --footer-social-margin: var(--ws-6x) 0 var(--ws-4x);
      --footer-social-list-margin: 0;
      --footer-social-label-padding: 0;
  }
}
.footer-bottom-list-link,
.footer-list-link {
  color: var(--footer-visitor-links-text);
  font-size: var(--footer-visitor-links-font-size);
  font-weight: var(--footer-visitor-links-font-weight);
  -webkit-text-decoration: var(--footer-visitor-links-underline);
  text-decoration: var(--footer-visitor-links-underline);
}
.footer-bottom-list-link:focus,
.footer-bottom-list-link:hover,
.footer-list-link:focus,
.footer-list-link:hover {
  color: var(--footer-visitor-links-text-hover);
  -webkit-text-decoration: var(--footer-visitor-links-underline-hover);
  text-decoration: var(--footer-visitor-links-underline-hover);
}
.footer-column-header {
  text-transform: var(--footer-visitor-header-case);
  font-size: var(--footer-visitor-header-font-size);
  font-weight: var(--footer-visitor-header-font-weight);
  color: var(--footer-visitor-header-text);
  margin-bottom: var(--footer-visitor-header-margin);
}
@media (max-width: 699.98px) {
  .footer-column-header {
      display: none;
  }
}
.footer-columns {
  display: grid;
  grid-column-gap: var(--footer-visitor-columns-gap);
  -moz-column-gap: var(--footer-visitor-columns-gap);
  column-gap: var(--footer-visitor-columns-gap);
}
@media (min-width: 700px) {
  .footer-columns {
      grid-template-columns: var(--footer-visitor-columns-template);
  }
}
.footer-list {
  display: grid;
  margin: var(--footer-visitor-list-margin);
  line-height: var(--footer-visitor-list-line-height);
}
@media (min-width: 700px) {
  .footer-list {
      grid-gap: var(--footer-visitor-list-gap);
      gap: var(--footer-visitor-list-gap);
  }
}
.footer-list-item {
  margin-bottom: var(--footer-visitor-list-item-margin);
}
.footer-accordion-content {
  display: none;
}
@media (max-width: 699.98px) {
  .footer-accordion-content ul li {
      margin-bottom: var(--ws-4x);
  }
}
.footer-toggle .footer-accordion-toggle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: var(--footer-visitor-header-align);
  border: 0;
  padding: var(--footer-visitor-header-padding);
  border-bottom: var(--footer-visitor-header-border);
  background: transparent;
}
@media (min-width: 700px) {
  .footer-toggle .footer-accordion-toggle {
      display: none;
  }
}
.footer-toggle .footer-accordion-toggle .up-icon {
  color: var(--footer-visitor-header-icon-text);
  transition: all 0.4s ease-out;
}
.footer-toggle .footer-accordion-toggle[aria-expanded="true"] .footer-accordion-icon {
  transform: rotate(-180deg);
}
.footer-toggle.up-is-open {
  border-bottom: var(--footer-visitor-header-border);
}
.footer-toggle.up-is-open .footer-accordion-toggle {
  border-bottom: 0;
}
.footer-toggle.up-is-open .footer-accordion-content {
  display: block;
}
@media (min-width: 700px) {
  .footer-toggle .footer-accordion-content {
      display: block;
  }
}
.footer-toggle .footer-accordion-icon {
  transition: all 0.4s ease-out;
}
@media (min-width: 700px) {
  .footer-toggle .footer-accordion-icon {
      display: none;
  }
}
@media (min-width: 700px) {
  .footer-bottom-container {
      display: flex;
  }
}
.footer-copy {
  margin: var(--footer-visitor-copy-margin);
  padding: var(--footer-visitor-copy-padding);
  font-size: var(--footer-visitor-copy-font-size);
}
@media (min-width: 700px) {
  .footer-copy {
      border-right: var(--footer-visitor-copy-border);
  }
}
.footer-bottom-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  grid-gap: var(--footer-visitor-bottom-list-gap);
  gap: var(--footer-visitor-bottom-list-gap);
  margin-bottom: 0;
}
@media (min-width: 700px) {
  .footer-bottom-list {
      flex-direction: row;
  }
}
.footer-bottom-list-item {
  margin-bottom: 0;
}
html[theme="dark"],
html[theme="dark"] [design-system="air-3-0"] {
  --footer-visitor-margin: auto 0 var(--ws-mobile-x);
}
.up-footer-social {
  --footer-social-margin: 0;
  --footer-social-padding: 0;
  --footer-social-border-top: 0;
  --footer-social-border-bottom: 0;
  --footer-social-gap: var(--ws-4x);
  --footer-social-label-padding: var(--ws-3x) 0;
  --footer-social-label-text: var(--text-on-footer);
  --footer-social-label-case: none;
  --footer-social-label-font-size: var(--font-size-base-sm);
  --footer-social-label-line-height: 15px;
  --footer-social-label-font-weight: var(--font-weight-body);
  --footer-social-label-gap: var(--ws-4x);
  --footer-social-list-gap: var(--ws-2x);
  --footer-social-list-margin: 0;
  --footer-social-list-item-margin: 0;
  --footer-download-padding: 0 0 var(--ws-4x);
  --footer-social-icon-border: var(--border-footer-icon);
  --footer-social-icon-bg: var(--bg-footer-icon);
  --footer-social-icon-bg-hover: var(--bg-footer-icon-hover);
  --footer-social-icon-text: var(--text-footer-icon);
  --footer-social-icon-size: var(--height-base);
  display: grid;
  margin: var(--footer-social-margin);
}
@media (min-width: 700px) {
  .up-footer-social {
      padding: var(--footer-social-padding);
      border-top: var(--footer-social-border-top);
      border-bottom: var(--footer-social-border-bottom);
      grid-template-columns: 1fr 1fr;
  }
}
.up-footer-social .up-footer-social-header {
  margin: 0;
  color: var(--footer-social-label-text);
  line-height: var(--footer-social-label-line-height);
  font-size: var(--footer-social-label-font-size);
  font-weight: var(--footer-social-label-font-weight);
  text-transform: var(--footer-social-label-case);
}
@media (max-width: 699.98px) {
  .up-footer-social .up-footer-social-header {
      padding: var(--footer-social-label-padding);
      border-top: var(--footer-social-border-top);
  }
}
.up-footer-social .up-footer-download {
  border-bottom: var(--footer-social-border-bottom);
  padding: var(--footer-download-padding);
}
@media (min-width: 700px) {
  .up-footer-social .up-footer-download {
      --footer-download-padding: 0;
      --footer-social-border-bottom: 0;
      justify-content: flex-end;
  }
}
@media (min-width: 700px) {
  .up-footer-social .up-footer-social-icons {
      display: flex;
      align-items: center;
      grid-gap: var(--footer-social-gap);
      gap: var(--footer-social-gap);
  }
}
.up-footer-social .up-footer-social-list {
  display: flex;
  align-items: center;
  grid-gap: var(--footer-social-list-gap);
  gap: var(--footer-social-list-gap);
  list-style: none;
  padding: 0;
  margin: var(--footer-social-list-margin);
}
.up-footer-social .up-footer-social-list-item {
  margin: var(--footer-social-list-item-margin);
}
.up-footer-social .up-footer-social-list-item-currency {
  margin-left: auto;
}
.up-footer-social .up-footer-social-list-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--footer-social-icon-size);
  height: var(--footer-social-icon-size);
  color: var(--footer-social-icon-text);
  background: var(--footer-social-icon-bg);
  text-align: center;
  border-radius: 50%;
  border: var(--footer-social-icon-border);
}
.up-footer-social .up-footer-social-list-link:hover {
  background: var(--footer-social-icon-bg-hover);
}
.up-footer-social .up-footer-social-i18n-modal-trigger {
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  grid-gap: var(--ws-1x);
  gap: var(--ws-1x);
}
.up-footer-social .up-footer-social-i18n-modal-trigger:hover {
  -webkit-text-decoration: var(--underline-link-hover);
  text-decoration: var(--underline-link-hover);
}
.up-footer-social .up-footer-social-i18n-modal-trigger,
.up-footer-social .up-footer-social-i18n-modal-trigger:focus,
.up-footer-social .up-footer-social-i18n-modal-trigger:hover {
  color: var(--footer-social-icon-text);
}
.up-footer-social .up-footer-social-i18n-modal-trigger .air3-icon {
  margin-top: -1px;
}
.keyboard-user .footer-accordion-toggle:focus,
.keyboard-user .footer-bottom-list-link:focus,
.keyboard-user .footer-list-link:focus,
.keyboard-user .up-footer-social-i18n-modal-trigger:focus,
.keyboard-user .up-footer-social-list-link:focus {
  --outline-text: var(--text-outline-accent);
}

      #visitor-v2-top-navigation-container .air3-icon {
          --icon-color-bg: var(--bg-inverse);
          --icon-color: currentColor;
          --icon-size: 24px;
      }
      @media (forced-colors: active) {
          #visitor-v2-top-navigation-container .air3-icon {
              --icon-color-bg: var(--hc-bg-base);
              fill: currentColor;
          }
          #visitor-v2-top-navigation-container .air3-icon.is-disabled {
              --icon-color: var(--hc-disabled);
          }
      }
      #visitor-v2-top-navigation-container .air3-icon {
          display: inline-flex;
          width: var(--icon-size);
          height: var(--icon-size);
          color: currentColor;
      }
      #visitor-v2-top-navigation-container .air3-icon > svg {
          object-fit: contain;
          width: 100% !important;
          height: 100% !important;
          display: block;
      }
      #visitor-v2-top-navigation-container .air3-icon.sm {
          --icon-size: 16px;
      }
      #visitor-v2-top-navigation-container .air3-icon.lg {
          --icon-size: 32px;
      }
      #visitor-v2-top-navigation-container .air3-icon.xl {
          --icon-size: 48px;
      }
      #visitor-v2-top-navigation-container :where(.up-n-link) > .air3-icon {
          vertical-align: middle;
          margin-top: -3px;
      }
      #visitor-v2-top-navigation-container :where(.up-n-link)[aria-disabled] {
          color: var(--text-on-disabled, var(--text-disabled));
          cursor: not-allowed;
          text-decoration: none;
      }
      #visitor-v2-top-navigation-container :where(.up-n-link)[aria-disabled].inverted {
          opacity: 0.4;
      }
      @media (forced-colors: active) {
          #visitor-v2-top-navigation-container :where(.up-n-link)[aria-disabled].inverted {
              opacity: 1;
          }
      }

      #visitor-v2-top-navigation-container .m-0 {
          margin: 0 !important;
      }
      #visitor-v2-top-navigation-container .m-1,
      #visitor-v2-top-navigation-container .m-1x {
          margin: var(--ws-1x) !important;
      }
      #visitor-v2-top-navigation-container .m-2,
      #visitor-v2-top-navigation-container .m-2x {
          margin: var(--ws-2x) !important;
      }
      #visitor-v2-top-navigation-container .m-3,
      #visitor-v2-top-navigation-container .m-3x {
          margin: var(--ws-3x) !important;
      }
      #visitor-v2-top-navigation-container .m-4,
      #visitor-v2-top-navigation-container .m-4x {
          margin: var(--ws-4x) !important;
      }
      #visitor-v2-top-navigation-container .m-5,
      #visitor-v2-top-navigation-container .m-6x {
          margin: var(--ws-6x) !important;
      }
      #visitor-v2-top-navigation-container .m-6,
      #visitor-v2-top-navigation-container .m-8x {
          margin: var(--ws-8x) !important;
      }
      #visitor-v2-top-navigation-container .m-10x {
          margin: var(--ws-10x) !important;
      }
      #visitor-v2-top-navigation-container .m-7,
      #visitor-v2-top-navigation-container .m-12x {
          margin: var(--ws-12x) !important;
      }
      #visitor-v2-top-navigation-container .m-auto {
          margin: auto !important;
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .m-md-0 {
              margin: 0 !important;
          }
          #visitor-v2-top-navigation-container .m-md-1,
          #visitor-v2-top-navigation-container .m-md-1x {
              margin: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .m-md-2,
          #visitor-v2-top-navigation-container .m-md-2x {
              margin: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .m-md-3,
          #visitor-v2-top-navigation-container .m-md-3x {
              margin: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .m-md-4,
          #visitor-v2-top-navigation-container .m-md-4x {
              margin: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .m-md-5,
          #visitor-v2-top-navigation-container .m-md-6x {
              margin: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .m-md-6,
          #visitor-v2-top-navigation-container .m-md-8x {
              margin: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .m-md-10x {
              margin: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .m-md-7,
          #visitor-v2-top-navigation-container .m-md-12x {
              margin: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .m-md-auto {
              margin: auto !important;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .m-lg-0 {
              margin: 0 !important;
          }
          #visitor-v2-top-navigation-container .m-lg-1,
          #visitor-v2-top-navigation-container .m-lg-1x {
              margin: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .m-lg-2,
          #visitor-v2-top-navigation-container .m-lg-2x {
              margin: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .m-lg-3,
          #visitor-v2-top-navigation-container .m-lg-3x {
              margin: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .m-lg-4,
          #visitor-v2-top-navigation-container .m-lg-4x {
              margin: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .m-lg-5,
          #visitor-v2-top-navigation-container .m-lg-6x {
              margin: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .m-lg-6,
          #visitor-v2-top-navigation-container .m-lg-8x {
              margin: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .m-lg-10x {
              margin: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .m-lg-7,
          #visitor-v2-top-navigation-container .m-lg-12x {
              margin: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .m-lg-auto {
              margin: auto !important;
          }
      }
      #visitor-v2-top-navigation-container .mx-0 {
          margin-right: 0 !important;
          margin-left: 0 !important;
      }
      #visitor-v2-top-navigation-container .mx-1,
      #visitor-v2-top-navigation-container .mx-1x {
          margin-right: var(--ws-1x) !important;
          margin-left: var(--ws-1x) !important;
      }
      #visitor-v2-top-navigation-container .mx-2,
      #visitor-v2-top-navigation-container .mx-2x {
          margin-right: var(--ws-2x) !important;
          margin-left: var(--ws-2x) !important;
      }
      #visitor-v2-top-navigation-container .mx-3,
      #visitor-v2-top-navigation-container .mx-3x {
          margin-right: var(--ws-3x) !important;
          margin-left: var(--ws-3x) !important;
      }
      #visitor-v2-top-navigation-container .mx-4,
      #visitor-v2-top-navigation-container .mx-4x {
          margin-right: var(--ws-4x) !important;
          margin-left: var(--ws-4x) !important;
      }
      #visitor-v2-top-navigation-container .mx-5,
      #visitor-v2-top-navigation-container .mx-6x {
          margin-right: var(--ws-6x) !important;
          margin-left: var(--ws-6x) !important;
      }
      #visitor-v2-top-navigation-container .mx-6,
      #visitor-v2-top-navigation-container .mx-8x {
          margin-right: var(--ws-8x) !important;
          margin-left: var(--ws-8x) !important;
      }
      #visitor-v2-top-navigation-container .mx-10x {
          margin-right: var(--ws-10x) !important;
          margin-left: var(--ws-10x) !important;
      }
      #visitor-v2-top-navigation-container .mx-7,
      #visitor-v2-top-navigation-container .mx-12x {
          margin-right: var(--ws-12x) !important;
          margin-left: var(--ws-12x) !important;
      }
      #visitor-v2-top-navigation-container .mx-auto {
          margin-right: auto !important;
          margin-left: auto !important;
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .mx-md-0 {
              margin-right: 0 !important;
              margin-left: 0 !important;
          }
          #visitor-v2-top-navigation-container .mx-md-1,
          #visitor-v2-top-navigation-container .mx-md-1x {
              margin-right: var(--ws-1x) !important;
              margin-left: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .mx-md-2,
          #visitor-v2-top-navigation-container .mx-md-2x {
              margin-right: var(--ws-2x) !important;
              margin-left: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .mx-md-3,
          #visitor-v2-top-navigation-container .mx-md-3x {
              margin-right: var(--ws-3x) !important;
              margin-left: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .mx-md-4,
          #visitor-v2-top-navigation-container .mx-md-4x {
              margin-right: var(--ws-4x) !important;
              margin-left: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .mx-md-5,
          #visitor-v2-top-navigation-container .mx-md-6x {
              margin-right: var(--ws-6x) !important;
              margin-left: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .mx-md-6,
          #visitor-v2-top-navigation-container .mx-md-8x {
              margin-right: var(--ws-8x) !important;
              margin-left: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .mx-md-10x {
              margin-right: var(--ws-10x) !important;
              margin-left: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .mx-md-7,
          #visitor-v2-top-navigation-container .mx-md-12x {
              margin-right: var(--ws-12x) !important;
              margin-left: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .mx-md-auto {
              margin-right: auto !important;
              margin-left: auto !important;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .mx-lg-0 {
              margin-right: 0 !important;
              margin-left: 0 !important;
          }
          #visitor-v2-top-navigation-container .mx-lg-1,
          #visitor-v2-top-navigation-container .mx-lg-1x {
              margin-right: var(--ws-1x) !important;
              margin-left: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .mx-lg-2,
          #visitor-v2-top-navigation-container .mx-lg-2x {
              margin-right: var(--ws-2x) !important;
              margin-left: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .mx-lg-3,
          #visitor-v2-top-navigation-container .mx-lg-3x {
              margin-right: var(--ws-3x) !important;
              margin-left: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .mx-lg-4,
          #visitor-v2-top-navigation-container .mx-lg-4x {
              margin-right: var(--ws-4x) !important;
              margin-left: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .mx-lg-5,
          #visitor-v2-top-navigation-container .mx-lg-6x {
              margin-right: var(--ws-6x) !important;
              margin-left: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .mx-lg-6,
          #visitor-v2-top-navigation-container .mx-lg-8x {
              margin-right: var(--ws-8x) !important;
              margin-left: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .mx-lg-10x {
              margin-right: var(--ws-10x) !important;
              margin-left: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .mx-lg-7,
          #visitor-v2-top-navigation-container .mx-lg-12x {
              margin-right: var(--ws-12x) !important;
              margin-left: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .mx-lg-auto {
              margin-right: auto !important;
              margin-left: auto !important;
          }
      }
      #visitor-v2-top-navigation-container .my-0 {
          margin-top: 0 !important;
          margin-bottom: 0 !important;
      }
      #visitor-v2-top-navigation-container .my-1,
      #visitor-v2-top-navigation-container .my-1x {
          margin-top: var(--ws-1x) !important;
          margin-bottom: var(--ws-1x) !important;
      }
      #visitor-v2-top-navigation-container .my-2,
      #visitor-v2-top-navigation-container .my-2x {
          margin-top: var(--ws-2x) !important;
          margin-bottom: var(--ws-2x) !important;
      }
      #visitor-v2-top-navigation-container .my-3,
      #visitor-v2-top-navigation-container .my-3x {
          margin-top: var(--ws-3x) !important;
          margin-bottom: var(--ws-3x) !important;
      }
      #visitor-v2-top-navigation-container .my-4,
      #visitor-v2-top-navigation-container .my-4x {
          margin-top: var(--ws-4x) !important;
          margin-bottom: var(--ws-4x) !important;
      }
      #visitor-v2-top-navigation-container .my-5,
      #visitor-v2-top-navigation-container .my-6x {
          margin-top: var(--ws-6x) !important;
          margin-bottom: var(--ws-6x) !important;
      }
      #visitor-v2-top-navigation-container .my-6,
      #visitor-v2-top-navigation-container .my-8x {
          margin-top: var(--ws-8x) !important;
          margin-bottom: var(--ws-8x) !important;
      }
      #visitor-v2-top-navigation-container .my-10x {
          margin-top: var(--ws-10x) !important;
          margin-bottom: var(--ws-10x) !important;
      }
      #visitor-v2-top-navigation-container .my-7,
      #visitor-v2-top-navigation-container .my-12x {
          margin-top: var(--ws-12x) !important;
          margin-bottom: var(--ws-12x) !important;
      }
      #visitor-v2-top-navigation-container .my-auto {
          margin-top: auto !important;
          margin-bottom: auto !important;
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .my-md-0 {
              margin-top: 0 !important;
              margin-bottom: 0 !important;
          }
          #visitor-v2-top-navigation-container .my-md-1,
          #visitor-v2-top-navigation-container .my-md-1x {
              margin-top: var(--ws-1x) !important;
              margin-bottom: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .my-md-2,
          #visitor-v2-top-navigation-container .my-md-2x {
              margin-top: var(--ws-2x) !important;
              margin-bottom: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .my-md-3,
          #visitor-v2-top-navigation-container .my-md-3x {
              margin-top: var(--ws-3x) !important;
              margin-bottom: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .my-md-4,
          #visitor-v2-top-navigation-container .my-md-4x {
              margin-top: var(--ws-4x) !important;
              margin-bottom: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .my-md-5,
          #visitor-v2-top-navigation-container .my-md-6x {
              margin-top: var(--ws-6x) !important;
              margin-bottom: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .my-md-6,
          #visitor-v2-top-navigation-container .my-md-8x {
              margin-top: var(--ws-8x) !important;
              margin-bottom: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .my-md-10x {
              margin-top: var(--ws-10x) !important;
              margin-bottom: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .my-md-7,
          #visitor-v2-top-navigation-container .my-md-12x {
              margin-top: var(--ws-12x) !important;
              margin-bottom: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .my-md-auto {
              margin-top: auto !important;
              margin-bottom: auto !important;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .my-lg-0 {
              margin-top: 0 !important;
              margin-bottom: 0 !important;
          }
          #visitor-v2-top-navigation-container .my-lg-1,
          #visitor-v2-top-navigation-container .my-lg-1x {
              margin-top: var(--ws-1x) !important;
              margin-bottom: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .my-lg-2,
          #visitor-v2-top-navigation-container .my-lg-2x {
              margin-top: var(--ws-2x) !important;
              margin-bottom: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .my-lg-3,
          #visitor-v2-top-navigation-container .my-lg-3x {
              margin-top: var(--ws-3x) !important;
              margin-bottom: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .my-lg-4,
          #visitor-v2-top-navigation-container .my-lg-4x {
              margin-top: var(--ws-4x) !important;
              margin-bottom: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .my-lg-5,
          #visitor-v2-top-navigation-container .my-lg-6x {
              margin-top: var(--ws-6x) !important;
              margin-bottom: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .my-lg-6,
          #visitor-v2-top-navigation-container .my-lg-8x {
              margin-top: var(--ws-8x) !important;
              margin-bottom: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .my-lg-10x {
              margin-top: var(--ws-10x) !important;
              margin-bottom: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .my-lg-7,
          #visitor-v2-top-navigation-container .my-lg-12x {
              margin-top: var(--ws-12x) !important;
              margin-bottom: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .my-lg-auto {
              margin-top: auto !important;
              margin-bottom: auto !important;
          }
      }
      #visitor-v2-top-navigation-container .mt-0 {
          margin-top: 0 !important;
      }
      #visitor-v2-top-navigation-container .mt-1,
      #visitor-v2-top-navigation-container .mt-1x {
          margin-top: var(--ws-1x) !important;
      }
      #visitor-v2-top-navigation-container .mt-2,
      #visitor-v2-top-navigation-container .mt-2x {
          margin-top: var(--ws-2x) !important;
      }
      #visitor-v2-top-navigation-container .mt-3,
      #visitor-v2-top-navigation-container .mt-3x {
          margin-top: var(--ws-3x) !important;
      }
      #visitor-v2-top-navigation-container .mt-4,
      #visitor-v2-top-navigation-container .mt-4x {
          margin-top: var(--ws-4x) !important;
      }
      #visitor-v2-top-navigation-container .mt-5,
      #visitor-v2-top-navigation-container .mt-6x {
          margin-top: var(--ws-6x) !important;
      }
      #visitor-v2-top-navigation-container .mt-6,
      #visitor-v2-top-navigation-container .mt-8x {
          margin-top: var(--ws-8x) !important;
      }
      #visitor-v2-top-navigation-container .mt-10x {
          margin-top: var(--ws-10x) !important;
      }
      #visitor-v2-top-navigation-container .mt-7,
      #visitor-v2-top-navigation-container .mt-12x {
          margin-top: var(--ws-12x) !important;
      }
      #visitor-v2-top-navigation-container .mt-auto {
          margin-top: auto !important;
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .mt-md-0 {
              margin-top: 0 !important;
          }
          #visitor-v2-top-navigation-container .mt-md-1,
          #visitor-v2-top-navigation-container .mt-md-1x {
              margin-top: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .mt-md-2,
          #visitor-v2-top-navigation-container .mt-md-2x {
              margin-top: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .mt-md-3,
          #visitor-v2-top-navigation-container .mt-md-3x {
              margin-top: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .mt-md-4,
          #visitor-v2-top-navigation-container .mt-md-4x {
              margin-top: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .mt-md-5,
          #visitor-v2-top-navigation-container .mt-md-6x {
              margin-top: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .mt-md-6,
          #visitor-v2-top-navigation-container .mt-md-8x {
              margin-top: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .mt-md-10x {
              margin-top: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .mt-md-7,
          #visitor-v2-top-navigation-container .mt-md-12x {
              margin-top: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .mt-md-auto {
              margin-top: auto !important;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .mt-lg-0 {
              margin-top: 0 !important;
          }
          #visitor-v2-top-navigation-container .mt-lg-1,
          #visitor-v2-top-navigation-container .mt-lg-1x {
              margin-top: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .mt-lg-2,
          #visitor-v2-top-navigation-container .mt-lg-2x {
              margin-top: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .mt-lg-3,
          #visitor-v2-top-navigation-container .mt-lg-3x {
              margin-top: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .mt-lg-4,
          #visitor-v2-top-navigation-container .mt-lg-4x {
              margin-top: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .mt-lg-5,
          #visitor-v2-top-navigation-container .mt-lg-6x {
              margin-top: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .mt-lg-6,
          #visitor-v2-top-navigation-container .mt-lg-8x {
              margin-top: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .mt-lg-10x {
              margin-top: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .mt-lg-7,
          #visitor-v2-top-navigation-container .mt-lg-12x {
              margin-top: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .mt-lg-auto {
              margin-top: auto !important;
          }
      }
      #visitor-v2-top-navigation-container .mr-0 {
          margin-right: 0 !important;
      }
      #visitor-v2-top-navigation-container .mr-1,
      #visitor-v2-top-navigation-container .mr-1x {
          margin-right: var(--ws-1x) !important;
      }
      #visitor-v2-top-navigation-container .mr-2,
      #visitor-v2-top-navigation-container .mr-2x {
          margin-right: var(--ws-2x) !important;
      }
      #visitor-v2-top-navigation-container .mr-3,
      #visitor-v2-top-navigation-container .mr-3x {
          margin-right: var(--ws-3x) !important;
      }
      #visitor-v2-top-navigation-container .mr-4,
      #visitor-v2-top-navigation-container .mr-4x {
          margin-right: var(--ws-4x) !important;
      }
      #visitor-v2-top-navigation-container .mr-5,
      #visitor-v2-top-navigation-container .mr-6x {
          margin-right: var(--ws-6x) !important;
      }
      #visitor-v2-top-navigation-container .mr-6,
      #visitor-v2-top-navigation-container .mr-8x {
          margin-right: var(--ws-8x) !important;
      }
      #visitor-v2-top-navigation-container .mr-10x {
          margin-right: var(--ws-10x) !important;
      }
      #visitor-v2-top-navigation-container .mr-7,
      #visitor-v2-top-navigation-container .mr-12x {
          margin-right: var(--ws-12x) !important;
      }
      #visitor-v2-top-navigation-container .mr-auto {
          margin-right: auto !important;
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .mr-md-0 {
              margin-right: 0 !important;
          }
          #visitor-v2-top-navigation-container .mr-md-1,
          #visitor-v2-top-navigation-container .mr-md-1x {
              margin-right: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .mr-md-2,
          #visitor-v2-top-navigation-container .mr-md-2x {
              margin-right: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .mr-md-3,
          #visitor-v2-top-navigation-container .mr-md-3x {
              margin-right: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .mr-md-4,
          #visitor-v2-top-navigation-container .mr-md-4x {
              margin-right: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .mr-md-5,
          #visitor-v2-top-navigation-container .mr-md-6x {
              margin-right: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .mr-md-6,
          #visitor-v2-top-navigation-container .mr-md-8x {
              margin-right: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .mr-md-10x {
              margin-right: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .mr-md-7,
          #visitor-v2-top-navigation-container .mr-md-12x {
              margin-right: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .mr-md-auto {
              margin-right: auto !important;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .mr-lg-0 {
              margin-right: 0 !important;
          }
          #visitor-v2-top-navigation-container .mr-lg-1,
          #visitor-v2-top-navigation-container .mr-lg-1x {
              margin-right: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .mr-lg-2,
          #visitor-v2-top-navigation-container .mr-lg-2x {
              margin-right: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .mr-lg-3,
          #visitor-v2-top-navigation-container .mr-lg-3x {
              margin-right: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .mr-lg-4,
          #visitor-v2-top-navigation-container .mr-lg-4x {
              margin-right: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .mr-lg-5,
          #visitor-v2-top-navigation-container .mr-lg-6x {
              margin-right: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .mr-lg-6,
          #visitor-v2-top-navigation-container .mr-lg-8x {
              margin-right: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .mr-lg-10x {
              margin-right: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .mr-lg-7,
          #visitor-v2-top-navigation-container .mr-lg-12 {
              margin-right: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .mr-lg-auto {
              margin-right: auto !important;
          }
      }
      #visitor-v2-top-navigation-container .mb-0 {
          margin-bottom: 0 !important;
      }
      #visitor-v2-top-navigation-container .mb-1,
      #visitor-v2-top-navigation-container .mb-1x {
          margin-bottom: var(--ws-1x) !important;
      }
      #visitor-v2-top-navigation-container .mb-2,
      #visitor-v2-top-navigation-container .mb-2x {
          margin-bottom: var(--ws-2x) !important;
      }
      #visitor-v2-top-navigation-container .mb-3,
      #visitor-v2-top-navigation-container .mb-3x {
          margin-bottom: var(--ws-3x) !important;
      }
      #visitor-v2-top-navigation-container .mb-4,
      #visitor-v2-top-navigation-container .mb-4x {
          margin-bottom: var(--ws-4x) !important;
      }
      #visitor-v2-top-navigation-container .mb-5,
      #visitor-v2-top-navigation-container .mb-6x {
          margin-bottom: var(--ws-6x) !important;
      }
      #visitor-v2-top-navigation-container .mb-6,
      #visitor-v2-top-navigation-container .mb-8x {
          margin-bottom: var(--ws-8x) !important;
      }
      #visitor-v2-top-navigation-container .mb-10x {
          margin-bottom: var(--ws-10x) !important;
      }
      #visitor-v2-top-navigation-container .mb-7,
      #visitor-v2-top-navigation-container .mb-12x {
          margin-bottom: var(--ws-12x) !important;
      }
      #visitor-v2-top-navigation-container .mb-auto {
          margin-bottom: auto !important;
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .mb-md-0 {
              margin-bottom: 0 !important;
          }
          #visitor-v2-top-navigation-container .mb-md-1,
          #visitor-v2-top-navigation-container .mb-md-1x {
              margin-bottom: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .mb-md-2,
          #visitor-v2-top-navigation-container .mb-md-2x {
              margin-bottom: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .mb-md-3,
          #visitor-v2-top-navigation-container .mb-md-3x {
              margin-bottom: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .mb-md-4,
          #visitor-v2-top-navigation-container .mb-md-4x {
              margin-bottom: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .mb-md-5,
          #visitor-v2-top-navigation-container .mb-md-6x {
              margin-bottom: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .mb-md-6,
          #visitor-v2-top-navigation-container .mb-md-8x {
              margin-bottom: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .mb-md-10x {
              margin-bottom: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .mb-md-7,
          #visitor-v2-top-navigation-container .mb-md-12x {
              margin-bottom: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .mb-md-auto {
              margin-bottom: auto !important;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .mb-lg-0 {
              margin-bottom: 0 !important;
          }
          #visitor-v2-top-navigation-container .mb-lg-1,
          #visitor-v2-top-navigation-container .mb-lg-1x {
              margin-bottom: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .mb-lg-2,
          #visitor-v2-top-navigation-container .mb-lg-2x {
              margin-bottom: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .mb-lg-3,
          #visitor-v2-top-navigation-container .mb-lg-3x {
              margin-bottom: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .mb-lg-4,
          #visitor-v2-top-navigation-container .mb-lg-4x {
              margin-bottom: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .mb-lg-5,
          #visitor-v2-top-navigation-container .mb-lg-6x {
              margin-bottom: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .mb-lg-6,
          #visitor-v2-top-navigation-container .mb-lg-8x {
              margin-bottom: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .mb-lg-10x {
              margin-bottom: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .mb-lg-7,
          #visitor-v2-top-navigation-container .mb-lg-12x {
              margin-bottom: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .mb-lg-auto {
              margin-bottom: auto !important;
          }
      }
      #visitor-v2-top-navigation-container .ml-0 {
          margin-left: 0 !important;
      }
      #visitor-v2-top-navigation-container .ml-1,
      #visitor-v2-top-navigation-container .ml-1x {
          margin-left: var(--ws-1x) !important;
      }
      #visitor-v2-top-navigation-container .ml-2,
      #visitor-v2-top-navigation-container .ml-2x {
          margin-left: var(--ws-2x) !important;
      }
      #visitor-v2-top-navigation-container .ml-3,
      #visitor-v2-top-navigation-container .ml-3x {
          margin-left: var(--ws-3x) !important;
      }
      #visitor-v2-top-navigation-container .ml-4,
      #visitor-v2-top-navigation-container .ml-4x {
          margin-left: var(--ws-4x) !important;
      }
      #visitor-v2-top-navigation-container .ml-5,
      #visitor-v2-top-navigation-container .ml-6x {
          margin-left: var(--ws-6x) !important;
      }
      #visitor-v2-top-navigation-container .ml-6,
      #visitor-v2-top-navigation-container .ml-8x {
          margin-left: var(--ws-8x) !important;
      }
      #visitor-v2-top-navigation-container .ml-10x {
          margin-left: var(--ws-10x) !important;
      }
      #visitor-v2-top-navigation-container .ml-7,
      #visitor-v2-top-navigation-container .ml-12x {
          margin-left: var(--ws-12x) !important;
      }
      #visitor-v2-top-navigation-container .ml-auto {
          margin-left: auto !important;
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .ml-md-0 {
              margin-left: 0 !important;
          }
          #visitor-v2-top-navigation-container .ml-md-1,
          #visitor-v2-top-navigation-container .ml-md-1x {
              margin-left: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .ml-md-2,
          #visitor-v2-top-navigation-container .ml-md-2x {
              margin-left: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .ml-md-3,
          #visitor-v2-top-navigation-container .ml-md-3x {
              margin-left: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .ml-md-4,
          #visitor-v2-top-navigation-container .ml-md-4x {
              margin-left: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .ml-md-5,
          #visitor-v2-top-navigation-container .ml-md-6x {
              margin-left: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .ml-md-6,
          #visitor-v2-top-navigation-container .ml-md-8x {
              margin-left: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .ml-md-10x {
              margin-left: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .ml-md-7,
          #visitor-v2-top-navigation-container .ml-md-12x {
              margin-left: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .ml-md-auto {
              margin-left: auto !important;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .ml-lg-0 {
              margin-left: 0 !important;
          }
          #visitor-v2-top-navigation-container .ml-lg-1,
          #visitor-v2-top-navigation-container .ml-lg-1x {
              margin-left: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .ml-lg-2,
          #visitor-v2-top-navigation-container .ml-lg-2x {
              margin-left: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .ml-lg-3,
          #visitor-v2-top-navigation-container .ml-lg-3x {
              margin-left: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .ml-lg-4,
          #visitor-v2-top-navigation-container .ml-lg-4x {
              margin-left: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .ml-lg-5,
          #visitor-v2-top-navigation-container .ml-lg-6x {
              margin-left: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .ml-lg-6,
          #visitor-v2-top-navigation-container .ml-lg-8x {
              margin-left: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .ml-lg-10x {
              margin-left: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .ml-lg-7,
          #visitor-v2-top-navigation-container .ml-lg-12x {
              margin-left: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .ml-lg-auto {
              margin-left: auto !important;
          }
      }

      #visitor-v2-top-navigation-container .d-none {
          display: none !important;
      }
      #visitor-v2-top-navigation-container .d-inline {
          display: inline !important;
      }
      #visitor-v2-top-navigation-container .d-inline-block {
          display: inline-block !important;
      }
      #visitor-v2-top-navigation-container .d-block {
          display: block !important;
      }
      #visitor-v2-top-navigation-container .d-grid {
          display: grid !important;
      }
      #visitor-v2-top-navigation-container .d-flex {
          display: flex !important;
      }
      #visitor-v2-top-navigation-container .d-inline-flex {
          display: inline-flex !important;
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .d-md-none {
              display: none !important;
          }
          #visitor-v2-top-navigation-container .d-md-inline {
              display: inline !important;
          }
          #visitor-v2-top-navigation-container .d-md-inline-block {
              display: inline-block !important;
          }
          #visitor-v2-top-navigation-container .d-md-block {
              display: block !important;
          }
          #visitor-v2-top-navigation-container .d-md-grid {
              display: grid !important;
          }
          #visitor-v2-top-navigation-container .d-md-flex {
              display: flex !important;
          }
          #visitor-v2-top-navigation-container .d-md-inline-flex {
              display: inline-flex !important;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .d-lg-none {
              display: none !important;
          }
          #visitor-v2-top-navigation-container .d-lg-inline {
              display: inline !important;
          }
          #visitor-v2-top-navigation-container .d-lg-inline-block {
              display: inline-block !important;
          }
          #visitor-v2-top-navigation-container .d-lg-block {
              display: block !important;
          }
          #visitor-v2-top-navigation-container .d-lg-grid {
              display: grid !important;
          }
          #visitor-v2-top-navigation-container .d-lg-flex {
              display: flex !important;
          }
          #visitor-v2-top-navigation-container .d-lg-inline-flex {
              display: inline-flex !important;
          }
      }

      #visitor-v2-top-navigation-container .p-0 {
          padding: 0 !important;
      }
      #visitor-v2-top-navigation-container .p-1,
      #visitor-v2-top-navigation-container .p-1x {
          padding: var(--ws-1x) !important;
      }
      #visitor-v2-top-navigation-container .p-2,
      #visitor-v2-top-navigation-container .p-2x {
          padding: var(--ws-2x) !important;
      }
      #visitor-v2-top-navigation-container .p-3,
      #visitor-v2-top-navigation-container .p-3x {
          padding: var(--ws-3x) !important;
      }
      #visitor-v2-top-navigation-container .p-4,
      #visitor-v2-top-navigation-container .p-4x {
          padding: var(--ws-4x) !important;
      }
      #visitor-v2-top-navigation-container .p-5,
      #visitor-v2-top-navigation-container .p-6x {
          padding: var(--ws-6x) !important;
      }
      #visitor-v2-top-navigation-container .p-6,
      #visitor-v2-top-navigation-container .p-8x {
          padding: var(--ws-8x) !important;
      }
      #visitor-v2-top-navigation-container .p-10x {
          padding: var(--ws-10x) !important;
      }
      #visitor-v2-top-navigation-container .p-7,
      #visitor-v2-top-navigation-container .p-12x {
          padding: var(--ws-12x) !important;
      }
      #visitor-v2-top-navigation-container .p-auto {
          padding: auto !important;
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .p-md-0 {
              padding: 0 !important;
          }
          #visitor-v2-top-navigation-container .p-md-1,
          #visitor-v2-top-navigation-container .p-md-1x {
              padding: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .p-md-2,
          #visitor-v2-top-navigation-container .p-md-2x {
              padding: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .p-md-3,
          #visitor-v2-top-navigation-container .p-md-3x {
              padding: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .p-md-4,
          #visitor-v2-top-navigation-container .p-md-4x {
              padding: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .p-md-5,
          #visitor-v2-top-navigation-container .p-md-6x {
              padding: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .p-md-6,
          #visitor-v2-top-navigation-container .p-md-8x {
              padding: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .p-md-10x {
              padding: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .p-md-7,
          #visitor-v2-top-navigation-container .p-md-12x {
              padding: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .p-md-auto {
              padding: auto !important;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .p-lg-0 {
              padding: 0 !important;
          }
          #visitor-v2-top-navigation-container .p-lg-1,
          #visitor-v2-top-navigation-container .p-lg-1x {
              padding: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .p-lg-2,
          #visitor-v2-top-navigation-container .p-lg-2x {
              padding: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .p-lg-3,
          #visitor-v2-top-navigation-container .p-lg-3x {
              padding: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .p-lg-4,
          #visitor-v2-top-navigation-container .p-lg-4x {
              padding: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .p-lg-5,
          #visitor-v2-top-navigation-container .p-lg-6x {
              padding: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .p-lg-6,
          #visitor-v2-top-navigation-container .p-lg-8x {
              padding: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .p-lg-10x {
              padding: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .p-lg-7,
          #visitor-v2-top-navigation-container .p-lg-12x {
              padding: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .p-lg-auto {
              padding: auto !important;
          }
      }
      #visitor-v2-top-navigation-container .px-0 {
          padding-right: 0 !important;
          padding-left: 0 !important;
      }
      #visitor-v2-top-navigation-container .px-1,
      #visitor-v2-top-navigation-container .px-1x {
          padding-right: var(--ws-1x) !important;
          padding-left: var(--ws-1x) !important;
      }
      #visitor-v2-top-navigation-container .px-2,
      #visitor-v2-top-navigation-container .px-2x {
          padding-right: var(--ws-2x) !important;
          padding-left: var(--ws-2x) !important;
      }
      #visitor-v2-top-navigation-container .px-3,
      #visitor-v2-top-navigation-container .px-3x {
          padding-right: var(--ws-3x) !important;
          padding-left: var(--ws-3x) !important;
      }
      #visitor-v2-top-navigation-container .px-4,
      #visitor-v2-top-navigation-container .px-4x {
          padding-right: var(--ws-4x) !important;
          padding-left: var(--ws-4x) !important;
      }
      #visitor-v2-top-navigation-container .px-5,
      #visitor-v2-top-navigation-container .px-6x {
          padding-right: var(--ws-6x) !important;
          padding-left: var(--ws-6x) !important;
      }
      #visitor-v2-top-navigation-container .px-6,
      #visitor-v2-top-navigation-container .px-8x {
          padding-right: var(--ws-8x) !important;
          padding-left: var(--ws-8x) !important;
      }
      #visitor-v2-top-navigation-container .px-10x {
          padding-right: var(--ws-10x) !important;
          padding-left: var(--ws-10x) !important;
      }
      #visitor-v2-top-navigation-container .px-7,
      #visitor-v2-top-navigation-container .px-12x {
          padding-right: var(--ws-12x) !important;
          padding-left: var(--ws-12x) !important;
      }
      #visitor-v2-top-navigation-container .px-auto {
          padding-right: auto !important;
          padding-left: auto !important;
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .px-md-0 {
              padding-right: 0 !important;
              padding-left: 0 !important;
          }
          #visitor-v2-top-navigation-container .px-md-1,
          #visitor-v2-top-navigation-container .px-md-1x {
              padding-right: var(--ws-1x) !important;
              padding-left: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .px-md-2,
          #visitor-v2-top-navigation-container .px-md-2x {
              padding-right: var(--ws-2x) !important;
              padding-left: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .px-md-3,
          #visitor-v2-top-navigation-container .px-md-3x {
              padding-right: var(--ws-3x) !important;
              padding-left: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .px-md-4,
          #visitor-v2-top-navigation-container .px-md-4x {
              padding-right: var(--ws-4x) !important;
              padding-left: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .px-md-5,
          #visitor-v2-top-navigation-container .px-md-6x {
              padding-right: var(--ws-6x) !important;
              padding-left: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .px-md-6,
          #visitor-v2-top-navigation-container .px-md-8x {
              padding-right: var(--ws-8x) !important;
              padding-left: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .px-md-10x {
              padding-right: var(--ws-10x) !important;
              padding-left: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .px-md-7,
          #visitor-v2-top-navigation-container .px-md-12x {
              padding-right: var(--ws-12x) !important;
              padding-left: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .px-md-auto {
              padding-right: auto !important;
              padding-left: auto !important;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .px-lg-0 {
              padding-right: 0 !important;
              padding-left: 0 !important;
          }
          #visitor-v2-top-navigation-container .px-lg-1,
          #visitor-v2-top-navigation-container .px-lg-1x {
              padding-right: var(--ws-1x) !important;
              padding-left: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .px-lg-2,
          #visitor-v2-top-navigation-container .px-lg-2x {
              padding-right: var(--ws-2x) !important;
              padding-left: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .px-lg-3,
          #visitor-v2-top-navigation-container .px-lg-3x {
              padding-right: var(--ws-3x) !important;
              padding-left: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .px-lg-4,
          #visitor-v2-top-navigation-container .px-lg-4x {
              padding-right: var(--ws-4x) !important;
              padding-left: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .px-lg-5,
          #visitor-v2-top-navigation-container .px-lg-6x {
              padding-right: var(--ws-6x) !important;
              padding-left: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .px-lg-6,
          #visitor-v2-top-navigation-container .px-lg-8x {
              padding-right: var(--ws-8x) !important;
              padding-left: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .px-lg-10x {
              padding-right: var(--ws-10x) !important;
              padding-left: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .px-lg-7,
          #visitor-v2-top-navigation-container .px-lg-12x {
              padding-right: var(--ws-12x) !important;
              padding-left: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .px-lg-auto {
              padding-right: auto !important;
              padding-left: auto !important;
          }
      }
      #visitor-v2-top-navigation-container .py-0 {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
      }
      #visitor-v2-top-navigation-container .py-1,
      #visitor-v2-top-navigation-container .py-1x {
          padding-top: var(--ws-1x) !important;
          padding-bottom: var(--ws-1x) !important;
      }
      #visitor-v2-top-navigation-container .py-2,
      #visitor-v2-top-navigation-container .py-2x {
          padding-top: var(--ws-2x) !important;
          padding-bottom: var(--ws-2x) !important;
      }
      #visitor-v2-top-navigation-container .py-3,
      #visitor-v2-top-navigation-container .py-3x {
          padding-top: var(--ws-3x) !important;
          padding-bottom: var(--ws-3x) !important;
      }
      #visitor-v2-top-navigation-container .py-4,
      #visitor-v2-top-navigation-container .py-4x {
          padding-top: var(--ws-4x) !important;
          padding-bottom: var(--ws-4x) !important;
      }
      #visitor-v2-top-navigation-container .py-5,
      #visitor-v2-top-navigation-container .py-6x {
          padding-top: var(--ws-6x) !important;
          padding-bottom: var(--ws-6x) !important;
      }
      #visitor-v2-top-navigation-container .py-6,
      #visitor-v2-top-navigation-container .py-8x {
          padding-top: var(--ws-8x) !important;
          padding-bottom: var(--ws-8x) !important;
      }
      #visitor-v2-top-navigation-container .py-10x {
          padding-top: var(--ws-10x) !important;
          padding-bottom: var(--ws-10x) !important;
      }
      #visitor-v2-top-navigation-container .py-7,
      #visitor-v2-top-navigation-container .py-12x {
          padding-top: var(--ws-12x) !important;
          padding-bottom: var(--ws-12x) !important;
      }
      #visitor-v2-top-navigation-container .py-auto {
          padding-top: auto !important;
          padding-bottom: auto !important;
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .py-md-0 {
              padding-top: 0 !important;
              padding-bottom: 0 !important;
          }
          #visitor-v2-top-navigation-container .py-md-1,
          #visitor-v2-top-navigation-container .py-md-1x {
              padding-top: var(--ws-1x) !important;
              padding-bottom: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .py-md-2,
          #visitor-v2-top-navigation-container .py-md-2x {
              padding-top: var(--ws-2x) !important;
              padding-bottom: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .py-md-3,
          #visitor-v2-top-navigation-container .py-md-3x {
              padding-top: var(--ws-3x) !important;
              padding-bottom: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .py-md-4,
          #visitor-v2-top-navigation-container .py-md-4x {
              padding-top: var(--ws-4x) !important;
              padding-bottom: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .py-md-5,
          #visitor-v2-top-navigation-container .py-md-6x {
              padding-top: var(--ws-6x) !important;
              padding-bottom: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .py-md-6,
          #visitor-v2-top-navigation-container .py-md-8x {
              padding-top: var(--ws-8x) !important;
              padding-bottom: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .py-md-10x {
              padding-top: var(--ws-10x) !important;
              padding-bottom: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .py-md-7,
          #visitor-v2-top-navigation-container .py-md-12x {
              padding-top: var(--ws-12x) !important;
              padding-bottom: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .py-md-auto {
              padding-top: auto !important;
              padding-bottom: auto !important;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .py-lg-0 {
              padding-top: 0 !important;
              padding-bottom: 0 !important;
          }
          #visitor-v2-top-navigation-container .py-lg-1,
          #visitor-v2-top-navigation-container .py-lg-1x {
              padding-top: var(--ws-1x) !important;
              padding-bottom: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .py-lg-2,
          #visitor-v2-top-navigation-container .py-lg-2x {
              padding-top: var(--ws-2x) !important;
              padding-bottom: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .py-lg-3,
          #visitor-v2-top-navigation-container .py-lg-3x {
              padding-top: var(--ws-3x) !important;
              padding-bottom: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .py-lg-4,
          #visitor-v2-top-navigation-container .py-lg-4x {
              padding-top: var(--ws-4x) !important;
              padding-bottom: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .py-lg-5,
          #visitor-v2-top-navigation-container .py-lg-6x {
              padding-top: var(--ws-6x) !important;
              padding-bottom: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .py-lg-6,
          #visitor-v2-top-navigation-container .py-lg-8x {
              padding-top: var(--ws-8x) !important;
              padding-bottom: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .py-lg-10x {
              padding-top: var(--ws-10x) !important;
              padding-bottom: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .py-lg-7,
          #visitor-v2-top-navigation-container .py-lg-12x {
              padding-top: var(--ws-12x) !important;
              padding-bottom: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .py-lg-auto {
              padding-top: auto !important;
              padding-bottom: auto !important;
          }
      }
      #visitor-v2-top-navigation-container .pt-0 {
          padding-top: 0 !important;
      }
      #visitor-v2-top-navigation-container .pt-1,
      #visitor-v2-top-navigation-container .pt-1x {
          padding-top: var(--ws-1x) !important;
      }
      #visitor-v2-top-navigation-container .pt-2,
      #visitor-v2-top-navigation-container .pt-2x {
          padding-top: var(--ws-2x) !important;
      }
      #visitor-v2-top-navigation-container .pt-3,
      #visitor-v2-top-navigation-container .pt-3x {
          padding-top: var(--ws-3x) !important;
      }
      #visitor-v2-top-navigation-container .pt-4,
      #visitor-v2-top-navigation-container .pt-4x {
          padding-top: var(--ws-4x) !important;
      }
      #visitor-v2-top-navigation-container .pt-5,
      #visitor-v2-top-navigation-container .pt-6x {
          padding-top: var(--ws-6x) !important;
      }
      #visitor-v2-top-navigation-container .pt-6,
      #visitor-v2-top-navigation-container .pt-8x {
          padding-top: var(--ws-8x) !important;
      }
      #visitor-v2-top-navigation-container .pt-10x {
          padding-top: var(--ws-10x) !important;
      }
      #visitor-v2-top-navigation-container .pt-7,
      #visitor-v2-top-navigation-container .pt-12x {
          padding-top: var(--ws-12x) !important;
      }
      #visitor-v2-top-navigation-container .pt-auto {
          padding-top: auto !important;
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .pt-md-0 {
              padding-top: 0 !important;
          }
          #visitor-v2-top-navigation-container .pt-md-1,
          #visitor-v2-top-navigation-container .pt-md-1x {
              padding-top: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .pt-md-2,
          #visitor-v2-top-navigation-container .pt-md-2x {
              padding-top: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .pt-md-3,
          #visitor-v2-top-navigation-container .pt-md-3x {
              padding-top: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .pt-md-4,
          #visitor-v2-top-navigation-container .pt-md-4x {
              padding-top: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .pt-md-5,
          #visitor-v2-top-navigation-container .pt-md-6x {
              padding-top: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .pt-md-6,
          #visitor-v2-top-navigation-container .pt-md-8x {
              padding-top: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .pt-md-10x {
              padding-top: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .pt-md-7,
          #visitor-v2-top-navigation-container .pt-md-12x {
              padding-top: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .pt-md-auto {
              padding-top: auto !important;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .pt-lg-0 {
              padding-top: 0 !important;
          }
          #visitor-v2-top-navigation-container .pt-lg-1,
          #visitor-v2-top-navigation-container .pt-lg-1x {
              padding-top: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .pt-lg-2,
          #visitor-v2-top-navigation-container .pt-lg-2x {
              padding-top: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .pt-lg-3,
          #visitor-v2-top-navigation-container .pt-lg-3x {
              padding-top: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .pt-lg-4,
          #visitor-v2-top-navigation-container .pt-lg-4x {
              padding-top: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .pt-lg-5,
          #visitor-v2-top-navigation-container .pt-lg-6x {
              padding-top: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .pt-lg-6,
          #visitor-v2-top-navigation-container .pt-lg-8x {
              padding-top: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .pt-lg-10x {
              padding-top: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .pt-lg-7,
          #visitor-v2-top-navigation-container .pt-lg-12x {
              padding-top: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .pt-lg-auto {
              padding-top: auto !important;
          }
      }
      #visitor-v2-top-navigation-container .pr-0 {
          padding-right: 0 !important;
      }
      #visitor-v2-top-navigation-container .pr-1,
      #visitor-v2-top-navigation-container .pr-1x {
          padding-right: var(--ws-1x) !important;
      }
      #visitor-v2-top-navigation-container .pr-2,
      #visitor-v2-top-navigation-container .pr-2x {
          padding-right: var(--ws-2x) !important;
      }
      #visitor-v2-top-navigation-container .pr-3,
      #visitor-v2-top-navigation-container .pr-3x {
          padding-right: var(--ws-3x) !important;
      }
      #visitor-v2-top-navigation-container .pr-4,
      #visitor-v2-top-navigation-container .pr-4x {
          padding-right: var(--ws-4x) !important;
      }
      #visitor-v2-top-navigation-container .pr-5,
      #visitor-v2-top-navigation-container .pr-6x {
          padding-right: var(--ws-6x) !important;
      }
      #visitor-v2-top-navigation-container .pr-6,
      #visitor-v2-top-navigation-container .pr-8x {
          padding-right: var(--ws-8x) !important;
      }
      #visitor-v2-top-navigation-container .pr-10x {
          padding-right: var(--ws-10x) !important;
      }
      #visitor-v2-top-navigation-container .pr-7,
      #visitor-v2-top-navigation-container .pr-12x {
          padding-right: var(--ws-12x) !important;
      }
      #visitor-v2-top-navigation-container .pr-auto {
          padding-right: auto !important;
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .pr-md-0 {
              padding-right: 0 !important;
          }
          #visitor-v2-top-navigation-container .pr-md-1,
          #visitor-v2-top-navigation-container .pr-md-1x {
              padding-right: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .pr-md-2,
          #visitor-v2-top-navigation-container .pr-md-2x {
              padding-right: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .pr-md-3,
          #visitor-v2-top-navigation-container .pr-md-3x {
              padding-right: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .pr-md-4,
          #visitor-v2-top-navigation-container .pr-md-4x {
              padding-right: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .pr-md-5,
          #visitor-v2-top-navigation-container .pr-md-6x {
              padding-right: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .pr-md-6,
          #visitor-v2-top-navigation-container .pr-md-8x {
              padding-right: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .pr-md-10x {
              padding-right: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .pr-md-7,
          #visitor-v2-top-navigation-container .pr-md-12x {
              padding-right: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .pr-md-auto {
              padding-right: auto !important;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .pr-lg-0 {
              padding-right: 0 !important;
          }
          #visitor-v2-top-navigation-container .pr-lg-1,
          #visitor-v2-top-navigation-container .pr-lg-1x {
              padding-right: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .pr-lg-2,
          #visitor-v2-top-navigation-container .pr-lg-2x {
              padding-right: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .pr-lg-3,
          #visitor-v2-top-navigation-container .pr-lg-3x {
              padding-right: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .pr-lg-4,
          #visitor-v2-top-navigation-container .pr-lg-4x {
              padding-right: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .pr-lg-5,
          #visitor-v2-top-navigation-container .pr-lg-6x {
              padding-right: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .pr-lg-6,
          #visitor-v2-top-navigation-container .pr-lg-8x {
              padding-right: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .pr-lg-10x {
              padding-right: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .pr-lg-7,
          #visitor-v2-top-navigation-container .pr-lg-12x {
              padding-right: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .pr-lg-auto {
              padding-right: auto !important;
          }
      }
      #visitor-v2-top-navigation-container .pb-0 {
          padding-bottom: 0 !important;
      }
      #visitor-v2-top-navigation-container .pb-1,
      #visitor-v2-top-navigation-container .pb-1x {
          padding-bottom: var(--ws-1x) !important;
      }
      #visitor-v2-top-navigation-container .pb-2,
      #visitor-v2-top-navigation-container .pb-2x {
          padding-bottom: var(--ws-2x) !important;
      }
      #visitor-v2-top-navigation-container .pb-3,
      #visitor-v2-top-navigation-container .pb-3x {
          padding-bottom: var(--ws-3x) !important;
      }
      #visitor-v2-top-navigation-container .pb-4,
      #visitor-v2-top-navigation-container .pb-4x {
          padding-bottom: var(--ws-4x) !important;
      }
      #visitor-v2-top-navigation-container .pb-5,
      #visitor-v2-top-navigation-container .pb-6x {
          padding-bottom: var(--ws-6x) !important;
      }
      #visitor-v2-top-navigation-container .pb-6,
      #visitor-v2-top-navigation-container .pb-8x {
          padding-bottom: var(--ws-8x) !important;
      }
      #visitor-v2-top-navigation-container .pb-10x {
          padding-bottom: var(--ws-10x) !important;
      }
      #visitor-v2-top-navigation-container .pb-7,
      #visitor-v2-top-navigation-container .pb-12x {
          padding-bottom: var(--ws-12x) !important;
      }
      #visitor-v2-top-navigation-container .pb-auto {
          padding-bottom: auto !important;
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .pb-md-0 {
              padding-bottom: 0 !important;
          }
          #visitor-v2-top-navigation-container .pb-md-1,
          #visitor-v2-top-navigation-container .pb-md-1x {
              padding-bottom: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .pb-md-2,
          #visitor-v2-top-navigation-container .pb-md-2x {
              padding-bottom: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .pb-md-3,
          #visitor-v2-top-navigation-container .pb-md-3x {
              padding-bottom: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .pb-md-4,
          #visitor-v2-top-navigation-container .pb-md-4x {
              padding-bottom: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .pb-md-5,
          #visitor-v2-top-navigation-container .pb-md-6x {
              padding-bottom: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .pb-md-6,
          #visitor-v2-top-navigation-container .pb-md-8x {
              padding-bottom: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .pb-md-10x {
              padding-bottom: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .pb-md-7,
          #visitor-v2-top-navigation-container .pb-md-12x {
              padding-bottom: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .pb-md-auto {
              padding-bottom: auto !important;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .pb-lg-0 {
              padding-bottom: 0 !important;
          }
          #visitor-v2-top-navigation-container .pb-lg-1,
          #visitor-v2-top-navigation-container .pb-lg-1x {
              padding-bottom: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .pb-lg-2,
          #visitor-v2-top-navigation-container .pb-lg-2x {
              padding-bottom: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .pb-lg-3,
          #visitor-v2-top-navigation-container .pb-lg-3x {
              padding-bottom: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .pb-lg-4,
          #visitor-v2-top-navigation-container .pb-lg-4x {
              padding-bottom: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .pb-lg-5,
          #visitor-v2-top-navigation-container .pb-lg-6x {
              padding-bottom: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .pb-lg-6,
          #visitor-v2-top-navigation-container .pb-lg-8x {
              padding-bottom: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .pb-lg-10x {
              padding-bottom: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .pb-lg-7,
          #visitor-v2-top-navigation-container .pb-lg-12x {
              padding-bottom: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .pb-lg-auto {
              padding-bottom: auto !important;
          }
      }
      #visitor-v2-top-navigation-container .pl-0 {
          padding-left: 0 !important;
      }
      #visitor-v2-top-navigation-container .pl-1,
      #visitor-v2-top-navigation-container .pl-1x {
          padding-left: var(--ws-1x) !important;
      }
      #visitor-v2-top-navigation-container .pl-2,
      #visitor-v2-top-navigation-container .pl-2x {
          padding-left: var(--ws-2x) !important;
      }
      #visitor-v2-top-navigation-container .pl-3,
      #visitor-v2-top-navigation-container .pl-3x {
          padding-left: var(--ws-3x) !important;
      }
      #visitor-v2-top-navigation-container .pl-4,
      #visitor-v2-top-navigation-container .pl-4x {
          padding-left: var(--ws-4x) !important;
      }
      #visitor-v2-top-navigation-container .pl-5,
      #visitor-v2-top-navigation-container .pl-6x {
          padding-left: var(--ws-6x) !important;
      }
      #visitor-v2-top-navigation-container .pl-6,
      #visitor-v2-top-navigation-container .pl-8x {
          padding-left: var(--ws-8x) !important;
      }
      #visitor-v2-top-navigation-container .pl-10x {
          padding-left: var(--ws-10x) !important;
      }
      #visitor-v2-top-navigation-container .pl-7,
      #visitor-v2-top-navigation-container .pl-12x {
          padding-left: var(--ws-12x) !important;
      }
      #visitor-v2-top-navigation-container .pl-auto {
          padding-left: auto !important;
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .pl-md-0 {
              padding-left: 0 !important;
          }
          #visitor-v2-top-navigation-container .pl-md-1,
          #visitor-v2-top-navigation-container .pl-md-1x {
              padding-left: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .pl-md-2,
          #visitor-v2-top-navigation-container .pl-md-2x {
              padding-left: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .pl-md-3,
          #visitor-v2-top-navigation-container .pl-md-3x {
              padding-left: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .pl-md-4,
          #visitor-v2-top-navigation-container .pl-md-4x {
              padding-left: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .pl-md-5,
          #visitor-v2-top-navigation-container .pl-md-6x {
              padding-left: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .pl-md-6,
          #visitor-v2-top-navigation-container .pl-md-8x {
              padding-left: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .pl-md-10x {
              padding-left: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .pl-md-7,
          #visitor-v2-top-navigation-container .pl-md-12x {
              padding-left: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .pl-md-auto {
              padding-left: auto !important;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .pl-lg-0 {
              padding-left: 0 !important;
          }
          #visitor-v2-top-navigation-container .pl-lg-1,
          #visitor-v2-top-navigation-container .pl-lg-1x {
              padding-left: var(--ws-1x) !important;
          }
          #visitor-v2-top-navigation-container .pl-lg-2,
          #visitor-v2-top-navigation-container .pl-lg-2x {
              padding-left: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .pl-lg-3,
          #visitor-v2-top-navigation-container .pl-lg-3x {
              padding-left: var(--ws-3x) !important;
          }
          #visitor-v2-top-navigation-container .pl-lg-4,
          #visitor-v2-top-navigation-container .pl-lg-4x {
              padding-left: var(--ws-4x) !important;
          }
          #visitor-v2-top-navigation-container .pl-lg-5,
          #visitor-v2-top-navigation-container .pl-lg-6x {
              padding-left: var(--ws-6x) !important;
          }
          #visitor-v2-top-navigation-container .pl-lg-6,
          #visitor-v2-top-navigation-container .pl-lg-8x {
              padding-left: var(--ws-8x) !important;
          }
          #visitor-v2-top-navigation-container .pl-lg-10x {
              padding-left: var(--ws-10x) !important;
          }
          #visitor-v2-top-navigation-container .pl-lg-7,
          #visitor-v2-top-navigation-container .pl-lg-12x {
              padding-left: var(--ws-12x) !important;
          }
          #visitor-v2-top-navigation-container .pl-lg-auto {
              padding-left: auto !important;
          }
      }

      #visitor-v2-top-navigation-container .layout-container {
          display: flex;
          flex-direction: column;
          min-height: 100%;
      }
      #visitor-v2-top-navigation-container .layout {
          flex: 1 0 auto;
      }
      #visitor-v2-top-navigation-container .layout > .container,
      #visitor-v2-top-navigation-container .layout > .container-visitor,
      #visitor-v2-top-navigation-container .layout > main > .container,
      #visitor-v2-top-navigation-container .layout > main > .container-visitor {
          padding-top: var(--container-ws-y);
          padding-bottom: var(--container-ws-y);
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .layout > .container,
          #visitor-v2-top-navigation-container .layout > .container-visitor,
          #visitor-v2-top-navigation-container .layout > main > .container,
          #visitor-v2-top-navigation-container .layout > main > .container-visitor {
              --container-ws-y: var(--ws-8x);
          }
      }
      #visitor-v2-top-navigation-container .container,
      #visitor-v2-top-navigation-container .container-visitor {
          --container-width: 1280px;
          --container-ws-x: var(--ws-mobile-x);
          --container-ws-y: var(--ws-mobile-x);
          position: relative;
          width: min(100%, calc(var(--container-width) + var(--container-ws-x) * 2));
          margin-left: auto;
          margin-right: auto;
          padding-right: var(--container-ws-x) !important;
          padding-left: var(--container-ws-x) !important;
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .container,
          #visitor-v2-top-navigation-container .container-visitor {
              --container-ws-x: var(--ws-8x);
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .container,
          #visitor-v2-top-navigation-container .container-visitor {
              --container-ws-x: var(--ws-20x);
          }
      }
      @media (max-width: 699.98px) {
          #visitor-v2-top-navigation-container .container,
          #visitor-v2-top-navigation-container .container-visitor {
              --card-radius-custom: 0;
          }
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .container .up-card {
              margin-left: 0;
              margin-right: 0;
          }
      }
      @media (prefers-reduced-motion: reduce) {
          #visitor-v2-top-navigation-container [class*="air3-transition"] {
              animation: none !important;
              transition: none !important;
          }
      }
      #visitor-v2-top-navigation-container .air3-transition-intro-enter-active,
      #visitor-v2-top-navigation-container .air3-transition-intro-leave-active {
          --animation-duration: 0.5s;
          --animation-timing-function: cubic-bezier(0.7, 0, 0.1, 1);
          --translate-x-start: -20px;
          --translate-x-end: 0;
          --translate-y-start: -20px;
          --translate-y-end: 0;
          --scale-x-start: 1;
          --scale-x-end: 1;
          --scale-y-start: 1;
          --scale-y-end: 1;
          --opacity-start: 0;
          --opacity-end: 1;
          --animation-delay: 0s;
          --transition-type: air3-transition-intro;
          animation: var(--transition-type) var(--animation-duration);
          animation-timing-function: var(--animation-timing-function);
          animation-delay: var(--animation-delay);
      }
      #visitor-v2-top-navigation-container .air3-transition-intro-leave-active {
          animation-direction: reverse;
      }
      @keyframes air3-transition-intro {
          0% {
              transform: translate(var(--translate-x-start), var(--translate-y-start)) scale(var(--scale-x-start), var(--scale-y-start));
              opacity: var(--opacity-start);
          }
          to {
              transform: translate(var(--translate-x-end), var(--translate-y-end)) scale(var(--scale-x-end), var(--scale-y-end));
              opacity: var(--opacity-end);
          }
      }
      @keyframes air3-fade-in-transition {
          0% {
              opacity: var(--opacity-start);
          }
          20% {
              opacity: var(--opacity-start);
          }
          to {
              opacity: var(--opacity-start);
          }
      }
      #visitor-v2-top-navigation-container .air3-btn {
          --btn-height: var(--height-base);
          --btn-gap: var(--ws-4x);
          --btn-padding-x: var(--ws-6x);
          --btn-border: var(--border-width-btn) solid var(--btn-border-color, transparent);
          --btn-font-size: var(--font-size-base);
          --btn-font-weight: var(--font-weight-strong);
          --btn-radius: var(--radius-pill);
          --btn-bg-primary: #000435;
          --btn-bg-primary-hover: #000435;
          --btn-text-primary: var(--text-on-btn-dark);
          --btn-text-primary-hover: var(--text-on-btn-dark-hover);
          --btn-text-icon-primary: var(--text-icon-on-btn-dark);
          --btn-border-color-primary: var(--border-color-btn-dark);
          --btn-shadow-primary: var(--shadow-on-btn-dark);
          --btn-bg-primary-inverted: var(--bg-btn-dark-inverted);
          --btn-bg-primary-inverted-hover: var(--bg-btn-dark-inverted-hover);
          --btn-text-primary-inverted: var(--text-on-btn-dark-inverted);
          --btn-text-primary-inverted-hover: var(--text-on-btn-dark-inverted-hover);
          --btn-text-icon-primary-inverted: var(--text-icon-on-btn-dark-inverted);
          --btn-border-color-primary-inverted: var(--border-color-btn-dark-inverted);
          --btn-shadow-primary-inverted: var(--shadow-on-btn-dark-inverted);
          --btn-bg-secondary: var(--bg-btn-light);
          --btn-bg-secondary-hover: var(--bg-btn-light-hover);
          --btn-text-secondary: var(--text-on-btn-light);
          --btn-text-secondary-hover: var(--text-on-btn-light-hover);
          --btn-text-icon-secondary: var(--text-icon-on-btn-light);
          --btn-border-color-secondary: var(--border-color-btn-light);
          --btn-shadow-secondary: var(--shadow-on-btn-light);
          --btn-bg-secondary-inverted: var(--bg-btn-light-inverted);
          --btn-bg-secondary-inverted-hover: var(--bg-btn-light-inverted-hover);
          --btn-text-secondary-inverted: var(--text-on-btn-light-inverted);
          --btn-text-secondary-inverted-hover: var(--text-on-btn-light-inverted-hover);
          --btn-text-icon-secondary-inverted: var(--text-icon-on-btn-light-inverted);
          --btn-border-color-secondary-inverted: var(--border-color-btn-light-inverted);
          --btn-shadow-secondary-inverted: var(--shadow-on-btn-light-inverted);
          --btn-bg-tertiary: var(--bg-btn-muted);
          --btn-bg-tertiary-hover: var(--bg-btn-muted-hover);
          --btn-text-tertiary: var(--text-on-btn-muted);
          --btn-text-tertiary-hover: var(--text-on-btn-muted-hover);
          --btn-text-icon-tertiary: var(--text-icon-on-btn-muted);
          --btn-border-color-tertiary: var(--border-color-btn-muted);
          --btn-shadow-tertiary: var(--shadow-on-btn-muted);
          --btn-bg-tertiary-inverted: var(--bg-btn-muted-inverted);
          --btn-bg-tertiary-inverted-hover: var(--bg-btn-muted-inverted-hover);
          --btn-text-tertiary-inverted: var(--text-on-btn-muted-inverted);
          --btn-text-tertiary-inverted-hover: var(--text-on-btn-muted-inverted-hover);
          --btn-text-icon-tertiary-inverted: var(--text-icon-on-btn-muted-inverted);
          --btn-border-color-tertiary-inverted: var(--border-color-btn-muted-inverted);
          --btn-shadow-tertiary-inverted: var(--shadow-on-btn-muted-inverted);
          --btn-font-weight-link: var(--font-weight-strong);
          --btn-text-link-inverted: var(--text-link-inverse);
          --btn-text-link-inverted-hover: var(--text-link-inverse-hover);
          --btn-height-sm: var(--height-sm);
          --btn-gap-sm: var(--ws-2x);
          --btn-padding-x-sm: var(--ws-4x);
          --btn-font-size-sm: var(--font-size-base-sm);
          --btn-bg-circle: var(--bg-btn-circle);
          --btn-bg-circle-hover: var(--bg-btn-circle-hover);
          --btn-text-circle: var(--text-on-btn-circle);
          --btn-text-circle-hover: var(--text-on-btn-circle-hover);
          --btn-bg-circle-inverted: var(--bg-btn-circle-inverted);
          --btn-bg-circle-inverted-hover: var(--bg-btn-circle-inverted-hover);
          --btn-text-circle-inverted: var(--text-on-btn-circle-inverted);
          --btn-text-circle-inverted-hover: var(--text-on-btn-circle-inverted-hover);
          --btn-size-counter: var(--height-sm);
          --btn-bg-counter: var(--hc-bg-btn, #000435);
          --btn-bg-counter-primary: #000435;
          --btn-bg-counter-primary-hover: var(--hc-bg-btn, #000435);
          --btn-text-counter: var(--hc-text-on-btn, #fff);
          --btn-text-counter-inline: var(--hc-text-on-selected, var(--up-lime));
          --btn-text-counter-inline-hover: var(--hc-bg-selected, var(--up-lime));
          display: inline-flex;
          justify-content: center;
          align-items: center;
          grid-column-gap: var(--btn-gap);
          column-gap: var(--btn-gap);
          max-height: var(--btn-height);
          line-height: calc(var(--btn-height) - 1px);
          padding: 0 var(--btn-padding-x);
          font-size: var(--btn-font-size);
          font-weight: var(--btn-font-weight);
          background-color: var(--btn-bg, transparent);
          color: var(--btn-text, inherit);
          border: var(--btn-border);
          border-radius: var(--btn-radius);
          box-shadow: var(--btn-shadow, none);
          white-space: nowrap;
          -webkit-user-select: none;
          user-select: none;
          transition: all 0.1s ease-in-out;
          cursor: pointer;
          text-decoration: none;
      }
      @media (hover: hover) {
          #visitor-v2-top-navigation-container .air3-btn:hover {
              --btn-bg: var(--btn-bg-hover, inherit);
              --btn-text: var(--btn-text-hover, inherit);
          }
      }
      #visitor-v2-top-navigation-container .air3-btn .air3-icon {
          flex: none;
          color: var(--btn-text-icon, inherit);
      }
      #visitor-v2-top-navigation-container .air3-btn:is(a) {
          --underline-link: none;
          --underline-link-hover: none;
          --text-link: var(--btn-text);
          --text-link-hover: var(--btn-text);
          --link-color-hover: var(--btn-text);
      }
      @media (forced-colors: active) {
          #visitor-v2-top-navigation-container .air3-btn:is(a):hover {
              border-color: var(--hc-bg-selected);
          }
      }
      #visitor-v2-top-navigation-container .air3-btn:active:not([disabled], .is-disabled) {
          transform: scale(var(--reduced-motion, 0.96));
      }
      #visitor-v2-top-navigation-container .air3-btn.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn[disabled] {
          --btn-text: var(--text-on-disabled);
          cursor: not-allowed;
      }
      #visitor-v2-top-navigation-container .air3-btn-primary {
          --btn-bg: #000435;
          --btn-bg-hover: #000435;
          --btn-shadow: var(--btn-shadow-primary);
          --btn-text-icon: var(--btn-text-icon-primary);
      }
      #visitor-v2-top-navigation-container .air3-btn-primary:not([disabled], .is-disabled) {
          --btn-border-color: var(--btn-border-color-primary);
      }
      @media (forced-colors: active) {
          #visitor-v2-top-navigation-container .air3-btn-primary:not([disabled], .is-disabled) {
              forced-color-adjust: none;
          }
          #visitor-v2-top-navigation-container .air3-btn-primary:not([disabled], .is-disabled):focus {
              outline-color: var(--hc-text-on-selected);
          }
      }
      #visitor-v2-top-navigation-container .air3-btn-primary,
      #visitor-v2-top-navigation-container .air3-btn-primary:is(a) {
          --btn-text: #fff;
          --btn-text-hover: var(--btn-text-primary-hover);
      }
      @media (forced-colors: active) {
          #visitor-v2-top-navigation-container .air3-btn-primary:is(a) {
              --btn-bg: var(--hc-text-link);
              --btn-text: var(--hc-bg-base);
              --btn-border-color-primary: var(--hc-text-link);
          }
      }
      #visitor-v2-top-navigation-container .air3-btn-primary-inverted {
          --btn-bg: var(--btn-bg-primary-inverted);
          --btn-bg-hover: var(--btn-bg-primary-inverted-hover);
          --btn-text: var(--btn-text-primary-inverted);
          --btn-text-hover: var(--btn-text-primary-inverted-hover);
          --btn-text-icon: var(--btn-text-icon-primary-inverted);
          --btn-border-color: var(--btn-border-color-primary-inverted);
          --btn-shadow: var(--btn-shadow-primary-inverted);
      }
      #visitor-v2-top-navigation-container .air3-btn-secondary {
          --btn-bg: var(--btn-bg-secondary);
          --btn-bg-hover: var(--btn-bg-secondary-hover);
          --btn-text: var(--btn-text-secondary);
          --btn-text-hover: var(--btn-text-secondary-hover);
          --btn-text-icon: var(--btn-text-icon-secondary);
          --btn-shadow: var(--btn-shadow-secondary);
      }
      #visitor-v2-top-navigation-container .air3-btn-secondary:not([disabled], .is-disabled) {
          --btn-border-color: var(--btn-border-color-secondary);
      }
      #visitor-v2-top-navigation-container .air3-btn-secondary-inverted {
          --btn-bg: var(--btn-bg-secondary-inverted);
          --btn-bg-hover: var(--btn-bg-secondary-inverted-hover);
          --btn-text-icon: var(--btn-text-icon-secondary-inverted);
          --btn-shadow: var(--btn-shadow-secondary-inverted);
      }
      #visitor-v2-top-navigation-container .air3-btn-secondary-inverted:not([disabled], .is-disabled) {
          --btn-border-color: var(--btn-border-color-secondary-inverted);
      }
      #visitor-v2-top-navigation-container .air3-btn-secondary-inverted,
      #visitor-v2-top-navigation-container .air3-btn-secondary-inverted:is(a) {
          --btn-text: var(--btn-text-secondary-inverted);
          --btn-text-hover: var(--btn-text-secondary-inverted-hover);
      }
      #visitor-v2-top-navigation-container .air3-btn-tertiary {
          --btn-bg: var(--btn-bg-tertiary);
          --btn-bg-hover: var(--btn-bg-tertiary-hover);
          --btn-text: var(--btn-text-tertiary);
          --btn-text-hover: var(--btn-text-tertiary-hover);
          --btn-text-icon: var(--btn-text-icon-tertiary);
          --btn-shadow: var(--btn-shadow-tertiary);
      }
      #visitor-v2-top-navigation-container .air3-btn-tertiary:not([disabled], .is-disabled) {
          --btn-border-color: var(--btn-border-color-tertiary);
      }
      #visitor-v2-top-navigation-container .air3-btn-tertiary-inverted {
          --btn-bg: var(--btn-bg-tertiary-inverted);
          --btn-bg-hover: var(--btn-bg-tertiary-inverted-hover);
          --btn-text: var(--btn-text-tertiary-inverted);
          --btn-text-hover: var(--btn-text-tertiary-inverted-hover);
          --btn-text-icon: var(--btn-text-icon-tertiary-inverted);
          --btn-border-color: var(--btn-border-color-tertiary-inverted);
          --btn-shadow: var(--btn-shadow-tertiary-inverted);
      }
      #visitor-v2-top-navigation-container .air3-btn-link {
          background: var(--bg-btn-link);
          color: var(--text-on-btn-link);
          text-decoration: var(--underline-btn-link);
      }
      #visitor-v2-top-navigation-container .air3-btn-link:hover:where(:not([disabled], .is-disabled)) {
          background: var(--bg-btn-link-hover);
          color: var(--text-on-btn-link-hover);
          text-decoration: var(--underline-btn-link-hover);
      }
      #visitor-v2-top-navigation-container .air3-btn-link:not(.air3-btn):hover {
          --underline-btn-link-hover: underline;
          color: var(--text-link-hover);
      }
      #visitor-v2-top-navigation-container .air3-btn-link-secondary {
          color: var(--text-link);
          text-decoration: var(--underline-link);
      }
      #visitor-v2-top-navigation-container .air3-btn-link-secondary:hover:where(:not([disabled], .is-disabled)) {
          color: var(--text-link-hover);
          text-decoration: var(--underline-link-hover);
      }
      #visitor-v2-top-navigation-container .air3-btn-link-inverted,
      #visitor-v2-top-navigation-container .air3-btn-link-inverted.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn-link-inverted[disabled] {
          --btn-bg: transparent;
          --btn-text: var(--btn-text-link-inverted);
      }
      @media (hover: hover) {
          #visitor-v2-top-navigation-container .air3-btn-link-inverted:hover:not([disabled], .is-disabled) {
              --btn-bg: transparent;
              --btn-text: var(--btn-text-link-inverted-hover);
          }
      }
      #visitor-v2-top-navigation-container .air3-btn-link,
      #visitor-v2-top-navigation-container .air3-btn-link-inverted,
      #visitor-v2-top-navigation-container .air3-btn-link-secondary {
          border: 0;
          font-weight: var(--font-weight-base);
      }
      #visitor-v2-top-navigation-container .air3-btn-link-inverted:not(.air3-btn),
      #visitor-v2-top-navigation-container .air3-btn-link-secondary:not(.air3-btn),
      #visitor-v2-top-navigation-container .air3-btn-link:not(.air3-btn) {
          padding: 0;
          background: transparent;
      }
      #visitor-v2-top-navigation-container .air3-btn-link-inverted.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn-link-inverted[disabled],
      #visitor-v2-top-navigation-container .air3-btn-link-secondary.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn-link-secondary[disabled],
      #visitor-v2-top-navigation-container .air3-btn-link.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn-link[disabled] {
          color: var(--text-on-disabled);
          cursor: not-allowed;
      }
      #visitor-v2-top-navigation-container .air3-btn-circle-inverted.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn-circle-inverted[disabled],
      #visitor-v2-top-navigation-container .air3-btn-link-inverted.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn-link-inverted[disabled],
      #visitor-v2-top-navigation-container .air3-btn-primary-inverted.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn-primary-inverted[disabled],
      #visitor-v2-top-navigation-container .air3-btn-secondary-inverted.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn-secondary-inverted[disabled],
      #visitor-v2-top-navigation-container .air3-btn-tertiary-inverted.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn-tertiary-inverted[disabled] {
          --btn-bg-hover: transparent;
          --btn-border-color: transparent;
          opacity: 0.4;
      }
      @media (forced-colors: active) {
          #visitor-v2-top-navigation-container .air3-btn-circle-inverted.is-disabled,
          #visitor-v2-top-navigation-container .air3-btn-circle-inverted[disabled],
          #visitor-v2-top-navigation-container .air3-btn-link-inverted.is-disabled,
          #visitor-v2-top-navigation-container .air3-btn-link-inverted[disabled],
          #visitor-v2-top-navigation-container .air3-btn-primary-inverted.is-disabled,
          #visitor-v2-top-navigation-container .air3-btn-primary-inverted[disabled],
          #visitor-v2-top-navigation-container .air3-btn-secondary-inverted.is-disabled,
          #visitor-v2-top-navigation-container .air3-btn-secondary-inverted[disabled],
          #visitor-v2-top-navigation-container .air3-btn-tertiary-inverted.is-disabled,
          #visitor-v2-top-navigation-container .air3-btn-tertiary-inverted[disabled] {
              opacity: 1;
          }
      }
      #visitor-v2-top-navigation-container .air3-btn-primary.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn-primary[disabled],
      #visitor-v2-top-navigation-container .air3-btn-secondary.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn-secondary[disabled],
      #visitor-v2-top-navigation-container .air3-btn-tertiary.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn-tertiary[disabled] {
          --btn-bg: var(--bg-disabled);
      }
      #visitor-v2-top-navigation-container .air3-btn-primary-inverted.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn-primary-inverted[disabled],
      #visitor-v2-top-navigation-container .air3-btn-secondary-inverted.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn-secondary-inverted[disabled],
      #visitor-v2-top-navigation-container .air3-btn-tertiary-inverted.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn-tertiary-inverted[disabled] {
          --btn-bg: var(--bg-disabled-inverse);
          --btn-text: var(--text-on-disabled-inverse);
      }
      #visitor-v2-top-navigation-container .air3-btn-sm {
          --btn-padding-x: var(--btn-padding-x-sm);
          --btn-font-size: var(--btn-font-size-sm);
          --btn-height: var(--btn-height-sm);
          --btn-gap: var(--btn-gap-sm);
      }
      #visitor-v2-top-navigation-container .air3-btn-circle,
      #visitor-v2-top-navigation-container .air3-btn-circle-inverted {
          width: var(--btn-height);
          height: var(--btn-height);
          padding: 0;
          border-radius: 50%;
          max-height: unset;
      }
      #visitor-v2-top-navigation-container
          .air3-btn-circle:not([disabled], .is-disabled, .air3-btn-primary, .air3-btn-secondary, .air3-btn-tertiary, .air3-btn-primary-inverted, .air3-btn-secondary-inverted, .air3-btn-tertiary-inverted) {
          --btn-bg: var(--btn-bg-circle);
          --btn-text: var(--btn-text-circle);
      }
      #visitor-v2-top-navigation-container
          .air3-btn-circle:not([disabled], .is-disabled, .air3-btn-primary, .air3-btn-secondary, .air3-btn-tertiary, .air3-btn-primary-inverted, .air3-btn-secondary-inverted, .air3-btn-tertiary-inverted):hover {
          --btn-bg: var(--btn-bg-circle-hover);
          --btn-text: var(--btn-text-circle-hover);
      }
      #visitor-v2-top-navigation-container .air3-btn-circle.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn-circle[disabled] {
          --btn-bg-hover: transparent;
      }
      #visitor-v2-top-navigation-container .air3-btn-circle-inverted,
      #visitor-v2-top-navigation-container .air3-btn-circle-inverted.is-disabled,
      #visitor-v2-top-navigation-container .air3-btn-circle-inverted[disabled] {
          --btn-text: var(--btn-text-circle-inverted);
      }
      #visitor-v2-top-navigation-container .air3-btn-circle-inverted:not([disabled], .is-disabled, .air3-btn-secondary, .air3-btn-tertiary) {
          --btn-bg: var(--btn-bg-circle-inverted);
          --btn-text: var(--btn-text-circle-inverted-hover);
      }
      #visitor-v2-top-navigation-container .air3-btn-circle-inverted:not([disabled], .is-disabled, .air3-btn-secondary, .air3-btn-tertiary):hover {
          --btn-bg: var(--btn-bg-circle-inverted-hover);
      }
      #visitor-v2-top-navigation-container .air3-btn-block {
          display: flex;
          width: 100%;
      }
      @media (max-width: 699.98px) {
          #visitor-v2-top-navigation-container .air3-btn-block-sm {
              width: 100%;
          }
      }
      #visitor-v2-top-navigation-container .air3-btn-counter .air3-btn-counter-text {
          flex: 1;
      }
      #visitor-v2-top-navigation-container .air3-btn-counter .air3-btn-counter-circle {
          background-color: var(--btn-bg-counter);
          color: var(--btn-text-counter);
          border-radius: 50%;
          width: var(--btn-size-counter);
          height: var(--btn-size-counter);
          min-width: var(--btn-size-counter);
          min-height: var(--btn-size-counter);
          line-height: 34px;
          margin-right: calc(var(--btn-padding-x) * -1 - var(--ws-1x) * -1);
      }
      @media (max-width: 699.98px) {
          #visitor-v2-top-navigation-container .air3-btn-counter[class*="air3-btn-block"] .air3-btn-counter-circle {
              margin-left: calc(var(--btn-gap) * -2);
          }
      }
      #visitor-v2-top-navigation-container .air3-btn-counter.air3-btn-primary {
          --btn-bg-counter: var(--btn-bg-counter-primary);
      }
      #visitor-v2-top-navigation-container .air3-btn-counter.air3-btn-primary:hover {
          --btn-bg-counter: var(--btn-bg-counter-primary-hover);
      }
      #visitor-v2-top-navigation-container .air3-btn-counter.is-disabled .air3-btn-counter-circle,
      #visitor-v2-top-navigation-container .air3-btn-counter[disabled] .air3-btn-counter-circle {
          --btn-bg-counter: var(--bg-disabled-inner);
          --btn-bg-counter-primary: var(--bg-disabled-inner);
          --btn-bg-counter-primary-hover: var(--bg-disabled-inner);
          --btn-text-counter: var(--text-on-disabled-inner);
      }
      #visitor-v2-top-navigation-container .air3-btn-counter .air3-btn-counter-inline {
          display: contents;
      }
      #visitor-v2-top-navigation-container .air3-btn-counter:not([disabled], .is-disabled) .air3-btn-counter-inline {
          color: var(--btn-text-counter-inline);
      }
      #visitor-v2-top-navigation-container .air3-btn-counter:not([disabled], .is-disabled):hover .air3-btn-counter-inline {
          --btn-text-counter-inline: var(--btn-text-counter-inline-hover);
      }
      @media (max-width: 991.98px) {
          body.nav-is-open .layout > :not(#up-s-top-nav-container):not(.nav-v2),
          body.nav-is-open .up-alert,
          body.nav-is-open footer,
          body.nav-search-is-open .layout > :not(#up-s-top-nav-container):not(.nav-v2),
          body.nav-search-is-open .up-alert,
          body.nav-search-is-open footer {
              display: none;
          }
          body.nav-is-open {
              overflow: visible !important;
          }
      }
      :root {
          --nav-height: 64px;
      }
      @media (max-width: 991.98px) {
          :root {
              --nav-height: 56px;
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 {
          --nav-bg: var(--bg-inverse);
          --nav-text: var(--text-dark-on-inverse);
          --nav-text-accent: var(--text-accent-on-inverse);
          --nav-font-size: 14px;
          --nav-font-size-xs: 11px;
          --nav-font-size-sm: 14px;
          --nav-font-size-lg: 16px;
          --nav-font-weight: var(--font-weight-base);
          --nav-letter-spacing: 0.4px;
          --nav-shadow: none;
          --nav-border: var(--border);
          --nav-link-text: var(--text-link-navigation);
          --nav-link-text-hover: var(--text-link-navigation-hover);
          --nav-link-text-selected: var(--text-link-navigation-selected);
          --nav-link-padding-mobile: calc(var(--nav-ws-4x) - 2px) 0;
          --nav-notification-bg: var(--bg-notification);
          --nav-notification-text: var(--text-on-notification);
          --nav-notification-border-color: var(--border-color-inverse);
          --nav-caret-text: var(--text-icon);
          --nav-icon-text: var(--text-icon);
          --nav-icon-text-selected: var(--text-icon-accent);
          --nav-icon-close-text: var(--text-icon);
          --nav-icon-close-size: 18px;
          --nav-icon-hamburger-selected: var(--text-icon);
          --nav-dropdown-bg: var(--bg-inverse);
          --nav-dropdown-text: var(--text-dark-on-inverse);
          --nav-dropdown-shadow: var(--shadow-popup);
          --nav-dropdown-link-bg: var(--bg-hoverable-light);
          --nav-dropdown-link-bg-hover: var(--bg-hoverable-light-hover);
          --nav-dropdown-link-text: var(--text-dark-on-hoverable-light);
          --nav-dropdown-link-text-hover: var(--text-dark-on-hoverable-light);
          --nav-dropdown-link-text-selected: var(--text-selected);
          --nav-dropdown-sub-label-text: var(--text-light-on-hoverable-light);
          --nav-dropdown-sub-label-font-size: var(--nav-font-size-xs);
          --nav-dropdown-sub-label-font-weight: var(--font-weight-strong);
          --nav-dropdown-radius: var(--radius-3x);
          --nav-dropdown-font-size: var(--nav-font-size-sm);
          --nav-dropdown-font-weight: var(--font-weight-base);
          --nav-dropdown-active-font-weight: var(--font-weight-strong);
          --nav-dropdown-border: var(--border);
          --nav-dropdown-border-color: var(--border-color);
          --nav-dropdown-notification-font-weight: var(--font-weight-body);
          --nav-dropdown-notification-unread-font-weight: var(--font-weight-base);
          --nav-dropdown-menu-size: 245px;
          --nav-bubble-bg: var(--bg-notification);
          --nav-bubble-text: var(--text-on-notification);
          --nav-bubble-size: 20px;
          --nav-bubble-font-size: var(--nav-font-size-xs);
          --nav-bubble-radius: var(--radius-pill);
          --nav-container-width: 100%;
          --nav-container-max-width: var(--max-width-global-container, 1800px);
          --nav-container-padding: var(--nav-ws-3x);
          --nav-user-avatar-size: 32px;
          --nav-menu-avatar-size: 32px;
          --nav-avatar-bg: var(--bg-placeholder);
          --nav-ws-1x: 5px;
          --nav-ws-2x: 10px;
          --nav-ws-3x: 15px;
          --nav-ws-4x: 20px;
          --nav-ws-5x: 25px;
          --nav-ws-6x: 30px;
          --nav-ws-8x: 40px;
          --nav-logo-width: 82px;
          --nav-tooltip-bg: var(--bg-inverse);
          --nav-tooltip-text: var(--text-dark-on-inverse);
          --nav-tooltip-radius: var(--radius-3x);
          --nav-tooltip-shadow: var(--shadow-popup);
          --nav-tooltip-zindex: var(--zindex-tooltip);
          --nav-dropdown-zindex: calc(var(--zindex-dropdown) + 1);
          --nav-status-bg: var(--bg-btn-group);
          --nav-status-bg-hover: var(--bg-btn-group-hover);
          --nav-status-border-color: var(--border-color-btn-group);
          --nav-status-border-width: var(--border-width-btn-group);
          --nav-status-border: var(--nav-status-border-width) solid var(--nav-status-border-color);
          --nav-status-border-radius: var(--radius-pill);
          --nav-status-shadow: var(--shadow-on-btn-group);
          --nav-status-btn-bg: var(--bg-btn-group-btn);
          --nav-status-btn-bg-hover: var(--bg-btn-group-btn-hover);
          --nav-status-btn-bg-selected: var(--bg-btn-group-btn-selected);
          --nav-status-btn-bg-selected-hover: var(--bg-btn-group-btn-selected-hover);
          --nav-status-btn-text: var(--text-on-btn-group-btn);
          --nav-status-btn-text-selected: var(--text-on-btn-group-btn-selected);
          --nav-status-btn-font-weight: var(--font-weight-base);
          --nav-status-btn-border: 1px solid transparent;
          --nav-status-btn-radius: var(--radius-pill);
          --nav-more-label-font-size: var(--nav-font-size-xs);
          --nav-more-label-font-weight: var(--font-weight-strong);
          --nav-more-label-text: var(--text-light-on-inverse);
          --nav-more-text: var(--text-dark-on-inverse);
          --nav-more-font-size: var(--nav-font-size-xs);
          --nav-more-link-text: var(--text-link);
          --nav-more-link-text-hover: var(--text-link-hover);
          --nav-second-level-text: var(--text-light-on-inverse);
          --nav-second-level-font-size: var(--nav-font-size);
          --nav-second-level-font-weight: var(--font-weight-strong);
          --nav-input-container-size: var(--height-base);
          --nav-input-size: calc(var(--height-base) - var(--border-width-control-muted) * 2);
          --nav-input-bg: var(--bg-control-muted);
          --nav-input-bg-hover: var(--bg-control-muted-hover-light);
          --nav-input-bg-selected: var(--bg-inverse);
          --nav-input-placeholder-text: var(--text-dark-on-control-muted);
          --nav-input-border-color: var(--border-color-control-muted);
          --nav-input-border-color-selected: var(--border-color-control-muted-selected);
          --nav-input-border-width: var(--border-width-control-muted);
          --nav-input-border: var(--nav-input-border-width) solid var(--nav-input-border-color);
          --nav-input-radius: 20px;
          --nav-search-icon-size: 20px;
          --nav-search-icons-text: var(--text-icon);
          --nav-search-item-bg: var(--bg-inverse);
          --nav-search-item-text: var(--text-light-on-inverse);
          --nav-search-item-text-selected: var(--text-dark-on-inverse);
          --nav-popover-zindex: var(--zindex-popover);
          --nav-popover-width: 335px;
          --nav-popover-arrow-size: 15px;
          --nav-popover-arrow-size-half: calc(var(--nav-popover-arrow-size) / 2);
          --nav-popover-arrow-y-offset: calc(var(--nav-popover-arrow-size-half) * -1);
          --nav-popover-arrow-x-offset: calc(50% - var(--nav-popover-arrow-size-half));
          --nav-popover-padding: var(--nav-ws-4x);
          --nav-popover-bg: var(--bg-dark);
          --nav-popover-text: var(--text-on-dark);
          --nav-popover-shadow: var(--shadow-popup);
          --nav-popover-radius: var(--radius-8x);
          --nav-popover-title-font-size: var(--nav-font-size-lg);
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2 {
              --nav-container-padding: var(--ws-mobile-x);
          }
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 {
              --nav-left-gap: 0;
              --nav-right-gap: 0;
              --nav-margin: 0;
              --nav-link-padding-desktop: var(--nav-ws-2x);
              --nav-container-padding: var(--ws-6x);
          }
      }
      @media (forced-colors: active) {
          #visitor-v2-top-navigation-container .nav-v2 {
              --nav-link-text-selected: var(--hc-bg-selected);
              --color-danger: var(--hc-text-on-base);
              --text-icon-accent: var(--hc-bg-selected);
              --nav-icon-text: var(--hc-icon);
              --nav-color-active: var(--hc-text-on-selected);
              --nav-tooltip-bg: var(--hc-bg-base);
              --nav-tooltip-text: var(--hc-text-on-base);
              --nav-status-btn-bg-selected: var(--hc-bg-selected);
              --nav-status-btn-text-selected: var(--hc-text-on-selected);
              --nav-status-btn-bg-selected-hover: var(--hc-bg-selected);
              --nav-status-border-color: var(--hc-text-on-btn);
              --nav-status-btn-bg-hover: var(--hc-bg-selected);
              --nav-input-border-color: var(--hc-text-on-btn);
              --nav-dropdown-link-bg: var(--hc-bg-base);
              --nav-dropdown-link-text: var(--hc-text-link);
              --nav-dropdown-link-bg-hover: var(--hc-bg-selected);
              --nav-dropdown-link-text-hover: var(--hc-text-on-selected);
              --nav-dropdown-link-text-selected: var(--hc-text-on-selected);
              --nav-dropdown-sub-label-text: inherit;
              --nav-bubble-bg: var(--hc-bg-base);
              --nav-bubble-text: var(--hc-text-on-base);
              --nav-notification-border-color: var(--hc-bg-selected);
              --nav-notification-bg: var(--hc-bg-selected);
              --nav-icon-close-text: var(--hc-text-on-selected);
              --nav-search-item-bg: var(--hc-bg-base);
              --nav-search-item-text-selected: var(--hc-bg-selected);
              --nav-icon-hamburger-selected: var(--hc-text-on-base);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 > .nav-secondary-menu {
          position: absolute;
          width: 100%;
          transition: transform 0.35s ease-out;
          transform: translateY(0);
          background: var(--nav-visitor-secondary-bg);
          border-top: var(--nav-visitor-secondary-border-top);
          border-bottom: var(--nav-visitor-secondary-border-bottom);
          z-index: var(--nav-visitor-secondary-zindex);
      }
      #visitor-v2-top-navigation-container .nav-v2 > .nav-secondary-menu.nav-secondary-menu-hidden {
          transform: translateY(-100%);
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav {
          justify-content: flex-start;
          width: min(100%, var(--nav-container-max-width));
          margin: auto;
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-subnav {
              padding: var(--nav-visitor-secondary-padding);
              display: flex;
              flex: 0 0 100%;
              min-height: var(--nav-visitor-secondary-height);
              transition: transform 0.35s ease-out;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item:first-child,
          #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li:first-child {
              margin-left: var(--nav-visitor-secondary-subnav-margin);
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item:last-child,
          #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li:last-child {
              margin-right: var(--nav-visitor-secondary-subnav-margin);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li {
          display: flex;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item .more-links-button,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item .nav-subnav-link,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item > a,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li .more-links-button,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li .nav-subnav-link,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li > a {
          display: flex;
          align-items: center;
          font-weight: var(--nav-visitor-secondary-weight);
          font-size: var(--nav-visitor-secondary-font-size);
          color: var(--nav-visitor-secondary-text);
          padding: var(--nav-visitor-secondary-subnav-padding);
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item .more-links-button:hover,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item .nav-subnav-link:hover,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item > a:hover,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li .more-links-button:hover,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li .nav-subnav-link:hover,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li > a:hover {
          color: var(--nav-visitor-secondary-text-hover);
          text-decoration: underline;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item .more-links-button:hover,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li .more-links-button:hover {
          color: var(--nav-visitor-secondary-text-hover);
          text-decoration: none;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item .more-links-icon,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li .more-links-icon {
          --icon-color: var(--text-icon);
          margin-left: var(--ws-1x);
          color: transparent;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item.subnav-more-links,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li.subnav-more-links {
          margin-right: 0;
          position: relative;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item.subnav-more-links:hover .nav-dropdown-menu,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li.subnav-more-links:hover .nav-dropdown-menu {
          display: flex;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item.subnav-more-links .nav-dropdown-menu,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li.subnav-more-links .nav-dropdown-menu {
          right: 0;
          left: auto;
          width: 250px;
          flex-direction: column;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item.subnav-more-links .nav-dropdown-menu:before,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li.subnav-more-links .nav-dropdown-menu:before {
          left: auto;
          right: var(--ws-2x);
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item.subnav-more-links .nav-dropdown-menu.is-open,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li.subnav-more-links .nav-dropdown-menu.is-open {
          display: flex;
      }
      #visitor-v2-top-navigation-container .nav-v2.nav-visitor {
          --nav-margin: 0;
          --nav-link-padding-mobile: var(--nav-ws-4x) 0;
          --nav-visitor-bg: var(--bg-inverse);
          --nav-visitor-zindex: var(--zindex-navbar-fixed);
          --nav-visitor-shadow: none;
          --nav-visitor-secondary-height: var(--nav-height);
          --nav-visitor-secondary-bg: var(--bg-inverse);
          --nav-visitor-secondary-font-size: calc(var(--font-size-base) + 1);
          --nav-visitor-secondary-padding: var(--nav-ws-2x) var(--nav-container-padding);
          --nav-visitor-secondary-weight: var(--font-weight-base);
          --nav-visitor-secondary-text: var(--text-link-navigation-dark);
          --nav-visitor-secondary-text-hover: var(--text-link-navigation-dark-hover);
          --nav-visitor-secondary-border-top: var(--border);
          --nav-visitor-secondary-border-bottom: none;
          --nav-visitor-secondary-zindex: 1;
          --nav-visitor-secondary-subnav-margin: calc(var(--nav-ws-2x) * -1);
          --nav-visitor-secondary-subnav-padding: var(--nav-ws-2x);
          box-shadow: var(--nav-visitor-shadow);
          width: 100%;
          height: var(--nav-height);
      }
      @media (max-width: 991px) {
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor {
              --nav-container-padding: 0;
              --nav-visitor-shadow: 0 0 0 1px var(--border-color);
          }
      }
      @media (min-height: 550px) {
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor {
              position: sticky;
              top: 0;
              left: 0;
              z-index: var(--nav-visitor-zindex);
          }
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-wrapper {
              height: 100%;
              background: var(--nav-visitor-bg);
              position: relative;
              z-index: 2;
          }
      }
      @media (max-width: 991px) {
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-container {
              position: static;
          }
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-header {
              grid-gap: var(--ws-4x);
              gap: var(--ws-4x);
              justify-content: flex-start;
          }
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-visitor-mobile-right {
              display: flex;
              flex: 1;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
          }
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-header-wrapper {
              padding: 0 var(--ws-mobile-x);
              background: var(--nav-visitor-bg);
              box-shadow: var(--nav-visitor-shadow);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2.nav-visitor .navbar-cta-btn {
          margin: 0 0 0 var(--ws-4x);
      }
      #visitor-v2-top-navigation-container .nav-v2.nav-visitor .navbar-cta {
          background-color: var(--nav-visitor-bg);
      }
      #visitor-v2-top-navigation-container .nav-collapsed-header {
          min-height: 55px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: var(--ws-2x);
      }
      #visitor-v2-top-navigation-container .nav-collapsed-close-btn {
          line-height: 0;
      }
      #visitor-v2-top-navigation-container .nav-collapsed-menu {
          display: none;
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-collapsed-menu {
              display: block;
          }
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-item {
              line-height: 14px;
          }
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-dropdown-menu:not(.nav-dropdown-search, .nav-search-switch-dropdown, .nav-search-input-dropdown) {
              padding-left: var(--ws-4x);
          }
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-dropdown-menu:not(.nav-dropdown-search, .nav-search-switch-dropdown, .nav-search-input-dropdown) li {
              border-left: 0;
          }
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-dropdown-menu:not(.nav-dropdown-search, .nav-search-switch-dropdown, .nav-search-input-dropdown) li a {
              padding: var(--ws-2x) 0;
          }
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-collapse {
              position: absolute;
              z-index: var(--zindex-navbar-fixed);
              background: var(--nav-visitor-bg);
              top: 0;
              width: 100%;
              height: 100vh;
              display: block;
              overflow: auto;
              -webkit-overflow-scrolling: touch;
              padding: 0 var(--ws-mobile-x) 170px;
          }
          @media (max-height: 549px) {
              #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-collapse {
                  height: calc(100vh - 80px);
                  height: calc(100dvh - 80px);
                  padding-bottom: var(--nav-ws-4x);
              }
          }
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-collapse .search-expand-button {
              display: none;
          }
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor #nav-right {
              border-top: var(--border);
          }
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-dropdown:not(.nav-menu-expandable) .nav-item .nav-caret {
              display: none;
          }
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-dropdown.open .nav-dropdown-menu,
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-dropdown.open .nav-subnav {
              display: block;
          }
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-left {
              flex-direction: row;
          }
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-left .nav-collapsed-header {
              flex: 1;
              margin-bottom: 0;
              flex-direction: row-reverse;
          }
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-left .nav-search-collapsed {
              display: flex;
              align-items: center;
          }
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-left,
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .nav-right,
          #visitor-v2-top-navigation-container .nav-v2.nav-visitor .navbar-cta {
              visibility: hidden;
          }
      }
      #visitor-v2-top-navigation-container .navbar-cta {
          position: fixed;
          width: 100%;
          padding: var(--nav-ws-4x) var(--ws-mobile-x);
          z-index: calc(var(--zindex-navbar-fixed) + 1);
          left: 0;
          bottom: 0;
          box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.3);
          display: flex;
          justify-content: center;
      }
      @media (max-width: 991.98px) {
          .footer-visitor,
          .visitor-page-container {
              transform: none;
              transition: transform 0.2s;
          }
          body.visitor-nav-is-open .footer-visitor,
          body.visitor-nav-is-open .visitor-page-container {
              transform: translateX(100%);
          }
          body.visitor-nav-is-open .up-alert,
          body.visitor-nav-is-open footer,
          body.visitor-nav-is-open main {
              display: none;
          }
          @media (min-height: 550px) {
              body.visitor-nav-is-open #__layout,
              body.visitor-nav-is-open #__nuxt {
                  overflow: hidden;
              }
          }
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-header-wrapper,
          #visitor-v2-top-navigation-container .nav-visitor .nav-collapse,
          #visitor-v2-top-navigation-container .nav-visitor .navbar-cta {
              transition: transform 0.2s;
          }
          #visitor-v2-top-navigation-container .nav-visitor .nav-collapse,
          #visitor-v2-top-navigation-container .nav-visitor .navbar-cta {
              transform: translateX(-105%);
          }
          #visitor-v2-top-navigation-container .nav-header-wrapper,
          body.visitor-nav-is-open #visitor-v2-top-navigation-container .nav-collapse,
          body.visitor-nav-is-open #visitor-v2-top-navigation-container .navbar-cta {
              transform: none;
          }
          body.visitor-nav-is-open #visitor-v2-top-navigation-container .nav-v2 {
              --nav-toggle: none;
              position: absolute;
              width: 100%;
              height: 100%;
              overflow: hidden;
          }
          body.visitor-nav-is-open #visitor-v2-top-navigation-container .nav-v2 .nav-left,
          body.visitor-nav-is-open #visitor-v2-top-navigation-container .nav-v2 .nav-right,
          body.visitor-nav-is-open #visitor-v2-top-navigation-container .nav-v2 .navbar-cta {
              visibility: visible;
          }
          body.visitor-nav-is-open #visitor-v2-top-navigation-container .nav-header-wrapper {
              transform: translateX(100%);
              display: none;
          }
          @media (max-height: 549px) {
              body.visitor-nav-is-open #visitor-v2-top-navigation-container .nav-visitor.nav-v2 .nav-collapse,
              body.visitor-nav-is-open #visitor-v2-top-navigation-container .nav-visitor.nav-v2 .navbar-cta {
                  position: static;
              }
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 > .nav-secondary-menu {
          position: absolute;
          width: 100%;
          transition: transform 0.35s ease-out;
          transform: translateY(0);
          background: var(--nav-visitor-secondary-bg);
          border-top: var(--nav-visitor-secondary-border-top);
          border-bottom: var(--nav-visitor-secondary-border-bottom);
          z-index: var(--nav-visitor-secondary-zindex);
      }
      #visitor-v2-top-navigation-container .nav-v2 > .nav-secondary-menu.nav-secondary-menu-hidden {
          transform: translateY(-100%);
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav {
          justify-content: flex-start;
          width: min(100%, var(--nav-container-max-width));
          margin: auto;
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-subnav {
              padding: var(--nav-visitor-secondary-padding);
              display: flex;
              flex: 0 0 auto;
              min-height: var(--nav-visitor-secondary-height);
              transition: transform 0.35s ease-out;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item:first-child,
          #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li:first-child {
              margin-left: var(--nav-visitor-secondary-subnav-margin);
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item:last-child,
          #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li:last-child {
              margin-right: var(--nav-visitor-secondary-subnav-margin);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li {
          display: flex;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item .more-links-button,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item .nav-subnav-link,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item > a,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li .more-links-button,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li .nav-subnav-link,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li > a {
          display: flex;
          align-items: center;
          font-weight: var(--nav-visitor-secondary-weight);
          font-size: var(--nav-visitor-secondary-font-size);
          color: var(--nav-visitor-secondary-text);
          padding: var(--nav-visitor-secondary-subnav-padding);
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item .more-links-button:hover,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item .nav-subnav-link:hover,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item > a:hover,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li .more-links-button:hover,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li .nav-subnav-link:hover,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li > a:hover {
          color: var(--bg-accent-interactive);
          text-decoration: underline;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item .more-links-button:hover,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li .more-links-button:hover {
          color: var(--nav-visitor-secondary-text-hover);
          text-decoration: none;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item .more-links-icon,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li .more-links-icon {
          --icon-color: var(--text-icon);
          margin-left: var(--ws-1x);
          color: transparent;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item.subnav-more-links,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li.subnav-more-links {
          margin-right: 0;
          position: relative;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item.subnav-more-links:hover .nav-dropdown-menu,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li.subnav-more-links:hover .nav-dropdown-menu {
          display: flex;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item.subnav-more-links .nav-dropdown-menu,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li.subnav-more-links .nav-dropdown-menu {
          right: 0;
          left: auto;
          width: 250px;
          flex-direction: column;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item.subnav-more-links .nav-dropdown-menu:before,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li.subnav-more-links .nav-dropdown-menu:before {
          left: auto;
          right: var(--ws-2x);
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav .nav-subnav-item.subnav-more-links .nav-dropdown-menu.is-open,
      #visitor-v2-top-navigation-container .nav-v2 .nav-subnav > li.subnav-more-links .nav-dropdown-menu.is-open {
          display: flex;
      }

      #visitor-v2-top-navigation-container .air3-grid-container {
          --template-col: repeat(12, 1fr);
          --row-gap: var(--ws-4x);
          --col-gap: var(--ws-4x);
          --gap: var(--row-gap) var(--col-gap);
          display: grid;
          grid-template-columns: var(--template-col);
          grid-gap: var(--gap);
          gap: var(--gap);
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .air3-grid-container {
              --row-gap: var(--ws-6x);
              --col-gap: var(--ws-6x);
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .air3-grid-container {
              --row-gap: var(--ws-8x);
              --col-gap: var(--ws-8x);
          }
      }
      #visitor-v2-top-navigation-container .air3-grid-container.gap-0 {
          --gap: 0;
      }
      #visitor-v2-top-navigation-container .air3-grid-container.col-gap-0 {
          --col-gap: 0;
      }
      #visitor-v2-top-navigation-container .air3-grid-container.row-gap-0 {
          --row-gap: 0;
      }
      #visitor-v2-top-navigation-container [class*="span-"] {
          grid-column: 1/-1;
          min-width: 0;
      }
      #visitor-v2-top-navigation-container .span-1 {
          grid-column: span 1;
      }
      #visitor-v2-top-navigation-container .span-2 {
          grid-column: span 2;
      }
      #visitor-v2-top-navigation-container .span-3 {
          grid-column: span 3;
      }
      #visitor-v2-top-navigation-container .span-4 {
          grid-column: span 4;
      }
      #visitor-v2-top-navigation-container .span-5 {
          grid-column: span 5;
      }
      #visitor-v2-top-navigation-container .span-6 {
          grid-column: span 6;
      }
      #visitor-v2-top-navigation-container .span-7 {
          grid-column: span 7;
      }
      #visitor-v2-top-navigation-container .span-8 {
          grid-column: span 8;
      }
      #visitor-v2-top-navigation-container .span-9 {
          grid-column: span 9;
      }
      #visitor-v2-top-navigation-container .span-10 {
          grid-column: span 10;
      }
      #visitor-v2-top-navigation-container .span-11 {
          grid-column: span 11;
      }
      #visitor-v2-top-navigation-container .span-12 {
          grid-column: span 12;
      }
      @media (min-width: 700px) {
          #visitor-v2-top-navigation-container .span-md-1 {
              grid-column: span 1;
          }
          #visitor-v2-top-navigation-container .span-md-2 {
              grid-column: span 2;
          }
          #visitor-v2-top-navigation-container .span-md-3 {
              grid-column: span 3;
          }
          #visitor-v2-top-navigation-container .span-md-4 {
              grid-column: span 4;
          }
          #visitor-v2-top-navigation-container .span-md-5 {
              grid-column: span 5;
          }
          #visitor-v2-top-navigation-container .span-md-6 {
              grid-column: span 6;
          }
          #visitor-v2-top-navigation-container .span-md-7 {
              grid-column: span 7;
          }
          #visitor-v2-top-navigation-container .span-md-8 {
              grid-column: span 8;
          }
          #visitor-v2-top-navigation-container .span-md-9 {
              grid-column: span 9;
          }
          #visitor-v2-top-navigation-container .span-md-10 {
              grid-column: span 10;
          }
          #visitor-v2-top-navigation-container .span-md-11 {
              grid-column: span 11;
          }
          #visitor-v2-top-navigation-container .span-md-12 {
              grid-column: span 12;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .span-lg-1 {
              grid-column: span 1;
          }
          #visitor-v2-top-navigation-container .span-lg-2 {
              grid-column: span 2;
          }
          #visitor-v2-top-navigation-container .span-lg-3 {
              grid-column: span 3;
          }
          #visitor-v2-top-navigation-container .span-lg-4 {
              grid-column: span 4;
          }
          #visitor-v2-top-navigation-container .span-lg-5 {
              grid-column: span 5;
          }
          #visitor-v2-top-navigation-container .span-lg-6 {
              grid-column: span 6;
          }
          #visitor-v2-top-navigation-container .span-lg-7 {
              grid-column: span 7;
          }
          #visitor-v2-top-navigation-container .span-lg-8 {
              grid-column: span 8;
          }
          #visitor-v2-top-navigation-container .span-lg-9 {
              grid-column: span 9;
          }
          #visitor-v2-top-navigation-container .span-lg-10 {
              grid-column: span 10;
          }
          #visitor-v2-top-navigation-container .span-lg-11 {
              grid-column: span 11;
          }
          #visitor-v2-top-navigation-container .span-lg-12 {
              grid-column: span 12;
          }
      }

      :root #visitor-v2-top-navigation-container {
          --ws-1x: 4px;
          --ws-2x: 8px;
          --ws-3x: 12px;
          --ws-4x: 16px;
          --ws-6x: 24px;
          --ws-8x: 32px;
          --ws-10x: 40px;
          --ws-12x: 48px;
          --ws-14x: 56px;
          --ws-16x: 64px;
          --ws-20x: 80px;
          --ws-24x: 96px;
          --radius-1x: 2px;
          --radius-2x: 4px;
          --radius-3x: 6px;
          --radius-4x: 8px;
          --radius-8x: 16px;
          --radius-12x: 24px;
          --radius-14x: 28px;
          --radius-pill: 10rem;
          --font-family-monospace: menlo, monaco, consolas, "Courier New", monospace;
          --font-weight-300: 300;
          --font-weight-400: 400;
          --font-weight-500: 500;
          --font-weight-700: 700;
          --font-weight-900: 900;
          --black: #000;
          --gray-10: #1c2d1c;
          --gray-15: #1f321f;
          --gray-20: #2a3b2c;
          --gray-25: #2f3f2f;
          --gray-30: #3a4b3a;
          --gray-40: #52614f;
          --gray-45: #5e6d55;
          --gray-50: #87997d;
          --gray-55: #9aaa97;
          --gray-60: #beccbe;
          --gray-70: #d5e0d5;
          --gray-80: #e4ebe4;
          --gray-90: #f2f7f2;
          --gray-95: #f7faf7;
          --white: #fff;
          --up-green: #000435;
          --up-green-dark: #3c8224;
          --brand-green: #108a00;
          --brand-green-hc: #0b6100;
          --up-green-hc: #0c5c00;
          --up-lime: #95df00;
          --up-lime-hc: #749b2d;
          --up-black: #000435;
          --up-black-dark: #021202;
          --blue: #1f57c3;
          --blue-hc: #1c4faf;
          --cyan-blue: #557fd1;
          --mint: #000435;
          --mint-hc: #3a5648;
          --forest: #000435;
          --sky: #01cdbe;
          --sky-hc: #015c56;
          --brown: #67541f;
          --brown-hc: #5d4c1c;
          --kiwi: #97922d;
          --kiwi-hc: #535019;
          --yellow: #debe1a;
          --yellow-hc: #63560c;
          --brick: #9b211b;
          --carmine-red: #e44d45;
          --clay: #bc5118;
          --clay-hc: #8d3c13;
          --orange-red: #ff4b25;
          --orange-red-hc: #992d16;
          --pink: #f66dbc;
          --pink-hc: #853b67;
          --lilac: #bda1e7;
          --lilac-hc: #5b4d6f;
          --heather: #a18085;
          --heather-hc: #644f52;
          --opacity-up-black-80: hsla(var(--up-black-hsl), 80%);
          --opacity-up-black-20: hsla(var(--up-black-hsl), 20%);
          --opacity-up-black-10: hsla(var(--up-black-hsl), 10%);
          --opacity-white-80: hsla(var(--white-hsl), 80%);
          --opacity-gray-80: hsl(var(--gray-80-h), var(--gray-80-s), calc(var(--gray-80-l) - 10%));
          --opacity-up-green-15: hsla(var(--up-green-hsl), 15%);
          --opacity-blue: hsl(var(--blue-h), var(--blue-s), calc(var(--blue-l) - 10%));
          --opacity-cyan-blue: hsl(var(--cyan-blue-h), var(--cyan-blue-s), calc(var(--cyan-blue-l) - 10%));
          --opacity-yellow-25: hsla(var(--yellow-hsl), 25%);
          --opacity-brick-15: hsla(var(--brick-hsl), 15%);
          --opacity-carmine-red-15: hsla(var(--carmine-red-hsl), 15%);
          --blue-h: 220;
          --blue-s: 73%;
          --blue-l: 44%;
          --cyan-blue-h: 220;
          --cyan-blue-s: 57%;
          --cyan-blue-l: 58%;
          --gray-80-h: 120;
          --gray-80-s: 15%;
          --gray-80-l: 91%;
          --up-black-hsl: 120, 100%, 6%;
          --up-green-hsl: 113, 100%, 33%;
          --yellow-hsl: 50, 79%, 49%;
          --brick-hsl: 3, 70%, 36%;
          --white-hsl: 0, 0%, 100%;
          --carmine-red-hsl: 3, 75%, 58%;
          --transparent-black: transparent;
          --transparent-white: hsla(0, 0%, 100%, 0);
          --transparent-white-85: hsla(0, 0%, 100%, 0.85);
          --transparent-black-85: rgba(0, 0, 0, 0.85);
          --zindex-navbar: 1000;
          --zindex-dropdown: 1000;
          --zindex-popover: 1010;
          --zindex-tooltip: 1030;
          --zindex-navbar-fixed: 1030;
          --zindex-section-loading: 1035;
          --zindex-modal-background: 1040;
          --zindex-modal: 1050;
          --zindex-fullscreen: 1050;
          --zindex-modal-popover: 1060;
          --zindex-toaster: 1060;
      }
      @media (forced-colors: active) {
          :root #visitor-v2-top-navigation-container {
              --hc-border: 1px solid;
              --hc-border-input: 1px solid ButtonText;
              --hc-border-disabled: 1px solid GrayText;
              --hc-bg-selected: Highlight;
              --hc-text-on-selected: HighlightText;
              --hc-bg-btn: ButtonFace;
              --hc-text-on-btn: ButtonText;
              --hc-bg-base: canvas;
              --hc-text-on-base: canvastext;
              --hc-text-link: linktext;
              --hc-disabled: GrayText;
              --bg-btn-dark: var(--hc-bg-selected);
              --text-on-btn-dark: var(--hc-text-on-selected);
              --border-color-btn-dark: var(--hc-bg-selected);
              --bg-btn-dark-hover: var(--bg-hc-base);
              --text-on-btn-dark-hover: var(--bg-hc-selected);
              --text-on-btn-dark-inverted-hover: var(--hc-bg-selected);
              --text-on-btn-light-hover: var(--bg-hc-selected);
              --text-on-btn-light-inverted-hover: var(--hc-bg-selected);
              --text-on-btn-muted-hover: var(--bg-hc-selected);
              --text-on-btn-link: var(--hc-text-on-btn);
              --text-on-btn-link-hover: var(--bg-hc-selected);
              --text-on-btn-circle-hover: var(--hc-bg-selected);
              --text-on-btn-circle-inverted-hover: var(--hc-bg-selected);
              --bg-btn-check-group-selected: var(--hc-bg-selected);
              --text-dark-on-btn-check-group: var(--hc-text-on-btn);
              --text-light-on-btn-check-group: var(--hc-text-on-btn);
              --text-dark-on-btn-check-group-selected: var(--hc-text-on-selected);
              --text-light-on-btn-check-group-selected: var(--hc-text-on-selected);
              --border-color-btn-check-group: var(--hc-text-on-btn);
              --border-color-btn-check-group-hover: var(--hc-bg-selected);
              --border-color-btn-check-group-selected: var(--hc-bg-selected);
              --bg-btn-check-light-hover: var(--hc-bg-selected);
              --bg-btn-check-light-selected: var(--hc-bg-selected);
              --bg-btn-check-light-selected-hover: var(--hc-bg-selected);
              --text-on-btn-check-light-selected: var(--hc-text-on-selected);
              --border-color-btn-check-dark: var(--hc-text-on-btn);
              --border-color-btn-check-dark-hover: var(--hc-bg-selected);
              --border-color-btn-check-dark-selected: var(--hc-bg-selected);
              --text-on-btn-check-dark: var(--hc-text-on-btn);
              --text-on-btn-check-dark-hover: var(--hc-bg-selected);
              --text-on-btn-check-dark-selected: var(--hc-bg-selected);
              --text-on-btn-check-dark-selected-hover: var(--hc-bg-selected);
              --border-color-btn-group: var(--hc-text-on-btn);
              --border-color-btn-group-selected: var(--hc-text-on-btn);
              --bg-btn-group-btn-hover: var(--hc-bg-selected);
              --bg-btn-group-btn-selected: var(--hc-bg-selected);
              --bg-btn-group-btn-selected-hover: var(--hc-bg-selected);
              --text-on-btn-group-btn-selected: var(--hc-text-on-selected);
              --text-link: var(--hc-text-link);
              --text-link-hover: var(--hc-text-link);
              --underline-link-hover: none;
              --border-color-inverse: var(--hc-bg-base);
              --border-color-control-check: var(--hc-text-on-base);
              --border-color-control-check-hover: var(--hc-text-on-base);
              --border-color-control: var(--hc-text-on-btn);
              --border-color-control-hover: var(--hc-text-on-btn);
              --border-color-control-selected: var(--hc-bg-selected);
              --bg-selected: var(--hc-bg-selected);
              --text-on-selected: var(--hc-text-on-selected);
              --bg-control-check-selected: var(--hc-bg-selected);
              --bg-control-check-selected-hover: var(--hc-bg-selected);
              --border-color-control-check-selected: var(--hc-bg-selected);
              --border-color-control-check-selected-hover: var(--hc-bg-selected);
              --text-icon-on-control-check: var(--hc-text-on-selected);
              --bg-hoverable-light-hover: var(--hc-bg-selected);
              --bg-hoverable-dark-hover: var(--hc-bg-selected);
              --text-link-navigation-selected: var(--hc-bg-selected);
              --bg-backdrop: var(--hc-bg-selected);
              --text-on-disabled: var(--hc-disabled);
              --text-on-disabled-inner: var(--hc-disabled);
              --text-outline: var(--hc-bg-selected);
              --text-outline-accent: var(--hc-bg-selected);
          }
      }
      @media (prefers-reduced-motion: reduce) {
          :root #visitor-v2-top-navigation-container {
              --reduced-motion: none;
          }
      }
      #visitor-v2-top-navigation-container [design-system="air-3-0"],
      [theme="air-2-76"] #visitor-v2-top-navigation-container,
      [theme="air-3-0"] #visitor-v2-top-navigation-container,
      html[theme="dark"] #visitor-v2-top-navigation-container,
      html[theme="high-contrast-light"] #visitor-v2-top-navigation-container {
          --ws-mobile-x: var(--ws-4x);
          --ws-heading: 0.5em;
          --height-base: 40px;
          --height-sm: 32px;
          --width-sm: 218px;
          --max-width-global-container: 1600px;
          --font-family-base: "Neue Montreal";
          --font-family-rebrand: Ubuntu, Ubuntu;
          --font-size-base: 16px;
          --line-height-base: 22px;
          --font-size-base-sm: 14px;
          --line-height-base-sm: 20px;
          --font-size-base-xs: 11px;
          --line-height-base-xs: 16px;
          --font-size-h1: 48px;
          --line-height-h1: 48px;
          --font-size-h2: 36px;
          --line-height-h2: 40px;
          --font-size-h3: 28px;
          --line-height-h3: 32px;
          --font-size-h4: 24px;
          --line-height-h4: 28px;
          --font-size-h5: 20px;
          --line-height-h5: 24px;
      }
      @media (max-width: 699px) {
          #visitor-v2-top-navigation-container [design-system="air-3-0"],
          [theme="air-2-76"] #visitor-v2-top-navigation-container,
          [theme="air-3-0"] #visitor-v2-top-navigation-container,
          html[theme="dark"] #visitor-v2-top-navigation-container,
          html[theme="high-contrast-light"] #visitor-v2-top-navigation-container {
              --font-size-h1: 36px;
              --line-height-h1: 40px;
              --font-size-h2: 32px;
              --line-height-h2: 36px;
              --font-size-h3: 28px;
              --line-height-h3: 32px;
          }
      }
      #visitor-v2-top-navigation-container [design-system="air-3-0"],
      [theme="air-2-76"] #visitor-v2-top-navigation-container,
      [theme="air-3-0"] #visitor-v2-top-navigation-container,
      html[theme="dark"] #visitor-v2-top-navigation-container,
      html[theme="high-contrast-light"] #visitor-v2-top-navigation-container {
          --font-weight-base: var(--font-weight-500);
          --font-weight-body: var(--font-weight-400);
          --font-weight-strong: var(--font-weight-500);
          --font-weight-heading: var(--font-weight-500);
          --font-weight-label: var(--font-weight-500);
          --font-weight-sub-label: var(--font-weight-400);
          --font-weight-rebrand: var(--font-weight-400);
          --letter-spacing-base: 0.02em;
          --letter-spacing-h1: 0;
          --letter-spacing-h2: 0.1px;
          --letter-spacing-h3: 0.2px;
          --letter-spacing-h4: 0.3px;
          --letter-spacing-h5: 0.4px;
          --letter-spacing-h1-rebrand: -2px;
          --letter-spacing-h2-rebrand: -1px;
          --letter-spacing-h3-rebrand: -0.5px;
          --letter-spacing-h4-rebrand: -0.25px;
          --letter-spacing-h5-rebrand: 0;
          --text-heading: inherit;
          --text-label: inherit;
          --text-link: var(--brand-green);
          --text-link-hover: var(--up-green);
          --text-link-navigation: var(--up-black);
          --text-link-navigation-hover: var(--up-green);
          --text-link-navigation-dark: var(--up-black);
          --text-link-navigation-dark-hover: var(--up-black);
          --text-link-navigation-light: var(--gray-45);
          --text-link-navigation-light-hover: var(--up-green);
          --text-link-navigation-selected: var(--brand-green);
          --text-link-inverse: var(--white);
          --text-link-inverse-hover: var(--opacity-white-80);
          --underline-link: underline;
          --underline-link-hover: underline;
          --border-color: var(--gray-70);
          --border: 1px solid var(--border-color);
          --border-color-inverse: var(--white);
          --border-inverse: 1px solid var(--border-color-inverse);
          --bg-info: var(--up-black);
          --text-on-info: var(--white);
          --bg-success: var(--up-green);
          --text-on-success: var(--white);
          --bg-warning: var(--yellow);
          --text-on-warning: var(--up-black);
          --bg-danger: var(--brick);
          --text-on-danger: var(--white);
          --bg-notification: var(--orange-red);
          --text-on-notification: var(--white);
          --bg-offline: var(--gray-60);
          --bg-info-light: var(--gray-95);
          --text-on-info-light: var(--up-black);
          --bg-success-light: var(--opacity-up-green-15);
          --text-on-success-light: var(--up-black);
          --bg-warning-light: var(--opacity-yellow-25);
          --text-on-warning-light: var(--up-black);
          --bg-danger-light: var(--opacity-brick-15);
          --text-on-danger-light: var(--up-black);
          --color-info: var(--up-black);
          --color-success: var(--up-green);
          --color-warning: var(--yellow);
          --color-danger: var(--brick);
          --color-notification: var(--orange-red);
          --border-width-btn: 2px;
          --bg-btn-dark: var(--brand-green);
          --bg-btn-dark-hover: var(--up-green);
          --text-on-btn-dark: var(--white);
          --text-on-btn-dark-hover: var(--white);
          --border-color-btn-dark: transparent;
          --shadow-on-btn-dark: none;
          --text-icon-on-btn-dark: inherit;
          --bg-btn-light: var(--white);
          --bg-btn-light-hover: var(--gray-95);
          --text-on-btn-light: var(--brand-green);
          --text-on-btn-light-hover: var(--brand-green);
          --border-color-btn-light: currentColor;
          --shadow-on-btn-light: none;
          --text-icon-on-btn-light: inherit;
          --bg-btn-muted: var(--white);
          --bg-btn-muted-hover: var(--gray-95);
          --text-on-btn-muted: var(--brand-green);
          --text-on-btn-muted-hover: var(--brand-green);
          --border-color-btn-muted: var(--gray-70);
          --shadow-on-btn-muted: none;
          --text-icon-on-btn-muted: inherit;
          --bg-btn-dark-inverted: var(--white);
          --bg-btn-dark-inverted-hover: var(--opacity-white-80);
          --text-on-btn-dark-inverted: var(--up-black);
          --text-on-btn-dark-inverted-hover: var(--up-black);
          --border-color-btn-dark-inverted: transparent;
          --shadow-on-btn-dark-inverted: none;
          --text-icon-on-btn-dark-inverted: inherit;
          --bg-btn-light-inverted: transparent;
          --bg-btn-light-inverted-hover: var(--opacity-up-black-20);
          --text-on-btn-light-inverted: var(--white);
          --text-on-btn-light-inverted-hover: var(--white);
          --border-color-btn-light-inverted: currentColor;
          --shadow-on-btn-light-inverted: none;
          --text-icon-on-btn-light-inverted: inherit;
          --bg-btn-muted-inverted: transparent;
          --bg-btn-muted-inverted-hover: var(--opacity-up-black-20);
          --text-on-btn-muted-inverted: var(--white);
          --text-on-btn-muted-inverted-hover: var(--white);
          --border-color-btn-muted-inverted: var(--opacity-up-black-20);
          --shadow-on-btn-muted-inverted: none;
          --text-icon-on-btn-muted-inverted: inherit;
          --bg-btn-link: transparent;
          --bg-btn-link-hover: transparent;
          --text-on-btn-link: var(--brand-green);
          --text-on-btn-link-hover: var(--up-green);
          --underline-btn-link: none;
          --underline-btn-link-hover: none;
          --bg-btn-circle: transparent;
          --bg-btn-circle-hover: var(--gray-90);
          --text-on-btn-circle: var(--brand-green);
          --text-on-btn-circle-hover: var(--brand-green);
          --bg-btn-circle-inverted: transparent;
          --bg-btn-circle-inverted-hover: var(--opacity-up-black-20);
          --text-on-btn-circle-inverted: var(--white);
          --text-on-btn-circle-inverted-hover: var(--white);
          --text-on-btn-close: var(--up-black);
          --text-on-btn-close-hover: var(--up-black);
          --text-inverse-on-btn-close: var(--white);
          --bg-btn-group: var(--white);
          --bg-btn-group-hover: var(--gray-95);
          --border-color-btn-group: var(--gray-70);
          --border-color-btn-group-selected: var(--gray-70);
          --border-width-btn-group: 2px;
          --shadow-on-btn-group: none;
          --bg-btn-group-btn: var(--white);
          --bg-btn-group-btn-hover: var(--gray-95);
          --bg-btn-group-btn-selected: var(--brand-green);
          --bg-btn-group-btn-selected-hover: var(--up-green);
          --text-on-btn-group-btn: var(--brand-green);
          --text-on-btn-group-btn-hover: var(--brand-green);
          --text-on-btn-group-btn-selected: var(--white);
          --bg-btn-check-light: var(--white);
          --bg-btn-check-light-hover: var(--gray-95);
          --bg-btn-check-light-selected: var(--brand-green);
          --bg-btn-check-light-selected-hover: var(--up-green);
          --text-on-btn-check-light: var(--brand-green);
          --text-on-btn-check-light-selected: var(--white);
          --border-color-btn-check-light: var(--gray-70);
          --border-color-btn-check-light-selected: transparent;
          --border-width-btn-check-light: 2px;
          --text-icon-on-btn-check-light: inherit;
          --bg-btn-check-dark: var(--white);
          --bg-btn-check-dark-hover: var(--white);
          --bg-btn-check-dark-selected: var(--brand-green);
          --bg-btn-check-dark-selected-hover: var(--up-green);
          --text-on-btn-check-dark: var(--up-black);
          --text-on-btn-check-dark-hover: var(--up-green);
          --text-on-btn-check-dark-selected: var(--white);
          --text-on-btn-check-dark-selected-hover: var(--white);
          --border-color-btn-check-dark: var(--up-black);
          --border-color-btn-check-dark-hover: var(--up-green);
          --border-color-btn-check-dark-selected: var(--brand-green);
          --border-color-btn-check-dark-selected-hover: var(--up-green);
          --border-width-btn-check-dark: 2px;
          --bg-btn-check-group: var(--white);
          --bg-btn-check-group-hover: var(--gray-95);
          --bg-btn-check-group-selected: var(--gray-95);
          --text-dark-on-btn-check-group: var(--up-black);
          --text-light-on-btn-check-group: var(--gray-45);
          --text-dark-on-btn-check-group-selected: var(--up-black);
          --text-light-on-btn-check-group-selected: var(--gray-45);
          --border-color-btn-check-group: var(--gray-70);
          --border-color-btn-check-group-hover: var(--brand-green);
          --border-color-btn-check-group-selected: var(--brand-green);
          --border-width-btn-check-group: 2px;
          --bg-control: var(--white);
          --text-dark-on-control: var(--up-black);
          --text-light-on-control: var(--gray-45);
          --text-muted-on-control: var(--gray-60);
          --text-icon-on-control: var(--up-black);
          --text-icon-on-control-hover: var(--up-black);
          --border-color-control: var(--gray-80);
          --border-color-control-hover: var(--opacity-gray-80);
          --border-color-control-selected: var(--gray-55);
          --border-width-control: 2px;
          --bg-control-muted: var(--white);
          --bg-control-muted-hover-dark: var(--gray-80);
          --bg-control-muted-hover-light: var(--gray-95);
          --text-dark-on-control-muted: var(--up-black);
          --text-light-on-control-muted: var(--gray-45);
          --border-color-control-muted: var(--gray-80);
          --border-color-control-muted-selected: var(--gray-55);
          --border-width-control-muted: 2px;
          --bg-control-check: var(--white);
          --bg-control-check-hover: var(--white);
          --bg-control-check-selected: var(--brand-green);
          --bg-control-check-selected-hover: var(--up-green);
          --border-color-control-check: var(--gray-60);
          --border-color-control-check-hover: var(--gray-55);
          --border-color-control-check-selected: var(--brand-green);
          --border-color-control-check-selected-hover: var(--up-green);
          --border-width-control-check: 2px;
          --text-icon-on-control-check: var(--white);
          --bg-hoverable-light: var(--white);
          --bg-hoverable-light-hover: var(--gray-90);
          --bg-on-hoverable-light: var(--gray-80);
          --text-dark-on-hoverable-light: var(--up-black);
          --text-light-on-hoverable-light: var(--gray-45);
          --text-accent-on-hoverable-light: var(--brand-green);
          --bg-hoverable-dark: var(--white);
          --bg-hoverable-dark-hover: var(--gray-95);
          --text-dark-on-hoverable-dark: var(--up-black);
          --text-light-on-hoverable-dark: var(--gray-45);
          --text-accent-on-hoverable-dark: var(--brand-green);
          --bg-interactive-muted-light: var(--gray-95);
          --bg-interactive-muted-light-hover: var(--gray-90);
          --text-dark-on-interactive-muted-light: var(--up-black);
          --text-light-on-interactive-muted-light: var(--gray-45);
          --bg-accent-interactive: var(--brand-green);
          --text-on-accent-interactive: var(--white);
          --bg-selected: var(--brand-green);
          --bg-selected-hover: var(--up-green);
          --text-on-selected: var(--white);
          --text-selected: var(--brand-green);
          --bg-body: var(--white);
          --text-dark-on-body: var(--up-black);
          --text-light-on-body: var(--gray-45);
          --text-accent-on-body: var(--up-green);
          --text-muted-on-body: var(--gray-60);
          --bg-inverse: var(--white);
          --text-dark-on-inverse: var(--up-black);
          --text-light-on-inverse: var(--gray-45);
          --text-accent-on-inverse: var(--up-green);
          --text-muted-on-inverse: var(--gray-60);
          --bg-accent: var(--up-green);
          --text-on-accent: var(--white);
          --bg-dark: var(--up-black);
          --text-on-dark: var(--white);
          --bg-light: var(--gray-45);
          --text-on-light: var(--white);
          --bg-placeholder: var(--gray-55);
          --text-on-placeholder: var(--white);
          --bg-placeholder-muted-dark: var(--gray-80);
          --bg-placeholder-muted-light: var(--gray-95);
          --bg-muted-dark: var(--gray-90);
          --bg-muted-light: var(--gray-95);
          --text-dark-on-muted: var(--up-black);
          --text-light-on-muted: var(--gray-45);
          --bg-mark: var(--yellow);
          --text-on-mark: var(--up-black);
          --bg-disabled: var(--gray-80);
          --text-on-disabled: var(--gray-55);
          --bg-disabled-inverse: var(--white);
          --text-on-disabled-inverse: var(--gray-45);
          --bg-disabled-inner: var(--gray-90);
          --text-on-disabled-inner: var(--gray-45);
          --color-highlight-1: var(--blue);
          --color-highlight-2: var(--forest);
          --color-highlight-3: var(--clay);
          --color-highlight-4: var(--lilac);
          --color-highlight-5: var(--sky);
          --color-highlight-6: var(--pink);
          --color-highlight-7: var(--brown);
          --color-highlight-8: var(--yellow);
          --color-highlight-9: var(--brick);
          --color-highlight-10: var(--up-lime);
          --color-highlight-11: var(--mint);
          --color-highlight-12: var(--heather);
          --text-on-highlight: var(--white);
          --text-icon: var(--up-black);
          --text-icon-inverse: var(--white);
          --text-icon-accent: var(--up-green);
          --text-outline: var(--up-black);
          --text-outline-accent: var(--brand-green);
          --bg-backdrop: var(--up-black);
          --opacity-backdrop: 0.6;
          --list-margin: 0;
          --list-padding: 0;
          --list-style-ul: none;
          --list-style-ol: none;
          --shadow-color-popup: rgba(0, 30, 0, 0.15);
          --shadow-popup: 0 0 10px 2px rgba(0, 30, 0, 0.15);
          --shadow-interactive: 0 0 10px rgba(0, 0, 0, 0.15);
          --shadow-interactive-hover: 0 0 20px rgba(0, 0, 0, 0.2);
          --shadow-scroll: var(--transparent-white);
          --shadow-opacity-scroll: var(--transparent-white-85);
          --max-width-footer-container: var(--max-width-global-container);
          --bg-footer-icon: transparent;
          --bg-footer-icon-hover: var(--gray-55);
          --text-on-footer-icon: var(--white);
          --border-footer-icon: 1px solid var(--gray-60);
          --bg-footer: var(--up-black);
          --text-on-footer: var(--white);
          --text-footer-link: var(--white);
          --text-footer-link-hover: var(--white);
          --border-color-footer: var(--gray-55);
          --border-top-footer: 0;
          --radius-footer: var(--radius-4x);
          --bg-footer-visitor: var(--up-black);
          --text-on-footer-visitor: var(--white);
          --text-footer-visitor-link: var(--white);
          --text-footer-visitor-link-hover: var(--white);
          --border-color-footer-visitor: var(--gray-55);
          --border-top-footer-visitor: 0;
          --radius-footer-visitor: var(--radius-4x);
      }
      [theme="air-2-76"] #visitor-v2-top-navigation-container {
          --font-weight-base: 400;
          --ws-heading: var(--ws-lg);
          --letter-spacing-h1: unset;
          --letter-spacing-h2: unset;
          --letter-spacing-h3: unset;
          --letter-spacing-h4: unset;
          --letter-spacing-h5: unset;
          --letter-spacing-h1-rebrand: unset;
          --letter-spacing-h2-rebrand: unset;
          --letter-spacing-h3-rebrand: unset;
          --letter-spacing-h4-rebrand: unset;
          --letter-spacing-h5-rebrand: unset;
      }
      body:not(.visitor-nav-is-open) #visitor-v2-top-navigation-container {
          height: var(--nav-height);
      }
      body:not(.visitor-nav-is-open) #visitor-v2-top-navigation-container .nav-visitor {
          position: fixed;
          z-index: var(--zindex-navbar-fixed);
          width: 100%;
      }
      @media (min-width: 992px) {
          body:not(.visitor-nav-is-open) #visitor-v2-top-navigation-container.has-subnav {
              height: calc(var(--nav-height) * 2);
          }
      }
      #visitor-v2-top-navigation-container .air3-btn {
          background-color: #000435 !important;
      }
      #visitor-v2-top-navigation-container .air3-btn:hover {
          background-color: #000435 !important;
      }
      @media (max-width: 991px) {
          #visitor-v2-top-navigation-container .navbar-cta {
              position: fixed !important;
          }
      }

      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .d-nav-lg-none {
              display: none !important;
          }
          #visitor-v2-top-navigation-container .d-nav-lg-inline {
              display: inline !important;
          }
          #visitor-v2-top-navigation-container .d-nav-lg-block {
              display: block !important;
          }
          #visitor-v2-top-navigation-container .d-nav-lg-flex {
              display: flex !important;
          }
          #visitor-v2-top-navigation-container .d-nav-lg-inline-flex {
              display: inline-flex !important;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .d-nav-xl-none {
              display: none !important;
          }
          #visitor-v2-top-navigation-container .d-nav-xl-inline {
              display: inline !important;
          }
          #visitor-v2-top-navigation-container .d-nav-xl-block {
              display: block !important;
          }
          #visitor-v2-top-navigation-container .d-nav-xl-flex {
              display: flex !important;
          }
          #visitor-v2-top-navigation-container .d-nav-xl-inline-flex {
              display: inline-flex !important;
          }
      }
      :root #visitor-v2-top-navigation-container {
          --nav-mobile-position: sticky;
      }
      @media (max-width: 991px) {
          #visitor-v2-top-navigation-container .visitor-page-container {
              transform: none !important;
          }
          #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-item.p-0,
          #visitor-v2-top-navigation-container .top-nav-visitor-ia .p-0 {
              padding: 0 !important;
          }
          #visitor-v2-top-navigation-container .top-nav-visitor-ia .py-2x {
              padding-top: var(--ws-2x) !important;
              padding-bottom: var(--ws-2x) !important;
          }
          #visitor-v2-top-navigation-container .top-nav-visitor-ia.nav-v2.nav-visitor .nav-collapse {
              height: calc(100vh - var(--nav-height));
          }
          #visitor-v2-top-navigation-container .top-nav-visitor-ia.nav-v2 .nav-right > li:not(:last-of-type) {
              border-bottom: none;
          }
          body.visitor-nav-is-open #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-header-wrapper {
              transform: none;
              display: block;
          }
          body.visitor-nav-is-open #visitor-v2-top-navigation-container .top-nav-visitor-ia .auth-links > a {
              display: none;
          }
          body.visitor-nav-is-open #visitor-v2-top-navigation-container .top-nav-visitor-ia .auth-links > a.d-none {
              display: inline !important;
          }
          body.visitor-nav-is-open #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-subnav-trigger-mobile {
              display: none;
          }
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .top-nav-visitor-ia.nav-external-subnav {
              margin-bottom: 0;
          }
          #visitor-v2-top-navigation-container .top-nav-visitor-ia.reduced-padding .nav-item {
              padding-left: var(--nav-ws-2x) !important;
              padding-right: var(--nav-ws-2x) !important;
          }
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-item-currency .nav-item-currency-label,
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-item-currency .nav-menu-item.nav-item {
          padding-left: 0 !important;
          padding-right: 0 !important;
      }
      @media (max-width: 991px) {
          #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-item-currency {
              width: auto;
          }
          #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-item-currency .nav-item-label {
              line-height: 14px !important;
          }
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .vs-width-100 {
          width: 100% !important;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-collapse .nav-right {
          order: 1;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-collapse .nav-right .nav-item .nav-item-label {
          line-height: 22px;
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-collapse .nav-right .nav-item .nav-item-label {
              padding-right: 3px;
          }
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-collapse .nav-right .nav-item {
              padding-right: var(--nav-ws-2x);
          }
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-collapse .nav-right .nav-caret {
          --size: 12px;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-collapse .nav-right .nav-caret svg {
          vertical-align: text-top;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-collapse .nav-left {
          order: 2;
          align-items: center;
          justify-content: flex-end;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-collapse .nav-left .login-link {
          white-space: nowrap;
          color: var(--nav-text);
          flex: 0 1;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-collapse .nav-left .login-link:hover {
          color: var(--nav-primary);
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-collapse .nav-left .navbar-cta-btn {
          padding: 8px var(--nav-ws-3x);
      }
      @media (min-width: 992px) and (max-width: 1199px) {
          #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-collapse .nav-left .nav-btn-icon.nav-search-catalog-clear {
              display: none;
          }
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .ia-link,
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-dropdown-menu .ia-link {
          display: block;
          line-height: 21px !important;
          padding: 6px var(--nav-ws-4x) 6px 0;
          font-weight: 300;
          color: var(--text-dark-on-inverse);
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .ia-link:hover,
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-dropdown-menu .ia-link:hover {
          color: var(--nav-primary);
          background-color: unset;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .ia-link-v2,
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-dropdown-menu .ia-link-v2 {
          display: block;
          line-height: 21px !important;
          padding: 6px var(--nav-ws-4x) 6px 0;
          font-weight: 300;
          color: var(--text-dark-on-inverse);
          border-radius: var(--radius-3x);
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .ia-link-v2:hover,
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-dropdown-menu .ia-link-v2:hover {
          color: var(--text-dark-on-inverse);
          background-color: var(--text-muted-on-inverse);
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .ia-link-v2.framed,
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-dropdown-menu .ia-link-v2.framed {
          overflow: hidden;
          box-shadow: 0 0 0 1px var(--text-muted-on-inverse);
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .ia-link-learn-more,
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-dropdown-menu .ia-link-learn-more {
          color: var(--nav-primary) !important;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .ia-link-learn-more:hover,
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-dropdown-menu .ia-link-learn-more:hover {
          color: var(--text-link-hover) !important;
          background-color: unset;
          text-decoration: underline;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia a.nav-item-mobile,
      #visitor-v2-top-navigation-container .top-nav-visitor-ia span.nav-item-mobile {
          color: var(--text-dark-on-inverse) !important;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-item-mobile {
          padding-top: var(--nav-ws-3x) !important;
          padding-bottom: 14px !important;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-item-mobile .up-icon {
          transition: transform 0.3s;
          color: var(--text-muted-on-inverse);
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-item-mobile .air3-icon {
          transition: transform 0.3s;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-item-mobile strong + span {
          font-size: 13px;
          margin-top: var(--nav-ws-1x);
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .ml-auto {
          margin-left: auto;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .white-space-cancel {
          white-space: normal !important;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .justify-content-center {
          justify-content: center !important;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .justify-space-between {
          justify-content: space-between !important;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .justify-flex-end {
          justify-content: flex-end !important;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .vertical-align-middle {
          vertical-align: middle !important;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .vertical-align-top {
          vertical-align: top !important;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .vs-text-sup {
          font-size: 6px;
          line-height: 16px;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .vs-text-small {
          font-size: 13px;
          line-height: 21px;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .vs-text-heading {
          font-size: 16px;
          line-height: 28px;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .vs-text-default {
          font-size: var(--nav-font-size);
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .vs-text-brow {
          border-top: 4px solid #000435;
          width: 50px;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 button.nav-item.nav-item-mobile[aria-expanded="true"] > * {
          position: relative;
          z-index: 1;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 button.nav-item.nav-item-mobile[aria-expanded="true"]:before {
          content: "";
          display: block;
          position: absolute;
          background-color: var(--text-muted-on-inverse);
          top: 0;
          bottom: 0;
          left: -15px;
          right: -15px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
          #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 #nav-right {
              width: 100%;
          }
          #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 #nav-right.search-is-open {
              transition: all 0.15s ease-out;
              white-space: nowrap;
              overflow: hidden;
              width: 0;
              opacity: 0;
          }
          #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .nav-search,
          #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .nav-search-catalog {
              min-width: 150px;
          }
          #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .nav-search-catalog form,
          #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .nav-search form {
              max-width: unset !important;
          }
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .nav-right .nav-dropdown,
          #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .nav-wrapper .nav-container {
              position: unset;
          }
          #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .nav-wrapper .nav-container {
              width: min(var(--nav-container-width), var(--nav-container-max-width));
          }
          #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .nav-wrapper .nav-container > ul > li.nav-dropdown {
              display: list-item;
          }
          #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .nav-right > .nav-dropdown > .nav-dropdown-menu {
              top: var(--nav-height);
              width: auto;
              right: 0;
              margin-top: 0;
              box-shadow: 0 2px 5px rgba(29, 67, 84, 0.2);
              border-radius: 0 0 var(--nav-dropdown-radius) var(--nav-dropdown-radius);
              border-top: var(--nav-visitor-secondary-border-top);
          }
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-header .mobile-sign-up-cta {
          padding: 8px 20px;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-collapsed-header {
          position: sticky;
          top: 0;
          z-index: 1;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-collapsed-header a,
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-header .auth-links a {
          color: var(--nav-text);
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-collapsed-header a:hover,
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-header .auth-links a:hover {
          color: var(--nav-primary);
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .ia-subitems-mobile {
          background: #fff;
          position: absolute;
          overflow: hidden;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .ia-subitems-mobile .nav-align {
          padding: 0 var(--nav-ws-3x);
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .ia-subitems-mobile .nav-collapsed-close-btn {
          padding: var(--nav-ws-3x);
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .ia-subitems-mobile .nav-collapsed-header {
          border-bottom: 1px solid var(--text-muted-on-inverse);
          background-color: var(--nav-bg);
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .ia-subitems-mobile .nav-collapsed-header .nav-item {
          margin-top: 2px;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .ia-subitems-mobile:not(.d-none) ~ li {
          display: none;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .ia-subitems-mobile ~ li {
          border: none !important;
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .ia-subitems-mobile > ul {
          overflow: auto;
          max-height: calc(100vh - 200px);
      }
      @media screen and (orientation: landscape) {
          #visitor-v2-top-navigation-container .top-nav-visitor-ia .ia-subitems-mobile > ul {
              max-height: calc(100vh - 65px);
          }
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .rotate-180 {
          transform: rotate(180deg);
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .rotate-right {
          transform: rotate(-90deg);
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-subnav-trigger-mobile {
          color: var(--nav-text);
          align-items: center;
          grid-gap: var(--nav-ws-1x);
          gap: var(--nav-ws-1x);
      }
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-subnav-trigger-mobile,
      #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-subnav-trigger-mobile .up-s-nav-icon {
          display: flex;
      }
      @media (max-width: 991px) {
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-collapse .nav-left,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-collapse .nav-left {
              display: none !important;
              visibility: hidden;
          }
          html[theme="air-2-76"] #visitor-v2-top-navigation-container #nav-main,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container #nav-main {
              margin-top: calc(var(--nav-height) + 1px);
              position: absolute !important;
              z-index: calc(var(--zindex-navbar) - 1);
          }
      }
      @media (max-width: 991px) and (max-height: 549px) {
          html[theme="air-2-76"] #visitor-v2-top-navigation-container #nav-main,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container #nav-main {
              position: static !important;
              margin-top: 1px;
              height: calc(100vh - var(--nav-height) - 80px);
              height: calc(100dvh - var(--nav-height) - 80px);
          }
      }
      @media (max-width: 991px) {
          html[theme="air-2-76"] #visitor-v2-top-navigation-container #nav-main .nav-right,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container #nav-main .nav-right {
              border-top: 0;
          }
      }
      html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-v2 .nav-right,
      html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-v2 .nav-right {
          justify-content: flex-start !important;
      }
      @media (min-width: 992px) {
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-v2 .nav-right .nav-dropdown .nav-dropdown-menu:before,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-v2 .nav-right .nav-dropdown .nav-dropdown-menu:before {
              display: none;
          }
      }
      html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-visitor.top-nav-visitor-ia .ia-link-learn-more,
      html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-visitor.top-nav-visitor-ia .ia-link-learn-more {
          color: var(--text-link) !important;
          text-decoration: underline;
      }
      html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-visitor.top-nav-visitor-ia .ia-link-learn-more:hover,
      html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-visitor.top-nav-visitor-ia .ia-link-learn-more:hover {
          text-decoration: none;
      }
      html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-visitor.top-nav-visitor-ia .subnav-more-links .nav-dropdown-menu a,
      html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-visitor.top-nav-visitor-ia .subnav-more-links .nav-dropdown-menu a {
          line-height: 22px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 #nav-right .nav-right,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 #nav-right .nav-right {
              justify-content: flex-start;
          }
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 #nav-right.search-is-open,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 #nav-right.search-is-open {
              flex-grow: 0 !important;
          }
      }
      html[theme="air-2-76"] #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .vs-color-text,
      html[theme^="air-3-0"] #visitor-v2-top-navigation-container .top-nav-visitor-ia.iav2 .vs-color-text {
          color: var(--text-dark-on-inverse);
      }
      html[theme="air-2-76"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu,
      html[theme^="air-3-0"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu {
          background: var(--nav-dropdown-bg);
          z-index: 0;
      }
      @media (min-width: 1200px) {
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu .nav-subnav .nav-secondary-item-title + li,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu .nav-subnav .nav-secondary-item-title + li {
              margin-left: var(--nav-ws-4x);
          }
      }
      html[theme="air-2-76"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu.nav-secondary-menu-nested .nav-secondary-children-container,
      html[theme^="air-3-0"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu.nav-secondary-menu-nested .nav-secondary-children-container {
          position: absolute;
          top: 100%;
          left: 0;
          width: var(--nav-container-width, 100%);
          display: none;
          height: auto;
          border-top: 1px solid var(--gray-04);
          background: var(--nav-bg);
          padding: var(--nav-ws-6x) 45px !important;
          box-shadow: 0 2px 5px rgba(29, 67, 84, 0.2);
      }
      html[theme="air-2-76"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu.nav-secondary-menu-nested .nav-secondary-children-container .nav-secondary-link-container,
      html[theme^="air-3-0"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu.nav-secondary-menu-nested .nav-secondary-children-container .nav-secondary-link-container {
          padding: 0 0 12px;
      }
      html[theme="air-2-76"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu.nav-secondary-menu-nested .nav-secondary-item-children > a,
      html[theme^="air-3-0"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu.nav-secondary-menu-nested .nav-secondary-item-children > a {
          position: relative;
      }
      html[theme="air-2-76"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu.nav-secondary-menu-nested .nav-secondary-item-children > a:before,
      html[theme^="air-3-0"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu.nav-secondary-menu-nested .nav-secondary-item-children > a:before {
          content: "";
          display: block;
          position: absolute;
          top: 30px;
          left: 0;
          height: 25px;
          width: 100%;
      }
      html[theme="air-2-76"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu.nav-secondary-menu-nested .nav-secondary-item-children a,
      html[theme^="air-3-0"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu.nav-secondary-menu-nested .nav-secondary-item-children a {
          cursor: pointer;
          color: var(--nav-text);
      }
      html[theme="air-2-76"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu.nav-secondary-menu-nested .nav-secondary-item-children:hover .nav-secondary-children-container,
      html[theme^="air-3-0"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu.nav-secondary-menu-nested .nav-secondary-item-children:hover .nav-secondary-children-container {
          display: block;
      }
      html[theme="air-2-76"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu .nav-secondary-item-title,
      html[theme^="air-3-0"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu .nav-secondary-item-title {
          display: none;
      }
      @media (min-width: 1200px) {
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu .nav-secondary-item-title,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu .nav-secondary-item-title {
              display: flex;
              align-items: center;
          }
      }
      html[theme="air-2-76"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu .nav-secondary-item-title a,
      html[theme="air-2-76"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu .nav-secondary-item-title a:hover,
      html[theme^="air-3-0"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu .nav-secondary-item-title a,
      html[theme^="air-3-0"] #visitor-v2-top-navigation-container .top-nav-visitor-ia .nav-secondary-menu .nav-secondary-item-title a:hover {
          display: block;
          color: var(--text-dark-on-inverse);
          box-shadow: 1px 0 0 rgba(0, 0, 0, 0.25);
          padding: 0 var(--nav-ws-4x) 0 var(--nav-ws-2x);
      }
      html[theme="air-2-76"] #visitor-v2-top-navigation-container .earn-as-apro,
      html[theme^="air-3-0"] #visitor-v2-top-navigation-container .earn-as-apro {
          padding: 10px 16px;
      }
      #visitor-v2-top-navigation-container .nav-dropdown.login-mobile-dropdown {
          display: none !important;
      }
      @media (max-width: 991px) {
          #visitor-v2-top-navigation-container .nav-dropdown.login-mobile-dropdown {
              display: block !important;
          }
      }
      @media (max-width: 400px) {
          #visitor-v2-top-navigation-container .nav-hide-tiny {
              display: none;
          }
      }
      #visitor-v2-top-navigation-container .get-started {
          display: flex;
          align-items: center;
      }
      #visitor-v2-top-navigation-container .get-started .air3-icon {
          margin-left: 6px;
      }
      @media (max-width: 991px) and (orientation: landscape) {
          body:not(.visitor-nav-is-open) #visitor-v2-top-navigation-container .nav-collapse {
              height: 0 !important;
              padding: 0 !important;
          }
          body:not(.visitor-nav-is-open) #visitor-v2-top-navigation-container .navbar-cta {
              display: none !important;
          }
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-hide-search-subnav .nav-subnav {
              display: none !important;
          }
      }
      #visitor-v2-top-navigation-container .nav-hide-search-subnav .nav-search-v3 {
          display: none !important;
      }
      @media (max-width: 991.98px) {
          body.nav-is-open #visitor-v2-top-navigation-container .nav-v2 {
              --nav-toggle: none;
              min-height: 100vh;
          }
          body.nav-is-open #visitor-v2-top-navigation-container .nav-collapse {
              display: block !important;
          }
          body:not(.nav-is-open) #visitor-v2-top-navigation-container .nav-collapse {
              display: none;
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 {
          --nav-toggle: flex;
          margin-bottom: var(--nav-margin);
          background: var(--nav-bg);
          color: var(--nav-text);
          box-shadow: var(--nav-shadow);
          letter-spacing: var(--nav-letter-spacing);
      }
      @media (forced-colors: active) {
          #visitor-v2-top-navigation-container .nav-v2 {
              border-bottom: var(--border-hc);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .up-skip-nav {
          padding: 14px var(--nav-ws-4x);
          font-size: calc(var(--font-size-base) + 1px);
          background: var(--nav-bg);
          color: var(--nav-text-accent);
          z-index: calc(var(--zindex-navbar-fixed) + 1);
      }
      #visitor-v2-top-navigation-container .nav-v2 .up-skip-nav:focus {
          width: auto;
          height: auto;
          clip: auto;
          clip-path: none;
          outline-offset: -2px;
      }
      @media print {
          #visitor-v2-top-navigation-container .nav-v2 .up-skip-nav {
              display: none;
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-d-flex {
          display: flex !important;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-d-none {
          display: none !important;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-d-block {
          display: block !important;
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-d-lg-none {
              display: none !important;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-d-lg-block {
              display: block !important;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-d-lg-flex {
              display: flex !important;
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .flex-1 {
          flex: 1;
      }
      #visitor-v2-top-navigation-container .nav-v2 .align-items-center {
          align-items: center;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-container {
          position: relative;
          margin-left: auto;
          margin-right: auto;
          width: min(var(--nav-container-width), var(--nav-container-max-width));
          padding-right: var(--nav-container-padding);
          padding-left: var(--nav-container-padding);
      }
      #visitor-v2-top-navigation-container .nav-v2,
      #visitor-v2-top-navigation-container .nav-v2 button,
      #visitor-v2-top-navigation-container .nav-v2 input {
          font-family: Ubuntu, Helvetica Neue, Helvetica, Arial, sans-serif;
          font-size: var(--nav-font-size);
      }
      #visitor-v2-top-navigation-container .nav-v2 input::-moz-placeholder {
          opacity: 1;
      }
      #visitor-v2-top-navigation-container .nav-v2 :where(ul, li, button:not(.air3-btn)) {
          padding: 0;
          margin: 0;
      }
      #visitor-v2-top-navigation-container .nav-v2 button:not(.air3-btn, .up-skip-nav, .nav-btn-status, .nav-search-switch-btn) {
          background: transparent;
          border: 0;
          cursor: pointer;
      }
      #visitor-v2-top-navigation-container .nav-v2 ul {
          list-style: none;
      }
      #visitor-v2-top-navigation-container .nav-v2 a,
      #visitor-v2-top-navigation-container .nav-v2 a:focus,
      #visitor-v2-top-navigation-container .nav-v2 a:hover {
          text-decoration: none;
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2,
          #visitor-v2-top-navigation-container .nav-v2 .nav-header {
              min-height: var(--nav-height);
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-align {
              flex-direction: column;
              align-items: stretch !important;
              width: 100%;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-arrow-end:not(.nav-dropdown-account) .nav-caret {
              display: none;
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-collapse {
          display: flex;
          flex: 1;
          height: 100%;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-item {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          text-decoration: none;
          font-size: var(--nav-font-size);
          font-weight: var(--nav-font-weight);
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-item {
              text-align: left;
              text-size-adjust: 100%;
          }
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-item {
              justify-content: space-between;
              padding: var(--nav-link-padding-mobile) !important;
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-item-label {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 0;
          border-radius: var(--radius);
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-item-label {
              padding: var(--nav-link-padding-desktop);
          }
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-item-label {
              width: 100%;
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu) {
          display: none;
          top: 100%;
          left: 0;
          z-index: var(--nav-dropdown-zindex);
          width: 100%;
          max-height: none;
          min-width: 224px;
          margin-top: var(--nav-ws-1x);
          border-radius: var(--nav-dropdown-radius);
          background-clip: padding-box;
      }
      @media (forced-colors: active) {
          #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu) {
              border: var(--border-hc);
          }
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu) {
              margin: 0 0 var(--nav-ws-2x);
          }
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu) {
              background-color: var(--nav-dropdown-bg);
              position: absolute;
              box-shadow: var(--nav-dropdown-shadow);
              overflow: visible;
              padding: calc(var(--nav-ws-2x) - 3px) 0;
          }
          #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu):before {
              content: "";
              display: block;
              position: absolute;
              top: -7px;
              left: 28px;
              border: 8px solid var(--nav-dropdown-bg);
              border-color: transparent transparent var(--nav-dropdown-bg) var(--nav-dropdown-bg);
              transform: translateX(-50%) rotate(135deg);
              box-shadow: -2px 2px 3px rgba(57, 73, 76, 0.1);
          }
      }
      @media (min-width: 992px) and (forced-colors: active) {
          #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu):before {
              content: none;
          }
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu):after {
              content: "";
              display: block;
              position: absolute;
              top: -15px;
              width: 100%;
              height: 15px;
              z-index: 0;
          }
          #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu) .nav-bubble:before {
              color: var(--nav-dropdown-bg) !important;
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu) li .nav-menu-item {
          display: block;
          padding: calc(var(--nav-ws-1x) + 1px) var(--nav-ws-3x);
          color: var(--nav-dropdown-link-text);
          font-size: var(--nav-dropdown-font-size);
          font-weight: var(--nav-dropdown-font-weight);
          width: 100%;
          text-align: left;
      }
      #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu) li .nav-menu-item,
      #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu) li .nav-menu-item div {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
      }
      #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu) li .nav-menu-item:hover {
          background-color: var(--nav-dropdown-link-bg-hover) !important;
          color: var(--nav-dropdown-link-text-hover);
      }
      @media (forced-colors: active) {
          #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu) li .nav-menu-item:hover {
              forced-color-adjust: none;
          }
          #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu) li .nav-menu-item:hover .up-s-nav-icon {
              --nav-icon-text: var(--hc-text-on-selected);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu) li.active .nav-menu-item {
          font-weight: var(--nav-dropdown-active-font-weight);
      }
      @media (forced-colors: active) {
          #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu) li.active .nav-menu-item {
              forced-color-adjust: none;
              background: var(--hc-bg-selected);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu) li.disabled a,
      #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu) li.disabled a:focus,
      #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu) li.disabled a:hover {
          color: var(--text-on-disabled);
          background-color: initial;
          cursor: not-allowed;
      }
      #visitor-v2-top-navigation-container .nav-v2 :where(.nav-dropdown-menu):not(.nav-dropdown-search):not(.nav-search-switch-dropdown) li.active .nav-menu-item {
          color: var(--nav-dropdown-link-text-selected);
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-dropdown {
          position: relative;
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-dropdown {
              display: flex;
              align-items: center;
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-dropdown.open .nav-dropdown-menu {
          display: block;
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-dropdown.open .nav-item {
              color: var(--nav-link-text-selected);
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-dropdown.open :where(.nav-icon) {
              --icon-color: var(--text-icon-accent);
          }
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 {
              height: var(--nav-height);
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-container {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              height: 100%;
          }
          #visitor-v2-top-navigation-container .nav-v2 .mw-350 {
              min-width: 350px !important;
          }
          #visitor-v2-top-navigation-container .nav-v2 .mw-240 {
              min-width: 240px !important;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-dropdown-scroll {
              overflow-y: auto;
              max-height: 375px;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-arrow-end .nav-dropdown-menu {
              left: auto;
              right: calc(var(--nav-ws-2x) * -1);
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-arrow-end .nav-dropdown-menu:before {
              left: auto;
              right: 15px;
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-item([aria-expanded="true"]) .nav-caret {
          transform: rotate(180deg);
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-caret {
          transition: transform 75ms ease-in;
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-caret:not(.nav-caret-sm) {
              display: none;
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-caret.nav-caret-open {
          transform: rotate(180deg);
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-dropdown-caret-btn {
          margin-top: 3px;
          margin-left: -7px;
          margin-right: 7px;
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-dropdown-caret-btn {
              display: none;
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-unread-placeholder {
          display: none;
          width: 20px;
      }
      body.kb-nav #visitor-v2-top-navigation-container .nav-v2 :focus {
          outline: 2px solid var(--text-outline);
      }
      body.kb-nav #visitor-v2-top-navigation-container .nav-v2 .nav-brand,
      body.kb-nav #visitor-v2-top-navigation-container .nav-v2 input {
          outline-offset: 3px;
      }
      body.kb-nav #visitor-v2-top-navigation-container .nav-v2 .nav-popover-close-btn {
          --outline-offset: -3px;
          --text-outline: var(--text-outline-accent);
      }
      @media (min-width: 992px) {
          body.kb-nav #visitor-v2-top-navigation-container .nav-v2 .nav-menu-item {
              outline-offset: -2px;
          }
      }
      body:not(.kb-nav) #visitor-v2-top-navigation-container .nav-v2 :focus {
          outline: 0;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-header {
          position: relative;
          justify-content: space-between;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-toggle {
          position: relative;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-toggle .nav-dot {
          top: calc(var(--nav-ws-1x) * -1) !important;
          right: -4px !important;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-toggle-inner {
          --hamburger-size: 24px;
          --hamburger-bg: var(--nav-icon-text);
          --hamburger-line-size: 2px;
          --hamburger-before-top: 4;
          --hamburger-after-top: 7;
          --hamburger-close-pos: 4;
          --hamburger-close-after-pos: -7;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-toggle-inner,
      #visitor-v2-top-navigation-container .nav-v2 .nav-toggle-inner:after,
      #visitor-v2-top-navigation-container .nav-v2 .nav-toggle-inner:before {
          width: var(--hamburger-size);
          height: var(--hamburger-line-size);
          background-color: var(--hamburger-bg);
          transition: transform 0.15s ease;
          border-radius: 10px;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-toggle-inner:after,
      #visitor-v2-top-navigation-container .nav-v2 .nav-toggle-inner:before {
          content: "";
          position: relative;
          display: block;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-toggle-inner:before {
          top: calc(var(--hamburger-line-size) * var(--hamburger-before-top));
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-toggle-inner:after {
          top: calc(var(--hamburger-line-size) * var(--hamburger-after-top));
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-toggle-inner.is-active {
          --hamburger-bg: var(--nav-icon-hamburger-selected);
          --hamburger-active-line-size: 3px;
          transform: translateY(calc(var(--hamburger-line-size) * var(--hamburger-close-pos))) rotate(45deg);
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-toggle-inner.is-active:after {
          transform: translateY(calc(var(--hamburger-line-size) * var(--hamburger-close-after-pos))) rotate(-90deg);
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-toggle-inner.is-active:before {
          display: none;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-toggle-box {
          width: var(--hamburger-size);
          height: 20px;
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-brand {
              margin-right: var(--nav-ws-5x);
          }
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-brand.has-partnership {
              margin-right: var(--nav-ws-1x);
          }
      }
      #visitor-v2-top-navigation-container .nav-logo {
          display: inline-flex;
          width: var(--nav-logo-width);
      }
      #visitor-v2-top-navigation-container .nav-logo svg {
          width: 100%;
          height: 100%;
      }
      @media (forced-colors: active) {
          #visitor-v2-top-navigation-container .nav-logo path,
          #visitor-v2-top-navigation-container .nav-logo polygon,
          #visitor-v2-top-navigation-container .nav-logo svg {
              fill: currentColor !important;
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 {
          --decoupled-search-bg: var(--bg-inverse);
          --decoupled-search-font-size: 14px;
          --decoupled-search-font-size-xs: 11px;
          --decoupled-search-font-size-sm: 14px;
          --decoupled-search-font-size-lg: 16px;
          --decoupled-search-font-size-xlg: 20px;
          --decoupled-search-font-weight: var(--font-weight-base);
          --decoupled-search-border: var(--border);
          --decoupled-search-link-text-selected: var(--text-link-navigation-selected);
          --decoupled-search-link-padding-mobile: calc(var(--decoupled-search-ws-4x) - 2px) 0;
          --decoupled-search-icon-text: var(--text-icon);
          --decoupled-search-dropdown-bg: var(--bg-inverse);
          --decoupled-search-dropdown-shadow: var(--shadow-popup);
          --decoupled-search-dropdown-link-bg-hover: var(--bg-hoverable-light-hover);
          --decoupled-search-dropdown-link-text: var(--text-dark-on-hoverable-light);
          --decoupled-search-dropdown-sub-label-text: var(--text-light-on-hoverable-light);
          --decoupled-search-dropdown-sub-label-font-size: var(--decoupled-search-font-size-xs);
          --decoupled-search-dropdown-radius: var(--radius-3x);
          --decoupled-search-ws-1x: 5px;
          --decoupled-search-ws-2x: 10px;
          --decoupled-search-ws-3x: 15px;
          --decoupled-search-ws-4x: 20px;
          --decoupled-search-ws-6x: 30px;
          --decoupled-search-ws-8x: 40px;
          --decoupled-search-input-container-size: var(--height-base);
          --decoupled-search-input-size: calc(var(--height-base) - var(--border-width-control-muted) * 2);
          --decoupled-search-input-size-mobile: var(--height-base);
          --decoupled-search-input-bg: var(--bg-control-muted);
          --decoupled-search-input-bg-hover: var(--bg-control-muted-hover-light);
          --decoupled-search-input-bg-selected: var(--bg-inverse);
          --decoupled-search-input-placeholder-text: var(--text-dark-on-control-muted);
          --decoupled-search-input-border-color: var(--border-color-control-muted);
          --decoupled-search-input-border-color-selected: var(--border-color-control-muted-selected);
          --decoupled-search-input-border-width: var(--border-width-control-muted);
          --decoupled-search-input-border: var(--decoupled-search-input-border-width) solid var(--decoupled-search-input-border-color);
          --decoupled-search-input-radius: 20px;
          --decoupled-search-search-icon-size: 20px;
          --decoupled-search-search-icons-text: var(--text-icon);
          --decoupled-search-search-item-bg: var(--bg-inverse);
          --decoupled-search-search-item-text: var(--text-light-on-inverse);
          --decoupled-search-search-item-text-selected: var(--brand-green);
      }
      @media (forced-colors: active) {
          #visitor-v2-top-navigation-container .nav-v2 {
              --decoupled-search-link-text-selected: var(--hc-bg-selected);
              --decoupled-search-icon-text: var(--hc-icon);
              --decoupled-search-input-border-color: var(--hc-text-on-btn);
              --decoupled-search-dropdown-link-text: var(--hc-text-link);
              --decoupled-search-dropdown-sub-label-text: inherit;
              --decoupled-search-search-item-bg: var(--hc-bg-base);
          }
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3 {
              --decoupled-search-search-icon-size: 24px;
              --decoupled-search-input-size: 32px;
              --decoupled-search-input-placeholder-text: var(--text-light-on-control-muted);
              --decoupled-search-input-border: 2px solid var(--decoupled-search-input-border-color-selected);
          }
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3 .nav-dropdown-menu {
              margin-top: var(--decoupled-search-ws-2x);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3 .nav-dropdown-menu:after,
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3 .nav-dropdown-menu:before {
          display: none;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3 .nav-dropdown-menu.is-open {
          display: block;
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3 .nav-dropdown-menu:hover .nav-menu-item.is-active:not(:hover) {
              background-color: initial;
          }
      }
      @media (min-width: 992px) and (forced-colors: active) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3 .nav-dropdown-menu:hover .nav-menu-item.is-active:not(:hover) {
              background: var(--hc-bg-selected);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3 .nav-dropdown-menu:focus-within .nav-menu-item.is-active {
          background-color: var(--decoupled-search-dropdown-link-bg-hover);
      }
      @media (forced-colors: active) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3 .nav-dropdown-menu:focus-within .nav-menu-item.is-active {
              --decoupled-search-dropdown-link-text: var(--hc-text-on-selected);
              --decoupled-search-icon-text: var(--hc-text-on-selected);
              forced-color-adjust: none;
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3 .nav-dropdown-menu:focus-within .nav-menu-item.is-active:not(:focus) {
          background-color: initial;
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3 .nav-dropdown-menu:focus-within .nav-menu-item:focus {
              background-color: var(--decoupled-search-dropdown-link-bg-hover);
          }
      }
      @media (min-width: 992px) and (forced-colors: active) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3 .nav-dropdown-menu:focus-within .nav-menu-item:focus {
              color: var(--hc-text-on-selected);
              background: var(--hc-bg-selected);
              forced-color-adjust: none;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3 .nav-dropdown-menu:focus-within .nav-menu-item:focus .up-s-nav-icon {
              --icon-color: var(--hc-text-on-selected);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-input-dropdown {
          grid-area: autoCompleteDropdown;
          overflow: auto;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-input-dropdown li .nav-menu-item {
          font-weight: 400;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-input-dropdown li .nav-menu-item .is-match {
          font-weight: var(--font-weight-strong);
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-input-dropdown li .nav-menu-item .up-s-nav-icon {
          position: relative;
          margin-right: var(--decoupled-search-ws-2x);
          top: 3px;
          --size: var(--decoupled-search-font-size-lg);
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-input-dropdown li .nav-menu-item .up-s-nav-icon {
              --size: var(--decoupled-search-search-icon-size);
              top: 6px;
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-input-dropdown li.try-searching-for {
          padding: var(--ws-smd) var(--ws-smd) var(--ws-xs);
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-input-dropdown {
              padding: var(--ws-sm) 0;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-input-dropdown li .nav-menu-item {
              padding: 8px 0;
              font-size: var(--decoupled-search-font-size-lg);
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-input-dropdown li.try-searching-for {
              padding: var(--ws-md) 0 var(--ws-sm);
              font-size: var(--decoupled-search-font-size-lg);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search {
          align-self: center;
          width: 100%;
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search {
              padding-right: var(--decoupled-search-ws-2x);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-input-container {
          position: relative;
          width: 100%;
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-input-container:focus-within :where(.nav-search-autosuggest-input),
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-input-container:hover :where(.nav-search-autosuggest-input) {
              background-color: var(--decoupled-search-input-bg-hover);
              border-color: var(--decoupled-search-input-bg-hover);
          }
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-form {
              display: flex;
              max-width: 380px;
              height: var(--decoupled-search-input-container-size);
              transition: max-width 0.2s ease-in-out;
              position: relative;
              margin-left: auto;
              border: var(--decoupled-search-input-border);
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-form:focus-within,
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-form:hover {
              background: var(--bg-control-muted-hover-dark);
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-form:focus-within .nav-search-switch-btn:before,
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-form:hover .nav-search-switch-btn:before {
              opacity: 0;
          }
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-form {
              display: grid;
              grid-template: "closeButton searchInput" "searchSwitch searchSwitch" "autoCompleteDropdown autoCompleteDropdown" 1fr / auto 1fr;
              width: 100%;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-form.is-expanded {
              padding: var(--ws-smd) var(--decoupled-search-ws-4x) 0 var(--decoupled-search-ws-4x);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-query-type {
          font-size: var(--decoupled-search-dropdown-sub-label-font-size);
          color: var(--decoupled-search-dropdown-sub-label-text);
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-option,
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-query-type {
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-option,
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-query-type {
              font-size: var(--decoupled-search-font-size-xlg);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-dropdown-container {
          grid-area: searchSwitch;
          position: relative;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-switch-btn {
          display: none;
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-switch-btn {
              display: flex;
              align-items: center;
              margin-left: auto;
              padding: var(--decoupled-search-ws-2x) var(--decoupled-search-ws-3x);
              font-weight: var(--decoupled-search-font-weight);
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-switch-btn:before {
              content: "";
              display: block;
              border-left: 1px solid var(--decoupled-search-input-border-color);
              height: calc(var(--decoupled-search-input-size) / 2);
              position: relative;
              right: var(--decoupled-search-ws-3x);
              opacity: 1;
              transition: opacity 0.1s ease-in-out;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-switch-btn .nav-caret {
              line-height: 0;
              margin-left: var(--decoupled-search-ws-1x);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-autosuggest-input,
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-form,
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-switch-btn {
          border-radius: var(--decoupled-search-input-radius);
          background-color: initial;
      }
      #visitor-v2-top-navigation-container .nav-v2 .search-wrapper .nav-search-autosuggest-input,
      #visitor-v2-top-navigation-container .nav-v2 .search-wrapper .nav-search-switch-btn {
          border: var(--decoupled-search-input-border);
          height: var(--decoupled-search-input-size);
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .search-wrapper .nav-search-autosuggest-input,
          #visitor-v2-top-navigation-container .nav-v2 .search-wrapper .nav-search-switch-btn {
              border-color: transparent;
          }
          #visitor-v2-top-navigation-container .nav-v2 .search-wrapper .nav-search-autosuggest-input:hover,
          #visitor-v2-top-navigation-container .nav-v2 .search-wrapper .nav-search-switch-btn:hover {
              background-color: var(--decoupled-search-input-bg-hover);
              border-color: var(--decoupled-search-input-bg-hover);
          }
          #visitor-v2-top-navigation-container .nav-v2 .search-wrapper .nav-search-autosuggest-input.is-open,
          #visitor-v2-top-navigation-container .nav-v2 .search-wrapper .nav-search-autosuggest-input:focus,
          #visitor-v2-top-navigation-container .nav-v2 .search-wrapper .nav-search-switch-btn.is-open,
          #visitor-v2-top-navigation-container .nav-v2 .search-wrapper .nav-search-switch-btn:focus {
              background-color: var(--decoupled-search-input-bg-selected);
              border-color: var(--decoupled-search-input-bg-selected);
              box-shadow: 0 0 0 var(--border-width-control-muted) var(--decoupled-search-input-border-color-selected);
          }
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2 .search-wrapper .nav-search-autosuggest-input,
          #visitor-v2-top-navigation-container .nav-v2 .search-wrapper .nav-search-switch-btn {
              height: var(--decoupled-search-input-size-mobile);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-autosuggest-input {
          grid-area: searchInput;
          width: 100%;
          font-size: var(--decoupled-search-font-size);
          font-weight: var(--font-weight-base);
          padding: 1px calc(var(--decoupled-search-ws-6x) + var(--decoupled-search-ws-1x)) 0 calc(var(--decoupled-search-search-icon-size) + var(--decoupled-search-ws-3x));
          background-image: none;
          background-clip: padding-box;
          -webkit-appearance: none;
          transition: all 0.15s ease-in-out;
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-autosuggest-input {
              width: calc(100% - var(--border-width-control-muted));
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-autosuggest-input:not(:focus)::placeholder {
              --decoupled-search-input-placeholder-text: var(--text-dark-on-control-muted);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-autosuggest-input::placeholder {
          color: var(--decoupled-search-input-placeholder-text);
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-autosuggest-input::-webkit-search-cancel-button,
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-autosuggest-input::-webkit-search-decoration {
          -webkit-appearance: none;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-btns {
          display: flex;
          position: absolute;
          top: 0;
          width: 100%;
          height: var(--decoupled-search-input-size);
          pointer-events: none;
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-btns {
              padding-left: var(--decoupled-search-ws-2x);
          }
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-btns {
              height: var(--decoupled-search-input-size-mobile);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-btns .nav-btn-icon {
          height: 100%;
          line-height: 0;
          color: var(--decoupled-search-search-icons-text);
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-btns .nav-search-icon {
          --size: var(--decoupled-search-search-icon-size);
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-btns > button,
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-btns > ul {
          pointer-events: auto;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-clear {
          margin-left: auto;
          margin-right: var(--decoupled-search-ws-1x);
          order: 3;
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-clear {
              margin-right: var(--decoupled-search-ws-2x);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-clear .up-s-nav-icon {
          color: var(--decoupled-search-icon-text);
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-switch-dropdown {
              left: calc(var(--decoupled-search-ws-4x) * -1 + var(--decoupled-search-ws-1x) * -1);
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-switch-dropdown li .nav-menu-item {
              cursor: pointer;
              text-align: left;
              line-height: 20px;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-switch-dropdown li .nav-menu-item .up-s-nav-icon {
              margin-top: 1px;
              margin-right: var(--decoupled-search-ws-1x);
              vertical-align: text-top;
              float: left;
          }
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-switch-dropdown {
              grid-gap: calc(var(--decoupled-search-ws-4x) + var(--decoupled-search-ws-1x));
              border: 0;
              border-bottom: var(--decoupled-search-border);
              border-radius: 0;
              margin: var(--ws-lg) 0 0;
              display: flex !important;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-switch-dropdown li .nav-menu-item,
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-switch-dropdown li .nav-menu-item:hover {
              background: var(--decoupled-search-search-item-bg);
              color: var(--decoupled-search-search-item-text);
              padding: 0;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-switch-dropdown li .nav-menu-item.is-active {
              color: var(--decoupled-search-search-item-text-selected);
              border-bottom: 2px solid var(--decoupled-search-search-item-text-selected) !important;
              background-color: var(--decoupled-search-search-item-bg) !important;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-switch-dropdown .nav-search-query-type,
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-switch-dropdown .up-s-nav-icon {
              display: none;
          }
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-btn {
              position: relative;
              left: var(--decoupled-search-ws-2x);
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-toggle {
              min-width: 24px;
              display: flex;
              align-items: center;
              grid-area: closeButton;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-toggle[aria-expanded="true"] {
              margin-right: var(--decoupled-search-ws-1x);
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-expanded {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: var(--zindex-navbar);
              display: flex;
              grid-gap: var(--decoupled-search-ws-2x);
              background-color: var(--decoupled-search-bg);
              animation: nav-search-expand 0.25s forwards;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-collapsed .nav-search-dropdown-container,
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-collapsed .nav-search-input-container,
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-collapsed .nav-search-input-dropdown {
              display: none !important;
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3-container {
          display: flex;
          flex-direction: column;
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3-container .nav-search-tablet-btn {
          display: none;
      }
      @media (min-width: 992px) and (max-width: 1199.98px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3-container {
              position: relative;
              align-items: flex-end;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3-container .nav-search-inline {
              display: none;
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-search-v3-container .nav-search-tablet-btn {
              display: block;
              line-height: 0;
              padding: var(--decoupled-search-ws-2x);
          }
      }
      @keyframes nav-search-expand {
          0% {
              left: 100%;
          }
          to {
              left: 0;
          }
      }
      #visitor-v2-top-navigation-container .up-s-nav-icon {
          --xs-size: 10px;
          --sm-size: 12px;
          --md-size: 24px;
          --size: var(--md-size);
          --icon-color: var(--nav-search-icon-text);
          display: inline-block;
          width: var(--size);
          height: var(--size);
      }
      #visitor-v2-top-navigation-container .up-s-nav-icon[size="xs"] {
          --size: var(--xs-size);
      }
      #visitor-v2-top-navigation-container .up-s-nav-icon[size="sm"] {
          --size: var(--sm-size);
      }
      #visitor-v2-top-navigation-container .nav-left {
          grid-gap: var(--nav-left-gap);
          gap: var(--nav-left-gap);
      }
      #visitor-v2-top-navigation-container .nav-v2 #nav-right,
      #visitor-v2-top-navigation-container .nav-v2 .nav-right {
          flex: 1;
          justify-content: flex-end;
      }
      @media (min-width: 992px) {
          #visitor-v2-top-navigation-container .nav-v2 #nav-right,
          #visitor-v2-top-navigation-container .nav-v2 .nav-right {
              grid-gap: var(--nav-right-gap);
              gap: var(--nav-right-gap);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .air3-search {
          flex: 1;
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2 .air3-search {
              display: none;
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-right > li .nav-item {
          color: var(--nav-link-text);
      }
      @media (max-width: 991.98px) and (forced-colors: active) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-right > li .nav-item[aria-expanded],
          #visitor-v2-top-navigation-container .nav-v2 .nav-right > li .nav-item[aria-expanded]:hover {
              color: var(--hc-text-on-btn);
          }
      }
      @media (hover: hover) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-right > li .nav-item:hover {
              color: var(--nav-link-text-hover);
          }
      }
      #visitor-v2-top-navigation-container .nav-v2 .nav-right > li.active .nav-item {
          color: var(--nav-link-text-selected);
      }
      @media (max-width: 991.98px) {
          #visitor-v2-top-navigation-container .nav-v2 .nav-right > li:not(:last-of-type) {
              border-bottom: var(--nav-dropdown-border);
          }
          #visitor-v2-top-navigation-container .nav-v2 .nav-right > li .nav-caret,
          #visitor-v2-top-navigation-container .nav-v2 .nav-right > li .nav-icon {
              margin-right: var(--nav-ws-2x);
          }
      }

      #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 {
          position: relative;
          padding-bottom: 50px;
      }
      #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .center-block {
          display: block;
          margin-left: auto;
          margin-right: auto;
      }
      #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .content {
          width: 335px;
          border-right: 1px solid var(--text-muted-on-inverse);
      }
      #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .content .nav-dropdown.open > button {
          background-color: var(--bg-hoverable-light-hover);
      }
      #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .content .nav-dropdown.open:after {
          content: "";
          display: block;
          float: left;
      }
      #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .content .nav-dropdown.open .nav-dropdown-menu {
          display: block !important;
      }
      #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .content .nav-dropdown-menu {
          display: none !important;
          top: 0 !important;
          right: 0;
          width: auto;
          box-shadow: none;
      }
      #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .content .air3-icon,
      #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .content .up-icon {
          position: absolute;
          right: var(--nav-ws-4x);
      }
      #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .content .dm-items {
          flex: 1;
          flex-direction: column;
          flex-wrap: wrap;
      }
      #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown-menu {
          left: 360px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-0:after {
              height: 230px;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-0 .dm-items {
              height: 170px;
              margin-top: var(--nav-ws-4x);
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1:after,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2:after {
              height: 260px;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .dm-items,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .dm-items {
              margin-top: 25px;
              height: 280px;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .dm-items li,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .dm-items li {
              width: 280px;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .ia-link-v2,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .ia-link-v2 div,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .ia-link-v2,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .ia-link-v2 div {
              white-space: unset;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .ia-link-v2 .label-wrapper,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .ia-link-v2 .label-wrapper {
              width: 150px;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .ia-link-v2 .img-wrapper,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .ia-link-v2 .img-wrapper {
              width: 80px;
              height: 80px;
          }
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-0:after {
              height: 370px;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1:after,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2:after {
              height: 260px;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .dm-info,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .dm-info {
              width: 195px;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .dm-items,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .dm-items {
              height: 370px;
              margin-top: 50px;
              margin-left: 0;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .dm-items li,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .dm-items li {
              width: 33.333%;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .dm-item-1,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .dm-item-1 {
              order: 1;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .dm-item-2,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .dm-item-2 {
              order: 3;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .dm-item-3,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .dm-item-3 {
              order: 5;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .dm-item-4,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .dm-item-4 {
              order: 2;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .dm-item-5,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .dm-item-5 {
              order: 4;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .dm-item-6,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .dm-item-6 {
              order: 6;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .ia-link-v2,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .ia-link-v2 div,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .ia-link-v2,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .ia-link-v2 div {
              white-space: unset;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .ia-link-v2 span,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .ia-link-v2 span {
              text-align: center;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .ia-link-v2 .label-wrapper,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .ia-link-v2 .label-wrapper {
              height: 60px;
              max-width: 140px;
              justify-content: center;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .ia-link-v2 .img-wrapper,
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .ia-link-v2 .img-wrapper {
              min-height: 100px;
              width: 100%;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-0 .dm-info {
              width: 260px;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-0 .dm-items {
              margin-top: 50px;
              margin-left: 120px;
              padding-right: 120px;
          }
      }
      @media (min-width: 992px) {
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .content .nav-dropdown .nav-dropdown-menu,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .content .nav-dropdown .nav-dropdown-menu {
              overflow-x: hidden;
          }
      }
      @media (min-width: 1200px) {
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .dm-items,
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .dm-items,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .dm-items,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .dm-items {
              max-width: 575px;
          }
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .dm-info,
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .dm-info,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .dm-info,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .dm-info {
              width: 196px;
          }
      }
      @media (min-width: 992px) {
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown-menu,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown-menu {
              left: 375px;
          }
      }
      @media (min-width: 1400px) {
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .content,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .content {
              width: 415px;
          }
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown-menu,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown-menu {
              left: 455px;
          }
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-0 .dm-info,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-0 .dm-info {
              width: 360px;
          }
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .dm-info,
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .dm-info,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-1 .dm-info,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown.open.nav-dropdown-2 .dm-info {
              width: 225px;
              margin-right: 20px;
          }
      }
      @media (min-width: 1600px) {
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown-menu,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-dropdown .find-talent-v2 .nav-dropdown-menu {
              left: calc(-361px + 50vw);
          }
      }
      #visitor-v2-top-navigation-container .up-s-nav-icon {
          --xs-size: 10px;
          --sm-size: 12px;
          --md-size: 24px;
          --size: var(--md-size);
          --icon-color: var(--nav-icon-text);
          display: inline-block;
          width: var(--size);
          height: var(--size);
      }
      #visitor-v2-top-navigation-container .up-s-nav-icon[size="xs"] {
          --size: var(--xs-size);
      }
      #visitor-v2-top-navigation-container .up-s-nav-icon[size="sm"] {
          --size: var(--sm-size);
      }

      #visitor-v2-top-navigation-container .nav-dropdown .find-work-v2 {
          padding-bottom: var(--nav-ws-8x);
      }
      #visitor-v2-top-navigation-container .nav-dropdown .find-work-v2 .content .find-work-v2-items {
          padding-right: var(--nav-ws-8x);
      }
      #visitor-v2-top-navigation-container .nav-dropdown .find-work-v2 .content ul a {
          white-space: unset;
      }
      @media (min-width: 1200px) {
          #visitor-v2-top-navigation-container .nav-dropdown .find-work-v2 .content ul {
              width: 300px;
          }
      }
      @media (min-width: 992px) and (max-width: 1199px) {
          #visitor-v2-top-navigation-container .nav-dropdown .find-work-v2 .content.treatment-1 ul {
              width: 330px;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-work-v2 .content.treatment-2 {
              flex-direction: column;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-work-v2 .content .find-work-v2-items {
              height: 175px;
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
          }
          #visitor-v2-top-navigation-container .nav-dropdown .find-work-v2 .content .find-work-v2-items li {
              width: 50%;
          }
      }
      @media (min-width: 1400px) {
          html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-dropdown .find-work-v2 .content ul,
          html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-dropdown .find-work-v2 .content ul {
              width: 450px;
          }
      }

      #visitor-v2-top-navigation-container .nav-dropdown .why-upwork-v2 {
          padding-bottom: var(--nav-ws-8x);
      }
      #visitor-v2-top-navigation-container .nav-dropdown .why-upwork-v2 .content {
          justify-content: space-between;
      }
      #visitor-v2-top-navigation-container .nav-dropdown .why-upwork-v2 .why-upwork-v2-articles {
          width: 380px;
      }
      #visitor-v2-top-navigation-container .nav-dropdown .why-upwork-v2 .why-upwork-v2-items {
          height: 220px;
          width: 600px;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
          #visitor-v2-top-navigation-container .nav-dropdown .why-upwork-v2 .why-upwork-v2-items {
              height: 260px;
              width: 510px;
          }
      }
      #visitor-v2-top-navigation-container .nav-dropdown .why-upwork-v2 .why-upwork-v2-items li {
          width: 50%;
          padding-right: var(--nav-ws-8x);
          flex: 1;
      }
      #visitor-v2-top-navigation-container .nav-dropdown .why-upwork-v2 .why-upwork-v2-items a {
          white-space: unset;
      }
      html[theme="air-2-76"] #visitor-v2-top-navigation-container .nav-dropdown .why-upwork-v2 .content,
      html[theme^="air-3-0"] #visitor-v2-top-navigation-container .nav-dropdown .why-upwork-v2 .content {
          max-width: 1050px;
      }
      #visitor-v2-top-navigation-container .vs-subnav a {
          color: var(--gray-01) !important;
          white-space: pre-wrap;
          line-height: 24px;
          font-size: var(--nav-font-size);
          font-weight: 500;
          text-decoration: none !important;
      }
      #visitor-v2-top-navigation-container .vs-subnav a:hover {
          color: var(--nav-primary) !important;
      }
      #visitor-v2-top-navigation-container .vs-subnav a.hoverable.up-n-link:hover {
          text-decoration: underline !important;
      }
      


      .checkbox-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;
        user-select: none;
        & > * {
          margin: .5rem 0.5rem;
        }
      }
      
      
      
      .checkbox-group-legend {
        font-size: 1.5rem;
        font-weight: 700;
        color: #9c9c9c;
        text-align: center;
        line-height: 1.125;
        margin-bottom: 1.25rem;
      }
      
      .checkbox-input {
        clip: rect(0 0 0 0);
        clip-path: inset(100%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
      
        &:checked + .checkbox-tile {
          border-color: #03C165;
          box-shadow: 0 5px 10px rgba(#000, 0.1);
          color: #03C165;
          &:before {
            transform: scale(1);
            opacity: 1;
            background-color: #03C165;
            border-color: #03C165;
          }
          
          .checkbox-icon, .checkbox-label {
            color: #03C165;
          }
        }
        
        &:focus + .checkbox-tile {
          border-color: #03C165;
          box-shadow: 0 5px 10px rgba(#000, 0.1), 0 0 0 4px #b5c9fc;
          &:before {
            transform: scale(1);
            opacity: 1;
          }
        }
      }
      
      .checkbox-tile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 4rem;
        border-radius: 0.5rem;
        border: 2px solid #b5bfd9;
        background-color: #fff;
        box-shadow: 0 5px 10px rgba(#000, 0.1);
        transition: 0.15s ease;
        cursor: pointer;
        position: relative;
      
        &:before {
          content: "";
          position: absolute;
          display: block;
          width: 1.25rem;
          height: 1.25rem;
          border: 2px solid #b5bfd9;
          background-color: #fff;
          border-radius: 50%;
          top: 0.25rem;
          left: 0.25rem;
          opacity: 0;
          transform: scale(0);
          transition: 0.25s ease;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
          background-size: 12px;
          background-repeat: no-repeat;
          background-position: 50% 50%;
        }
      
        &:hover {
          border-color: #03C165;
          &:before {
            transform: scale(1);
            opacity: 1;
          }
        }
      }
      
      .checkbox-icon {
        transition: .375s ease;
        color: #494949;
        svg {
          width: 3rem;
          height: 3rem;
        }
      }
      
      .checkbox-label {
        color: #707070;
        transition: .375s ease;
        text-align: center;
      }
      
